import { EntityListType } from 'interfaces/SearchModel/Search';
import useToggleFavoriteSavedSearch from './useToggleFavoriteSavedSearch';
import useUpdateFavoriteList from './useUpdateFavoriteList';
/**
 * Hook to mutate favorites/pinned items
 * @param type EntityListType
 * @param id EntityId
 * @param isPinned if the item is already pinned, calls unpin mutation
 * @returns onClick handler that calls the appropriate mutation
 */
export const useFavoriteItem = (type, id, isPinned) => {
    const { toggleFavoriteMode } = useToggleFavoriteSavedSearch();
    const toggleFavoriteList = useUpdateFavoriteList({
        id,
        type,
        isPinned
    });
    const handleFavorite = () => {
        if (type === EntityListType.COMPANY_SAVED_SEARCH ||
            type === EntityListType.PEOPLE_SAVED_SEARCH) {
            return toggleFavoriteMode(id, isPinned);
        }
        else if (type === EntityListType.COMPANY_WATCHLIST ||
            type === EntityListType.PEOPLE_WATCHLIST) {
            return toggleFavoriteList();
        }
        else {
            throw new Error('Invalid EntityListType');
        }
    };
    return handleFavorite;
};
