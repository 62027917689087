import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SettingsGearIcon } from 'assets/harmonic-icons';
import { SectionDivider, SmallSectionHeader, SubSectionHeader } from 'components/QueryBuilder/Common/Typography';
import Button from 'harmonic-components/Button/Button';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { FilterGroupGeneratorId, SectorTagsRowTypes } from 'interfaces/SearchV2';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import { chipDisplayOrder } from './SectorPresets';
import SectorSectionTagsRowV2 from './SectorSectionTagsRowV2';
const SectorPresetsPill = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const [isExpanded, setIsExpanded] = React.useState(Boolean(controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET]?.preset));
    const [selectedPreset, setSelectedPreset] = React.useState('');
    React.useEffect(() => {
        setSelectedPreset(controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET]?.preset || '');
    }, [controlledSearchState]);
    const onSectorPresetClick = (selected, preset) => {
        analytics.trackCustomEvent({
            event: CustomTrackEvent.SECTOR_PRESET_CLICK,
            properties: {
                selected,
                preset
            }
        });
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET, !selected ? { preset: preset } : undefined));
    };
    if (!isExpanded) {
        return (_jsx("div", { className: "flex", children: _jsx(Button, { type: "secondary", leadingIcon: SettingsGearIcon, emphasis: "high", label: "Sector filters", onClick: () => setIsExpanded(true), fullWidth: false }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(SectionDivider, {}), _jsx(SmallSectionHeader, { children: "Sector tags" }), _jsxs("div", { className: "flex flex-col gap-g70", children: [_jsx(SubSectionHeader, { children: "Industry" }), _jsx(SectorSectionTagsRowV2, { onUpdateSearchQuery: onUpdateSearchQuery, searchQuery: searchQuery, rowType: SectorTagsRowTypes.MARKET_VERTICAL, disabled: disabled }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Technology" }), _jsx(SectorSectionTagsRowV2, { onUpdateSearchQuery: onUpdateSearchQuery, searchQuery: searchQuery, rowType: SectorTagsRowTypes.TECHNOLOGY_TYPE, disabled: disabled })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Customer type" }), _jsx(SectorSectionTagsRowV2, { onUpdateSearchQuery: onUpdateSearchQuery, searchQuery: searchQuery, rowType: SectorTagsRowTypes.CUSTOMER, disabled: disabled })] }), _jsx(SubSectionHeader, { children: "Sector presets" }), _jsx("div", { className: "flex flex-col gap-g50", children: _jsx("div", { className: "flex gap-g20 flex-wrap", children: chipDisplayOrder.map((chipMeta) => (_jsx(RadioPill, { label: chipMeta.title, selected: selectedPreset === chipMeta.sectorPreset, leadingIcon: chipMeta.icon, onClick: () => onSectorPresetClick(selectedPreset === chipMeta.sectorPreset, chipMeta.sectorPreset) }, chipMeta.title))) }) })] })] }));
};
export default SectorPresetsPill;
