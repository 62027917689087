import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isNil } from 'lodash';
const SegmentedControl = ({ selectedOption, options, onChange, variant = 'default' }) => {
    return (_jsx("div", { className: classNames('inline-flex p-p10 bg-surface-raised', variant === 'default' && 'rounded-br90', variant === 'compact' && 'rounded-br20', variant === 'full-width' && 'w-full rounded-br30'), children: options.map((option) => {
            const LeadingIcon = option.leadingIcon;
            const badgeCount = option.badgeCount;
            return (_jsxs("button", { disabled: option.disabled, onClick: () => onChange(option.value), "aria-selected": option.value === selectedOption, "data-testid": `segmented-control-${option.value}`, className: classNames('text-content-default typography-label bg-segmentedControl-enabled flex gap-g10 items-center', 'hover:bg-segmentedControl-hover active:bg-segmentedControl-pressed focus:shadow-int-focus-outside', 'aria-selected:bg-segmentedControl-selected aria-selected:shadow-int-elevation-enabled aria-selected:hover:shadow-int-elevation-hover', 'disabled:text-content-muted disabled:aria-selected:shadow-int-elevation-disabled disabled:aria-selected:text-content-muted', variant === 'default' && 'rounded-br90 px-p50 py-p30', variant === 'compact' && 'rounded-br20 px-p40 py-p20', variant === 'full-width' &&
                    'w-full rounded-br30 m-p10 justify-center'), children: [LeadingIcon && _jsx(LeadingIcon, { className: "w-4 h-4" }), _jsx("span", { className: "px-p20", children: option.label }), !isNil(badgeCount) && (_jsx("span", { className: "px-p20 bg-surface-sentiment-neutral typography-label-small rounded-br20", "data-testid": `badge-count-${option.label}`, children: badgeCount }))] }, option.value));
        }) }));
};
export default SegmentedControl;
