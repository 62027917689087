import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { DateRangeShortHand } from 'interfaces/SearchV2';
import { isCustomRangeMode } from 'utils/searchV2/searchV2';
import CustomRange from './CustomRange/CustomRange';
import { DateTypeEnum } from './CustomRange/DateModal';
const options = [
    {
        label: 'Any',
        value: undefined
    },
    {
        label: `Since ${new Date().getFullYear()}`,
        value: DateRangeShortHand.PAST_YEAR
    },
    {
        label: `Since ${new Date().getFullYear() - 1}`,
        value: DateRangeShortHand.PAST_TWO_YEARS
    }
];
const FoundingDateSelect = ({ selected, dataTestId, customRangeValue, onChange }) => {
    const isCustomMode = isCustomRangeMode(selected);
    return (_jsxs("div", { "data-testid": dataTestId, className: "flex flex-row flex-wrap gap-g40", children: [options.map((option) => {
                return (_jsx(RadioPill, { label: option.label, selected: selected === option.value, onClick: () => onChange(option.value) }, option.value));
            }), _jsx(CustomRange, { mode: isCustomMode ? selected : undefined, value: customRangeValue, onChange: (mode, value) => {
                    onChange(mode, value);
                }, dateType: DateTypeEnum.YEAR })] }));
};
export default FoundingDateSelect;
