import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import RangeDatePicker from './RangeDatePicker';
import SingleDatePicker from './SingleDatePicker';
import './datepicker.css';
const DatePicker = (props) => {
    if (props.type === 'single')
        return (_jsx(SingleDatePicker, { selectedDate: props.selectedDate, fullWidth: props.fullWidth, onChange: props.onChange }));
    return (_jsx(RangeDatePicker, { startDate: props.startDate, endDate: props.endDate, onChange: props.onChange }));
};
export default DatePicker;
