import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
const Accordion = ({ open, children }) => {
    const [childrenHeight, setChildrenHeight] = useState(0);
    const parentRef = useRef(null);
    useEffect(() => {
        if (parentRef.current) {
            setChildrenHeight(parentRef.current.clientHeight);
        }
    }, [parentRef.current, children]);
    return (_jsx("div", { "aria-expanded": open, className: classNames('overflow-hidden duration-300'), style: {
            maxHeight: open ? childrenHeight : 0
        }, children: _jsx("div", { ref: parentRef, children: children }) }));
};
export default Accordion;
