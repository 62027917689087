import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FilterIcon from 'assets/harmonic-icons/filter';
import classNames from 'classnames';
import { EntityListType } from 'interfaces/SearchModel/Search';
import * as React from 'react';
import { SearchFilterContext } from './SearchFilterGroupBase';
import SingleFilter from './SingleFilter/SingleFilter';
import { FilterConditionWrapper } from './styles';
import { getGroupTitle } from './utils';
const FilterQuery = ({ recursiveLevel, disabled, entityType, QuickFiltersComponent }) => {
    const searchFilterContext = React.useContext(SearchFilterContext);
    const searchGroup = searchFilterContext.rootSearchFilterGroup;
    const searchFilters = searchGroup.filters ?? [];
    const groupName = searchGroup.name;
    const descriptionText = entityType === EntityListType.COMPANY_SAVED_SEARCH ||
        entityType === EntityListType.PEOPLE_SAVED_SEARCH
        ? 'Add filters to refine your search results'
        : 'Add filters to refine your results';
    return (_jsxs(FilterConditionWrapper, { children: [searchFilters?.length === 0 && searchGroup?.filter_groups?.length === 0 && (_jsxs("div", { className: classNames('rounded-md w-full bg-surface-nested border-border flex text-center flex-col', {
                    'py-p100 border border-solid': recursiveLevel === 0,
                    'py-p50': recursiveLevel > 0
                }), children: [_jsxs("div", { className: "typography-title-extraSmall mx-auto", children: [_jsx(FilterIcon, { className: "inline-block mr-p30 text-content-default" }), "No filters"] }), _jsx("div", { className: "typography-label text-content-weak mt-p50", children: descriptionText }), QuickFiltersComponent] })), _jsxs("div", { children: [groupName && (_jsx("div", { className: "title typography-label", children: getGroupTitle(groupName) })), _jsx("div", { children: _jsx("div", { className: "container", children: searchFilters.map((filter, filterIndex) => {
                                return (_createElement(SingleFilter, { disabled: disabled, recursiveLevel: recursiveLevel, showWhereClause: filterIndex === 0, showJoinToggle: filterIndex === 1, ...filter, index: filterIndex, entityType: entityType, key: filter.key || `${filter.field}_${filterIndex}` }));
                            }) }) })] })] }));
};
export default FilterQuery;
