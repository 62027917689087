import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FlagIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Checkboxes from 'components/QueryBuilder/Common/Checkboxes';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Modal from 'harmonic-components/Modal/Modal';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { useState } from 'react';
import { submitDataIssue } from 'utils/hasuraApi';
import { displayToast } from 'utils/toasts';
import { Form } from './Form';
const DEFAULT_PLACEHOLDER_TEXT = 'e.g. This company shows 2 funding rounds, but there are actually 3';
export const ReportDataIssueLegalDisclaimer = (props) => {
    const { className } = props;
    return (_jsx("p", { className: classNames(className, 'typography-label-small-default text-content-weak'), children: "This feedback may be used to correct or verify our data." }));
};
const ReportDataIssue = ({ reportParams, placeholderText, commonlyReportedIssues }) => {
    //define state
    const [dialogOpen, setDialogOpen] = useState(false);
    const [textInput, setTextInput] = useState('');
    const [selectedCommonlyReportedIssues, setSelectedCommonlyReportedIssues] = useState([]);
    const [loading, setLoading] = useState(false);
    //define event handlers
    const onChangeSelectedCommonlyReportedIssues = (value) => {
        const index = selectedCommonlyReportedIssues.indexOf(value);
        if (index === -1) {
            //add value to array
            setSelectedCommonlyReportedIssues((prev) => [...prev, value]);
        }
        else {
            //remove value from array
            setSelectedCommonlyReportedIssues((prev) => {
                const newArray = [...prev];
                newArray.splice(index, 1);
                return newArray;
            });
        }
    };
    const onClose = () => {
        setDialogOpen(false);
        setSelectedCommonlyReportedIssues([]);
        setTextInput('');
        setLoading(false);
    };
    const onSubmit = async () => {
        setLoading(true);
        const reportText = selectedCommonlyReportedIssues.length > 0
            ? `[${selectedCommonlyReportedIssues.join(', ')}] ${textInput}`
            : textInput;
        try {
            submitDataIssue({ ...reportParams, reportText });
            displayToast({
                primaryText: 'Report submitted successfully',
                mode: 'success'
            });
        }
        catch (e) {
            displayToast({
                primaryText: 'There was an error submitting report',
                mode: 'error'
            });
        }
        finally {
            setLoading(false);
            onClose();
        }
    };
    const hasCommonlyReportedIssues = !!commonlyReportedIssues?.length;
    return (_jsxs("div", { "data-testid": "ReportDataIssue", children: [_jsx(IconButton, { icon: FlagIcon, onClick: () => setDialogOpen(true), type: "secondary", emphasis: "high", size: "default", dataTestId: "ReportDataIssue-Button" }), _jsx(Form, { onSubmit: onSubmit, children: _jsxs(Modal, { isOpen: dialogOpen, onClose: onClose, children: [_jsx(ModalTitle, { title: "Suggest a correction" }), _jsxs("div", { "data-testid": "ReportDataIssue-Popover", className: "p-p50", children: [hasCommonlyReportedIssues && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pb-p20", children: _jsx("p", { className: "typography-label-default text-content-weak", children: "Choose one or more of the commonly reported issues:" }) }), _jsx("div", { className: "flex flex-col gap-g20 pb-p30", children: _jsx(Checkboxes, { dataTestId: "ReportDataIssue-CommonlyReportedIssues-Checkboxes", options: commonlyReportedIssues, selectedValues: selectedCommonlyReportedIssues, onValueChange: (newValues) => setSelectedCommonlyReportedIssues(newValues ?? []), singleColumn: true }) }), _jsx("div", { className: "pb-p20", children: _jsx("p", { className: "typography-label-default text-content-weak", children: "Please provide any additional details." }) })] })), _jsx(TextArea, { value: textInput, onChange: (e) => setTextInput(e.target.value), placeholder: placeholderText ?? DEFAULT_PLACEHOLDER_TEXT, ...(hasCommonlyReportedIssues && { rows: 2 }) }), _jsx(ReportDataIssueLegalDisclaimer, { className: "pl-p10 pt-p30" })] }), _jsx(ModalFooter, { children: _jsx(Button, { dataTestId: "ReportDataIssue-Submit-Button", type: "primary", label: "Submit", isDisabled: loading, loading: loading, onClick: onSubmit }) })] }) })] }));
};
export default ReportDataIssue;
