import { useCallback } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
export const usePeopleSearchSort = () => {
    const { editFilterStore, peopleExplorePageSort } = useShallowFilterStore([
        'editFilterStore',
        'peopleExplorePageSort'
    ]);
    const changeSort = useCallback(() => ({ field, descending }) => {
        editFilterStore('peopleExplorePageSort', {
            sortField: field,
            descending: descending
        });
    }, [editFilterStore]);
    return {
        changeSort,
        sort: peopleExplorePageSort
    };
};
