import { jsx as _jsx } from "react/jsx-runtime";
import { TextareaAutosize } from '@material-ui/core';
import { ListCustomFieldType, PersonListCustomFieldValueMode } from '__generated__/graphql';
import classNames from 'classnames';
import SingleDatePicker from 'harmonic-components/DatePicker/SingleDatePicker';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useTeamMembers from 'hooks/useTeamMembers';
import { isNil } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { formatNumberListCustomFieldValue, isNumberAndNaN, parseNumberListCustomFieldValue } from 'utils/utilities';
import { getNthColor } from '../../../../utils/colors';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
import { LoadingCellRenderer } from './CellRenderers';
import { CustomFieldSelect } from './components/CustomFieldSelect';
import { formatSelectListCustomFieldMetadata } from './utils';
export const DEFAULT_HEIGHT = 54;
export const CustomColumnSingleSelectCellEditor = React.forwardRef((props, ref) => {
    const { onValueChange } = props;
    const [value, setValue] = React.useState(props.value);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleChange = useCallback((newValue) => {
        setValue(newValue);
        onValueChange?.(newValue);
        setSearchTerm('');
    }, [onValueChange]);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const gridContext = props.context;
    const handleUpdateOptions = useCallback(async (newOption) => {
        const newOptions = formatSelectListCustomFieldMetadata({
            newOption,
            options
        });
        if (props.colDef.headerName && gridContext.watchlistUrn) {
            const result = await gridContext.updateCustomField({
                watchlistUrn: gridContext.watchlistUrn,
                customFieldUrn: props.colDef.field,
                name: props.colDef.headerName,
                fieldType: ListCustomFieldType.SINGLE_SELECT,
                metadata: newOptions
            }, {
                updateEditColumnsConfig: false
            });
            const options = (result?.data?.updateCompanyWatchlistCustomField
                .metadata).options;
            setOptions(options);
            const newValue = options[options.length - 1].urn;
            handleChange(newValue);
        }
    }, [options, props.colDef, gridContext, handleChange]);
    const handleClick = useCallback((option) => {
        handleChange(option.urn);
        setTimeout(() => {
            props.api.stopEditing();
        }, 0);
    }, [props.api, handleChange]);
    const listItems = useMemo(() => {
        return filteredOptions.map((option) => {
            const onClick = () => {
                handleClick(option);
            };
            return (_jsx(SelectListItem, { label: option.name, value: option.urn, onClick: onClick, color: option.color, variant: ListVariant.tag, selected: option.urn === value }, option.urn));
        });
    }, [filteredOptions, value, handleClick]);
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value ? [value] : undefined, onAddNewOption: handleUpdateOptions, onRemove: () => {
                    handleChange(null);
                }, size: props.size, children: listItems }) }) }));
});
export const CustomColumnPersonSelectCellEditor = React.forwardRef((props, ref) => {
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const options = useMemo(() => {
        return (activeTeamMembers?.map((member, i) => ({
            urn: member?.user?.entityUrn,
            name: member?.user?.name,
            color: getNthColor(colorOptions, i)
        })) ?? []);
    }, [activeTeamMembers]);
    const mode = props?.mode;
    const [value, setValue] = React.useState(props.value ?? []);
    const [searchTerm, setSearchTerm] = React.useState('');
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleChange = (newVal) => {
        let formattedValue = newVal;
        if (mode === PersonListCustomFieldValueMode.SINGLE) {
            formattedValue =
                newVal.length > 0 ? [newVal[newVal.length - 1]] : newVal;
        }
        setValue(formattedValue);
        props.onValueChange?.(formattedValue);
    };
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options
        .filter((option) => option?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    const setAdditionalValue = (option) => {
        if (value.includes(option.urn)) {
            handleChange(value.filter((v) => v !== option.urn));
        }
        else {
            handleChange([...value, option.urn]);
        }
        setSearchTerm('');
    };
    const handeClick = (option) => {
        setAdditionalValue(option);
    };
    const listItems = useMemo(() => {
        return filteredOptions.map((option) => {
            const onClick = () => {
                handeClick(option);
            };
            return (_jsx(SelectListItem, { label: option.name, value: option.urn, onClick: onClick, color: option.color, variant: ListVariant.tag, selected: value.includes(option.urn) }, option.urn));
        });
    }, [filteredOptions, value, handeClick]);
    if (!activeTeamMembers || activeTeamMembers.length === 0) {
        return _jsx(LoadingCellRenderer, {});
    }
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value, onRemove: (val) => {
                    handleChange(value.filter((v) => v !== val));
                }, size: props.size, children: listItems }) }) }));
});
export const CustomColumnMultiSelectCellEditor = React.forwardRef((props, ref) => {
    const { onValueChange } = props;
    const [value, setValue] = React.useState(props.value ?? []);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    const gridContext = props.context;
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleChange = useCallback((newVal) => {
        setValue(newVal);
        onValueChange?.(newVal);
    }, [onValueChange]);
    const inputRef = useRef(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const setAdditionalValue = useCallback((option) => {
        if (value.includes(option.urn)) {
            handleChange(value.filter((v) => v !== option.urn));
        }
        else {
            handleChange([...value, option.urn]);
        }
        setSearchTerm('');
    }, [handleChange, value]);
    const handleUpdateOptions = useCallback(async (newOption) => {
        const newOptions = formatSelectListCustomFieldMetadata({
            newOption,
            options
        });
        if (props.colDef.headerName && gridContext.watchlistUrn) {
            const result = await gridContext.updateCustomField({
                watchlistUrn: gridContext.watchlistUrn,
                customFieldUrn: props.colDef.field,
                name: props.colDef.headerName,
                fieldType: ListCustomFieldType.SINGLE_SELECT,
                metadata: newOptions
            }, {
                updateEditColumnsConfig: false
            });
            const options = (result?.data?.updateCompanyWatchlistCustomField
                .metadata).options;
            setOptions(options);
            setAdditionalValue(options[options.length - 1]);
            setSearchTerm('');
            inputRef?.current?.focus();
        }
    }, [gridContext, options, props.colDef, setAdditionalValue]);
    const handleRemove = useCallback((val) => {
        handleChange(value.filter((v) => v !== val));
    }, [handleChange, value]);
    const handeClick = useCallback((option) => {
        setAdditionalValue(option);
    }, [setAdditionalValue]);
    const listItems = useMemo(() => {
        return filteredOptions.map((option) => {
            const onClick = () => {
                handeClick(option);
            };
            return (_jsx(SelectListItem, { label: option.name, value: option.urn, onClick: onClick, color: option.color, variant: ListVariant.tag, selected: value.includes(option.urn) }, option.urn));
        });
    }, [filteredOptions, value, handeClick]);
    return (_jsx("div", { style: { width }, children: _jsx("div", { className: "flex flex-col gap-g40 bg-surface-default", children: _jsx(CustomFieldSelect, { options: options, api: props.api, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: value, onAddNewOption: handleUpdateOptions, onRemove: handleRemove, inputRef: inputRef, dataTestId: "CustomColumnMultiSelectCellEditor-Select", size: props.size, children: listItems }) }) }));
});
export const CustomColumnTextCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value || '');
    const refInput = React.useRef(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    React.useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.setSelectionRange(refInput.current.value.length, refInput.current.value.length);
        }
    }, [refInput]);
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleChange = (e) => {
        setValue(e.target.value);
        props.onValueChange?.(e.target.value);
    };
    return (_jsx(TextareaAutosize, { onBlur: () => {
            refInput?.current?.focus();
        }, onFocus: (event) => {
            if (event.target) {
                event.target.scrollTop = event.target.scrollHeight;
            }
        }, onKeyDown: (e) => {
            if (e.code === 'Enter' && !e.shiftKey) {
                props.api.stopEditing();
            }
        }, autoFocus: true, className: classNames('bg-surface-default rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default', props.size === 'small' ? 'min-h-[28px] p-p20' : 'min-h-[77px] p-p50'), ...(props.size === 'small' && { minRows: 1 }), onChange: handleChange, ref: refInput, value: value, style: { width } }));
});
export const CustomColumnNumberCellEditor = React.forwardRef((props, ref) => {
    const num = props.value;
    const [value, setValue] = React.useState(isNil(num) || isNumberAndNaN(num) ? undefined : num?.toString());
    const refInput = React.useRef(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    React.useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.setSelectionRange(refInput.current.value.length, refInput.current.value.length);
        }
    }, [refInput]);
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleOnChange = (e) => {
        setValue(parseNumberListCustomFieldValue(e.target.value));
        props.onValueChange?.(parseNumberListCustomFieldValue(e.target.value));
    };
    const formattedNumber = formatNumberListCustomFieldValue(value, props.numberFormat, false);
    return (_jsx(TextareaAutosize, { onBlur: () => {
            refInput?.current?.focus();
        }, autoFocus: true, inputMode: "decimal", onKeyDown: (e) => {
            if (e.code === 'Enter') {
                props.api.stopEditing();
            }
        }, className: classNames('resize-none bg-surface-default rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default', props.size === 'small' ? 'min-h-[28px] p-p20' : 'min-h-[77px] p-p50'), ...(props.size === 'small' && { minRows: 1 }), onChange: handleOnChange, ref: refInput, value: formattedNumber, style: { width } }));
});
export const CustomColumnDateCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const dateFormat = props.dateFormat;
    const handleChange = (date) => {
        setValue(date);
        setTimeout(() => {
            props.api.stopEditing();
        }, 10);
        props.onValueChange?.(date);
    };
    return (_jsx("div", { className: "inline-block h-[77px]", style: { width }, children: _jsx(SingleDatePicker, { minHeight: 77, selectedDate: value, onChange: handleChange, native: false, dateFormat: dateFormat, alwaysOpen: true, fullWidth: true, autoFocus: true }) }));
});
