import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import { ReactComponent as BoxHandHoldingUsd } from 'assets/bxs-hand-holding-usd.svg';
import { ArrowRightIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { isNil } from 'lodash';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import React, { useMemo } from 'react';
import { getInvestorFundingRoundsMap, getSortedFundingRoundInvestors } from 'utils/funding';
import FundingTotal from '../Cards/FundingTotal';
import CompanyFinancingContentLoader from '../CompanyFinancing/CompanyFinancingContentLoader';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';
const CompanyOverviewFinancing = ({ companyId, onTabChange }) => {
    const { data } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const investorsMap = useMemo(() => {
        const investors = data?.getCompanyById?.funding?.investors ?? [];
        return investors.reduce((acc, investor) => {
            if (!investor?.name)
                return acc;
            acc[investor.name] = investor;
            return acc;
        }, {});
    }, [data?.getCompanyById?.funding?.investors]);
    const loading = isNil(data);
    const companyFunding = data?.getCompanyById?.funding;
    const fundingRounds = companyFunding?.fundingRounds ?? [];
    const investors = companyFunding?.investors ?? [];
    const filteredInvestors = investors?.filter((investor) => investor?.name !== '');
    const rbrExist = fundingRounds.length > 0;
    const noInvestorsExist = fundingRounds?.length === 0 && filteredInvestors?.length === 0;
    const investorsFundingRoundMap = useMemo(() => getInvestorFundingRoundsMap(fundingRounds), [fundingRounds]);
    const renderInvestorsWithRbr = () => {
        const sortedFundingRoundInvestors = getSortedFundingRoundInvestors(fundingRounds);
        return (_jsx("div", { className: "grid sm:grid-cols-2 gap-g50", children: sortedFundingRoundInvestors.slice(0, 4).map((investor) => {
                const investorName = investor?.investorName;
                const logoUrl = investorName
                    ? investorsMap[investorName]?.logoUrl
                    : '';
                const entityUrn = investor?.entityUrn ?? '';
                return (_jsx(InvestorCard, { investorName: investorName, logoUrl: logoUrl, entityUrn: entityUrn, dataTestId: "CompanyOverviewFinancing-Rbr-Investor", rounds: investorsFundingRoundMap?.[investor?.entityUrn] ?? [] }, investorName));
            }) }));
    };
    const renderInvestorsWithoutRbr = () => {
        return (_jsx("div", { "data-testid": "CompanyFinancing-Investors-Without-Rbr", className: "mt-6 grid grid-cols-2 gap-4", children: filteredInvestors.slice(0, 4).map((investor) => {
                const investorName = investor?.name;
                const logoUrl = investor?.logoUrl;
                const entityUrn = investor?.__typename === 'Company'
                    ? investor?.companyUrn
                    : investor?.personUrn;
                return (_jsx(InvestorCard, { investorName: investorName, logoUrl: logoUrl, entityUrn: entityUrn, dataTestId: "CompanyOverviewFinancing-NoRbr-Investor" }, investorName));
            }) }));
    };
    const renderFundingCards = () => {
        const totalFundingRounds = companyFunding?.numFundingRounds || 'Unknown';
        return (_jsxs("div", { className: "grid sm:grid-cols-3 gap-g60 my-g80", children: [_jsx(FundingTotal, { companyId: companyId }), _jsx(MetricCard, { icon: BoxBarChart, value: totalFundingRounds, label: "Funding rounds" }), _jsx(MetricCard, { icon: BoxHandHoldingUsd, value: investors?.length, label: "Investors" })] }));
    };
    const renderSeeAllButton = () => {
        if (!onTabChange)
            return null;
        return (_jsx(IconButton, { onClick: () => onTabChange(CompanyNavbarOptions.FINANCING), icon: ArrowRightIcon, size: "compact" }));
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex justify-between items-center pb-p70 border-b border-solid border-border", children: [_jsxs("div", { className: "flex items-center gap-g40", children: [_jsx("p", { className: "text-content-title typography-title-small", children: "Investors" }), _jsx(Badge, { color: "neutral", label: investors?.length?.toString() ?? '', size: "large" })] }), renderSeeAllButton()] }), loading ? (_jsx(CompanyFinancingContentLoader, {})) : (_jsxs(_Fragment, { children: [renderFundingCards(), !noInvestorsExist && (_jsx("div", { children: rbrExist
                            ? renderInvestorsWithRbr()
                            : renderInvestorsWithoutRbr() }))] }))] }));
};
export default CompanyOverviewFinancing;
