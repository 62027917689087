import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import { getBackgroundColorClassFromShorthandAndIntensity, getContentColorClassFromShorthandAndIntensity, getIconColorClassFromShorthandAndIntensity } from 'common/utils/design';
export const Badge = _ref => {
  let {
    size = 'medium',
    label,
    tooltipTitle,
    color = 'neutral',
    intensity = 'subtle',
    leadingIcon,
    trailingIcon,
    dataTestId,
    suffix,
    borderRadius = 'default'
  } = _ref;
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  const badgeContent = () => _jsxs("div", {
    "data-testid": dataTestId,
    className: classNames('inline-flex gap-g10 items-center justify-center', {
      'rounded-br90': borderRadius == 'default',
      'rounded-br20': borderRadius == 'square'
    }, {
      'py-p10 px-p40 typography-label-default-default min-w-[25px]': size == 'large',
      'py-p10 px-p40 typography-label-small-default': size == 'medium',
      'py-p00 px-p30 typography-label-small-default': size == 'small'
    }, getBackgroundColorClassFromShorthandAndIntensity(color, intensity), getContentColorClassFromShorthandAndIntensity(color, intensity)),
    children: [leadingIcon && _jsx("div", {
      className: "min-w-4",
      children: _jsx(LeadingIcon, {
        className: classNames({
          'h-4 w-4': size == 'large'
        }, {
          'h-3 w-3': size == 'medium'
        }, {
          'h-3 w-3': size == 'small'
        }, getIconColorClassFromShorthandAndIntensity(color, intensity))
      })
    }), label && _jsxs("p", {
      className: "line-clamp-1",
      children: [label, suffix && _jsxs("span", {
        className: classNames(getIconColorClassFromShorthandAndIntensity(color, intensity)),
        children: [' ', suffix]
      })]
    }), trailingIcon && _jsx(TrailingIcon, {
      className: classNames({
        'h-4 w-4': size == 'large'
      }, {
        'h-3 w-3': size == 'medium'
      }, {
        'h-3 w-3': size == 'small'
      }, getIconColorClassFromShorthandAndIntensity(color, intensity))
    })]
  });
  return tooltipTitle ? _jsx(Tooltip, {
    body: tooltipTitle,
    enterDelayMs: 400,
    children: badgeContent()
  }) : badgeContent();
};