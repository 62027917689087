import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import InternalLink from 'components/common/InternalLink';
import NetworkBadgeWithTooltip from 'components/common/Network/NetworkBadgeWithTooltip';
import Avatar from 'harmonic-components/Avatar/Avatar';
const EmployeeAttribute = ({ src, href, name, entityUrn, disabled = false, internalLink = false, isInNetwork = false }) => {
    const classes = classNames('flex items-start gap-g30 pl-p10 pr-p30 py-p10 rounded-br30', 'aria-disabled:bg-int-fill-secondary-disabled aria-disabled:cursor-default', href && 'cursor-pointer', !href && 'cursor-default', isInNetwork &&
        'bg-surface-sentiment-informative hover:bg-surface-sentiment-hover-informative', !isInNetwork &&
        'bg-int-fill-secondary-enabled hover:bg-int-fill-secondary-hover');
    const mainContent = (_jsxs("div", { className: "flex items-center gap-g20", children: [_jsx(Avatar, { size: "medium", kind: "face", src: src, alt: "person-profile-picture" }), _jsx("p", { className: "text-content-strong typography-label-default-default line-clamp-1", children: name }), isInNetwork && (_jsx("a", { href: href + '?utm_source=harmonic', target: "_blank", rel: "noopener noreferrer", children: _jsx(NetworkBadgeWithTooltip, { targetEntityUrn: entityUrn, targetName: name, variant: "attribute" }) }))] }));
    if (!internalLink)
        return (_jsx("a", { className: classes, "aria-disabled": disabled, href: href, target: "_blank", rel: "noopener noreferrer", children: mainContent }));
    return (_jsx(InternalLink, { to: { pathname: href }, children: _jsx("div", { className: classes, "data-testid": "employee-attribute", children: mainContent }) }));
};
export default EmployeeAttribute;
