import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { growthMetricsDisplayNames, HighlightCategoryMap } from 'utils/constants';
import { SortDepartment, SortGroup, SortTractionMetricGranularity, SortTractionMetricOption } from 'utils/sort';
import { formatCustomerType, formatSpecificTags, formatTags, shouldUseV2TagsEnum } from '../../../../utils/utilities';
import { CustomColumnTextCellEditor } from '../CellRenderers/CustomColumnCellRenderers';
import { suppressCellExitOnShiftEnter } from './utils';
export const COMPANY_HEADER_LEGAL_NAME = {
    field: 'company.legal_name',
    headerName: 'Legal name',
    hide: true,
    sortable: false,
    searchModelIdentifier: 'company_legal_name'
};
export const COMPANY_HEADER_DESCRIPTION = {
    field: 'company.description',
    headerName: 'Description',
    initialWidth: 400,
    sortable: false,
    cellRenderer: 'LongTextCellRenderer',
    searchModelIdentifier: 'company_description',
    filterModelIdentifier: 'company_keywords'
};
export const COMPANY_HEADER_EXTERNAL_DESCRIPTION = {
    field: 'company.external_description',
    headerName: 'External description',
    initialWidth: 400,
    sortable: false,
    cellRenderer: 'LongTextCellRenderer',
    searchModelIdentifier: 'company_external_description'
};
const COMPANY_HEADER_USER_NOTES = {
    field: 'company.user_custom_fields.custom_text',
    headerName: 'My notes',
    hide: false,
    initialWidth: 240,
    sortable: false,
    editable: true,
    customTooltip: 'Only you can see and edit this field',
    cellRenderer: 'CustomColumnTextCellRenderer',
    cellRendererParams: {
        showEmptyValue: true,
        emptyValueText: 'Add notes'
    },
    cellEditor: CustomColumnTextCellEditor,
    cellEditorPopup: true,
    suppressKeyboardEvent: suppressCellExitOnShiftEnter
};
const COMPANY_HEADER_TEAM_NOTES = {
    field: 'company.team_custom_fields.custom_text',
    headerName: 'Team notes',
    hide: false,
    initialWidth: 240,
    sortable: false,
    editable: true,
    cellRenderer: 'CustomColumnTextCellRenderer',
    cellRendererParams: {
        showEmptyValue: true,
        emptyValueText: 'Add notes'
    },
    customTooltip: 'All users in your organization can see and edit this field',
    cellEditor: CustomColumnTextCellEditor,
    cellEditorPopup: true,
    suppressKeyboardEvent: suppressCellExitOnShiftEnter
};
export const CommonCompanyDetailHeaders = [
    COMPANY_HEADER_USER_NOTES,
    COMPANY_HEADER_TEAM_NOTES,
    /**
     * * * * * * * * * * * *
     * Core Details        *
     * * * * * * * * * * * *
     */
    COMPANY_HEADER_LEGAL_NAME,
    {
        field: 'company.highlights',
        headerName: 'Company highlights',
        initialWidth: 500,
        sortable: false,
        wrapText: true,
        minWidth: 400,
        cellStyle: { whiteSpace: 'normal' },
        searchModelIdentifier: 'company_highlights',
        filterModelIdentifier: 'combined_highlight_categories',
        cellRenderer: 'CompanyHighlightsCellRenderer',
        equals: (a, b) => {
            return a?.company?.highlights.length === b?.company?.highlights.length;
        },
        valueGetter: (params) => {
            return (params.data.company?.highlights ?? []).map((highlight) => {
                if (highlight) {
                    return {
                        category: HighlightCategoryMap[highlight.category] || highlight.category,
                        text: highlight.text
                    };
                }
            });
        }
    },
    COMPANY_HEADER_DESCRIPTION,
    COMPANY_HEADER_EXTERNAL_DESCRIPTION,
    {
        field: 'company.person_relationships_founders_and_ceos',
        headerName: 'Founders & CEOs',
        sortable: false,
        initialWidth: 500,
        minWidth: 400,
        cellRenderer: 'PeopleListCellRenderer',
        equals: (a, b) => {
            return (a?.company?.person_relationships_founders_and_ceos.length ===
                b?.company?.person_relationships_founders_and_ceos.length);
        }
    },
    {
        field: 'company.person_relationships_executives',
        headerName: 'Executives',
        sortable: false,
        initialWidth: 500,
        minWidth: 400,
        cellRenderer: 'PeopleListCellRenderer',
        equals: (a, b) => {
            return (a?.company?.person_relationships_executives.length ===
                b?.company?.person_relationships_executives.length);
        }
    },
    {
        field: 'company.leadership_prior_companies',
        headerName: "Leadership's prior experiences",
        sortable: false,
        initialWidth: 500,
        minWidth: 400,
        cellRenderer: 'CompanyEmployeesExperienceCellRenderer',
        customTooltip: "The most recent companies that the company's leadership team (founders and executives) have worked at"
    },
    {
        field: 'company.person_relationships',
        headerName: 'Team',
        sortable: false,
        initialWidth: 500,
        minWidth: 400,
        cellRenderer: 'PeopleListCellRenderer',
        customTooltip: "The company's leadership team (founders and executives)",
        equals: (a, b) => {
            return (a?.company?.person_relationships.length ===
                b?.company?.person_relationships.length);
        }
    },
    {
        field: 'company.user_connections_overview.correspondence_summary.last_meeting_at',
        headerName: 'Last meeting date',
        initialWidth: 235,
        cellRenderer: 'CorrespondenceDateCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_customers_connections_last_meeting_at',
        filterModelIdentifier: 'company_customers_connections_last_meeting_at',
        sortContext: {
            sortGroup: SortGroup.LAST_MEETING_DATE
        }
    },
    {
        field: 'company.user_connections_overview.correspondence_summary.last_meeting_contact_person_email',
        headerName: 'Last meeting with',
        initialWidth: 235,
        cellRenderer: 'CorrespondencePersonCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_customers_connections_last_meeting_person_email',
        filterModelIdentifier: 'company_customers_connections_last_meeting_person_email',
        sortContext: {
            sortGroup: SortGroup.LAST_MEETING_WITH
        }
    },
    {
        field: 'company.user_connections_overview.correspondence_summary.last_email_at',
        headerName: 'Last email date',
        initialWidth: 235,
        cellRenderer: 'CorrespondenceDateCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_customers_connections_last_email_at',
        filterModelIdentifier: 'company_customers_connections_last_email_at',
        sortContext: {
            sortGroup: SortGroup.LAST_CONTACTED_DATE
        }
    },
    {
        field: 'company.user_connections_overview.correspondence_summary.last_email_contact_person_email',
        headerName: 'Last email with',
        initialWidth: 235,
        cellRenderer: 'CorrespondencePersonCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_customers_connections_last_email_person_email',
        filterModelIdentifier: 'company_customers_connections_last_email_person_email',
        sortContext: {
            sortGroup: SortGroup.LAST_CONTACTED_BY
        }
    },
    {
        field: 'company.user_connections',
        headerName: 'Team network',
        sortable: false,
        initialWidth: 300,
        cellRenderer: 'UserConnectionsCellRenderer',
        customTooltip: 'See who on your team has the best path into this company.',
        equals: (a, b) => {
            return (a?.company?.user_connections.length ===
                b?.company?.user_connections.length);
        }
    },
    {
        field: 'company.affinity_lists',
        headerName: 'Affinity lists',
        sortable: false,
        initialWidth: 200,
        cellRenderer: 'AffinityListsCellRenderer',
        customTooltip: 'See which of your integrated Affinity lists include this company.'
    },
    {
        field: 'company.lists',
        headerName: 'Lists',
        sortable: false,
        initialWidth: 400,
        cellRenderer: 'ListsCellRenderer',
        valueGetter: (params) => {
            const lists = [];
            lists.push(...(params.data.company?.watchlists?.map((watchlist) => ({
                name: watchlist?.name,
                isAffinityList: false,
                entityUrn: watchlist?.entityUrn
            })) ?? []));
            lists.push(...(params.data.company?.affinityLists?.map((affinityList) => ({
                name: affinityList?.name,
                isAffinityList: true
            })) ?? []));
            return lists;
        }
    },
    {
        field: 'company.person_linkedin_profiles',
        headerName: 'Team LinkedIn profiles',
        sortable: false,
        initialWidth: 500,
        minWidth: 400,
        cellRenderer: 'PeopleListCellRenderer',
        equals: (a, b) => {
            return (a?.company?.person_linkedin_profiles.length ===
                b?.company?.person_linkedin_profiles.length);
        }
    },
    {
        field: 'company.headcount',
        headerName: 'Headcount',
        hide: false,
        initialWidth: 130,
        cellRenderer: 'NumberCellRenderer',
        sortable: true,
        searchModelIdentifier: CompanyColumnsConfig.HEADCOUNT.searchModelIdentifier,
        filterModelIdentifier: 'company_headcount',
        sortContext: {
            sortGroup: SortGroup.HEADCOUNT
        }
    },
    {
        field: 'company.external_headcount',
        headerName: 'External headcount',
        hide: false,
        initialWidth: 130,
        cellRenderer: 'NumberCellRenderer',
        sortable: false
    },
    {
        field: 'company.founding_date',
        headerName: 'Founding date',
        initialWidth: 200,
        cellRenderer: 'DateCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_founding_date',
        filterModelIdentifier: 'company_founding_year',
        sortContext: {
            sortGroup: SortGroup.FOUNDING_DATE
        }
    },
    {
        field: 'company.typed_tags',
        headerName: 'Tags',
        initialWidth: 500,
        sortable: false,
        wrapText: true,
        cellStyle: { whiteSpace: 'normal' },
        searchModelIdentifier: 'typed_tags',
        filterModelIdentifier: 'typed_tag_values_v2',
        cellRenderer: 'TypedTagsCellRenderer',
        minWidth: 400,
        valueGetter: (params) => {
            return formatTags(params.data.company?.typed_tags, params.data.company?.typed_tags_v2, shouldUseV2TagsEnum.BOTH);
        }
    },
    {
        field: 'company.company_type',
        headerName: 'Company type',
        hide: false,
        sortable: false,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['UNKNOWN', 'STARTUP', null]
        },
        cellRenderer: 'CompanyTypeCellRenderer',
        searchModelIdentifier: 'company_type',
        filterModelIdentifier: 'company_type'
    },
    {
        field: 'company.stage',
        headerName: 'Stage',
        cellRenderer: 'StageCellRenderer',
        searchModelIdentifier: 'company_stage',
        filterModelIdentifier: 'company_funding_stage',
        sortable: false
    },
    /**
     * * * * * * * * * * * *
     * Location            *
     * * * * * * * * * * * *
     */
    {
        field: 'company.city',
        headerName: 'City',
        initialWidth: 150,
        hide: false,
        cellRenderer: 'LocationCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_city',
        filterModelIdentifier: 'company_city',
        sortContext: {
            sortGroup: SortGroup.CITY
        }
    },
    {
        field: 'company.state',
        headerName: 'State',
        initialWidth: 150,
        hide: false,
        cellRenderer: 'LocationCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_state',
        filterModelIdentifier: 'company_state',
        sortContext: {
            sortGroup: SortGroup.STATE
        }
    },
    {
        field: 'company.country',
        headerName: 'Country',
        initialWidth: 150,
        hide: false,
        cellRenderer: 'LocationCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_country',
        filterModelIdentifier: 'company_country',
        sortContext: {
            sortGroup: SortGroup.COUNTRY
        }
    },
    /**
     * * * * * * * * * * * *
     * Links               *
     * * * * * * * * * * * *
     */
    {
        field: 'company.website_url',
        headerName: 'Website',
        sortable: false,
        hide: false,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer',
        searchModelIdentifier: 'company_website_url',
        filterModelIdentifier: 'company_website_url'
    },
    {
        field: 'company.linkedin_url',
        headerName: 'LinkedIn',
        sortable: false,
        hide: false,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer',
        searchModelIdentifier: 'company_linkedin_url',
        filterModelIdentifier: 'company_linkedin_url'
    },
    /**
     * * * * * * * * * * * *
     * Funding             *
     * * * * * * * * * * * *
     */
    {
        field: 'company.num_funding_rounds',
        headerName: '# Funding rounds',
        sortable: false,
        cellRenderer: 'FundingCellRenderer',
        searchModelIdentifier: 'company_num_funding_rounds',
        filterModelIdentifier: 'company_num_funding_rounds'
    },
    {
        field: 'company.last_funding_at',
        headerName: 'Last funding date',
        initialWidth: 210,
        cellRenderer: 'FundingCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_last_funding_date',
        filterModelIdentifier: 'company_last_funding_date',
        sortContext: {
            sortGroup: SortGroup.LAST_FUNDING_DATE
        }
    },
    {
        field: 'company.last_funding_type',
        headerName: 'Last funding type',
        cellRenderer: 'StageCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_last_funding_type',
        filterModelIdentifier: 'company_last_funding_type',
        sortContext: {
            sortGroup: SortGroup.LAST_FUNDING_TYPE
        }
    },
    {
        field: 'company.last_funding_total',
        initialWidth: 250,
        headerName: 'Last funding round total',
        cellRenderer: 'FundingCellRenderer',
        sortable: true,
        searchModelIdentifier: 'company_last_funding_total',
        filterModelIdentifier: 'company_last_funding_total',
        sortContext: {
            sortGroup: SortGroup.LAST_FUNDING_TOTAL
        }
    },
    {
        field: 'company.funding_total',
        headerName: 'Funding total',
        sortable: false,
        cellRenderer: 'FundingCellRenderer',
        searchModelIdentifier: 'company_funding_total',
        filterModelIdentifier: 'company_funding_total'
    },
    {
        field: 'company.funding_per_employee',
        headerName: 'Funding per employee',
        sortable: false,
        cellRenderer: 'FundingCellRenderer',
        searchModelIdentifier: 'funding_per_employee',
        filterModelIdentifier: 'funding_per_employee'
    },
    {
        field: 'company.investors',
        headerName: 'Investors',
        sortable: false,
        initialWidth: 375,
        searchModelIdentifier: 'company_investors',
        filterModelIdentifier: 'company_investor_names',
        cellRenderer: 'InvestorsCellRenderer'
    },
    /**
     * * * * * * * * * * * *
     * Tags                *
     * * * * * * * * * * * *
     */
    {
        field: 'company.customer_type',
        headerName: 'Customer type',
        sortable: false,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['B2B', 'B2C', 'B2B & B2C', null]
        },
        searchModelIdentifier: 'company_customer_type',
        filterModelIdentifier: 'company_customer_type',
        cellRenderer: 'CustomerTypeCellRenderer',
        valueGetter: (params) => {
            return formatCustomerType(params.data.company?.customer_type, params.data.company?.typed_tags_v2, true);
        }
    },
    {
        field: 'company.industry_tags',
        headerName: 'Industry tags',
        sortable: false,
        hide: true,
        searchModelIdentifier: 'company_industry_tags',
        filterModelIdentifier: 'company_industry_tags',
        cellRenderer: 'TagsCellRenderer',
        valueGetter: (params) => {
            return formatSpecificTags(params.data.company?.typed_tags, ['INDUSTRY'], params.data.company?.typed_tags_v2, ['MARKET_VERTICAL'], shouldUseV2TagsEnum.BOTH);
        }
    },
    {
        field: 'company.technology_tags',
        headerName: 'Technology tags',
        sortable: false,
        hide: true,
        searchModelIdentifier: 'company_technology_tags',
        filterModelIdentifier: 'company_technology_tags',
        minWidth: 200,
        cellRenderer: 'TagsCellRenderer',
        valueGetter: (params) => {
            return formatSpecificTags(params.data.company?.typed_tags, ['TECHNOLOGY'], params.data.company?.typed_tags_v2, ['TECHNOLOGY_TYPE'], shouldUseV2TagsEnum.BOTH);
        }
    },
    {
        field: 'company.accelerators',
        headerName: 'Accelerators',
        sortable: false,
        hide: true,
        searchModelIdentifier: 'company_accelerator_tags',
        cellRenderer: 'AcceleratorsCellRenderer',
        valueGetter: (params) => {
            return formatSpecificTags(params.data.company?.typed_tags, ['YC_BATCH', 'PRODUCT_HUNT', 'ACCELERATOR'], params.data.company?.typed_tags_v2, ['YC_BATCH', 'PRODUCT_HUNT', 'ACCELERATOR'], shouldUseV2TagsEnum.BOTH);
        }
    },
    /**
     * * * * * * * * * * * *
     * Contact             *
     * * * * * * * * * * * *
     */
    {
        field: 'company.company_emails',
        headerName: 'Company emails',
        initialWidth: 270,
        sortable: false,
        cellRenderer: 'EmailsCellRenderer'
    },
    {
        field: 'company.team_emails',
        headerName: 'Team emails',
        initialWidth: 270,
        sortable: false,
        cellRenderer: 'EmailsCellRenderer'
    },
    {
        field: 'company.contact_numbers',
        headerName: 'Phone number',
        initialWidth: 270,
        sortable: false,
        cellRenderer: 'PhoneNumbersCellRenderer',
        hide: true
    },
    /**
     * * * * * * * * * * * *
     * Social Links        *
     * * * * * * * * * * * *
     */
    {
        field: 'company.twitter_url',
        headerName: 'Twitter',
        sortable: false,
        hide: false,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer',
        searchModelIdentifier: 'company_twitter_url',
        filterModelIdentifier: 'company_twitter_url'
    },
    {
        field: 'company.twitter_handle',
        headerName: 'Twitter handle',
        sortable: false,
        hide: false,
        initialWidth: 155,
        cellRenderer: 'LongTextCellRenderer',
        valueGetter: (params) => {
            return params.data.company?.socials?.twitter?.url?.split('https://twitter.com/')[1]
                ? `@${params.data.company?.socials?.twitter?.url?.split('https://twitter.com/')[1]}`
                : '';
        }
    },
    {
        field: 'company.crunchbase_url',
        headerName: 'Crunchbase',
        sortable: false,
        hide: false,
        initialWidth: 400,
        cellRenderer: 'LinkCellRenderer',
        searchModelIdentifier: 'company_crunchbase_url',
        filterModelIdentifier: 'company_crunchbase_url'
    },
    {
        field: 'company.pitchbook_url',
        headerName: 'Pitchbook',
        sortable: false,
        hide: false,
        initialWidth: 400,
        cellRenderer: 'LinkCellRenderer',
        searchModelIdentifier: 'company_pitchbook_url',
        filterModelIdentifier: 'company_pitchbook_url'
    },
    {
        field: 'company.instagram_url',
        headerName: 'Instagram',
        sortable: false,
        hide: false,
        initialWidth: 135,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.facebook_url',
        headerName: 'Facebook',
        sortable: false,
        hide: false,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.angellist_url',
        headerName: 'AngelList',
        sortable: false,
        hide: false,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.indeed_url',
        headerName: 'Indeed',
        sortable: false,
        hide: true,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.youtube_url',
        headerName: 'YouTube',
        sortable: false,
        hide: true,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.monster_url',
        headerName: 'Monster',
        sortable: false,
        hide: true,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    {
        field: 'company.stackoverflow_url',
        headerName: 'StackOverflow',
        sortable: false,
        hide: true,
        initialWidth: 300,
        cellRenderer: 'LinkCellRenderer'
    },
    /**
     * * * * * * * * * * * *
     * Social Data         *
     * * * * * * * * * * * *
     */
    {
        field: 'company.linkedin_follower_count',
        headerName: 'LinkedIn followers',
        sortable: false,
        hide: true,
        searchModelIdentifier: 'company_linkedin_follower_count',
        filterModelIdentifier: 'company_linkedin_follower_count',
        cellRenderer: 'NumberCellRenderer'
    },
    {
        field: 'company.twitter_total_followers',
        headerName: 'Twitter followers',
        sortable: false,
        hide: true,
        cellRenderer: 'NumberCellRenderer'
    },
    {
        field: 'company.instagram_num_followers',
        headerName: 'Instagram followers',
        sortable: false,
        hide: true,
        cellRenderer: 'NumberCellRenderer',
        filterModelIdentifier: 'company_instagram_follower_count'
    },
    {
        field: 'company.facebook_total_page_likes',
        headerName: 'Facebook page likes',
        sortable: false,
        hide: true,
        cellRenderer: 'NumberCellRenderer',
        filterModelIdentifier: 'company_facebook_like_count'
    }
];
const GrowthMetricsHeaderMetadata = [
    {
        id: 'headcount',
        displayName: growthMetricsDisplayNames['headcount'],
        showByDefault: true,
        widthPadding: 40,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.ALL
    },
    {
        id: 'headcount_advisor',
        displayName: growthMetricsDisplayNames['headcount_advisor'],
        showByDefault: false,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.ADVISOR
    },
    {
        id: 'headcount_customer_success',
        displayName: growthMetricsDisplayNames['headcount_customer_success'],
        showByDefault: false,
        widthPadding: 150,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.CUSTOMER_SUCCESS
    },
    {
        id: 'headcount_data',
        displayName: growthMetricsDisplayNames['headcount_data'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.DATA
    },
    {
        id: 'headcount_design',
        displayName: growthMetricsDisplayNames['headcount_design'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.DESIGN
    },
    {
        id: 'headcount_engineering',
        displayName: growthMetricsDisplayNames['headcount_engineering'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 150,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.ENGINEERING
    },
    {
        id: 'headcount_finance',
        displayName: growthMetricsDisplayNames['headcount_finance'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.FINANCE
    },
    {
        id: 'headcount_legal',
        displayName: growthMetricsDisplayNames['headcount_legal'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.LEGAL
    },
    {
        id: 'web_traffic',
        displayName: growthMetricsDisplayNames['web_traffic'],
        showByDefault: false,
        disablePrefixInSearchModelIdentifier: true,
        latestMetricFilterModelIdentifier: 'web_traffic',
        widthPadding: 100,
        sortGroup: SortGroup.WEB_TRAFFIC
    },
    {
        id: 'headcount_marketing',
        displayName: growthMetricsDisplayNames['headcount_marketing'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.MARKETING
    },
    {
        id: 'headcount_operations',
        displayName: growthMetricsDisplayNames['headcount_operations'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.OPERATIONS
    },
    {
        id: 'headcount_other',
        displayName: growthMetricsDisplayNames['headcount_other'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.OTHER
    },
    {
        id: 'headcount_people',
        displayName: growthMetricsDisplayNames['headcount_people'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.PEOPLE
    },
    {
        id: 'headcount_product',
        displayName: growthMetricsDisplayNames['headcount_product'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT
    },
    {
        id: 'headcount_sales',
        displayName: growthMetricsDisplayNames['headcount_sales'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.SALES
    },
    {
        id: 'headcount_support',
        displayName: growthMetricsDisplayNames['headcount_support'],
        showByDefault: false,
        isCurrentMetricValueMissing: true,
        widthPadding: 100,
        sortGroup: SortGroup.HEADCOUNT,
        department: SortDepartment.SUPPORT
    },
    {
        id: 'funding_total',
        displayName: growthMetricsDisplayNames['funding_total'],
        showByDefault: true,
        cellRenderer: 'FundingCellRenderer',
        sortGroup: SortGroup.FUNDING_TOTAL
    },
    {
        id: 'linkedin_follower_count',
        displayName: growthMetricsDisplayNames['linkedin_follower_count'],
        showByDefault: false,
        widthPadding: 100,
        sortGroup: SortGroup.LINKEDIN_FOLLOWERS
    },
    {
        id: 'facebook_page_like_count',
        displayName: growthMetricsDisplayNames['facebook_total_page_likes'],
        showByDefault: false,
        widthPadding: 100,
        sortGroup: SortGroup.FACEBOOK_LIKES,
        latestMetricFilterModelIdentifier: 'company_facebook_like_count'
    },
    {
        id: 'twitter_follower_count',
        displayName: growthMetricsDisplayNames['twitter_follower_count'],
        showByDefault: false,
        widthPadding: 100,
        sortGroup: SortGroup.TWITTER_FOLLOWERS,
        latestMetricFilterModelIdentifier: 'company_twitter_follower_count'
    },
    {
        id: 'instagram_follower_count',
        displayName: growthMetricsDisplayNames['instagram_follower_count'],
        showByDefault: false,
        widthPadding: 100,
        sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
        latestMetricFilterModelIdentifier: 'company_instagram_follower_count'
    }
];
export const GrowthMetricsHeaders = GrowthMetricsHeaderMetadata.flatMap((growthMetric) => {
    const prefix = growthMetric.disablePrefixInSearchModelIdentifier
        ? ''
        : 'company_';
    const width = 200;
    return [
        ...(growthMetric.isCurrentMetricValueMissing
            ? []
            : [
                {
                    field: `company.${growthMetric.id}`,
                    headerName: `${growthMetric.displayName}`,
                    hide: !growthMetric.showByDefault,
                    initialWidth: 200 + (growthMetric.widthPadding || 0),
                    sortable: true,
                    searchModelIdentifier: `${prefix}${growthMetric.id}`,
                    filterModelIdentifier: `${prefix}${growthMetric.id}`,
                    sortContext: {
                        sortGroup: growthMetric.sortGroup,
                        department: growthMetric.department,
                        tractionMetricOption: SortTractionMetricOption.TOTAL
                    },
                    cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer'
                }
            ]),
        {
            field: `company.traction_metrics.${growthMetric.id}.value_14d_ago`,
            headerName: `${growthMetric.displayName} (14d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
            // No filterModelIdentifier for this column
            searchModelIdentifier: `${prefix}${growthMetric.id}_value_14d_ago`,
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.TOTAL,
                tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.real_change_14d_ago`,
            headerName: `${growthMetric.displayName} ∆ (14d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_real_change_14d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_real_change_14d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.DELTA,
                tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.percent_change_14d_ago`,
            headerName: `${growthMetric.displayName} % (14d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_percent_change_14d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_percent_change_14d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.PERCENT,
                tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.value_30d_ago`,
            headerName: `${growthMetric.displayName} (30d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_value_30d_ago`,
            cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.TOTAL
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.real_change_30d_ago`,
            headerName: `${growthMetric.displayName} ∆ (30d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_real_change_30d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_real_change_30d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.DELTA,
                tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.percent_change_30d_ago`,
            headerName: `${growthMetric.displayName} % (30d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_percent_change_30d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_percent_change_30d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.PERCENT,
                tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.value_90d_ago`,
            headerName: `${growthMetric.displayName} (90d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_value_90d_ago`,
            cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.TOTAL
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.real_change_90d_ago`,
            headerName: `${growthMetric.displayName} ∆ (90d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_real_change_90d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_real_change_90d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.DELTA,
                tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.percent_change_90d_ago`,
            headerName: `${growthMetric.displayName} % (90d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_percent_change_90d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_percent_change_90d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.PERCENT,
                tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.value_180d_ago`,
            headerName: `${growthMetric.displayName} (180d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_value_180d_ago`,
            cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.TOTAL
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.real_change_180d_ago`,
            headerName: `${growthMetric.displayName} ∆ (180d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_real_change_180d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_real_change_180d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.DELTA,
                tractionMetricGranularity: SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.percent_change_180d_ago`,
            headerName: `${growthMetric.displayName} % (180d)`,
            hide: !growthMetric.showByDefault,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_percent_change_180d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_percent_change_180d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.PERCENT,
                tractionMetricGranularity: SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.value_365d_ago`,
            headerName: `${growthMetric.displayName} (365d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_value_365d_ago`,
            cellRenderer: growthMetric.cellRenderer || 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.TOTAL
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.real_change_365d_ago`,
            headerName: `${growthMetric.displayName} ∆ (365d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_real_change_365d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_real_change_365d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.DELTA,
                tractionMetricGranularity: SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
            }
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.latest_metric_value`,
            headerName: `${growthMetric.displayName}`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            ...(growthMetric.latestMetricFilterModelIdentifier && {
                filterModelIdentifier: growthMetric.latestMetricFilterModelIdentifier
            }),
            cellRenderer: 'DerivedMetricCellRenderer',
            sortable: false,
            sortContext: undefined
        },
        {
            field: `company.traction_metrics.${growthMetric.id}.percent_change_365d_ago`,
            headerName: `${growthMetric.displayName} % (365d)`,
            hide: true,
            initialWidth: width + (growthMetric.widthPadding || 0),
            sortable: true,
            searchModelIdentifier: `${prefix}${growthMetric.id}_percent_change_365d_ago`,
            filterModelIdentifier: `${prefix}${growthMetric.id}_percent_change_365d_ago`,
            cellRenderer: 'DerivedMetricCellRenderer',
            sortContext: {
                sortGroup: growthMetric.sortGroup,
                department: growthMetric.department,
                tractionMetricOption: SortTractionMetricOption.PERCENT,
                tractionMetricGranularity: SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
            }
        }
    ];
});
