import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SmartFieldIcon from 'assets/harmonic-icons/smart-field';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import { clone } from 'lodash';
import React, { useEffect } from 'react';
import CollapsibleFieldWrapper from './CollapsibleFieldWrapper';
import CorrespondenceDateSelect from './CorrespondenceDateSelect';
import IntegratedEmailUserMultiselect from './IntegratedEmailUserMultiselect';
const CorrespondencesSubSection = ({ generatorArgs, onUpdateGeneratorArgs, addTopMarginOnFieldOpen = false }) => {
    const [fieldsOpen, setFieldsOpen] = React.useState([]);
    useEffect(() => {
        const initialFieldsOpen = [];
        if (generatorArgs?.last_meeting_date_range_value) {
            initialFieldsOpen.push('lastMeetingDate');
        }
        if (generatorArgs?.last_meeting_with) {
            initialFieldsOpen.push('lastMeetingWith');
        }
        if (generatorArgs?.last_email_date_range_value) {
            initialFieldsOpen.push('lastEmailDate');
        }
        if (generatorArgs?.last_email_with) {
            initialFieldsOpen.push('lastEmailWith');
        }
        setFieldsOpen(initialFieldsOpen);
    }, []);
    const removeArguments = (keys) => {
        let newArgs = clone(generatorArgs);
        if (newArgs) {
            keys.forEach((key) => {
                delete newArgs?.[key];
            });
        }
        if (!newArgs || Object.keys(newArgs).length === 0) {
            newArgs = undefined;
        }
        onUpdateGeneratorArgs(newArgs);
    };
    const openField = (field) => {
        if (!fieldsOpen.includes(field)) {
            setFieldsOpen([...fieldsOpen, field]);
        }
    };
    const closeField = (field) => {
        setFieldsOpen(fieldsOpen.filter((f) => f !== field));
    };
    const lastMeetingDateOpen = fieldsOpen.includes('lastMeetingDate');
    const lastMeetingWithOpen = fieldsOpen.includes('lastMeetingWith');
    const lastEmailDateOpen = fieldsOpen.includes('lastEmailDate');
    const lastEmailWithOpen = fieldsOpen.includes('lastEmailWith');
    const anyFieldOpen = fieldsOpen.length > 0;
    const LastMeetingDateFieldInput = (_jsx(CollapsibleFieldWrapper, { onRemove: () => {
            closeField('lastMeetingDate');
            removeArguments([
                'last_meeting_date_range_value',
                'last_meeting_date_custom_range'
            ]);
        }, title: _jsxs("div", { className: "flex gap-g20", children: [_jsx("p", { children: "Last meeting date" }), _jsx(SmartFieldIcon, {})] }), children: _jsx(CorrespondenceDateSelect, { selected: generatorArgs?.last_meeting_date_range_value, customRangeValue: generatorArgs?.last_meeting_date_custom_range, onChange: (newValue, newCustomValue) => {
                const updatedValues = {
                    last_meeting_date_range_value: newValue
                };
                if (newCustomValue) {
                    updatedValues.last_meeting_date_custom_range = newCustomValue;
                }
                onUpdateGeneratorArgs({ ...generatorArgs, ...updatedValues });
            } }) }));
    const LastMeetingWithFieldInput = (_jsx(CollapsibleFieldWrapper, { onRemove: () => {
            closeField('lastMeetingWith');
            removeArguments(['last_meeting_with']);
        }, title: _jsxs("div", { className: "flex gap-g20", children: [_jsx("p", { children: "Last meeting with" }), _jsx(SmartFieldIcon, {})] }), children: _jsx(IntegratedEmailUserMultiselect, { selected: generatorArgs?.last_meeting_with ?? [], onChange: (newEmails) => {
                onUpdateGeneratorArgs({
                    ...generatorArgs,
                    last_meeting_with: newEmails
                });
            } }) }));
    const LastEmailDateFieldInput = (_jsx(CollapsibleFieldWrapper, { onRemove: () => {
            closeField('lastEmailDate');
            removeArguments([
                'last_email_date_range_value',
                'last_email_date_custom_range'
            ]);
        }, title: _jsxs("div", { className: "flex gap-g20", children: [_jsx("p", { children: "Last email date" }), _jsx(SmartFieldIcon, {})] }), children: _jsx(CorrespondenceDateSelect, { selected: generatorArgs?.last_email_date_range_value, customRangeValue: generatorArgs?.last_email_date_custom_range, onChange: (newValue, newCustomValue) => {
                const updatedValues = {
                    last_email_date_range_value: newValue
                };
                if (newCustomValue) {
                    updatedValues.last_email_date_custom_range = newCustomValue;
                }
                onUpdateGeneratorArgs({ ...generatorArgs, ...updatedValues });
            } }) }));
    const LastEmailWithFieldInput = (_jsx(CollapsibleFieldWrapper, { onRemove: () => {
            closeField('lastEmailWith');
            removeArguments(['last_email_with']);
        }, title: _jsxs("div", { className: "flex gap-g20", children: [_jsx("p", { children: "Last email with" }), _jsx(SmartFieldIcon, {})] }), children: _jsx(IntegratedEmailUserMultiselect, { selected: generatorArgs?.last_email_with ?? [], onChange: (newEmails) => {
                onUpdateGeneratorArgs({
                    ...generatorArgs,
                    last_email_with: newEmails
                });
            } }) }));
    const ExpandedFields = (_jsx("div", { className: classNames('flex flex-col gap-g90', 
        // TODO: Remove this conditional margin.
        // This is a hacky stopgap until we can fully reevaluate the
        // hierarchy of headings across company/people search
        anyFieldOpen && addTopMarginOnFieldOpen && 'mt-p90'), children: fieldsOpen.map((field) => {
            switch (field) {
                case 'lastMeetingDate':
                    return LastMeetingDateFieldInput;
                case 'lastMeetingWith':
                    return LastMeetingWithFieldInput;
                case 'lastEmailDate':
                    return LastEmailDateFieldInput;
                case 'lastEmailWith':
                    return LastEmailWithFieldInput;
                default:
                    return null;
            }
        }) }));
    const ExpandableButtons = () => (_jsxs("div", { className: "flex gap-g40", children: [!lastMeetingDateOpen && (_jsx(Button, { dataTestId: `CorrespondencesFilters-ExpandableButton-lastMeetingDate`, leadingIcon: SmartFieldIcon, label: "Last meeting date", type: "secondary", emphasis: "high", onClick: () => openField('lastMeetingDate') }, "Last meeting date")), !lastMeetingWithOpen && (_jsx(Button, { dataTestId: `CorrespondencesFilters-ExpandableButton-lastMeetingWith`, leadingIcon: SmartFieldIcon, label: "Last meeting with", type: "secondary", emphasis: "high", onClick: () => openField('lastMeetingWith') }, "Last meeting with")), !lastEmailDateOpen && (_jsx(Button, { dataTestId: `CorrespondencesFilters-ExpandableButton-lastEmailDate`, leadingIcon: SmartFieldIcon, label: "Last email date", type: "secondary", emphasis: "high", onClick: () => openField('lastEmailDate') }, "Last email date")), !lastEmailWithOpen && (_jsx(Button, { dataTestId: `CorrespondencesFilters-ExpandableButton-lastEmailWith`, leadingIcon: SmartFieldIcon, label: "Last email with", type: "secondary", emphasis: "high", onClick: () => openField('lastEmailWith') }, "Last email with"))] }));
    return (_jsxs("div", { className: "flex flex-col gap-g50", children: [ExpandedFields, _jsx(ExpandableButtons, {})] }));
};
export default CorrespondencesSubSection;
