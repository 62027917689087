import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const NetworkAttributeBadge = () => {
  return _jsxs("svg", {
    "data-testid": "network-attribute-badge",
    width: "20",
    height: "16",
    viewBox: "0 0 20 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [_jsx("path", {
      d: "M0 8C0 3.58172 3.58172 0 8 0H12C16.4183 0 20 3.58172 20 8C20 12.4183 16.4183 16 12 16H8C3.58172 16 0 12.4183 0 8Z",
      fill: "#4282B3",
      "fill-opacity": "0.16"
    }), _jsx("path", {
      d: "M8.241 11.0236C8.241 11.9901 7.4575 12.7736 6.491 12.7736C5.5245 12.7736 4.741 11.9901 4.741 11.0236C4.741 10.0571 5.5245 9.27356 6.491 9.27356C7.4575 9.27356 8.241 10.0571 8.241 11.0236Z",
      fill: "#376695"
    }), _jsx("path", {
      d: "M15.5872 11.0236C15.5872 11.9901 14.8037 12.7736 13.8372 12.7736C12.8707 12.7736 12.0872 11.9901 12.0872 11.0236C12.0872 10.0571 12.8707 9.27357 13.8372 9.27357C14.8037 9.27357 15.5872 10.0571 15.5872 11.0236Z",
      fill: "#376695"
    }), _jsx("path", {
      d: "M11.7702 4.70898C11.7702 5.67548 10.9867 6.45898 10.0202 6.45898C9.05367 6.45898 8.27017 5.67548 8.27017 4.70898C8.27017 3.74249 9.05367 2.95898 10.0202 2.95898C10.9867 2.95898 11.7702 3.74249 11.7702 4.70898Z",
      fill: "#376695"
    }), _jsx("path", {
      d: "M10.8935 5.73642L10.0275 6.23642L12.4216 10.3831L13.2876 9.88306L10.8935 5.73642Z",
      fill: "#376695"
    }), _jsx("path", {
      d: "M9.16148 5.73692L10.0275 6.23642L7.63345 10.3836L6.76742 9.88355L9.16148 5.73692Z",
      fill: "#376695"
    }), _jsx("path", {
      d: "M12.4142 11.3836V10.3836H7.6261V11.3836H12.4142Z",
      fill: "#376695"
    })]
  });
};
export default NetworkAttributeBadge;