import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import Checkboxes from '../Common/Checkboxes';
import DateWithSelect, { DateWithSelectMode } from '../Common/DateWithSelect';
import IncludeExcludeMultiselect from '../Common/IncludeExcludeMultiselect';
import { SubSectionHeader } from '../Common/Typography';
import { useSchoolTypeahead } from '../Common/hooks/useSchoolTypeahead';
const DegreeOptions = [
    {
        label: 'PhD',
        value: 'PHD'
    },
    {
        label: 'MBA',
        value: 'MASTER_OF_BUSINESS_ADMINISTRATION'
    },
    {
        label: "Master's",
        value: 'MASTERS_DEGREE'
    },
    {
        label: "Bachelor's",
        value: 'BACHELORS_DEGREE'
    },
    {
        label: 'J.D.',
        value: 'JURIS_DOCTOR'
    },
    {
        label: 'M.D.',
        value: 'DOCTOR_OF_MEDICINE'
    }
];
const EducationSection = ({ searchQuery, onUpdateSearchQuery }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const educationExperienceState = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION];
    const educationExperienceSchools = educationExperienceState?.schools ?? [];
    const educationExperienceSchoolsExcluded = educationExperienceState?.schools_exclude_all_of ?? [];
    const degreesList = educationExperienceState?.degrees;
    const startDateComparator = educationExperienceState?.start_date_comparator ||
        DateWithSelectMode.AFTER;
    const startDate = parseDateForComponent(educationExperienceState?.start_date);
    const endDateComparator = educationExperienceState?.end_date_comparator ||
        DateWithSelectMode.BEFORE;
    const endDate = parseDateForComponent(educationExperienceState?.end_date);
    const updateValues = (newValues) => {
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION, {
            ...(educationExperienceState ?? {}),
            ...newValues
        }));
    };
    const { options: schoolTypeaheadOptions, handleSearch } = useSchoolTypeahead();
    const handleOnStartDateChange = (mode, date) => {
        if (isNil(mode) || isNil(date)) {
            return updateValues({
                start_date: undefined,
                start_date_comparator: undefined
            });
        }
        updateValues({
            start_date: parseDateForGenerator(date),
            start_date_comparator: mode
        });
    };
    const handleOnEndDateChange = (mode, date) => {
        if (isNil(mode) || isNil(date)) {
            return updateValues({
                start_date: undefined,
                start_date_comparator: undefined
            });
        }
        updateValues({
            end_date: parseDateForGenerator(date),
            end_date_comparator: mode
        });
    };
    return (_jsxs("div", { className: "w-full flex flex-col gap-g80 pt-p40", children: [_jsxs("div", { className: "flex flex-col gap-g10 pr-p50", children: [_jsx(SubSectionHeader, { children: "School" }), _jsx(IncludeExcludeMultiselect, { dataTestId: "education-experience-schools-field", options: schoolTypeaheadOptions, included: educationExperienceSchools, excluded: educationExperienceSchoolsExcluded, onChangeInclude: (newValues) => {
                            updateValues({ schools: newValues });
                            handleSearch('');
                        }, onChangeExclude: (newValues) => updateValues({ schools_exclude_all_of: newValues }), placeholder: "Search or add a school", freeSolo: true, onSearch: handleSearch })] }), _jsxs("div", { className: "flex flex-col gap-g10 pr-p50", children: [_jsx(SubSectionHeader, { children: "Degree" }), _jsx(Checkboxes, { dataTestId: "education-experience-degrees-field", options: DegreeOptions, selectedValues: degreesList, onValueChange: (newValues) => updateValues({ degrees: newValues }) })] }), _jsxs("div", { className: "grid grid-cols-2 gap-g90 pr-p50", children: [_jsxs("div", { className: "flex flex-col gap-g10 pr-p50", children: [_jsx(SubSectionHeader, { children: "Start date" }), _jsx(DateWithSelect, { mode: startDateComparator, onChange: handleOnStartDateChange, value: startDate, label: "Add start date", dataTestId: "education-experience-start-date-field" })] }), _jsxs("div", { className: "flex flex-col gap-g10 pr-p50", children: [_jsx(SubSectionHeader, { children: "End date" }), _jsx(DateWithSelect, { mode: endDateComparator, onChange: handleOnEndDateChange, value: endDate, label: "Add end date" })] })] })] }));
};
const parseDateForGenerator = (date) => {
    if (Array.isArray(date)) {
        return date.map((d) => d.replaceAll('/', '-'));
    }
    return date ? date.replaceAll('/', '-') : undefined;
};
const parseDateForComponent = (date) => {
    if (Array.isArray(date)) {
        return date.map((d) => d.replaceAll('/', '-'));
    }
    return date ? date.replaceAll('-', '/') : undefined;
};
export default EducationSection;
