/**
 * Formats headers for cell renderers by creating a map of headers to determine if header
 * is pointing to field that is returned by the query without extended data.
 */
export const formatHeadersForCellRenderers = ({ headers, excludeHeaders }) => {
    return headers
        .filter((header) => {
        return (header.field !==
            excludeHeaders?.find((excludeHeader) => header.field === excludeHeader.field)?.field);
    })
        .reduce((headers, currentHeader) => {
        if (currentHeader.field) {
            headers[currentHeader.field] = true;
        }
        return headers;
    }, {});
};
export const suppressCellExitOnShiftEnter = (params) => {
    if (params.editing && params.event.key === 'Enter' && params.event.shiftKey) {
        return true;
    }
    return false;
};
