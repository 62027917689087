import styled from 'styled-components';
export const AgGridWrapper = styled.div `
  /* Start the highlight chips of company cell at top while 'Empty' text should be in center */

  .ag-cell[col-id='company.highlights'] .ag-cell-wrapper,
  .ag-cell[col-id='company.typed_tags'] .ag-cell-wrapper {
    align-items: flex-start;
  }

  .ag-cell[col-id='company.highlights'] .ag-cell-value,
  .ag-cell[col-id='company.highlights'] .ag-react-container,
  .ag-cell[col-id='company.typed_tags'] .ag-cell-value,
  .ag-cell[col-id='company.typed_tags'] .ag-react-container,
  .ag-cell[col-id='experience.currentCompany'] .ag-cell-value,
  .ag-cell[col-id='experience.currentCompany'] .ag-react-container,
  .ag-cell[col-id='experience.previousCompanies'] .ag-cell-value,
  .ag-cell[col-id='experience.previousCompanies'] .ag-react-container {
    height: 100%;
  }

  .ag-overlay:not(.ag-hidden) {
    background-color: rgba(255, 255, 255, 0.88);
    pointer-events: all;
  }
`;
import { Tooltip, withStyles } from '@material-ui/core';
export const ArrowTooltip = withStyles(() => ({
    // TODO: edit these default styles
    tooltip: {
        backgroundColor: 'white',
        gap: '2px',
        padding: '4px',
        maxWidth: '300px',
        // TODO: Convert the color to a variable
        border: '1px solid #CDCFD0'
    },
    arrow: {
        color: '#CDCFD0'
    }
}))(Tooltip);
