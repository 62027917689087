import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useTeamMembers from 'hooks/useTeamMembers';
import { useState } from 'react';
const UserMultiSelectByName = ({ onChange, selected, placeholder, initialFocus = false, freeSolo, mode = null }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { activeTeamMembers, loading } = useTeamMembers({
        fetchPolicy: 'cache-only'
    });
    const options = activeTeamMembers?.map((member) => ({
        value: member?.user?.entityUrn,
        label: member?.user?.name
    })) ?? [];
    const filteredOptions = options
        .filter((option) => option?.label?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLocaleLowerCase()));
    const onClickListItem = (option) => {
        const selectedOption = selected.find((s) => s === option.value);
        if (selectedOption) {
            onChange(selected.filter((s) => s !== option.value));
        }
        else {
            onChange([...selected, option.value ?? '']);
        }
        setSearchTerm('');
    };
    return (_jsxs(Select, { multiple: true, filterable: true, freeSolo: freeSolo, onAdd: (value) => {
            onChange([...selected, value]);
            setSearchTerm('');
        }, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selected, placeholder: placeholder, getLabelFromValue: (value) => options.find((o) => o.value === value)?.label ?? '', getTagColorFromValue: (value) => {
            const valueIndex = options.findIndex((option) => option.value === value);
            const color = colorOptions[valueIndex % colorOptions.length]?.color;
            return color;
        }, onRemove: (key) => onChange(selected.filter((s) => s !== key)), initialFocus: initialFocus, clampValues: true, dropdownMaxHeight: "300px", children: [loading && (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(HarmonicLoader, { showText: false }) })), !loading &&
                filteredOptions?.map((option) => {
                    const color = colorOptions[options.indexOf(option) % colorOptions.length]?.color;
                    if (!option)
                        return null;
                    return (_jsx(SelectListItem, { value: option.value ?? '', label: option.label ?? '', color: color, onClick: () => onClickListItem(option), variant: ListVariant.tag }, option.value));
                })] }));
};
export default UserMultiSelectByName;
