import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { isNil } from 'lodash';
import { useState } from 'react';
import { formatDateRangeByMode, formatYearRangeByMode } from 'utils/searchV2/searchV2';
import DateModal, { DateTypeEnum } from './DateModal';
const CustomRange = ({ mode, value, onChange, dateType = DateTypeEnum.DAY }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const pillLabel = dateType === DateTypeEnum.DAY
        ? formatDateRangeByMode('Custom range', mode, value)
        : formatYearRangeByMode('Custom range', mode, value);
    return (_jsxs("div", { children: [_jsx(RadioPill, { dataTestId: "CustomRange-Radio-Pill", selected: isOpen || !isNil(value), label: pillLabel, onClick: handleClick }), _jsx(DateModal, { anchorEl: anchorEl, onClose: handleClose, open: isOpen, mode: mode, value: value, onChange: onChange, dateType: dateType })] }));
};
export default CustomRange;
