import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragDropContext } from '@hello-pangea/dnd';
import { PlusIcon } from 'assets/harmonic-icons';
import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import Button from 'harmonic-components/Button/Button';
import useTeamMembers from 'hooks/useTeamMembers';
import React, { createContext, useState } from 'react';
import useTableStore from 'stores/tableStore';
import { ListCustomFieldType } from '../../../__generated__/graphql';
import analytics, { CustomTrackEvent } from '../../../utils/analytics';
import { getCustomFieldValue } from './utils';
export const NOT_APPLICABLE_FIELD_OPTION = {
    name: 'N/A',
    urn: 'n/a',
    color: 'informative'
};
export const BoardContext = createContext({
    isDragging: false
});
export const BoardView = ({ watchlistId, customField, boardColumn, onDragEnd }) => {
    const editSidebar = useTableStore((state) => state.editSidebar);
    const [isDragging, setIsDragging] = useState(false);
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const formattedTeamMembers = activeTeamMembers
        ?.map((member, index) => ({
        color: colorOptions[index % colorOptions.length].color,
        urn: member?.user?.entityUrn,
        name: member?.user?.name
    }))
        .filter((val) => val.name)
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    if (customField.metadata?.__typename !== 'SelectListCustomFieldMetadata' &&
        customField.metadata?.__typename !== 'PersonListCustomFieldMetadata') {
        // TODO: This is an exception, the kanban view shouldn't be rendered
        // without on a custom field thar is not a select list type. Doing this hack
        // while we work on the prototype.
        return null;
    }
    const fieldUrn = customField.urn;
    let options = customField.metadata
        ?.options ?? [];
    if (customField.type === ListCustomFieldType.PERSON) {
        options = formattedTeamMembers;
    }
    const BoardColumn = boardColumn;
    const handleDragStart = () => {
        setIsDragging(true);
    };
    const handleDragEnd = async (result) => {
        const customFieldValue = result.destination?.droppableId;
        if (!customFieldValue)
            return;
        const previousCustomFieldUrn = result.source.droppableId;
        if (previousCustomFieldUrn === customFieldValue)
            return;
        analytics.trackCustomEvent({
            event: CustomTrackEvent.BOARD_VIEW_DROP_END,
            properties: {
                watchlistId: watchlistId,
                customFieldUrn: fieldUrn,
                customFieldValueUrn: customFieldValue,
                previousCustomFieldValueUrn: previousCustomFieldUrn
            }
        });
        setIsDragging(false);
        await onDragEnd?.({
            customFieldValueUrn: getCustomFieldValue(customFieldValue),
            previousCustomFieldValueUrn: getCustomFieldValue(previousCustomFieldUrn),
            watchlistEntryUrn: result.draggableId,
            customFieldUrn: customField?.urn,
            index: result.destination?.index || 0,
            customFieldType: customField.type
        });
    };
    const NOT_APPLICABLE_FIELD_OPTION = {
        name: `No ${customField.name}`,
        urn: 'n/a',
        color: 'neutral'
    };
    const onAddMoreOption = () => {
        editSidebar('customizeFieldModal', {
            open: true,
            mode: 'edit',
            colId: fieldUrn
        });
    };
    return (_jsx("div", { className: "flex flex-col h-full py-2 px-1", children: _jsxs("div", { className: "flex w-full overflow-x-scroll", children: [_jsx(DragDropContext, { onDragStart: handleDragStart, onDragEnd: handleDragEnd, children: _jsxs(BoardContext.Provider, { value: { isDragging }, children: [_jsx(BoardColumn, { watchlistId: watchlistId, fieldUrn: fieldUrn, option: NOT_APPLICABLE_FIELD_OPTION, fieldType: customField.type }), options?.map((option, i) => (_jsx(BoardColumn, { watchlistId: watchlistId, fieldUrn: fieldUrn, option: option, fieldType: customField.type }, i)))] }) }), _jsx("div", { className: "ml-g30 z-100 mt-[1px]", children: _jsx(Button, { onClick: onAddMoreOption, label: "Add option", leadingIcon: PlusIcon }) })] }) }));
};
