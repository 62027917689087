import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import { HarmonicIcon, LinkedInIcon, SquareArrowTopRightIcon } from 'assets/harmonic-icons';
import { ReactComponent as CalculatorIcon } from 'assets/socials/bxl-calculator.svg';
import classNames from 'classnames';
import BarLineChart from 'components/Charts/BarLineChart';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import SegmentedControl from 'harmonic-components/SegmentedControl/SegmentedControl';
import { HeadcountMetricsType } from 'interfaces/DataModel/Company';
import { isEqualWith, isNil } from 'lodash';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { useState } from 'react';
import { formatHeadcountFundingForChart } from 'utils/chart';
import { truncateMoneyValue } from 'utils/utilities';
import FundingTotal from '../Cards/FundingTotal';
import HeadcountChange from '../Cards/HeadcountChange';
import CompanyTimelineGraphLegends from './CompanyTimelineGraphLegends';
const CompanyTimelineContentLoader = () => {
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex flex-row justify-between gap-10 grid-cols-3 mt-5 mb-4", children: [...Array(3)].map((_, index) => (_jsx("div", { className: "flex w-full h-20 py-px animated-box-pulse" }, index))) }), _jsx("div", { className: "mt-5 h-80 py-14 bg-slate-200 rounded-md animate-pulse" })] }));
};
const CompanyHeadcountFundingGraph = ({ companyId }) => {
    const { data: funding } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const { data: socials } = useQuery(getCompanyMetrics, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const [headcountType, setHeadcountType] = useState(HeadcountMetricsType.CORRECTED_HEADCOUNT);
    const fundingData = funding?.getCompanyById;
    const tractionData = socials?.getCompanyById;
    const foundingDate = tractionData?.foundingDate?.date ?? '';
    const totalFundingRounds = fundingData?.funding?.numFundingRounds || 'Unknown';
    const fundingPerEmployee = funding?.getCompanyById?.fundingPerEmployee;
    const useCorrectedHeadcount = headcountType === HeadcountMetricsType.CORRECTED_HEADCOUNT;
    const correctedChartData = formatHeadcountFundingForChart(tractionData, fundingData, true);
    const originalChartData = formatHeadcountFundingForChart(tractionData, fundingData, false);
    const chartData = useCorrectedHeadcount
        ? correctedChartData
        : originalChartData;
    let barLineChartKeyProps = {};
    // Chart.js does not animate if data is same when toggling between corrected and real.
    // This key trick is to force animate when data are same.
    if (isEqualWith(correctedChartData, originalChartData)) {
        barLineChartKeyProps = { key: headcountType };
    }
    const renderFundingPerEmployee = () => {
        const fundingPerEmployeeValue = !isNil(fundingPerEmployee)
            ? truncateMoneyValue(fundingPerEmployee)
            : 'Unknown';
        return (_jsx(MetricCard, { icon: CalculatorIcon, value: fundingPerEmployeeValue, label: "Funding per employee" }));
    };
    const fundingTotal = fundingData?.funding?.fundingTotal;
    const showLoader = !fundingData || !tractionData;
    return (_jsxs("div", { children: [_jsx("p", { className: classNames('typography-title-medium text-content-title border-b border-solid border-border pb-p70'), children: "Headcount & funding" }), showLoader ? (_jsx(CompanyTimelineContentLoader, {})) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mt-p80 mb-g80 flex grid grid-cols-2 xl:grid-cols-4 gap-g60", children: [_jsx(FundingTotal, { companyId: companyId }), _jsx(HeadcountChange, { companyId: companyId }), _jsx(MetricCard, { icon: BoxBarChart, value: totalFundingRounds, label: "Funding rounds" }), renderFundingPerEmployee()] }), _jsxs("div", { className: "p-p60 flex items-center border border-solid border-border rounded-tl-br30 rounded-tr-br30", children: [_jsx("div", { className: classNames('inline-flex'), children: _jsx(SegmentedControl, { options: [
                                        {
                                            label: 'Harmonic',
                                            value: HeadcountMetricsType.CORRECTED_HEADCOUNT,
                                            leadingIcon: ({ className }) => (_jsx(HarmonicIcon, { applyCurrentColor: false, className: className }))
                                        },
                                        {
                                            label: 'Linkedin',
                                            value: HeadcountMetricsType.LINKEDIN_HEADCOUNT,
                                            leadingIcon: ({ className }) => (_jsx(LinkedInIcon, { applyCurrentColor: false, className: className }))
                                        }
                                    ], selectedOption: headcountType, onChange: (newOption) => setHeadcountType(newOption) }) }), _jsxs("a", { target: "_blank", href: "https://support.harmonic.ai/en/articles/8567598-corrected-headcount", rel: "noreferrer", className: classNames('ml-g60 typography-paragraph-small cursor-pointer text-content-weak flex gap-g20 items-center'), children: ["Why our Headcount data is different from LinkedIn\u2019s", _jsx(SquareArrowTopRightIcon, { className: "w-4 h-4" })] })] }), _jsxs("div", { className: classNames('p-p60 border border-solid border-border rounded-bl-br30 rounded-br-br30 border-t-0'), children: [_jsxs("div", { className: "flex justify-between typography-label-small text-content-weak", children: [_jsx("p", { children: "Headcount" }), _jsx("p", { children: "Funding" })] }), _jsx("div", { className: "h-72 mt-g60", children: _jsx(BarLineChart, { ...barLineChartKeyProps, lineChartData: chartData.lineChartDataPoints, barChartData: chartData.barChartDataPoints, foundingDate: foundingDate }) }), _jsx(CompanyTimelineGraphLegends, { barChartData: chartData.barChartDataPoints, lineChartData: chartData.lineChartDataPoints, fundingTotal: fundingTotal })] })] }))] }));
};
export default CompanyHeadcountFundingGraph;
