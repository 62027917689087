import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Button from '../../harmonic-components/Button/Button';
import FilterPill from '../../harmonic-components/FilterPill/FilterPill';
import { CompanyFilterTabs, PeopleFilterTabs } from '../../interfaces/SearchV2';
import analytics, { CustomTrackEvent } from '../../utils/analytics';
export const getFilterTabOptions = (isWatchlistPage) => [
    {
        label: 'General',
        value: CompanyFilterTabs.GENERAL
    },
    {
        label: 'Funding',
        value: CompanyFilterTabs.FUNDING
    },
    {
        label: 'Team',
        value: CompanyFilterTabs.TEAM
    },
    {
        label: 'Product',
        value: CompanyFilterTabs.PRODUCT
    },
    {
        label: isWatchlistPage ? 'Filter' : 'Advanced',
        value: CompanyFilterTabs.CUSTOM
    },
    {
        label: 'Filter',
        value: PeopleFilterTabs.FILTER
    },
    {
        label: 'General',
        value: PeopleFilterTabs.GENERAL
    },
    {
        label: 'Current experience',
        value: PeopleFilterTabs.CURRENT_EXP
    },
    {
        label: 'Past experience',
        value: PeopleFilterTabs.PAST_EXP
    },
    {
        label: 'Education',
        value: PeopleFilterTabs.EDUCATION
    },
    {
        label: isWatchlistPage ? 'Filter' : 'Advanced',
        value: PeopleFilterTabs.CUSTOM
    }
];
export const FilterTabSelector = ({ selectedFilterTab, setSelectedFilterTab, onResetSearch, activeFilterCountByTab, showPopover, tabsToShow, FilterTabOptions, showResetButton = true }) => {
    const hasAnyFilter = activeFilterCountByTab &&
        Object.values(activeFilterCountByTab).some((filters) => filters > 0);
    return (_jsx("div", { className: classNames('flex items-center mr-p50', {
            'border-b border-solid border-border -mx-3 px-3 pb-4': showPopover,
            'pr-3 pb-p10': !showPopover
        }), children: _jsxs("div", { className: classNames('flex gap-2'), "data-testid": "Query-Builder-Tabs", children: [FilterTabOptions.filter((tab) => tabsToShow.includes(tab.value)).map(({ label, value }) => {
                    return (_jsx(FilterPill, { dataTestId: 'filter-section-button-' + value, label: label, isSelected: selectedFilterTab === value, onClick: () => {
                            analytics.trackCustomEvent({
                                event: CustomTrackEvent.SEARCH_SECTION_CLICKED,
                                properties: {
                                    section: value
                                }
                            });
                            setSelectedFilterTab(value);
                        }, badgeCount: activeFilterCountByTab?.[value] }, value));
                }), showResetButton && (_jsx(Button, { label: "Clear all", onClick: onResetSearch, emphasis: "low", isDisabled: !hasAnyFilter, dataTestId: "query-builder-reset-button" }))] }) }));
};
