import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import Button from 'harmonic-components/Button/Button';
import useRenderPerformance from '../../hooks/useRenderPerformance';
import { TruncatedListV2 } from './TruncatedListV2';
const LineclampButton = ({ numClamped, handleExpand, className }) => {
    return (_jsx("div", { className: className, children: _jsx(Button, { dataTestId: "TruncatedListButton", size: "tiny", label: `+${numClamped}`, onClick: handleExpand }) }));
};
const TruncatedList = ({ children, height, divider, lineclampButton = (props) => _jsx(LineclampButton, { ...props }), wrapperClassName, contentClassName }) => {
    useRenderPerformance('TruncatedList', children);
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleExpand = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };
    const handleClose = (event) => {
        event.preventDefault();
        setExpanded(false);
        setAnchorEl(null);
    };
    return (_jsxs("div", { className: classNames('flex flex-row justify-start', wrapperClassName), children: [_jsx(TruncatedListV2, { className: "flex items-start flex-wrap gap-g30", renderTruncator: ({ hiddenItemsCount }) => {
                    return lineclampButton({
                        numClamped: hiddenItemsCount,
                        handleExpand
                    });
                }, style: { maxHeight: height + 4 }, children: children }), _jsx(Popover, { open: expanded, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, className: "ml-p30 overflow-hidden bg-none rounded-br40 visible-scrollbar", PaperProps: {
                    style: {
                        borderRadius: 6
                    }
                }, children: _jsx("div", { "data-testid": "TruncatedListPopover", className: "p-p50 max-h-96 overflow-y-scroll flex flex-col gap-y-g40 whitespace-nowrap bg-surface-default shadow-static-elevation-floating border-[1.5px] border-solid border-border rounded-br30", children: children }) })] }));
};
export default TruncatedList;
