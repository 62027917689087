import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RangeInput from 'harmonic-components/RangeInput/RangeInput';
import { companyFundingTotalDefaultValue, FilterGroupGeneratorId, FundingLastRoundDateOptions, fundingSliderStepRanges } from 'interfaces/SearchV2';
import React from 'react';
import ChipWithCustomRange from '../Common/ChipWithCustomRange';
import { formatCustomRange, getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import ButtonWrapper from '../Common/ButtonWrapper';
import Checkboxes from '../Common/Checkboxes';
import InvestorMultiselectByName from '../Common/InvestorMultiselectByName';
import { SubSectionHeader } from '../Common/Typography';
import FundingSectionAccelerator from './FundingSectionAccelerator';
const LastFundingDateOptions = [
    {
        label: 'Any',
        value: undefined
    },
    {
        label: 'Past 4-12 months',
        value: FundingLastRoundDateOptions.PAST_FOUR_TO_TWELVE_MONTHS
    },
    {
        label: 'Past 12-24 months',
        value: FundingLastRoundDateOptions.PAST_TWELVE_TO_TWENTY_FOUR_MONTHS
    }
];
const fundingStageOptions = [
    {
        label: 'Stealth',
        value: 'STEALTH'
    },
    {
        label: 'Series A',
        value: 'SERIES_A'
    },
    {
        label: 'Pre-seed',
        value: 'PRE_SEED'
    },
    {
        label: 'Series B',
        value: 'SERIES_B'
    },
    {
        label: 'Seed',
        value: 'SEED'
    },
    {
        label: 'Series C+',
        value: 'SERIES_C_PLUS'
    }
];
const FundingSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const includeInvestors = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS]?.investors;
    const excludeInvestors = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS]?.investors;
    const fundingStages = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE]?.stages;
    const fundingTotalRangeField = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE];
    const fundingTotalRangeValue = fundingTotalRangeField?.range;
    const fundingUndisclosed = fundingTotalRangeField?.include_undisclosed;
    const fundingLastRoundDate = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE];
    const updateFundingUndisclosed = (e) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE, {
        range: fundingTotalRangeValue,
        include_undisclosed: e.target.checked
    }));
    return (_jsx("div", { className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-g90", children: [_jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Company stage" }), _jsx(Checkboxes, { dataTestId: "Funding-Stage-Checkboxes", options: fundingStageOptions, selectedValues: fundingStages, onValueChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE, newValues !== undefined ? { stages: newValues } : undefined)), disabled: disabled })] }), _jsxs("div", { "data-testid": "total-funding-raised-section", children: [_jsx(SubSectionHeader, { children: "Total funding raised" }), _jsx("div", { className: "w-1/2", children: _jsx(RangeInput, { dataTestId: "Total-Funding-Raised-Input", value: fundingTotalRangeValue, defaultValue: companyFundingTotalDefaultValue.range, sliderStepRanges: fundingSliderStepRanges, prefix: "$", disabled: disabled, onValueChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE, newValues !== undefined
                                    ? {
                                        range: newValues
                                    }
                                    : undefined)) }) }), fundingTotalRangeValue && (_jsx("div", { className: "mt-2 flex gap-2", children: _jsx(Checkbox, { onChange: updateFundingUndisclosed, label: "Include undisclosed", checked: fundingUndisclosed }) }))] }), _jsxs("div", { "data-testid": "last-funding-date-section", children: [_jsx(SubSectionHeader, { children: "Last funding date" }), _jsx(ChipWithCustomRange, { dataTestId: "Last-Funding-Date-Input", onValueChange: (newValue) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE, newValue?.value !== undefined
                                ? {
                                    range_value: newValue.value,
                                    custom_range: formatCustomRange(newValue.customRange)
                                }
                                : undefined)), disabled: disabled, selectedValue: fundingLastRoundDate?.range_value, selectedCustomRange: fundingLastRoundDate?.custom_range, options: LastFundingDateOptions }, "funding-date")] }), _jsx("div", { "data-testid": "investors-section", children: _jsxs("div", { className: "grid grid-cols-2 gap-g90", children: [_jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Include investors" }), _jsx(ButtonWrapper, { label: "Include investors", leadingIcon: CirclePlus, skipToChildren: (includeInvestors && includeInvestors.length > 0) ?? false, children: _jsx(InvestorMultiselectByName, { placeholder: "Select investors to include", onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS, newValues !== undefined && newValues.length > 0
                                                ? {
                                                    investors: newValues
                                                }
                                                : undefined)), initialFocus: (includeInvestors ?? []).length === 0, selected: includeInvestors ?? [] }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Exclude investors" }), _jsx(ButtonWrapper, { label: "Exclude investors", leadingIcon: CircleMinus, skipToChildren: (excludeInvestors && excludeInvestors.length > 0) ?? false, children: _jsx(InvestorMultiselectByName, { placeholder: "Select investors to exclude", onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS, newValues !== undefined && newValues.length > 0
                                                ? {
                                                    investors: newValues
                                                }
                                                : undefined)), initialFocus: (excludeInvestors ?? []).length === 0, selected: excludeInvestors ?? [] }) })] })] }) }), _jsx(FundingSectionAccelerator, { controlledSearchState: controlledSearchState, searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled })] }) }));
};
export default FundingSection;
