import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import NetworkAttributeBadge from 'common/components/Network/NetworkAttributeBadge';
import NetworkBadge from 'common/components/Network/NetworkBadge';
import NetworkSummaryBadge from 'common/components/Network/NetworkSummaryBadge';
import NetworkTooltip from 'common/components/Network/NetworkTooltip';
import { getIdFromUrn, getUrnFragment } from 'common/utils/urn';
import { useAppState } from 'hooks/useAppState';
import { compact } from 'lodash';
import { getCompanyUserConnectionsWithOverviewAndEvents } from 'queries/getCompanyUserConnections';
import getPersonUserConnections from 'queries/getPersonUserConnections';
const NetworkBadgeWithTooltip = (props) => {
    const { usersInNetwork = [], includeSummaryText, variant = 'badge', targetEntityUrn, targetName } = props;
    const entityType = getUrnFragment(targetEntityUrn, 2);
    const entityId = getIdFromUrn(targetEntityUrn);
    if ((entityType != 'person' && entityType != 'company') || !entityId) {
        throw new Error('Invalid URN');
    }
    const connectionsQuery = entityType === 'person'
        ? getPersonUserConnections
        : getCompanyUserConnectionsWithOverviewAndEvents;
    const [loadConnections, { data: connectionsData, loading: connectionsLoading }] = useLazyQuery(connectionsQuery, {
        variables: {
            id: parseInt(entityId)
        }
    });
    const currentUserEmail = useAppState((state) => {
        return state.auth.user?.email ?? '';
    });
    const BadgeComponent = variant === 'attribute'
        ? NetworkAttributeBadge
        : includeSummaryText
            ? NetworkSummaryBadge
            : NetworkBadge;
    const userConnections = compact(connectionsData?.getPersonById
        ?.userConnections ||
        connectionsData
            ?.getCompanyById?.userConnections);
    return (_jsx("div", { "data-testid": "network-badge-tooltip", children: _jsx(NetworkTooltip, { userConnections: connectionsLoading
                ? undefined
                : userConnections, loadConnections: loadConnections, currentUserEmail: currentUserEmail, targetName: targetName, children: _jsx(BadgeComponent, { usersInNetwork: usersInNetwork, currentUserEmail: currentUserEmail }) }) }));
};
export default NetworkBadgeWithTooltip;
