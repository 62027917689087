import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BellFilledIcon, CompanyIcon, ListIcon, PersonIcon, SavedSearchIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import usePeopleWatchlistEnabledDigests from 'hooks/digests/usePeopleWatchlistEnabledDigests';
import { EntityType } from 'hooks/useDashboardLocation';
import useSavedSearchEnabledSubscriptions from 'hooks/useSavedSearchEnabledSubscriptions';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { Link } from 'react-router-dom';
import { LOADING_ROW_ID } from 'utils/constants';
import useNetNewCounts from '../Searches/NetNew/useNetNewCounts';
import { CollectionType } from '../types';
import { getCollectionTypeFromEntityListType, getEntityTypeFromEntityListType } from '../utils';
import Favorites from './Favorites';
const EntityLeadingIcon = ({ entityType }) => {
    const type = getCollectionTypeFromEntityListType.get(entityType);
    return (_jsx("div", { className: "w-5 h-5 rounded-br20 bg-surface-sentiment-neutral flex items-center justify-center", children: type === CollectionType.LISTS ? (_jsx(ListIcon, { className: "text-content-default w-4 h-4" })) : (_jsx(SavedSearchIcon, { className: "text-content-default w-4 h-4" })) }));
};
const EntityPostfixIcon = ({ entityType }) => {
    const type = getEntityTypeFromEntityListType.get(entityType);
    return (_jsx("div", { className: "flex items-center justify-center", children: type === EntityType.PERSON ? (_jsx(Badge, { label: "People", leadingIcon: PersonIcon, color: "expressive-5", intensity: "subtle" })) : (_jsx(Badge, { label: "Companies", leadingIcon: CompanyIcon, color: "expressive-22", intensity: "subtle" })) }));
};
const NetNewRowBadge = ({ savedSearchUrn }) => {
    const { hasNetNew } = useNetNewCounts();
    if (!hasNetNew(savedSearchUrn))
        return null;
    return (_jsx(Badge, { label: "New results!", intensity: "subtle", color: "expressive-37" }));
};
const NameCellComponent = (rowData) => {
    const { hasSavedSearchSubscription } = useSavedSearchEnabledSubscriptions();
    const { hasEnabledPeopleWatchlistDigest } = usePeopleWatchlistEnabledDigests();
    if (rowData.data.id == LOADING_ROW_ID) {
        return _jsx(LoadingCellRenderer, {});
    }
    return (_jsx("div", { className: "typography-label-default-default text-content-strong p-p60", children: _jsxs(Link, { to: rowData.data.url, className: "flex flex-row gap-g30", children: [_jsx(EntityLeadingIcon, { entityType: rowData.data.entityType }), _jsx("span", { className: "truncate min-w-[1ch]", children: rowData.data.name }), _jsx(EntityPostfixIcon, { entityType: rowData.data.entityType }), (rowData.data.entityType === EntityListType.COMPANY_SAVED_SEARCH ||
                    rowData.data.entityType === EntityListType.PEOPLE_SAVED_SEARCH) && (_jsx(NetNewRowBadge, { savedSearchUrn: rowData.data.entityUrn })), (hasSavedSearchSubscription(rowData?.data?.entityUrn) ||
                    hasEnabledPeopleWatchlistDigest(rowData?.data?.entityUrn)) && (_jsx(BellFilledIcon, { className: "self-center text-int-fill-net-new-selected-enabled cursor-default min-w-4" })), _jsx(Favorites, { isFavourited: rowData.data?.userOptions?.isPinned, id: rowData.data.id, type: rowData.data.entityType })] }) }));
};
export default NameCellComponent;
