module.exports = {
  spectrum: {
    1: {
      alpha: 'rgba(204, 0, 0, 0.12)',
      light: 'rgba(255, 102, 102, 1)',
      saturated: 'rgba(255, 31, 31, 1)',
      dark: 'rgba(204, 0, 0, 1)'
    },
    2: {
      alpha: 'rgba(255, 64, 0, 0.12)',
      light: 'rgba(255, 83, 26, 1)',
      saturated: 'rgba(255, 140, 102, 1)',
      dark: 'rgba(204, 51, 0, 1)'
    },
    3: {
      alpha: 'rgba(255, 128, 0, 0.12)',
      light: 'rgba(255, 178, 102, 1)',
      saturated: 'rgba(255, 133, 10, 1)',
      dark: 'rgba(204, 102, 0, 1)'
    },
    4: {
      alpha: 'rgba(255, 191, 0, 0.16)',
      light: 'rgba(255, 217, 102, 1)',
      saturated: 'rgba(255, 196, 20, 1)',
      dark: 'rgba(178, 134, 0, 1)'
    },
    5: {
      alpha: 'rgba(114, 230, 5, 0.16)',
      light: 'rgba(178, 240, 117, 1)',
      saturated: 'rgba(143, 236, 50, 1)',
      dark: 'rgba(89, 161, 18, 1)'
    },
    6: {
      alpha: 'rgba(5, 168, 5, 0.12)',
      light: 'rgba(142, 215, 142, 1)',
      saturated: 'rgba(56, 204, 56, 1)',
      dark: 'rgba(53, 151, 53, 1)'
    },
    7: {
      alpha: 'rgba(5, 168, 84, 0.12)',
      light: 'rgba(142, 215, 178, 1)',
      saturated: 'rgba(36, 229, 133, 1)',
      dark: 'rgba(53, 151, 102, 1)'
    },
    8: {
      alpha: 'rgba(5, 230, 230, 0.12)',
      light: 'rgba(71, 235, 235, 1)',
      saturated: 'rgba(20, 215, 215, 1)',
      dark: 'rgba(16, 147, 147, 1)'
    },
    9: {
      alpha: 'rgba(4, 189, 251, 0.12)',
      light: 'rgba(105, 215, 252, 1)',
      saturated: 'rgba(5, 185, 245, 1)',
      dark: 'rgba(4, 151, 200, 1)'
    },
    10: {
      alpha: 'rgba(0, 128, 255, 0.12)',
      light: 'rgba(129, 191, 253, 1)',
      saturated: 'rgba(36, 145, 255, 1)',
      dark: 'rgba(3, 102, 201, 1)'
    },
    11: {
      alpha: 'rgba(4, 66, 251, 0.16)',
      light: 'rgba(155, 180, 253, 1)',
      saturated: 'rgba(31, 87, 255, 1)',
      dark: 'rgba(4, 53, 200, 1)'
    },
    12: {
      alpha: 'rgba(38, 5, 255, 0.16)',
      light: 'rgba(169, 154, 254, 1)',
      saturated: 'rgba(95, 66, 255, 1)',
      dark: 'rgba(32, 2, 202, 1)'
    },
    13: {
      alpha: 'rgba(114, 5, 230, 0.16)',
      light: 'rgba(191, 140, 242, 1)',
      saturated: 'rgba(135, 26, 244, 1)',
      dark: 'rgba(102, 20, 184, 1)'
    },
    14: {
      alpha: 'rgba(149, 0, 255, 0.12)',
      light: 'rgba(209, 117, 240, 1)',
      saturated: 'rgba(190, 26, 244, 1)',
      dark: 'rgba(143, 20, 184, 1)'
    },
    15: {
      alpha: 'rgba(255, 10, 194, 0.12)',
      light: 'rgba(247, 110, 213, 1)',
      saturated: 'rgba(245, 15, 188, 1)',
      dark: 'rgba(193, 11, 147, 1)'
    },
    16: {
      alpha: 'rgba(230, 5, 174, 0.12)',
      light: 'rgba(240, 117, 178, 1)',
      saturated: 'rgba(244, 26, 135, 1)',
      dark: 'rgba(184, 20, 102, 1)'
    }
  }
};