import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownV2, ChevronUpV2 } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import React, { useState } from 'react';
const AcceleratorItem = ({ name, count, checked, indeterminate, onChange, isParent, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    return (_jsxs("div", { children: [_jsxs("div", { className: classNames('flex justify-between items-center p-p40 rounded-br20 cursor-pointer', isHovered && 'bg-gray-50'), onMouseOver: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), onClick: () => {
                    if (isParent) {
                        setIsOpen(!isOpen);
                    }
                    else {
                        onChange();
                    }
                }, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Checkbox, { checked: checked, variant: indeterminate ? 'indeterminate' : 'default', onChange: onChange, label: name, id: `accelerator-checkbox-${name}`, dataTestId: `accelerator-checkbox-${name}`, stopPropagation: true }), count !== undefined && (_jsxs("span", { className: "ml-1 text-content-weak", children: ["(", count, ")"] }))] }), isParent && (_jsx("div", { className: "flex ml-4 h-full justify-end", "data-testid": `expand-${name}`, children: isOpen ? _jsx(ChevronUpV2, {}) : _jsx(ChevronDownV2, {}) }))] }), isParent && isOpen && children && (_jsx("div", { className: "ml-p60", children: children }))] }));
};
export default AcceleratorItem;
