import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { BusinessIcon, TeamIcon } from 'assets/harmonic-icons';
import { Tooltip } from 'common/components';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
export const popularFiltersUniqueNames = [
    'company_last_funding_type',
    'company_last_funding_date',
    'company_funding_total',
    'typed_tag_values_v2',
    'company_headcount',
    'company_investor_names',
    'company_created_at',
    'company_founding_date'
];
const entityPopFiltersMap = {
    companies: popularFiltersUniqueNames,
    people: [
        'person_position_company_id',
        'person_position_role_type',
        'person_last_company_id',
        'person_last_role_type'
    ]
};
const PopularFilters = ({ fields, onUpdateComparatorName }) => {
    const currentUrl = window.location.pathname;
    const entityType = currentUrl.includes('/people') ? 'people' : 'companies';
    const getPopularFilters = () => {
        const popularFilters = [];
        entityPopFiltersMap[entityType].forEach((filter) => {
            const foundIndex = fields.findIndex((field) => field.unique_name === filter);
            if (foundIndex !== -1)
                popularFilters.push(fields[foundIndex]);
        });
        return popularFilters;
    };
    const getIcon = (uniqueName) => {
        return uniqueName === 'employee_experience_company_name_text'
            ? TeamIcon
            : BusinessIcon;
    };
    return (_jsx(_Fragment, { children: getPopularFilters().map(({ description, display_name, unique_name, urn }) => (_jsx(Tooltip, { title: description, alignment: "top", children: _jsx(ListItem, { value: unique_name, variant: ListVariant.wrap, testId: unique_name, primaryIcon: getIcon(unique_name), label: display_name, onClick: () => onUpdateComparatorName(unique_name, urn) }) }, unique_name))) }));
};
export default PopularFilters;
