import { SemanticField } from '__generated__/graphql';
export var SemanticOptionsEnum;
(function (SemanticOptionsEnum) {
    SemanticOptionsEnum["PRECISE"] = "Precise";
    SemanticOptionsEnum["STANDARD"] = "Standard";
    SemanticOptionsEnum["BROAD"] = "Broad";
    SemanticOptionsEnum["CUSTOM"] = "Custom"; // For internal users only - via the settings gear
})(SemanticOptionsEnum || (SemanticOptionsEnum = {}));
export const SEMANTIC_OPTIONS = [
    {
        label: SemanticOptionsEnum.PRECISE,
        value: 0.65
    },
    {
        label: SemanticOptionsEnum.STANDARD,
        value: 0.55
    },
    {
        label: SemanticOptionsEnum.BROAD,
        value: 0.45
    }
];
// For POC page (/semantic)
export const DEFAULT_SEMANTIC_POC_SETTINGS = {
    first: 25,
    similarity: SEMANTIC_OPTIONS[1].value,
    k: 1000,
    sortByEngagement: true,
    fieldsToMatch: [
        SemanticField.WEBSITE_TEXT_EMBEDDING,
        SemanticField.EXTERNAL_DESCRIPTION_EMBEDDING
    ],
    query: '',
    after: null,
    expandSemanticQuery: true
};
// For company page
export const DEFAULT_SEMANTIC_INPUTS = {
    query: '',
    k: 1000,
    similarity: SEMANTIC_OPTIONS[1].value
};
