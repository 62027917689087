import { jsx as _jsx } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks';
const ButtonWrapper = ({ skipToChildren, children, onClick, ...props }) => {
    const [showChildren, setShowChildren] = useState(false);
    const outsideClickRef = useOutsideClick(() => setShowChildren(false));
    const childrenWrapper = _jsx("div", { ref: outsideClickRef, children: children });
    if (skipToChildren || showChildren)
        return childrenWrapper;
    return (_jsx(Button, { onClick: () => {
            onClick?.();
            setShowChildren(!showChildren);
        }, ...props }));
};
export default ButtonWrapper;
