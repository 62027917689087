import { jsx as _jsx } from "react/jsx-runtime";
import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import TimelineRange from './CustomRange/TimelineRange';
export var DateWithSelectMode;
(function (DateWithSelectMode) {
    DateWithSelectMode["BEFORE"] = "before";
    DateWithSelectMode["AFTER"] = "after";
    DateWithSelectMode["EXACT"] = "exact";
    DateWithSelectMode["BETWEEN"] = "between";
    DateWithSelectMode["ROLLING_RANGE"] = "rollingRange";
})(DateWithSelectMode || (DateWithSelectMode = {}));
const DateWithSelect = ({ mode = DateWithSelectMode.BEFORE, value, onChange, label, dataTestId }) => {
    const handleOnChange = (mode, value) => {
        if (isNil(mode) || isNil(value)) {
            onChange(undefined, undefined);
            return;
        }
        if (mode === BackendCustomRangeModeType.CUSTOM) {
            if (value[0] === null && value[1]) {
                onChange(DateWithSelectMode.BEFORE, value[1]);
                return;
            }
            if (value[0] && value[1] === null) {
                onChange(DateWithSelectMode.AFTER, value[0]);
                return;
            }
            if (value[0] && value[1]) {
                onChange(DateWithSelectMode.BETWEEN, value);
                return;
            }
        }
        else if (mode === BackendCustomRangeModeType.CUSTOM_ROLLING) {
            onChange(DateWithSelectMode.ROLLING_RANGE, value);
            return;
        }
    };
    const newValue = useMemo(() => {
        if (!value) {
            return null;
        }
        if (mode === DateWithSelectMode.BEFORE) {
            return [null, value];
        }
        if (mode === DateWithSelectMode.AFTER) {
            return [value, null];
        }
        return value;
    }, [mode, value]);
    return (_jsx("div", { className: "flex flex-col gap-g20", children: _jsx("div", { className: "flex gap-g30", children: _jsx(TimelineRange, { mode: mode === DateWithSelectMode.ROLLING_RANGE
                    ? BackendCustomRangeModeType.CUSTOM_ROLLING
                    : BackendCustomRangeModeType.CUSTOM, value: newValue, onChange: handleOnChange, label: label, dataTestId: dataTestId }) }) }));
};
export default DateWithSelect;
