import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import classNames from 'classnames';
import { isBoolean } from 'lodash';
/**
 * @param children - The element that will trigger the tooltip
 * @param body - what the tooltip will display
 */
export const Tooltip = _ref => {
  let {
    title,
    body,
    shortcutChipText,
    children,
    alignment,
    interactive,
    open,
    enterDelayMs = 0,
    arrow = true,
    disabled = false,
    fullWidth = false
  } = _ref;
  let openProps = {};
  if (isBoolean(open)) openProps = {
    open
  };
  const getTooltipText = () => {
    // empty strings will not get rendered by MUI (Zero-length titles string are never displayed)
    if (!title && !body && !shortcutChipText) return '';
    return _jsxs("div", {
      className: classNames('flex justify-between gap-g40', {
        'items-center': !body
      }),
      children: [_jsxs("div", {
        children: [title && _jsx("p", {
          className: "text-content-inverted-title typography-paragraph-small-strong",
          children: title
        }), body && _jsx("div", {
          className: "text-content-inverted-default typography-paragraph-small",
          children: body
        })]
      }), shortcutChipText && _jsx("div", {
        children: _jsx("button", {
          className: "px-p40 py-p10 rounded-br20 bg-surface-inverted-raised",
          children: _jsx("span", {
            className: "px-p10 text-content-inverted-default typography-label-small",
            children: shortcutChipText
          })
        })
      })]
    });
  };
  return _jsx(MaterialTooltip, {
    ...openProps,
    placement: alignment,
    arrow: arrow,
    interactive: interactive,
    enterDelay: enterDelayMs,
    enterNextDelay: enterDelayMs,
    disableHoverListener: disabled,
    disableFocusListener: disabled,
    disableTouchListener: disabled,
    classes: {
      tooltip: 'bg-surface-inverted-elevated p-p40 rounded-br40',
      arrow: 'text-surface-inverted-elevated'
    },
    title: getTooltipText(),
    children: _jsx("div", {
      className: fullWidth ? 'w-full' : '',
      children: children
    })
  });
};