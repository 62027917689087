import { ResultsView } from 'interfaces/SearchModel/Search';
import { difference, union } from 'lodash';
import { create } from 'zustand';
import { useShallowStoreGeneric } from './util';
const useTableStore = create((set, get) => ({
    customizeFieldModal: {
        open: false
    },
    displayMenuSidebar: {
        open: false
    },
    editFieldsSidebar: {
        open: false
    },
    gridRowHeight: 78,
    selectedRowIds: [],
    selectedNetNewIds: [],
    actionsBarOpen: false,
    addToListOpenInActionsBar: false,
    allRowsSelected: false,
    tableColumns: [],
    totalCount: 0,
    addToListHoverActionOpenForCompanyId: null,
    view: ResultsView.GRID,
    lastGridLoadingTime: null,
    companyListLocalSearchTerm: {},
    personListLocalSearchTerm: {},
    selectAllStateEnabled: false,
    loadedExtendedUrns: {},
    setAddToListHoverActionOpenForCompanyId: (id) => {
        set(() => ({ addToListHoverActionOpenForCompanyId: id }));
    },
    addToLoadedExtendedUrns: (ids) => {
        set((state) => ({
            ...state,
            loadedExtendedUrns: ids.reduce((acc, urn) => ({ ...acc, [urn]: true }), state.loadedExtendedUrns)
        }));
    },
    editTableStoreData: (key, payload) => set((state) => ({
        ...state,
        [key]: payload
    })),
    editSidebar: (key, payload) => {
        // Close all sidebars and apply the new state of the sidebar that is being edited
        set((state) => ({
            ...state,
            customizeFieldModal: {
                ...state.customizeFieldModal,
                open: false
            },
            displayMenuSidebar: {
                ...state.displayMenuSidebar,
                open: false
            },
            editFieldsSidebar: {
                ...state.editFieldsSidebar,
                open: false
            },
            [key]: payload
        }));
    },
    isSidebarOpen: () => {
        return (get().customizeFieldModal.open ||
            get().displayMenuSidebar.open ||
            get().editFieldsSidebar.open);
    },
    addSelectedRowIds: (rowIds) => {
        set((state) => {
            const currentlySelectedRows = state.selectedRowIds;
            const updatedRowIds = union(currentlySelectedRows, rowIds);
            return {
                selectedRowIds: updatedRowIds
            };
        });
    },
    removeSelectedRowIds: (rowIds) => {
        set((state) => {
            const currentlySelectedRows = state.selectedRowIds;
            const updatedRowIds = difference(currentlySelectedRows, rowIds);
            return {
                selectedRowIds: updatedRowIds
            };
        });
    },
    addSelectedNetNewIds: (rowIds) => {
        set((state) => {
            const currentlySelectedRows = state.selectedNetNewIds;
            const updatedRowIds = union(currentlySelectedRows, rowIds);
            return {
                selectedNetNewIds: updatedRowIds
            };
        });
    },
    removeSelectedNetNewIds: (rowIds) => {
        set((state) => {
            const currentlySelectedRows = state.selectedNetNewIds;
            const updatedRowIds = difference(currentlySelectedRows, rowIds);
            return {
                selectedNetNewIds: updatedRowIds
            };
        });
    }
}));
// Only re-render the component when the selected keys change
export const useShallowTableStore = (keys) => useShallowStoreGeneric(useTableStore, keys);
export default useTableStore;
