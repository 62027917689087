import { jsx as _jsx } from "react/jsx-runtime";
import { urnFrom } from 'common/utils/urn';
import NetworkBadgeWithTooltip from 'components/common/Network/NetworkBadgeWithTooltip';
import { EntityType } from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import { get, isNil, uniqBy } from 'lodash';
import React from 'react';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { useAppState } from '../../../../../hooks/useAppState';
import { DASHBOARD_ROUTE, SPLITS } from '../../../../../utils/constants';
import { CompanyNavbarOptions } from '../../../Company/CompanyHeader/CompanyHeader';
import { NoDataSubRenderer } from '../CellRenderers';
export const UserConnections = (props) => {
    const userConnections = props.value;
    const company = get(props, 'node.data.company');
    const person = get(props, 'node.data.person');
    const field = props.colDef?.field ?? '';
    const entityType = !isNil(company) ? EntityType.COMPANY : EntityType.PERSON;
    const entityId = entityType === EntityType.COMPANY ? company?.id : person?.id;
    const entityName = (entityType === EntityType.COMPANY
        ? company?.name
        : get(person, 'fullName')) ?? '';
    const currentUserEmail = useAppState((state) => state.auth.user?.email ?? '');
    const { enabled: useNetworkMappingNewTooltips } = useFlags(SPLITS.networkMappingNewTooltips);
    if (!entityId) {
        return _jsx(NoDataSubRenderer, {});
    }
    const items = React.useMemo(() => {
        const connections = (userConnections ?? []).map((connection) => ({
            ...connection,
            user: {
                ...connection.user,
                name: connection.user?.email === currentUserEmail
                    ? 'You'
                    : connection.user?.name
            }
        }));
        return uniqBy(connections, (connection) => connection.user?.email).map((connection) => ({
            text: connection.user?.name ?? connection.user?.email ?? '',
            href: field.includes('company.')
                ? `${DASHBOARD_ROUTE}/company/${company?.id}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
                : `${DASHBOARD_ROUTE}/person/${person?.id}`
        }));
    }, [userConnections, currentUserEmail, field, company?.id, person?.id]);
    if (items.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    if (useNetworkMappingNewTooltips) {
        return (_jsx("div", { className: "inline-block", children: _jsx(NetworkBadgeWithTooltip, { targetEntityUrn: urnFrom(entityType, entityId), targetName: entityName, usersInNetwork: userConnections?.map((connection) => ({
                    name: connection.user?.name ?? connection.user?.email ?? '',
                    email: connection.user?.email ?? ''
                })) ?? [], includeSummaryText: true }) }));
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: items.map((item, index) => (_jsx("div", { children: _jsx(PersonAttribute, { src: "", name: item.text, href: item.href }) }, index))) }) }));
};
