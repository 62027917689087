import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import React from 'react';
const ChipSelect = ({ selectedValue, onValueChange, options, disabled }) => {
    return (_jsx("div", { className: classnames('flex gap-2 flex-wrap', {
            'opacity-50': disabled
        }), children: options.map((option) => (_jsx(RadioPill, { label: option.label, selected: selectedValue === option.value, onClick: () => onValueChange(option.value) }, option.value))) }));
};
export default ChipSelect;
