import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TrashcanIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { getBackgroundColorClassFromShorthandAndIntensity } from 'common/utils/design';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks';
export const colorOptions = [
    {
        label: 'Grey',
        color: 'neutral'
    },
    {
        label: 'Red',
        color: 'expressive-45'
    },
    {
        label: 'Orange',
        color: 'expressive-5'
    },
    {
        label: 'Yellow',
        color: 'expressive-8'
    },
    {
        label: 'Green',
        color: 'expressive-11'
    },
    {
        label: 'Teal',
        color: 'expressive-22'
    },
    {
        label: 'Blue',
        color: 'expressive-27'
    },
    {
        label: 'Purple',
        color: 'expressive-31'
    },
    {
        label: 'Lavender',
        color: 'expressive-35'
    },
    {
        label: 'Pink',
        color: 'expressive-39'
    }
];
const EditOptionItem = ({ name, color, onEdit, onDelete, handleClose }) => {
    const [inputValue, setInputValue] = useState(name);
    const saveEdit = () => {
        onEdit(inputValue, color);
    };
    const outsideClickRef = useOutsideClick(() => {
        saveEdit();
        handleClose();
    });
    return (_jsx("div", { ref: outsideClickRef, children: _jsxs(Dropdown, { children: [_jsx(TextInput, { value: inputValue, onChange: (e) => setInputValue(e.target.value), autoFocus: true, onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            saveEdit();
                        }
                    } }), _jsx("div", { className: "w-full", children: colorOptions.map((option) => {
                        return (_jsx(ListItem, { label: option.label, value: option.color, primaryIcon: () => (_jsx("svg", { className: classNames(`w-4 h-4 ${getBackgroundColorClassFromShorthandAndIntensity(option.color, 'subtle')}`) })), selected: color === option.color, onClick: (e) => {
                                e?.stopPropagation();
                                onEdit(inputValue, option.color);
                                handleClose();
                            }, variant: ListVariant.default }, option.color));
                    }) }), _jsx("div", { className: "border-t border-solid border-border w-full", children: _jsx(ListItem, { label: "Delete option", variant: ListVariant.default, value: "Delete option", onClick: onDelete, destructive: true, primaryIcon: TrashcanIcon }) })] }) }));
};
export default EditOptionItem;
