import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown, ChevronLeft, ChevronRight } from 'assets/harmonic-icons';
import { DateTypeEnum } from 'components/QueryBuilder/Common/CustomRange/DateModal';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { isNil, range } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useOutsideClick } from 'utils/hooks';
import './datepicker.css';
const years = range(1990, dayjs(new Date()).get('year') + 1, 1).reverse();
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
const DatePickerHeader = ({ decreaseMonth, prevMonthButtonDisabled, date, changeYear, changeMonth, increaseMonth, increaseYear, decreaseYear, nextMonthButtonDisabled, selectedDate, dateType = DateTypeEnum.DAY, onChange }) => {
    const [yearOpen, setYearOpen] = useState(false);
    // When Today button is clicked, the header year and month changes to current date
    useEffect(() => {
        if (isNil(selectedDate) || !dayjs(selectedDate).isValid())
            return;
        const year = dayjs(selectedDate).year();
        const month = dayjs(selectedDate).month();
        if (year !== dayjs(date).year() || month !== dayjs(date).month()) {
            changeYear(year);
            changeMonth(month);
        }
    }, [selectedDate, changeMonth, changeYear]);
    const yearRef = useOutsideClick(() => setYearOpen(false));
    const isYear = dateType === DateTypeEnum.YEAR;
    const getLabel = isYear
        ? dayjs(date).year().toString()
        : `${months[dayjs(date).month()]} ${dayjs(date).year()}`;
    const onDecreaseButtonClick = isYear ? decreaseYear : decreaseMonth;
    const onIncreaseButtonClick = isYear ? increaseYear : increaseMonth;
    return (_jsxs("div", { className: "flex w-full justify-between p-p30", children: [_jsx("div", { children: _jsx(IconButton, { icon: ChevronLeft, type: "secondary", emphasis: "medium", size: "compact", onClick: onDecreaseButtonClick, isDisabled: prevMonthButtonDisabled, dataTestId: "DatePickerHeader-PrevMonthButton" }) }), _jsxs("div", { ref: yearRef, className: "relative", children: [_jsx(Button, { type: "secondary", emphasis: "low", size: "compact", label: getLabel, onClick: () => setYearOpen(true), dataTestId: "DatePickerHeader-YearButton", trailingIcon: ChevronDown }), yearOpen && (_jsx("div", { className: "mt-p20 w-[130px] flex flex-col h-[200px] overflow-y-hidden absolute z-10", children: _jsx(Dropdown, { children: years.map((option) => (_jsx(ListItem, { variant: ListVariant.default, value: option.toString(), label: option.toString(), selected: option.toString() === dayjs(date).year().toString(), onClick: (e) => {
                                    if (onChange && isYear)
                                        onChange(new Date(option, 0));
                                    setYearOpen(false);
                                    changeYear(option);
                                } }, option))) }) }))] }), _jsx(IconButton, { icon: ChevronRight, type: "secondary", emphasis: "medium", size: "compact", onClick: onIncreaseButtonClick, dataTestId: "DatePickerHeader-NextMonthButton", isDisabled: nextMonthButtonDisabled })] }));
};
export default DatePickerHeader;
