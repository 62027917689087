import { useQuery } from '@apollo/client';
import { GET_PEOPLE_WATCHLIST_DIGEST_CONFIG } from 'queries/getPeopleWatchlistDigestConfig';
import { useMemo } from 'react';
const usePeopleWatchlistEnabledDigests = () => {
    const { data: getPeopleWatchlistDigestConfig } = useQuery(GET_PEOPLE_WATCHLIST_DIGEST_CONFIG);
    const enabledPeopleWatchlistDigests = useMemo(() => {
        const digests = new Set();
        const config = getPeopleWatchlistDigestConfig?.getPeopleWatchlistDigestConfig;
        if (!config || !config.peopleWatchlists)
            return digests;
        config.peopleWatchlists.forEach((wl) => {
            digests.add(wl);
        });
        return digests;
    }, [getPeopleWatchlistDigestConfig]);
    const hasEnabledPeopleWatchlistDigest = (watchlistUrn) => {
        if (!watchlistUrn)
            return false;
        return enabledPeopleWatchlistDigests.has(watchlistUrn);
    };
    return { hasEnabledPeopleWatchlistDigest };
};
export default usePeopleWatchlistEnabledDigests;
