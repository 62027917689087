import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
export var DashboardLocation;
(function (DashboardLocation) {
    DashboardLocation["DISCOVER_DASHBOARD"] = "discover_dashboard";
    DashboardLocation["COMPANY_SEARCH"] = "company_search";
    DashboardLocation["PEOPLE_SEARCH"] = "people_search";
    DashboardLocation["COMPANY_LIST"] = "company_list";
    DashboardLocation["PERSON_LIST"] = "person_list";
    DashboardLocation["PERSON_PROFILE"] = "person_profile";
    DashboardLocation["COMPANY_PROFILE"] = "company_profile";
    DashboardLocation["INVESTOR_PROFILE"] = "investor_profile";
    DashboardLocation["LISTS"] = "lists";
    DashboardLocation["SEARCHES"] = "searches";
    DashboardLocation["SEARCH_PAGE"] = "search_page";
    DashboardLocation["SEMANTIC"] = "semantic";
})(DashboardLocation || (DashboardLocation = {}));
export var EntityType;
(function (EntityType) {
    EntityType["COMPANY"] = "company";
    EntityType["PERSON"] = "person";
    EntityType["INVESTOR"] = "investor";
})(EntityType || (EntityType = {}));
const useDashboardLocation = () => {
    const params = useParams();
    const { pathname } = useLocation();
    const urn = useMemo(() => params['*']?.match(/urn:(.*)/)?.[0], [params]);
    const location = useMemo(() => {
        if (pathname.includes('/dashboard/companies')) {
            return DashboardLocation.COMPANY_SEARCH;
        }
        else if (pathname.includes('/dashboard/people_watchlist')) {
            return DashboardLocation.PERSON_LIST;
        }
        else if (pathname.includes('/dashboard/people')) {
            return DashboardLocation.PEOPLE_SEARCH;
        }
        else if (pathname.includes('/dashboard/watchlist')) {
            return DashboardLocation.COMPANY_LIST;
        }
        else if (pathname.includes('/dashboard/company')) {
            return DashboardLocation.COMPANY_PROFILE;
        }
        else if (pathname.includes('/dashboard/person')) {
            return DashboardLocation.PERSON_PROFILE;
        }
        else if (pathname.includes('/dashboard/investor')) {
            return DashboardLocation.INVESTOR_PROFILE;
        }
        else if (pathname.includes('/dashboard/lists')) {
            return DashboardLocation.LISTS;
        }
        else if (pathname.includes('/dashboard/searches')) {
            return DashboardLocation.SEARCHES;
        }
        else if (pathname.includes('/dashboard/search')) {
            return DashboardLocation.SEARCH_PAGE;
        }
        else if (pathname.includes('/dashboard/semantic')) {
            return DashboardLocation.SEMANTIC;
        }
    }, [pathname]);
    return {
        location,
        urn,
        entityType: location?.includes('company')
            ? EntityType.COMPANY
            : EntityType.PERSON
    };
};
export default useDashboardLocation;
