import { useHotkeys } from 'react-hotkeys-hook';
import usePersistedZustandStore from 'stores/persistedZustandStore';
import useStore from 'stores/zustandStore';
// Library limitation where it does not directly provide scopes for a given hotkey.
// Hence, we are storing the scope in the description field.
const generateHotKeyDescription = (scopes, description) => {
    if (!scopes) {
        return description;
    }
    if (Array.isArray(scopes)) {
        return `${scopes.join(', ')} -- ${description}`;
    }
    return `${scopes} -- ${description}`;
};
export const extractScopeAndDescription = (text) => {
    const [scope, description] = text.split(' -- ');
    return { scope, description };
};
const useHotkeysWrapper = ({ keys, callback, options, dependencies, forceEnable // if true, hotkeys will be enabled regardless of globalEnableHotkeys
 }) => {
    // If hotkeys are globally enabled (i.e. user explicitly enables them in settings)
    const globalEnableHotkeys = usePersistedZustandStore((state) => state.globalEnableHotkeys);
    // If hotkeys are temporarily disabled (i.e. because a modal is open)
    const disableHotkeys = useStore((state) => state.disableHotkeys);
    const description = generateHotKeyDescription(options?.scopes, options?.description);
    const amendedOptions = {
        ...options,
        description,
        enabled: (globalEnableHotkeys || forceEnable) &&
            !disableHotkeys &&
            (options?.enabled === undefined || options.enabled)
    };
    return useHotkeys(keys, callback, amendedOptions, dependencies);
};
export default useHotkeysWrapper;
