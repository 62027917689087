import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { usePerformanceLogging } from '../../../../hooks/usePerformanceLogging';
import { LOADING_ROW_ID } from '../../../../utils/constants';
import { defaultColDef } from '../../../../utils/grid';
import { CellRenderers, LoadingCellRenderer } from './CellRenderers';
import { useIsExtendedLoading } from './hooks/useIsExtendedLoading';
export const CommonCellRenderer = (props) => {
    const { logGridFirstDataRendered, logGridFirstExtendedDataRendered } = usePerformanceLogging();
    const isExtendedLoading = useIsExtendedLoading(props);
    const { column, headers, header, data, node } = props;
    const cellRendererName = header.cellRenderer || defaultColDef.cellRenderer;
    const rowIndex = node?.rowIndex ?? 0;
    const field = column?.getColDef().field;
    const isExtended = field && !headers[field];
    useEffect(() => {
        if (rowIndex === 0 && data) {
            if (!isExtended) {
                logGridFirstDataRendered();
            }
            else {
                logGridFirstExtendedDataRendered();
            }
        }
    }, [
        rowIndex,
        data,
        isExtended,
        logGridFirstDataRendered,
        logGridFirstExtendedDataRendered
    ]);
    if ((isExtended && isExtendedLoading) ||
        (!isExtended && !data) ||
        data?.id === LOADING_ROW_ID) {
        return _jsx(LoadingCellRenderer, {});
    }
    const Component = CellRenderers[cellRendererName];
    if (!Component)
        return null;
    return _jsx(Component, { ...props });
};
