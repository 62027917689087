// helper function for now as we migrate things over to the new structure
// we need an array for the select component as that is what it expects
import { chipDisplayOrder } from '../RegionPresets';
export const parseSelectedLocations = (locationState) => {
    if (!locationState) {
        return {
            locationObject: [],
            includedLocationsArray: [],
            excludedLocationsArray: []
        };
    }
    const locationObject = [];
    const locationArray = [];
    const excludedLocationArray = [];
    const keys = [
        'city',
        'state',
        'country',
        'region'
    ];
    for (const key of keys) {
        if (locationState[key]) {
            locationState[key]?.forEach((value) => {
                if (key === 'region') {
                    value =
                        chipDisplayOrder.find((chip) => chip.region === value)?.title ??
                            value;
                }
                locationObject.push({
                    text: value,
                    type: key
                });
                locationArray.push(value);
            });
        }
    }
    locationState.locations?.forEach((location) => {
        locationObject.push({ ...location, type: 'locations' });
        locationArray.push(location.text);
    });
    locationState.excluded_locations?.forEach((location) => {
        excludedLocationArray.push(location.text);
        locationObject.push({
            ...location,
            type: 'excluded_locations'
        });
    });
    return {
        locationObject,
        includedLocationsArray: locationArray,
        excludedLocationsArray: excludedLocationArray
    };
};
