import { jsx as _jsx } from "react/jsx-runtime";
import Slider from '@material-ui/core/Slider';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getClosestNumberIndexInAnArray } from 'utils/utilities';
import ThumbComponent from './ThumbComponent';
// Converts slider internal smaller value to the big values from the stepRanges array that we passed
const convertSliderValueToExternalValue = (stepIndex, stepRanges) => {
    return stepRanges[stepIndex];
};
// Converts big numbers to internal state smaller value(usually from 1 to 100 at max).
// Simply it stores the index to map to stepRanges value.
const convertExternalValueToSliderValue = (value, stepRanges) => {
    if (!stepRanges)
        return value;
    const minimumElementIndex = getClosestNumberIndexInAnArray(value[0], stepRanges);
    const maximumElementIndex = getClosestNumberIndexInAnArray(value[1], stepRanges);
    return [minimumElementIndex, maximumElementIndex];
};
const RangeSlider = ({ value, onValueChange, stepRanges, isActive, disabled }) => {
    const [currentRangeValue, setCurrentRangeValue] = useState(convertExternalValueToSliderValue(value, stepRanges));
    useEffect(() => {
        setCurrentRangeValue(convertExternalValueToSliderValue(value, stepRanges));
    }, [value, stepRanges]);
    const onSliderValueChange = (newValue) => {
        if (!stepRanges)
            return onValueChange(newValue);
        const formattedMinValue = convertSliderValueToExternalValue(newValue[0], stepRanges);
        const formattedMaxValue = convertSliderValueToExternalValue(newValue[1], stepRanges);
        const formattedValue = [
            newValue[0] === currentRangeValue[0] ? value[0] : formattedMinValue,
            newValue[1] === currentRangeValue[1] ? value[1] : formattedMaxValue
        ];
        onValueChange(formattedValue);
    };
    return (_jsx(Slider, { value: currentRangeValue, onChange: (_, newValue) => onSliderValueChange(newValue), classes: {
            root: classNames('w-full shadow-none', {
                'opacity-50': disabled
            }, {
                'text-content-default': isActive,
                'text-border': !isActive
            }),
            rail: 'text-border h-[2px]',
            thumb: '-mt-2'
        }, max: stepRanges.length - 1, min: 0, step: 1, disabled: disabled, ThumbComponent: (props) => (
        //eslint-disable-next-line
        //@ts-ignore
        _jsx(ThumbComponent, { ...props, disabled: disabled, selected: isActive })), "aria-labelledby": "range-slider" }));
};
export default RangeSlider;
