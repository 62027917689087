import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoCircle from 'assets/harmonic-icons/info-circle';
import { Tooltip } from 'common';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import { HighlightCategory } from 'utils/constants';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import Checkboxes from '../Common/Checkboxes';
import { SubSectionHeader } from '../Common/Typography';
const peopleHighlightOptions = [
    {
        label: 'Prior exit',
        value: HighlightCategory.PRIOR_EXIT
    },
    {
        label: 'Free agent',
        value: HighlightCategory.FREE_AGENT
    },
    {
        label: 'Major tech company experience',
        value: HighlightCategory.MAJOR_TECH_COMPANY_EXPERIENCE
    },
    {
        label: 'Top university',
        value: HighlightCategory.TOP_UNIVERSITY
    },
    {
        label: 'HBCU Alum',
        value: HighlightCategory.HBCU_ALUM
    },
    {
        label: 'Top company alum',
        value: HighlightCategory.TOP_COMPANY_ALUM
    },
    {
        label: 'Prior VC-backed founder',
        value: HighlightCategory.PRIOR_VC_BACKED_FOUNDER
    },
    {
        label: 'Prior VC-backed executive',
        value: HighlightCategory.PRIOR_VC_BACKED_EXECUTIVE
    },
    {
        label: 'Deep technical background',
        value: HighlightCategory.DEEP_TECHNICAL_BACKGROUND
    },
    {
        label: 'Major research institution experience',
        value: HighlightCategory.MAJOR_RESEARCH_INSTITUTION_EXPERIENCE
    },
    {
        label: 'Previously raised $5M+',
        value: HighlightCategory.FIVE_M_CLUB
    },
    {
        label: 'Elite industry experience',
        value: HighlightCategory.ELITE_INDUSTRY_EXPERIENCE
    },
    {
        label: 'Previously raised $10M+',
        value: HighlightCategory.TEN_M_CLUB
    },
    {
        label: 'Seasoned operator',
        value: HighlightCategory.SEASONED_OPERATOR
    },
    {
        label: 'Previously raised $50M+',
        value: HighlightCategory.FIFTY_M_PLUS_CLUB
    },
    {
        label: 'Seasoned founder',
        value: HighlightCategory.SEASONED_FOUNDER
    },
    {
        label: 'YC backed founder',
        value: HighlightCategory.YC_BACKED_FOUNDER
    },
    {
        label: 'Top AI experience',
        value: HighlightCategory.TOP_AI_EXPERIENCE
    },
    {
        label: 'Top Web3 experience',
        value: HighlightCategory.TOP_WEB3_EXPERIENCE
    }
];
const GeneralHighlightsSubSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const [selectAllHighlightsChecked, setSelectAllHighlightsChecked] = React.useState(false);
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const currentHighlightsState = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS];
    const updateValues = (newValues) => {
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS, newValues !== undefined
            ? {
                ...newValues
            }
            : undefined));
    };
    const onToggleSelectAllHighlights = () => {
        if (selectAllHighlightsChecked) {
            updateValues(undefined);
        }
        else {
            updateValues({ highlights: peopleHighlightOptions.map((o) => o.value) });
        }
        setSelectAllHighlightsChecked(!selectAllHighlightsChecked);
    };
    const peopleHighlights = currentHighlightsState?.highlights ?? [];
    return (_jsxs("div", { "data-testid": "Highlights-Section", className: "w-full", children: [_jsx(SubSectionHeader, { children: _jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex flex-row items-center", children: ["Highlights", _jsx("div", { className: "ml-p20", children: _jsx(Tooltip, { body: "Learn more about our highlights", interactive: true, children: _jsx("a", { href: "https://support.harmonic.ai/en/articles/5881310-highlights", target: "_blank", rel: "noopener noreferrer", children: _jsx(IconButton, { icon: InfoCircle, size: "tiny", emphasis: "low", onClick: () => { } }) }) }) })] }), _jsx(Checkbox, { onChange: onToggleSelectAllHighlights, checked: selectAllHighlightsChecked, variant: selectAllHighlightsChecked ? 'default' : 'indeterminate', label: "Select all highlights", labelAlignment: "left", id: "select-all-founder", dataTestId: "Founder-Highlights-Select-All" })] }) }), _jsx("div", { className: "flex flex-col gap-g90", children: _jsx(Checkboxes, { dataTestId: "Team-Founder-Highlights-Checkboxes", options: peopleHighlightOptions, selectedValues: peopleHighlights, onValueChange: (newValues) => updateValues(!newValues
                        ? undefined
                        : { highlights: newValues }), disabled: disabled }) })] }));
};
export default GeneralHighlightsSubSection;
