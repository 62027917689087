import { PeopleWatchlistType, SearchType, UserWatchlistType } from '__generated__/graphql';
import { EntityType } from 'hooks/useDashboardLocation';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { isNil } from 'lodash';
import { CollectionType, CreatorEnum, VisibilityEnum } from './types';
import { initialGridFilterState } from './useCollectionsStore';
export const SPECIAL_LIST_TYPES = [
    UserWatchlistType.USER_FAVORITED_ENTITIES,
    UserWatchlistType.USER_HIDDEN_ENTITIES,
    PeopleWatchlistType.TEAM_CONNECTIONS,
    PeopleWatchlistType.USER_CONNECTIONS
];
const CREATED_BY_HARMONIC = {
    name: 'Harmonic',
    email: undefined,
    entityUrn: undefined
};
// Type guard so TypeScript doesn't complain
export function isNotNull(value) {
    return !isNil(value);
}
export const isOwnerThisUser = (owner, userUrn) => {
    return !!(owner?.__typename === 'User' &&
        userUrn &&
        owner.entityUrn === userUrn);
};
// Type guard to check if an object has `owner`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasOwner(entity) {
    return entity && 'owner' in entity && entity.owner?.entityUrn !== undefined;
}
// Type guard to check if an object has `creator`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasCreator(entity) {
    return (entity && 'creator' in entity && entity.creator?.entityUrn !== undefined);
}
export const getOwnerEmail = (owner) => {
    return owner?.__typename === 'User' ? owner.email || null : null;
};
export const getOwnerName = (owner) => {
    return owner?.name || null;
};
// Common conditions for both lists and saved searches
const getCommonConditions = (gridFilterSettings, currentUser) => {
    const conditions = [];
    if (gridFilterSettings.creator !== CreatorEnum.anyone) {
        conditions.push((item) => !!item?.creator &&
            'entityUrn' in item.creator &&
            item?.creator?.entityUrn === currentUser);
    }
    if (gridFilterSettings.visibility !== VisibilityEnum.any) {
        conditions.push((item) => gridFilterSettings.visibility === VisibilityEnum.private
            ? !item.sharedWithTeam
            : item.sharedWithTeam);
    }
    return conditions;
};
// List-specific filtering and mapping functions
const filterListData = (listData, currentTab, conditions) => {
    const currentTabData = [
        ...(listData.getCompanyWatchlistsForTeam || []),
        ...(listData.getPeopleWatchlistsForTeam || [])
    ];
    const data = currentTabData
        .filter(isNotNull)
        .map((item) => {
        // check if the list is a company watchlist or a people watchlist else throw an error
        if (!item.entityUrn.includes('company_watchlist') &&
            !item.entityUrn.includes('people_watchlist')) {
            throw new Error('Invalid list type');
        }
        const entityType = item.entityUrn.includes('company_watchlist')
            ? EntityListType.COMPANY_WATCHLIST
            : EntityListType.PEOPLE_WATCHLIST;
        const isCreatedByHarmonic = SPECIAL_LIST_TYPES.includes(item.userWatchlistType);
        return {
            ...item,
            url: getUrlFromEntityListType(entityType, item.entityUrn),
            entityType: entityType,
            creator: isCreatedByHarmonic ? CREATED_BY_HARMONIC : item.owner
        };
    })
        .filter((item) => conditions.every((condition) => condition(item)));
    return {
        all: data,
        companies: data.filter((item) => item.entityType === EntityListType.COMPANY_WATCHLIST),
        people: data.filter((item) => item.entityType === EntityListType.PEOPLE_WATCHLIST)
    };
};
// Saved search-specific filtering and mapping functions
const filterSavedSearchData = (savedSearchData, currentTab, conditions) => {
    const data = (savedSearchData.getSavedSearchesForTeam || [])
        .filter(isNotNull)
        .filter((item) => item.type === SearchType.COMPANIES_LIST ||
        item.type === SearchType.PERSONS)
        .map((item) => {
        const entityListType = item.type === SearchType.COMPANIES_LIST
            ? EntityListType.COMPANY_SAVED_SEARCH
            : EntityListType.PEOPLE_SAVED_SEARCH;
        return {
            ...item,
            sharedWithTeam: !item.isPrivate,
            url: getUrlFromEntityListType(entityListType, item.entityUrn),
            entityType: entityListType
        };
    })
        .filter((item) => conditions.every((condition) => condition(item)));
    return {
        all: data,
        companies: data.filter((item) => item.entityType === EntityListType.COMPANY_SAVED_SEARCH),
        people: data.filter((item) => item.entityType === EntityListType.PEOPLE_SAVED_SEARCH)
    };
};
export const getFilteredData = ({ data, currentUser, currentTab, gridFilterSettings, isList }) => {
    if (!data) {
        return { all: [], companies: [], people: [] };
    }
    const conditions = getCommonConditions(gridFilterSettings, currentUser);
    if (isList) {
        const listData = data;
        return filterListData(listData, currentTab, conditions);
    }
    else {
        const savedSearchData = data;
        return filterSavedSearchData(savedSearchData, currentTab, conditions);
    }
};
export const getCollectionTypeFromEntityListType = new Map([
    [EntityListType.COMPANY_WATCHLIST, CollectionType.LISTS],
    [EntityListType.PEOPLE_WATCHLIST, CollectionType.LISTS],
    [EntityListType.COMPANY_SAVED_SEARCH, CollectionType.SEARCHES],
    [EntityListType.PEOPLE_SAVED_SEARCH, CollectionType.SEARCHES]
]);
export const getEntityTypeFromEntityListType = new Map([
    [EntityListType.COMPANY_WATCHLIST, EntityType.COMPANY],
    [EntityListType.PEOPLE_WATCHLIST, EntityType.PERSON],
    [EntityListType.COMPANY_SAVED_SEARCH, EntityType.COMPANY],
    [EntityListType.PEOPLE_SAVED_SEARCH, EntityType.PERSON]
]);
export const getActiveFiltersCount = (gridFilterSettings) => {
    const initialFilterSettings = initialGridFilterState.gridFilterSettings;
    return Object.keys(gridFilterSettings).reduce((acc, key) => gridFilterSettings[key] !==
        initialFilterSettings[key]
        ? acc + 1
        : acc, 0);
};
export const getFavoritedLists = (data) => {
    if (!data) {
        return [];
    }
    const companyLists = data.getCompanyWatchlistsForTeam || [];
    const peopleLists = data.getPeopleWatchlistsForTeam || [];
    const combinedlists = [
        ...companyLists.map((item) => ({
            ...item,
            entityListType: EntityListType.COMPANY_WATCHLIST
        })),
        ...peopleLists.map((item) => ({
            ...item,
            entityListType: EntityListType.PEOPLE_WATCHLIST
        }))
    ].filter(isNotNull);
    return combinedlists.filter((list) => list.userOptions?.isPinned);
};
export const getUrlFromEntityListType = (entityType, entityUrn) => {
    if (!entityUrn || !entityType) {
        throw new Error('entityUrn and entityType are required');
    }
    switch (entityType) {
        case EntityListType.COMPANY_WATCHLIST:
            return `/dashboard/watchlist/${entityUrn}`;
        case EntityListType.PEOPLE_WATCHLIST:
            return `/dashboard/people_watchlist/${entityUrn}`;
        case EntityListType.COMPANY_SAVED_SEARCH:
            return `/dashboard/companies/${entityUrn}`;
        case EntityListType.PEOPLE_SAVED_SEARCH:
            return `/dashboard/people/${entityUrn}`;
    }
};
export const getInputVariableByType = ({ type, id }) => {
    const entityType = getEntityTypeFromEntityListType.get(type);
    return entityType === EntityType.COMPANY
        ? { companyCollectionId: id }
        : { peopleWatchlistId: id };
};
/**
 * sorts net new searches and special watchlist types to the top of the grid view
 * if no sort is currently applied
 */
export const sortRowsToTop = (params, hasNetNew, sortModel) => {
    // only if no sort is currently applied then we bring it to the top
    if (sortModel?.some((sort) => sort.sort))
        return;
    const rowNodes = params.nodes;
    let nextInsertPos = 0;
    for (let i = 0; i < rowNodes.length; i++) {
        const userWatchlistType = rowNodes[i].data?.userWatchlistType;
        if (SPECIAL_LIST_TYPES.includes(userWatchlistType) ||
            hasNetNew(rowNodes[i]?.data?.entityUrn)) {
            rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
            nextInsertPos++;
        }
    }
};
