import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloClient, useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import { ReactComponent as AffinityConfirmationImage } from 'assets/affinity-confirmation-image.svg';
import { AffinityIcon, CircleCheckIcon, ListIcon } from 'assets/harmonic-icons';
import { Tooltip } from 'common/components/Tooltip';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import Select from 'harmonic-components/Select/Select';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { getAffinityWatchlistStatusOwnerOptions, getCustomerAffinityApiKeyIsSet, pushToAffinity, removeFromAffinity } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import { HarmonicLoader } from '../ResultsWrapper/LoadingOverlay';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';
const AddCompanyToAffinityButton = ({ companyId, isDisabled, tooltipText }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterTerm, setFilterTerm] = useState('');
    const { affinityLists } = useFetchWatchlists();
    const { getWatchlistContainingCompanies } = useWatchlistActions();
    const { show } = useModal();
    const [isAffinityIntegrated, setIsAffinityIntegrated] = useState(false);
    const [loadingAffinityIntegrationStatus, setLoadingAffinityIntegrationStatus] = useState(false);
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    useEffect(() => {
        setLoadingAffinityIntegrationStatus(true);
        if (affinityIntegrationMocked) {
            setIsAffinityIntegrated(true);
            setLoadingAffinityIntegrationStatus(false);
            return;
        }
        getCustomerAffinityApiKeyIsSet()
            .then((isSet) => {
            if (isSet) {
                setIsAffinityIntegrated(isSet);
            }
        })
            .finally(() => setLoadingAffinityIntegrationStatus(false));
    }, [affinityIntegrationMocked]);
    const handleClose = () => {
        setFilterTerm('');
        setAnchorEl(null);
    };
    const handleOpen = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleAddToCRMClick = () => {
        show(_jsx(CRMNotSetUpYet, {}));
    };
    const open = Boolean(anchorEl);
    const affinityListsContainingCompany = getWatchlistContainingCompanies(affinityLists, [companyId]);
    const sortedAffinitiyLists = affinityLists.sort((a, b) => {
        const isSelectedA = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === a.id);
        const isSelectedB = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === b.id);
        // If one of them is selected and the other is not, the selected one should come first
        if (isSelectedA && !isSelectedB) {
            return -1;
        }
        else if (!isSelectedA && isSelectedB) {
            return 1;
        }
        else {
            // If both are selected or both are not selected, sort alphabetically
            return a.name.localeCompare(b.name);
        }
    });
    const filteredAffinityLists = sortedAffinitiyLists.filter((affinityList) => affinityList.name.toLowerCase().includes(filterTerm.toLocaleLowerCase()));
    if (loadingAffinityIntegrationStatus) {
        return _jsx(AddCompanytoWatchlistButtonContentLoader, {});
    }
    if (!isAffinityIntegrated) {
        return _jsx(Button, { onClick: handleAddToCRMClick, label: "Add to CRM" });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: tooltipText, children: _jsx(Button, { dataTestId: "AddCompanyToAffinityButton", leadingIcon: affinityListsContainingCompany.length > 0
                        ? CircleCheckIcon
                        : AffinityIcon, onClick: handleOpen, label: affinityListsContainingCompany.length > 0
                        ? 'In Affinity'
                        : 'Add to Affinity', isDisabled: isDisabled, isSelected: open }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, className: "mt-p40", children: _jsx("div", { className: "w-[264px] truncate line-clamp-1", children: _jsx(Dropdown, { filterPlaceholder: "Search Affinity lists", filterTerm: filterTerm, onFilterTermChange: (value) => setFilterTerm(value), children: filteredAffinityLists.map((affinityList) => {
                            const isSelected = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === affinityList.id);
                            return (_jsx(ListItem, { variant: ListVariant.checkboxes, label: affinityList.name, value: affinityList.id, onChange: () => {
                                    handleClose();
                                    if (isSelected) {
                                        show(_jsx(RemoveCompanyFromAffinity, { companyId: companyId, affinityListId: affinityList.id }));
                                    }
                                    else {
                                        show(_jsx(AddCompanyToAffinityDetails, { companyId: companyId, affinityListId: affinityList.id }));
                                    }
                                }, selected: isSelected, primaryIcon: AffinityIcon }, affinityList.id));
                        }) }) }) })] }));
};
const CRMNotSetUpYet = () => {
    const { close } = useModal();
    const navigate = useNavigate();
    const onSubmit = () => {
        navigate('/settings?t=integrations');
        close();
    };
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: "No CRM integrated yet" }), _jsx("div", { className: "flex flex-col items-start self-stretch p-p60 max-w-[360px]", children: _jsx("p", { className: "text-content-weak typography-label-default-default", children: "Please integrate with your CRM first. Please visit the setup page to go through the setup." }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", onClick: () => close() }), _jsx(Button, { type: "primary", label: "Set up CRM Integration", onClick: onSubmit })] })] }));
};
const RemoveCompanyFromAffinity = ({ companyId, affinityListId }) => {
    const client = useApolloClient();
    const { data: companyProfileData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const { affinityLists } = useFetchWatchlists();
    const { close } = useModal();
    const selectedAffinityList = affinityLists.find((affinityList) => affinityList.id === affinityListId);
    const [loading, setLoading] = useState(false);
    const companyName = companyProfileData?.getCompanyById?.name ?? '';
    const affinityListName = selectedAffinityList?.name ?? '';
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const removeCompanyFromAffinityListCache = (affinityListId, companyId) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Company',
                id: companyId
            }), // The id of the company in the cache.
            fields: {
                watchlists: (existingWatchlists) => {
                    return existingWatchlists.filter((watchlistRef) => client.cache.identify({
                        __typename: 'CompanyWatchlist',
                        id: affinityListId
                    }) !== watchlistRef.__ref);
                }
            }
        });
    };
    const onRemove = async () => {
        setLoading(true);
        if (affinityIntegrationMocked) {
            removeCompanyFromAffinityListCache(affinityListId, companyId);
            displayToast({
                primaryText: 'Successfully removed',
                mode: 'success'
            });
            setLoading(false);
            close();
            return;
        }
        await removeFromAffinity(affinityListId, companyId);
        removeCompanyFromAffinityListCache(affinityListId, companyId);
        displayToast({
            primaryText: 'Successfully removed',
            mode: 'success'
        });
        setLoading(false);
        close();
    };
    return (_jsxs("div", { className: "", children: [_jsx(ModalTitle, { title: `Remove from Affinity list` }), _jsx("div", { className: "pb-p80 pt-p20 px-p20 max-w-[360px]", children: _jsxs("p", { className: "text-content-default typography-paragraph-default-default p-p40", children: ["Are you sure you want to remove ", companyName, " from ", affinityListName, "?"] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", onClick: () => close() }), _jsx(Button, { type: "negative", label: "Remove from Affinity list", loading: loading, onClick: onRemove })] })] }));
};
const AddCompanyToAffinityDetails = ({ companyId, affinityListId }) => {
    const client = useApolloClient();
    const { data: companyProfileData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const { affinityLists } = useFetchWatchlists();
    const { close, show } = useModal();
    const [currentlySelectedAffinityList, setCurrentlySelectedAffinityList] = useState(affinityListId);
    const [currentlySelectedStatus, setCurrentlySelectedStatus] = useState('');
    const [currentlySelectedOwners, setCurrentlySelectedOwners] = useState([]);
    const [owners, setOwnerOptions] = useState([]);
    const [status_options, setStatusOptions] = useState([]);
    const [pushToAffinityLoading, setPushToAffinityLoading] = useState(false);
    const [fetchConfigLoading, setFetchConfigLoading] = useState(false);
    const companyName = companyProfileData?.getCompanyById?.name ?? '';
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const addCompanyToAffinityListCache = (affinityListId, companyId) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Company',
                id: companyId
            }), // The id of the company in the cache.
            fields: {
                watchlists: (existingWatchlists) => {
                    return [
                        ...existingWatchlists,
                        // Add the reference to the watchlist in the cache
                        {
                            __ref: client.cache.identify({
                                __typename: 'CompanyWatchlist',
                                id: affinityListId
                            })
                        }
                    ];
                }
            }
        });
    };
    const onSubmit = async () => {
        setPushToAffinityLoading(true);
        if (affinityIntegrationMocked) {
            addCompanyToAffinityListCache(currentlySelectedAffinityList, companyId);
            show(_jsx(AddCompanyToAffinityConfirmation, { companyId: companyId, affinityListId: currentlySelectedAffinityList, listUrl: `https://www.harmonic.ai/affinity/lists/${currentlySelectedAffinityList}`, entityViewUrl: `https://www.harmonic.ai/affinity/entities/${companyId}` }));
            setPushToAffinityLoading(false);
            return;
        }
        try {
            const resp = await pushToAffinity(currentlySelectedAffinityList, [companyId], currentlySelectedOwners, currentlySelectedStatus);
            const affinityCompanyUrl = resp.results[`urn:harmonic:company:${companyId}`]?.entity_view_url;
            addCompanyToAffinityListCache(currentlySelectedAffinityList, companyId);
            show(_jsx(AddCompanyToAffinityConfirmation, { companyId: companyId, affinityListId: currentlySelectedAffinityList, listUrl: resp.list_url, entityViewUrl: affinityCompanyUrl }));
        }
        catch (err) {
            logger.error('Error adding company to Affinity', {
                error: err
            });
            displayToast({
                primaryText: 'Error adding company to Affinity. Please try again later',
                mode: 'error'
            });
        }
        finally {
            setPushToAffinityLoading(false);
        }
    };
    useEffect(() => {
        setFetchConfigLoading(true);
        if (affinityIntegrationMocked) {
            setOwnerOptions([
                {
                    urn: 'urn:harmonic:user:1',
                    first_name: 'Carter',
                    last_name: 'Headley',
                    primary_email: 'carter@harmonic.ai'
                },
                {
                    urn: 'urn:harmonic:user:2',
                    primary_email: 'max@harmonic.ai',
                    first_name: 'Max',
                    last_name: 'Ruderman'
                }
            ]);
            setStatusOptions([
                {
                    id: '1',
                    text: 'New'
                },
                {
                    id: '2',
                    text: 'Qualified'
                }
            ]);
            setCurrentlySelectedStatus('1');
            setCurrentlySelectedOwners(['urn:harmonic:user:1']);
            setFetchConfigLoading(false);
            return;
        }
        getAffinityWatchlistStatusOwnerOptions(currentlySelectedAffinityList).then((options) => {
            setOwnerOptions(options.owners);
            setStatusOptions(options.status_options);
            if (options.suggested_status) {
                setCurrentlySelectedStatus(options.suggested_status);
            }
            if (options.suggested_owners && options.suggested_owners.length > 0) {
                setCurrentlySelectedOwners(options.suggested_owners);
            }
            setFetchConfigLoading(false);
        });
    }, [
        affinityListId,
        currentlySelectedAffinityList,
        affinityIntegrationMocked
    ]);
    const [ownersFilterTerm, setOwnersFilterTerm] = useState('');
    const filteredOwners = owners.filter((owner) => {
        const fullName = owner.first_name + ' ' + owner.last_name;
        return fullName.toLowerCase().includes(ownersFilterTerm.toLowerCase());
    });
    const sortedOwners = filteredOwners.sort((a, b) => (a.first_name + ' ' + a.last_name).localeCompare(b.first_name + ' ' + b.last_name));
    if (fetchConfigLoading || pushToAffinityLoading) {
        return (_jsxs("div", { children: [_jsx(ModalTitle, { title: `Add ${companyName} to Affinity list` }), _jsx("div", { className: "w-[440px] h-[256px] flex items-center justify-center", children: _jsx(HarmonicLoader, { showText: !pushToAffinityLoading }) })] }));
    }
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: `Add ${companyName} to Affinity list` }), _jsx("div", { className: "p-p60", children: _jsxs("div", { className: "flex flex-col items-start gap-g50 self-stretch", children: [_jsxs("div", { className: "flex flex-col items-start gap-g20 self-stretch", children: [_jsx("p", { className: "text-content-weak typography-label-default-default", children: "Affinity list" }), _jsx(Select, { fullWidth: true, getLabelFromValue: (value) => affinityLists.find((al) => al.id === value)?.name ?? '', multiple: false, selected: currentlySelectedAffinityList, children: affinityLists.map((affinityList) => (_jsx(ListItem, { primaryIcon: ListIcon, variant: ListVariant.default, label: affinityList.name, value: affinityList.id, onClick: () => {
                                            setCurrentlySelectedAffinityList(affinityList.id);
                                        }, selected: affinityList.id === currentlySelectedAffinityList }, affinityList.id))) }), _jsxs("p", { className: "text-content-weak typography-label-default-default", children: ["Harmonic is only able to display lists you have", ' ', _jsx("a", { className: "text-content-strong typography-paragraph-default-link cursor-pointer", children: "imported" }), "."] })] }), _jsxs("div", { className: "flex flex-col items-start gap-g20 self-stretch", children: [_jsx("p", { className: "text-content-weak typography-label-default-default", children: "Status" }), _jsx(Select, { fullWidth: true, multiple: false, selected: currentlySelectedStatus, getLabelFromValue: (id) => status_options.find((s) => s.id === id)?.text ?? '', children: status_options.map((status) => (_jsx(ListItem, { variant: ListVariant.default, label: status.text, value: status.id, onClick: () => setCurrentlySelectedStatus(status.id), selected: status.id === currentlySelectedStatus }, status.id))) })] }), _jsxs("div", { className: "flex flex-col items-start gap-g20 self-stretch", children: [_jsx("p", { className: "text-content-weak typography-label-default-default", children: "Owners" }), _jsx(Select, { getLabelFromValue: (value) => {
                                        const owner = owners.find((o) => o.urn.toString() === value);
                                        return owner?.first_name + ' ' + owner?.last_name;
                                    }, fullWidth: true, multiple: true, selected: currentlySelectedOwners.map((owner) => owner.toString()), onRemove: (key) => setCurrentlySelectedOwners((prev) => prev.filter((p) => p.toString() !== key)), filterTerm: ownersFilterTerm, onFilterTermChange: (value) => setOwnersFilterTerm(value), filterable: true, children: sortedOwners.map((owner) => (_jsx(ListItem, { variant: ListVariant.checkboxes, label: owner.first_name + ' ' + owner.last_name, value: owner.urn, onChange: () => {
                                            setCurrentlySelectedOwners((prev) => prev.some((p) => p === owner.urn)
                                                ? prev.filter((p) => p !== owner.urn)
                                                : [...prev, owner.urn]);
                                            setOwnersFilterTerm('');
                                        }, selected: currentlySelectedOwners.some((p) => p === owner.urn) }, owner.urn))) })] })] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", onClick: () => close() }), _jsx(Button, { type: "primary", label: "Add to Affinity list", onClick: onSubmit })] })] }));
};
const AddCompanyToAffinityConfirmation = ({ companyId, affinityListId, listUrl, entityViewUrl }) => {
    const { data: companyProfileData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const { affinityLists } = useFetchWatchlists();
    const { close } = useModal();
    const selectedAffinityList = affinityLists.find((affinityList) => affinityList.id === affinityListId);
    const companyName = companyProfileData?.getCompanyById?.name ?? '';
    const affinityListName = selectedAffinityList?.name ?? '';
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: `Add ${companyName} to Affinity list` }), _jsx("div", { className: "h-[256px] px-[56px] flex items-center justify-center", children: _jsxs("div", { className: "flex flex-col items-center gap-g80", children: [_jsxs("div", { className: "flex flex-col justify-center items-center gap-g40", children: [_jsx(AffinityConfirmationImage, {}), _jsxs("p", { className: "text-content-title typography-title-extraSmall", children: [companyName, " has been successfully added to ", affinityListName] })] }), _jsxs("div", { className: "flex gap-g20", children: [entityViewUrl && (_jsx("a", { href: entityViewUrl, onClick: (e) => e.preventDefault(), children: _jsx(Button, { label: `View ${companyName} in Affinity`, onClick: () => window.open(entityViewUrl), emphasis: "medium" }) })), listUrl && (_jsx("a", { href: listUrl, onClick: (e) => e.preventDefault(), children: _jsx(Button, { label: `Go to list in Affinity`, onClick: () => window.open(listUrl), emphasis: "medium" }) }))] })] }) }), _jsx(ModalFooter, { children: _jsx(Button, { label: "Close", type: "primary", onClick: () => close() }) })] }));
};
export default AddCompanyToAffinityButton;
