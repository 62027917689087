import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MyNetworkList from 'assets/harmonic-icons/my-network-list';
import NotInNetwork from 'assets/harmonic-icons/not-in-network';
import TeamNetworkList from 'assets/harmonic-icons/team-network-list';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import useFlags from 'hooks/useFlags';
import { ConnectionScope } from 'interfaces/SearchV2';
import React from 'react';
import { SPLITS } from 'utils/constants';
import CorrespondencesSubSection from '../Common/CorrespondencesSubSection';
const ConnectionsSubSection = ({ generatorArgs, onUpdateGeneratorArgs }) => {
    const { enabled: correspondencesEnabled } = useFlags(SPLITS.showCorrespondencesInSearch);
    return (_jsxs("div", { className: "flex flex-col gap-g50", children: [_jsxs("div", { className: "flex items-start gap-g40 self-stretch", children: [_jsx(RadioPill, { dataTestId: "Any-Connections-Radio-Pill", onClick: () => {
                            onUpdateGeneratorArgs({
                                ...generatorArgs,
                                connection_status: ConnectionScope.ANY
                            });
                        }, label: "Any", selected: generatorArgs?.connection_status === ConnectionScope.ANY }), _jsx(RadioPill, { dataTestId: "My-Connections-Radio-Pill", onClick: () => {
                            onUpdateGeneratorArgs({
                                ...generatorArgs,
                                connection_status: ConnectionScope.MY_CONNECTIONS
                            });
                        }, leadingIcon: MyNetworkList, label: "In my network", selected: generatorArgs?.connection_status === ConnectionScope.MY_CONNECTIONS }), _jsx(RadioPill, { dataTestId: "Team-Connections-Radio-Pill", onClick: () => {
                            onUpdateGeneratorArgs({
                                ...generatorArgs,
                                connection_status: ConnectionScope.TEAM_CONNECTIONS
                            });
                        }, leadingIcon: TeamNetworkList, label: "In team network", selected: generatorArgs?.connection_status ===
                            ConnectionScope.TEAM_CONNECTIONS }), _jsx(RadioPill, { dataTestId: "No-Connections-Radio-Pill", onClick: () => {
                            onUpdateGeneratorArgs({
                                ...generatorArgs,
                                connection_status: ConnectionScope.NO_CONNECTIONS
                            });
                        }, leadingIcon: NotInNetwork, label: "Not in network", selected: generatorArgs?.connection_status === ConnectionScope.NO_CONNECTIONS })] }), correspondencesEnabled && (_jsx(CorrespondencesSubSection, { addTopMarginOnFieldOpen: true, generatorArgs: generatorArgs, onUpdateGeneratorArgs: onUpdateGeneratorArgs }))] }));
};
export default ConnectionsSubSection;
