import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoCircle from 'assets/harmonic-icons/info-circle';
import { Tooltip } from 'common';
import { SmallSectionHeader, SubSectionHeader } from 'components/QueryBuilder/Common/Typography';
import IconButton from 'harmonic-components/IconButton/IconButton';
import SectorKeywords from '../SectorKeywords';
import SemanticInput from './SemanticInput';
const TextSearchSection = ({ andKeywords = [], orKeywords = [], notKeywords = [], handleUpdateKeywords, disabled, semanticInputs }) => {
    return (_jsxs("div", { "data-testid": "Text-Search-Section", children: [_jsx(SmallSectionHeader, { children: "Text search" }), _jsxs("div", { "data-testid": "semantic-search-section", children: [_jsx(SubSectionHeader, { children: _jsxs("div", { className: "flex flex-row items-center gap-g20", children: ["Semantic", ' ', _jsx(Tooltip, { body: "Tips for using semantic keywords", interactive: true, children: _jsx("a", { href: "https://support.harmonic.ai/en/articles/10375135-semantic-keywords", target: "_blank", rel: "noopener noreferrer", "data-testid": "info-icon-semantic", children: _jsx(IconButton, { icon: InfoCircle, size: "tiny", emphasis: "low" }) }) })] }) }), _jsx(SemanticInput, { value: semanticInputs, onChange: (value) => {
                            handleUpdateKeywords(orKeywords, notKeywords, andKeywords, value);
                        } })] }), _jsxs("div", { className: "mt-p70", "data-testid": "exact-matching-section", children: [_jsx(SubSectionHeader, { children: "Exact matching" }), _jsx(SectorKeywords, { "data-testid": "exact-keywords", andKeywords: andKeywords, orKeywords: orKeywords, notKeywords: notKeywords, handleUpdateKeywords: handleUpdateKeywords, disabled: disabled })] })] }));
};
export default TextSearchSection;
