import { jsx as _jsx } from "react/jsx-runtime";
import EntityListSettingsDropdown from 'components/Dashboard/DashboardLayout/EntityListSettingsDropdown';
import { LOADING_ROW_ID } from 'utils/constants';
const RowActionsComponent = (rowData) => {
    const { data } = rowData;
    const { entityType, id, userOptions } = data;
    if (rowData.data.id === LOADING_ROW_ID) {
        return null;
    }
    return (_jsx("div", { className: "flex justify-center h-full", children: _jsx("div", { className: "my-auto", children: _jsx(EntityListSettingsDropdown, { entityId: id.toString(), entityType: entityType, isPinned: userOptions?.isPinned }) }) }));
};
export default RowActionsComponent;
