import { relayStylePagination } from '@apollo/client/utilities';
import { logger } from '../utils/logger';
export const mergeCustomFieldValue = (existing, incoming, { readField, mergeObjects }) => {
    if (existing && incoming) {
        const existingUpdatedAt = readField('updatedAt', existing);
        const incomingUpdatedAt = readField('updatedAt', incoming);
        if (existingUpdatedAt &&
            incomingUpdatedAt &&
            new Date(existingUpdatedAt).getTime() >=
                new Date(incomingUpdatedAt).getTime()) {
            return existing;
        }
    }
    return mergeObjects(existing, incoming);
};
export const filteredRelayStylePagination = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
keyArgs, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
fieldFilterFunction) => {
    const config = relayStylePagination(keyArgs);
    return {
        ...config,
        merge: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        existing, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        incoming, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options) => {
            const { merge } = config;
            if (typeof merge !== 'function')
                return existing;
            const filteredIncomingEdges = incoming.edges?.filter((edge) => {
                return fieldFilterFunction(options, edge);
            });
            const filteredIncoming = {
                ...incoming,
                edges: filteredIncomingEdges
            };
            return merge(existing, filteredIncoming, options);
        }
    };
};
export const mergeAppStore = (persistedState, currentState) => {
    const mergedState = {
        ...currentState,
        auth: {
            ...currentState.auth,
            ...persistedState.auth
        },
        // Prevent persisting dashboard state
        dashboard: currentState.dashboard,
        localSearch: {
            ...currentState.localSearch,
            ...persistedState.localSearch
        },
        collection: {
            ...currentState.collection,
            ...persistedState.collection
        }
    };
    return mergedState;
};
/**
 * Creates a merge field filter for Apollo cache that validates required nested fields
 * @param pathToCheck Array of field names to traverse and validate
 * @param finalField The final field that must exist
 * @returns A filter function for Apollo cache merge fields
 */
export const createMergeFieldFilter = (pathToCheck, finalField) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ({ readField }, edge) => {
        let currentRef = readField('node', edge);
        // Check each field in the path
        for (const field of pathToCheck) {
            if (!currentRef)
                return true;
            currentRef = readField(field, currentRef);
        }
        if (!currentRef)
            return true;
        const field = readField(finalField, currentRef);
        if (!field) {
            logger.info('Filtering out edges without required field', {
                pathToCheck,
                finalField,
                currentRef
            });
        }
        return !!field;
    };
};
