import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { generateDraftTypeClasses, generateFocusClasses, generateInvertedTypeClasses, generateNegativeTypeClasses, generateNetNewTypeClasses, generatePrimaryTypeClasses, generateSecondaryTypeClasses } from './utils';
const generateSizeClasses = (size) => {
    if (size === 'tiny')
        return 'px-p20 py-p10';
    if (size === 'compact')
        return 'px-p40 py-p20';
    return 'px-p50 py-p40';
};
const Button = forwardRef(({ type = 'secondary', emphasis = 'high', size = 'default', isSelected = false, isDisabled = false, label, leadingIcon, trailingIcon, onClick, onMouseDown, autoFocus, loading, dataTestId, fullWidth, trailingBadgeCount }, ref) => {
    let mainClasses = '';
    if (type === 'primary')
        mainClasses = generatePrimaryTypeClasses(isSelected, isDisabled);
    else if (type === 'negative')
        mainClasses = generateNegativeTypeClasses(isSelected, isDisabled);
    else if (type === 'secondary')
        mainClasses = generateSecondaryTypeClasses(emphasis, isSelected, isDisabled);
    else if (type === 'inverted')
        mainClasses = generateInvertedTypeClasses(emphasis, isSelected, isDisabled);
    else if (type === 'net-new')
        mainClasses = generateNetNewTypeClasses(isSelected, isDisabled);
    else if (type === 'draft')
        mainClasses = generateDraftTypeClasses(isSelected, isDisabled);
    const sizeClasses = generateSizeClasses(size);
    const focusClasses = generateFocusClasses(type);
    const LeadingIcon = leadingIcon;
    const TrailingIcon = trailingIcon;
    return (_jsxs("button", { ref: ref, type: "button", className: classNames(mainClasses, sizeClasses, focusClasses, 
        //border radius and font sizes are same in all variations. Change it if needed
        'rounded-br30', 'typography-label', 'flex items-center justify-center whitespace-nowrap', 'relative', 'gap-g20', fullWidth && 'w-full', 'data-visual-test-no-radius' // visual tests will ignore radius to avoid flakiness
        ), disabled: isDisabled, onClick: onClick, onMouseDown: onMouseDown, "data-testid": dataTestId, autoFocus: autoFocus, children: [LeadingIcon && (_jsx("span", { className: "p-p10 items-center flex", children: _jsx(LeadingIcon, { className: "w-4 h-4" }) })), label && (_jsx("span", { className: classNames('px-p10', loading && 'text-transparent'), children: label })), loading && (_jsx("div", { className: "absolute inset-0 flex justify-center items-center", children: _jsx(CircularProgress, { classes: {
                        root: 'text-inherit'
                    }, size: 16 }) })), TrailingIcon && (_jsx("span", { children: _jsx(TrailingIcon, { className: "w-4 h-4" }) })), !!trailingBadgeCount && (_jsx("div", { className: "flex bg-surface-sentiment-highlight-informative rounded-br20 h-4 w-4 typography-label-small-default justify-center items-center text-content-inverted-strong", children: trailingBadgeCount }))] }));
});
export default Button;
