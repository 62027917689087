import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CompanySearchFilterGroup from 'components/Dashboard/Grids/SearchQueryBuilder/CompanySearchFilterGroup';
import PeopleSearchFilterGroup from 'components/Dashboard/Grids/SearchQueryBuilder/PeopleSearchFilterGroup';
import { EntityListType } from 'interfaces/SearchModel/Search';
import React, { useCallback } from 'react';
import { SubSectionHeader } from './Typography';
const CustomSection = ({ searchQuery, onUpdateSearchQuery, disabled, entityType }) => {
    const onUpdateCustomFilterGroup = useCallback((newFilterGroup) => {
        onUpdateSearchQuery({
            filter_group: newFilterGroup,
            controlled_filter_group: searchQuery.controlled_filter_group
        });
    }, []);
    return (_jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Advanced filters" }), _jsxs("p", { className: "typography-label-default-default text-content-weak", children: ["Show only", ' ', entityType === EntityListType.COMPANY_SAVED_SEARCH ||
                        entityType === EntityListType.COMPANY_WATCHLIST
                        ? 'companies'
                        : 'people', ' ', "matching the following criteria"] }), entityType === EntityListType.COMPANY_SAVED_SEARCH ? (_jsx(CompanySearchFilterGroup, { recursiveLevel: 0, isSearchOwner: false, filterGroup: searchQuery.filter_group, updateParentFilterGroup: onUpdateCustomFilterGroup, disabled: disabled })) : (_jsx(PeopleSearchFilterGroup, { recursiveLevel: 0, isSearchOwner: false, filterGroup: searchQuery.filter_group, updateParentFilterGroup: onUpdateCustomFilterGroup, disabled: disabled }))] }));
};
export default CustomSection;
