import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WarningIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { useModal } from 'hooks/useModal';
import { EntityListType } from 'interfaces/SearchModel/Search';
const DeleteEntityConfirmationModal = ({ entityName, onClose, onDeleteEntityList, entityType }) => {
    const title = entityType === EntityListType.COMPANY_SAVED_SEARCH
        ? `Delete ${entityName} search`
        : entityType === EntityListType.PEOPLE_SAVED_SEARCH
            ? `Delete ${entityName} search`
            : entityType === EntityListType.COMPANY_WATCHLIST
                ? `Delete ${entityName} list`
                : entityType === EntityListType.PEOPLE_WATCHLIST
                    ? `Delete ${entityName} list`
                    : '';
    const typeText = entityType === EntityListType.COMPANY_WATCHLIST ||
        entityType === EntityListType.PEOPLE_WATCHLIST
        ? 'list'
        : 'search';
    const onDeleteButtonClicked = async () => {
        onClose();
        await onDeleteEntityList();
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalTitle, { title: title, titleIcon: WarningIcon, sentiment: "negative" }), _jsxs("p", { className: "typography-paragraph text-content-default px-p50 pt-p50 pb-p80", id: "alert-dialog-description", children: ["Are you sure you want to delete this ", typeText, "? This ", _jsx("br", {}), " action cannot be undone."] }), _jsxs(ModalFooter, { children: [_jsx("button", {}), _jsx(Button, { "data-testid": "DeleteEntityListModal-CancelButton", onClick: onClose, type: "secondary", emphasis: "medium", label: "Cancel", autoFocus: true }), _jsx(Button, { "data-testid": "DeleteEntityListModal-DeleteButton", onClick: onDeleteButtonClicked, type: "negative", emphasis: "high", label: "Delete", autoFocus: true })] })] }));
};
export function useDeleteEntityListConfirmationModal() {
    const { show, isOpen, close } = useModal();
    const showDeleteEntityListConfirmationModal = (props) => {
        show(_jsx(DeleteEntityConfirmationModal, { ...props, onClose: close }), {
            showCloseBtn: false
        });
    };
    return {
        showDeleteEntityListConfirmationModal,
        isDeleteEntityListConfirmationModalOpen: isOpen
    };
}
