import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CompanyMultiselectById from 'components/QueryBuilder/Common/CompanyMultiselectById';
import CompanyMultiselectByName from 'components/QueryBuilder/Common/CompanyMultiselectByName';
import IntegratedEmailUserMultiselect from 'components/QueryBuilder/Common/IntegratedEmailUserMultiselect';
import InvestorMultiselectByName from 'components/QueryBuilder/Common/InvestorMultiselectByName';
import ListMultiselect from 'components/QueryBuilder/Common/ListMultiselect';
import PersonMultiSelectById from 'components/QueryBuilder/Common/PersonMultiselectById';
import SchoolMultiselect from 'components/QueryBuilder/Common/SchoolMultiselect';
import UserMultiSelectByName from 'components/QueryBuilder/Common/UserMultiselectByName';
import TextField from 'components/common/lib/TextField';
import DatePicker from 'harmonic-components/DatePicker/DatePicker';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { EntityListType, FilterArrayComparator, FilterDateComparator, FilterDisplayFormatType, FilterListComparator, FilterNumberComparator, FilterStringComparator, SearchValueToDisplayNameMap, TableFilterType } from 'interfaces/SearchModel/Search';
import { capitalize, compact, isEqual, toNumber } from 'lodash';
import get from 'lodash/get';
import toString from 'lodash/toString';
import React, { useEffect } from 'react';
import { AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER } from 'utils/constants';
import { returnNumberFromString } from 'utils/utilities';
import { getFormattedFilterValueBasedOnDisplayType } from '../utils';
const ValueInput = (props) => {
    const [filterValue, setFilterValue] = React.useState(props.filter_value);
    const [rangeFromValue, setRangeFromValue] = React.useState(props.rangeFromValue);
    const [rangeToValue, setRangeToValue] = React.useState(props.rangeToValue);
    useEffect(() => {
        if (!isEqual(props.filter_value, filterValue)) {
            setFilterValue(props.filter_value);
        }
    }, [props.filter_value]);
    const type = Array.isArray(props.filterType)
        ? props.filterType[0]
        : props.filterType;
    const [autocompleteSearchTerm, setAutocompleteSearchTerm] = React.useState('');
    const companyIdSearchFields = [
        'person_position_company_id',
        'person_last_company_id',
        'person_experience_company_id'
    ];
    const companyNameSearchFields = [
        'person_position_company_name',
        'person_last_company_name',
        'person_experience_company_name',
        'company_investor_names'
    ];
    if (props.comparator == FilterStringComparator.IS_EMPTY ||
        props.comparator == FilterStringComparator.IS_NOT_EMPTY) {
        return _jsx(_Fragment, {});
    }
    if (props.field === 'person_name_id' &&
        [
            FilterListComparator.IS_IN_LIST,
            FilterListComparator.IS_NOT_IN_LIST
        ].includes(props.comparator)) {
        return (_jsx(ListMultiselect, { selected: props.multiSelectValues, onChange: (newOptions) => {
                props.onUpdateComparatorValue(undefined, newOptions);
            }, mode: "people_watchlist", dataTestid: "person-watchlist-multiselect" }));
    }
    if (props.field === 'person_name_id' &&
        [FilterArrayComparator.ANY_OF, FilterArrayComparator.NOT_ANY_OF].includes(props.comparator)) {
        return (_jsx(PersonMultiSelectById, { selected: props.multiSelectValues.map((value) => ({
                id: toNumber(value),
                name: value
            })), onChange: (newOptions) => {
                props.onUpdateComparatorValue(undefined, compact(newOptions?.map((o) => toString(o.id))) ?? []);
            } }));
    }
    if ([...companyIdSearchFields, ...companyNameSearchFields].includes(props.field) &&
        [
            FilterListComparator.IS_IN_LIST,
            FilterListComparator.IS_NOT_IN_LIST
        ].includes(props.comparator)) {
        return (_jsx("div", { className: "min-w-48", children: _jsx(ListMultiselect, { selected: props.multiSelectValues, onChange: (newOptions) => {
                    props.onUpdateComparatorValue(undefined, newOptions);
                }, mode: "company_watchlist", dataTestid: "company-watchlist-multiselect" }) }));
    }
    if (companyIdSearchFields.includes(props.field)) {
        return (_jsx(CompanyMultiselectById, { selected: props.multiSelectValues.map((value) => ({
                id: toNumber(value),
                name: value
            })), onChange: (newOptions) => {
                props.onUpdateComparatorValue(undefined, compact(newOptions?.map((o) => toString(o.id))) ?? []);
            } }));
    }
    if (props.field === 'person_education_experience_school' ||
        props.field === 'employee_education_experience_school') {
        return (_jsx(SchoolMultiselect, { selected: props.multiSelectValues, onChange: (newOptions) => {
                props.onUpdateComparatorValue(undefined, newOptions);
            }, entityType: props.field === 'person_education_experience_school'
                ? EntityListType.PEOPLE_SAVED_SEARCH
                : EntityListType.COMPANY_SAVED_SEARCH }));
    }
    if (props.field === 'company_customers_connections_last_email_person_email' ||
        props.field === 'company_customers_connections_last_meeting_person_email') {
        return (_jsx(IntegratedEmailUserMultiselect, { selected: props.multiSelectValues, onChange: (newOptions) => {
                props.onUpdateComparatorValue(undefined, newOptions);
            } }));
    }
    if (props.field === 'company_investor_names' ||
        props.field === 'person_position_company_investor_names' ||
        props.field === 'person_last_company_investor_names' ||
        props.field === 'person_experience_company_investor_names') {
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(InvestorMultiselectByName, { dataTestId: "investor-company-multiselect", selected: props.multiSelectValues, onChange: (newOptions) => {
                    props.onUpdateComparatorValue(undefined, newOptions);
                }, 
                // For this field, users can paste in a watchlist ID
                // that will be expanded in the backend.
                freeSolo: props.field === 'company_investor_names' }) }));
    }
    if (props.field === 'employee_experience_company_name' ||
        props.field === 'person_position_company_name' ||
        props.field === 'person_last_company_name' ||
        props.field === 'person_experience_company_name') {
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(CompanyMultiselectByName, { dataTestId: "company-multiselect", selected: props.multiSelectValues, onChange: (newOptions) => {
                    props.onUpdateComparatorValue(undefined, newOptions);
                }, 
                // For this field, users can paste in a watchlist ID
                // that will be expanded in the backend.
                freeSolo: props.field === 'employee_experience_company_name' }) }));
    }
    if (type === TableFilterType.BOOLEAN) {
        const booleanOptions = [
            {
                label: 'True',
                value: true
            },
            {
                label: 'False',
                value: false
            }
        ];
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(Select, { selected: toString(props.filter_value), multiple: false, getLabelFromValue: (value) => capitalize(value), dropdownMaxHeight: "400px", children: booleanOptions.map((option) => (_jsx(ListItem, { value: toString(option.value), label: option.label, onClick: () => {
                        props.onUpdateComparatorValue({
                            target: {
                                value: option.value
                            }
                        });
                    }, variant: ListVariant.default }, option.label))) }) }));
    }
    if (props.field === 'company_custom_field_person' ||
        props.field === 'person_custom_field_person' ||
        props.field === 'person_entry_added_by' ||
        props.field === 'company_entry_added_by') {
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(UserMultiSelectByName, { selected: props.multiSelectValues, onChange: (newOptions) => {
                    props.onUpdateComparatorValue(undefined, newOptions);
                }, freeSolo: false }) }));
    }
    if (props.field === 'company_custom_field_single_select' ||
        props.field === 'company_custom_field_multi_select' ||
        props.field === 'person_custom_field_single_select' ||
        props.field === 'person_custom_field_multi_select' ||
        props.field === 'company_custom_field_status' ||
        props.field === 'person_custom_field_status') {
        const allowedValues = props.selectValues ?? [];
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(Select, { multiple: true, selected: props.multiSelectValues, filterable: true, filterTerm: autocompleteSearchTerm, onFilterTermChange: setAutocompleteSearchTerm, freeSolo: !allowedValues?.length, hideChevronDown: !allowedValues?.length, hideDropdown: !allowedValues?.length, placeholder: props.allowedValues?.length == 0
                    ? AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER
                    : '', clampValues: true, onAdd: (keyword) => {
                    if (!props.multiSelectValues.includes(keyword)) {
                        props.onAddMultiSelectValue(keyword);
                    }
                    setAutocompleteSearchTerm('');
                }, onRemove: (keyword) => {
                    props.onRemoveMultiSelectValue(keyword);
                    setAutocompleteSearchTerm('');
                }, getTagColorFromValue: (value) => {
                    const color = (allowedValues.find((v) => v.value === value)
                        ?.color ?? '');
                    return color;
                }, getLabelFromValue: (value) => {
                    return allowedValues.find((v) => v.value === value)?.label ?? '';
                }, dropdownMaxHeight: "400px", children: allowedValues
                    .filter((allowedValue) => allowedValue.label
                    .toLocaleLowerCase()
                    .includes(autocompleteSearchTerm))
                    .map((value) => (_jsx(ListItem, { value: value.value, label: value.label, selected: props.multiSelectValues.includes(value.value), onClick: (e) => {
                        if (!props.multiSelectValues.includes(value.value)) {
                            props.onAddMultiSelectValue(value.value);
                        }
                        else {
                            props.onRemoveMultiSelectValue(value.value);
                        }
                        setAutocompleteSearchTerm('');
                    }, color: value.color, variant: ListVariant.tag }, value.value))) }) }));
    }
    if (type === TableFilterType.ARRAY) {
        const allowedValues = props.allowedValues
            ?.filter((value) => value)
            .map((value) => ({
            label: get(SearchValueToDisplayNameMap, value, value),
            value: value
        })) || [];
        return (_jsx("div", { className: "w-52 flex-grow", children: _jsx(Select, { multiple: true, selected: props.multiSelectValues, filterable: true, filterTerm: autocompleteSearchTerm, onFilterTermChange: setAutocompleteSearchTerm, freeSolo: !allowedValues?.length, hideChevronDown: !allowedValues?.length, hideDropdown: !allowedValues?.length, placeholder: props.allowedValues?.length == 0
                    ? AUTOCOMPLETE_CHIP_ENTRY_PLACEHOLDER
                    : '', clampValues: true, onAdd: (keyword) => {
                    if (!props.multiSelectValues.includes(keyword)) {
                        props.onAddMultiSelectValue(keyword);
                    }
                    setAutocompleteSearchTerm('');
                }, onRemove: (keyword) => {
                    props.onRemoveMultiSelectValue(keyword);
                    setAutocompleteSearchTerm('');
                }, getLabelFromValue: (value) => {
                    return value
                        ? get(SearchValueToDisplayNameMap, value, value)
                        : value ?? '';
                }, dropdownMaxHeight: "300px", dataTestId: "array-filter", children: allowedValues
                    .filter((allowedValue) => allowedValue.label
                    .toLocaleLowerCase()
                    .includes(autocompleteSearchTerm))
                    .map((value) => (_jsx(ListItem, { value: value.value, label: value.label, selected: props.multiSelectValues.includes(value.value), onChange: (e) => {
                        if (e.target.checked) {
                            props.onAddMultiSelectValue(value.value);
                        }
                        else {
                            props.onRemoveMultiSelectValue(value.value);
                        }
                        setAutocompleteSearchTerm('');
                    }, variant: ListVariant.checkboxes }, value.value))) }) }));
    }
    if (props.comparator === FilterNumberComparator.IN_RANGE ||
        props.comparator === FilterDateComparator.IN_DAYS_AGO_RANGE ||
        props.comparator === FilterDateComparator.NOT_IN_DAYS_AGO_RANGE) {
        const rangeType = type === TableFilterType.DATE ? 'date' : 'string';
        const showDaysAgoLabel = props.comparator === FilterDateComparator.IN_DAYS_AGO_RANGE ||
            props.comparator === FilterDateComparator.NOT_IN_DAYS_AGO_RANGE;
        if (rangeType === 'date') {
            const formattedRangeFromValue = rangeFromValue !== 'null' ? rangeFromValue : null;
            const formattedRangeToValue = rangeToValue !== 'null' ? rangeToValue : null;
            return (_jsx(DatePicker, { type: "range", startDate: formattedRangeFromValue, endDate: formattedRangeToValue, onChange: (startDate, endDate) => {
                    props.onUpdateDateRangeValue(startDate, endDate);
                    setRangeFromValue(startDate);
                    setRangeToValue(endDate);
                } }));
        }
        const formattedRangeFromValue = getFormattedFilterValueBasedOnDisplayType(rangeFromValue, props.displayFormatType);
        const formattedRangeToValue = getFormattedFilterValueBasedOnDisplayType(rangeToValue, props.displayFormatType);
        return (_jsxs("div", { className: "value-range-input-container flex-row flex-grow gap-1.5", children: [_jsx("div", { className: "w-40", children: _jsx(TextField, { dataTestId: "SingleFilter-FieldInput-TextField", value: formattedRangeFromValue, suffix: showDaysAgoLabel ? 'days ago' : '', type: rangeType, placeholder: "From", size: "small", className: "value-input", register: props.valueInputRef, onChange: (e) => {
                            let value = e.target.value;
                            if (e.target.type === 'text') {
                                value = returnNumberFromString(value);
                            }
                            setRangeFromValue(value);
                        }, onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                props.onUpdateComparatorRangeFromValue(e);
                            }
                        }, onBlur: props.onUpdateComparatorRangeFromValue, disabled: props.disabled }, `${type}-from`) }), _jsx("span", { className: "self-center", children: "-" }), _jsx("div", { className: "w-40", children: _jsx(TextField, { disabled: props.disabled, dataTestId: "SingleFilter-FieldInput-TextField", value: formattedRangeToValue, type: rangeType, placeholder: "To", suffix: showDaysAgoLabel ? 'days ago' : '', size: "small", className: "value-input", onChange: (e) => {
                            let value = e.target.value;
                            if (e.target.type === 'text') {
                                value = returnNumberFromString(value);
                            }
                            setRangeToValue(value);
                        }, onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                props.onUpdateComparatorRangeToValue(e);
                            }
                        }, onBlur: props.onUpdateComparatorRangeToValue }, `${type}-to`) })] }));
    }
    if (type === TableFilterType.INTEGER) {
        const formattedFilterValue = getFormattedFilterValueBasedOnDisplayType(filterValue, props.displayFormatType);
        return (_jsx("div", { className: "w-48 flex-grow", children: _jsx(TextField, { disabled: props.disabled, dataTestId: "SingleFilter-FieldInput-TextField", value: formattedFilterValue, type: type === TableFilterType.DATE ? 'date' : 'text', size: "small", suffix: props.displayFormatType === FilterDisplayFormatType.PERCENTAGE
                    ? '%'
                    : '', register: props.valueInputRef, className: "value-input", onKeyDown: (e) => {
                    if (e.key === 'Backspace') {
                        if (props.field?.includes('_percent_')) {
                            let newValue = toString(filterValue || '');
                            newValue = newValue.substring(0, newValue.length - 1);
                            setFilterValue(newValue);
                        }
                    }
                    if (e.key === 'Enter') {
                        props.onUpdateComparatorValue(e);
                    }
                }, onChange: (e) => {
                    const value = (e.target?.value).replace(/[^-?\d]/g, '');
                    setFilterValue(value);
                }, onBlur: props.onUpdateComparatorValue }, `${type}-value`) }));
    }
    if (type === TableFilterType.DATE) {
        return (_jsx("div", { className: "w-48 flex-grow", children: _jsx(DatePicker, { type: "single", selectedDate: props.filter_value, onChange: (value) => {
                    props.onUpdateComparatorValue({
                        target: {
                            value
                        }
                    });
                } }) }));
    }
    return (_jsx("div", { className: "w-48 flex-grow", children: _jsx(TextField, { disabled: props.disabled, dataTestId: "SingleFilter-FieldInput-TextField", defaultValue: props.filter_value || '', type: type === TableFilterType.DATE ? 'date' : 'text', size: "small", className: "value-input", register: props.valueInputRef, onBlur: props.onUpdateComparatorValue, onKeyDown: (e) => {
                if (e.key === 'Enter') {
                    props.onUpdateComparatorValue(e);
                }
            } }, `${type}-value`) }));
};
export default ValueInput;
