import { INITIAL_COMPANY_SORT, INITIAL_PEOPLE_SORT, INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY, INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY } from 'interfaces/SearchModel/Search';
import { FILTER_SESSION_STORE_VERSION_PREFIX } from 'utils/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';
const useFilterStore = create(persist((set, get) => ({
    searchStates: {},
    companiesDashboardFilters: INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY,
    peopleDashboardFilters: INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY,
    companyExplorePageSort: {
        sortField: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
    },
    peopleExplorePageSort: {
        sortField: INITIAL_PEOPLE_SORT.sortField,
        descending: INITIAL_PEOPLE_SORT.sortDescending
    },
    resetListFilter: false,
    searchFieldForListFilter: '',
    editSearchState: (key, payload) => set((state) => ({
        ...state,
        searchStates: { ...state.searchStates, [key]: payload }
    })),
    editFilterStore: (key, payload) => set((state) => ({
        ...state,
        [key]: payload
    }))
}), {
    // Update it anytime you make some changes to schema
    version: 24,
    name: `${FILTER_SESSION_STORE_VERSION_PREFIX}filter-storage` // unique name
}));
export const useShallowFilterStore = (keys) => useShallowStoreGeneric(useFilterStore, keys);
export default useFilterStore;
