import { jsx as _jsx } from "react/jsx-runtime";
import { LockedIcon } from 'assets/harmonic-icons';
import GroupPeople from 'assets/harmonic-icons/group-people';
import { Badge } from 'common/components';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import { LOADING_ROW_ID } from 'utils/constants';
import { VisibilityEnum } from '../types';
export const VisibilityCellComponent = (visibility) => {
    if (visibility.data.id == LOADING_ROW_ID) {
        return _jsx(LoadingCellRenderer, {});
    }
    return visibility.value == VisibilityEnum.private ? (_jsx(Badge, { label: "Private", color: "expressive-12", leadingIcon: LockedIcon })) : (_jsx(Badge, { label: "Shared", color: "expressive-23", leadingIcon: GroupPeople }));
};
