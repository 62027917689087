import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { companyTeamHeadcountPercentGrowthDefaultValue, companyTeamHeadcountRangeDefaultValue, convertStringArrayToSelectedTypeaheadResultArray, FilterGroupGeneratorId, headcountGrowthStepRanges, HeadcountPercentGrowthTimeline, headcountStepRanges } from 'interfaces/SearchV2';
import React, { useEffect, useState } from 'react';
import { HighlightCategory, SPLITS } from 'utils/constants';
//eslint-disable-next-line
import { PlusIcon } from 'assets/harmonic-icons';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import InfoCircle from 'assets/harmonic-icons/info-circle';
import { Tooltip } from 'common';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import IconButton from 'harmonic-components/IconButton/IconButton';
import RangeInput from 'harmonic-components/RangeInput/RangeInput';
import useFlags from 'hooks/useFlags';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import ButtonWrapper from '../Common/ButtonWrapper';
import Checkboxes from '../Common/Checkboxes';
import CompanyMultiselectById from '../Common/CompanyMultiselectById';
import { ConnectionsBanner } from '../Common/ConnectionsBanner';
import ConnectionsSubSection from '../Common/ConnectionsSubSection';
import ListMultiselect from '../Common/ListMultiselect';
import MultiselectWithMode from '../Common/MultiselectWithMode';
import RoleTypeMultiselect from '../Common/RoleTypeMultiselect';
import SchoolMultiselect from '../Common/SchoolMultiselect';
import { SectionDivider, SmallSectionHeader, SubSectionHeader } from '../Common/Typography';
export const founderHighlightOptions = [
    {
        label: 'Prior exit',
        value: HighlightCategory.PRIOR_EXIT
    },
    {
        label: 'Major tech company experience',
        value: HighlightCategory.MAJOR_TECH_COMPANY_EXPERIENCE
    },
    {
        label: 'Top university',
        value: HighlightCategory.TOP_UNIVERSITY
    },
    {
        label: 'HBCU Alum',
        value: HighlightCategory.HBCU_ALUM
    },
    {
        label: 'Top company alum',
        value: HighlightCategory.TOP_COMPANY_ALUM
    },
    {
        label: 'Prior VC-backed founder',
        value: HighlightCategory.PRIOR_VC_BACKED_FOUNDER
    },
    {
        label: 'Prior VC-backed executive',
        value: HighlightCategory.PRIOR_VC_BACKED_EXECUTIVE
    },
    {
        label: 'Deep technical background',
        value: HighlightCategory.DEEP_TECHNICAL_BACKGROUND
    },
    {
        label: 'Major research institution experience',
        value: HighlightCategory.MAJOR_RESEARCH_INSTITUTION_EXPERIENCE
    },
    {
        label: 'Previously raised $5M+',
        value: HighlightCategory.FIVE_M_CLUB
    },
    {
        label: 'Elite industry experience',
        value: HighlightCategory.ELITE_INDUSTRY_EXPERIENCE
    },
    {
        label: 'Previously raised $10M+',
        value: HighlightCategory.TEN_M_CLUB
    },
    {
        label: 'Angel-backed',
        value: HighlightCategory.ANGEL_BACKED
    },
    {
        label: 'Previously raised $50M+',
        value: HighlightCategory.FIFTY_M_PLUS_CLUB
    },
    {
        label: 'Seasoned operator',
        value: HighlightCategory.SEASONED_OPERATOR
    },
    {
        label: 'Seasoned founder',
        value: HighlightCategory.SEASONED_FOUNDER
    },
    {
        label: 'YC backed founder',
        value: HighlightCategory.YC_BACKED_FOUNDER
    },
    {
        label: 'Top AI experience',
        value: HighlightCategory.TOP_AI_EXPERIENCE
    },
    {
        label: 'Top Web3 experience',
        value: HighlightCategory.TOP_WEB3_EXPERIENCE
    }
];
const TeamSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const [selectAllHighlightsChecked, setSelectAllHighlightsChecked] = useState(false);
    const { enabled: correspondencesEnabled } = useFlags(SPLITS.showCorrespondencesInSearch);
    const includeFromPeopleWatchlistIds = controlledSearchState?.[FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST]?.watchlist_ids;
    const excludeFromPeopleWatchlistIds = controlledSearchState?.[FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST]?.watchlist_ids;
    const foundingRoleTitles = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE]?.past_role_titles || [];
    const companyHeadcountRange = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE]?.range;
    const companyHeadcountGrowthPercentRange = controlledSearchState?.[FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE]?.range;
    const companyHighlights = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS]?.highlights;
    const foundingExperience = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE];
    const foundingExperiencePastCompanies = convertStringArrayToSelectedTypeaheadResultArray(foundingExperience?.past_companies);
    const foundingExperiencePastRoles = foundingExperience?.past_roles ?? [];
    const foundingExperiencePastSchools = foundingExperience?.past_schools ?? [];
    const foundingExperiencePastCompanyListIds = foundingExperience?.past_company_list_ids ?? [];
    useEffect(() => {
        if (foundingExperience &&
            foundingExperiencePastCompanies.length === 0 &&
            foundingExperiencePastRoles.length === 0 &&
            foundingExperiencePastSchools.length === 0 &&
            foundingRoleTitles.length === 0 &&
            foundingExperiencePastCompanyListIds.length === 0) {
            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, undefined));
        }
    }, [
        foundingExperiencePastCompanies,
        foundingExperiencePastRoles,
        foundingExperiencePastSchools
    ]);
    const teamMemberRoleTitles = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE]?.past_role_titles || [];
    const teamMemberExperience = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE];
    const teamMemberExperiencePastCompanies = convertStringArrayToSelectedTypeaheadResultArray(teamMemberExperience?.past_companies);
    const teamMemberExperiencePastRoles = teamMemberExperience?.past_roles ?? [];
    const teamMemberExperiencePastSchools = teamMemberExperience?.past_schools ?? [];
    const teamMemberPastExperienceCompanyListIds = teamMemberExperience?.past_company_list_ids ?? [];
    const getCurrentFounderExperienceFilterValues = () => {
        return {
            past_companies: foundingExperiencePastCompanies,
            past_roles: foundingExperiencePastRoles,
            past_role_titles: foundingRoleTitles,
            past_schools: foundingExperiencePastSchools,
            past_company_list_ids: foundingExperiencePastCompanyListIds
        };
    };
    const getCurrentTeamMemberExperienceFilterValues = () => {
        return {
            past_companies: teamMemberExperiencePastCompanies,
            past_roles: teamMemberExperiencePastRoles,
            past_role_titles: teamMemberRoleTitles,
            past_schools: teamMemberExperiencePastSchools,
            past_company_list_ids: teamMemberPastExperienceCompanyListIds
        };
    };
    useEffect(() => {
        if (teamMemberExperience &&
            teamMemberExperiencePastCompanies.length === 0 &&
            teamMemberExperiencePastRoles.length === 0 &&
            teamMemberExperiencePastSchools.length === 0 &&
            teamMemberRoleTitles.length === 0 &&
            teamMemberPastExperienceCompanyListIds.length === 0) {
            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, undefined));
        }
    }, [
        teamMemberExperiencePastCompanies,
        teamMemberExperiencePastRoles,
        teamMemberExperiencePastSchools
    ]);
    const onToggleSelectAllHighlights = (e) => {
        setSelectAllHighlightsChecked(e.target.checked);
        if (e.target.checked) {
            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS, {
                highlights: founderHighlightOptions.map((option) => option.value)
            }));
            return;
        }
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS, undefined));
    };
    const allFounderHighlightsChecked = (companyHighlights ?? []).length === founderHighlightOptions.length;
    return (_jsx("div", { className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-g90", children: [_jsxs("div", { id: "Headcount", className: "grid grid-cols-2 gap-g80", children: [_jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Headcount" }), _jsx(RangeInput, { dataTestId: "Team-Headcount-Input", onValueChange: (newValue) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE, newValue !== undefined
                                        ? {
                                            range: newValue
                                        }
                                        : undefined)), defaultValue: companyTeamHeadcountRangeDefaultValue.range, sliderStepRanges: headcountStepRanges, value: companyHeadcountRange, disabled: disabled })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "90d headcount growth" }), _jsx(RangeInput, { onValueChange: (newValue) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE, newValue !== undefined
                                        ? {
                                            range: newValue,
                                            timeline: HeadcountPercentGrowthTimeline.THREE_MONTHS
                                        }
                                        : undefined)), defaultValue: companyTeamHeadcountPercentGrowthDefaultValue.range, sliderStepRanges: headcountGrowthStepRanges, value: companyHeadcountGrowthPercentRange, disabled: disabled, suffix: "%" })] })] }), correspondencesEnabled && (_jsxs(_Fragment, { children: [_jsx(SectionDivider, {}), _jsxs("div", { id: "Correspondence-Connections", children: [_jsx("div", { className: "mb-g80", children: _jsx(ConnectionsBanner, {}) }), _jsx(SmallSectionHeader, { children: "Network" }), _jsx(ConnectionsSubSection, { generatorArgs: controlledSearchState?.[FilterGroupGeneratorId
                                        .SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES], onUpdateGeneratorArgs: (newArgs) => {
                                        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES, newArgs));
                                    } })] })] })), _jsx(SectionDivider, {}), _jsxs("div", { id: "Founder-Experience", children: [_jsx(SmallSectionHeader, { children: "Founder experience" }), _jsx(SubSectionHeader, { children: _jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex flex-row items-center", children: ["Founder highlights", ' ', _jsx("div", { className: "ml-p20", children: _jsx(Tooltip, { body: "Learn more about our highlights", interactive: true, children: _jsx("a", { href: "https://support.harmonic.ai/en/articles/5881310-highlights", target: "_blank", rel: "noopener noreferrer", children: _jsx(IconButton, { icon: InfoCircle, size: "tiny", emphasis: "low", onClick: () => { } }) }) }) })] }), ' ', _jsx(Checkbox, { onChange: onToggleSelectAllHighlights, checked: selectAllHighlightsChecked, variant: allFounderHighlightsChecked ? 'default' : 'indeterminate', label: "Select all highlights", labelAlignment: "left", id: "select-all-founder", dataTestId: "Founder-Highlights-Select-All" })] }) }), _jsxs("div", { className: "flex flex-col gap-g90", children: [_jsx(Checkboxes, { dataTestId: "Team-Founder-Highlights-Checkboxes", options: founderHighlightOptions, selectedValues: companyHighlights, onValueChange: (newValues) => {
                                        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS, newValues !== undefined
                                            ? {
                                                highlights: newValues
                                            }
                                            : undefined));
                                    }, disabled: disabled }), _jsxs("div", { className: "grid grid-cols-2 gap-g90", children: [_jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Prior companies" }), _jsx(ButtonWrapper, { label: "Add company", leadingIcon: PlusIcon, skipToChildren: foundingExperiencePastCompanies?.length > 0, children: _jsx(CompanyMultiselectById, { selected: foundingExperiencePastCompanies, initialFocus: foundingExperiencePastCompanies.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, {
                                                            ...getCurrentFounderExperienceFilterValues(),
                                                            past_companies: newValues ?? []
                                                        })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Prior companies in list" }), _jsx(ButtonWrapper, { label: "Include list", leadingIcon: PlusIcon, skipToChildren: foundingExperiencePastCompanyListIds?.length > 0, children: _jsx(ListMultiselect, { placeholder: "Select company lists to include", mode: "company_watchlist", initialFocus: foundingExperiencePastCompanyListIds.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, {
                                                            ...getCurrentFounderExperienceFilterValues(),
                                                            past_company_list_ids: newValues ?? []
                                                        })), selected: foundingExperiencePastCompanyListIds ?? [] }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Prior role types" }), _jsx(ButtonWrapper, { label: "Add role types", leadingIcon: PlusIcon, skipToChildren: foundingExperiencePastRoles.length > 0, children: _jsx(RoleTypeMultiselect, { selected: foundingExperiencePastRoles, initialFocus: foundingExperiencePastRoles.length === 0, entityType: EntityListType.COMPANY_SAVED_SEARCH, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, {
                                                            ...getCurrentFounderExperienceFilterValues(),
                                                            past_roles: newValues ?? []
                                                        })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: " Prior role title" }), _jsx(ButtonWrapper, { skipToChildren: foundingRoleTitles.length > 0, label: "Add role title", leadingIcon: PlusIcon, children: _jsx(MultiselectWithMode, { selected: foundingRoleTitles, initialFocus: foundingRoleTitles.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, {
                                                            ...getCurrentFounderExperienceFilterValues(),
                                                            past_role_titles: newValues ?? []
                                                        })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Education" }), _jsx(ButtonWrapper, { label: "Add schools", leadingIcon: PlusIcon, skipToChildren: foundingExperiencePastSchools.length > 0, children: _jsx(SchoolMultiselect, { selected: foundingExperiencePastSchools, initialFocus: foundingExperiencePastSchools.length === 0, entityType: EntityListType.COMPANY_SAVED_SEARCH, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE, {
                                                            ...getCurrentFounderExperienceFilterValues(),
                                                            past_schools: newValues ?? []
                                                        })) }) })] })] })] })] }), _jsx(SectionDivider, {}), _jsxs("div", { id: "Team-Experience", children: [_jsx(SmallSectionHeader, { children: " Team experience" }), _jsxs("div", { className: "grid grid-cols-2 gap-g90", children: [_jsxs("div", { children: [_jsx(SubSectionHeader, { children: " Prior companies" }), _jsx(ButtonWrapper, { label: "Add company", leadingIcon: PlusIcon, skipToChildren: teamMemberExperiencePastCompanies?.length > 0, children: _jsx(CompanyMultiselectById, { selected: teamMemberExperiencePastCompanies, initialFocus: teamMemberExperiencePastCompanies.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, {
                                                    ...getCurrentTeamMemberExperienceFilterValues(),
                                                    past_companies: newValues ?? []
                                                })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: " Prior companies in list" }), _jsx(ButtonWrapper, { label: "Include list", leadingIcon: PlusIcon, skipToChildren: teamMemberPastExperienceCompanyListIds?.length > 0, children: _jsx(ListMultiselect, { placeholder: "Select company lists to include", mode: "company_watchlist", initialFocus: teamMemberPastExperienceCompanyListIds.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, {
                                                    ...getCurrentTeamMemberExperienceFilterValues(),
                                                    past_company_list_ids: newValues ?? []
                                                })), selected: teamMemberPastExperienceCompanyListIds ?? [] }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: " Prior role types" }), _jsx(ButtonWrapper, { label: "Add role types", leadingIcon: PlusIcon, skipToChildren: teamMemberExperiencePastRoles.length > 0, children: _jsx(RoleTypeMultiselect, { selected: teamMemberExperiencePastRoles, initialFocus: teamMemberExperiencePastRoles.length === 0, entityType: EntityListType.COMPANY_SAVED_SEARCH, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, {
                                                    ...getCurrentTeamMemberExperienceFilterValues(),
                                                    past_roles: newValues ?? []
                                                })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: " Prior role title" }), _jsx(ButtonWrapper, { skipToChildren: teamMemberRoleTitles.length > 0, label: "Add role title", leadingIcon: PlusIcon, children: _jsx(MultiselectWithMode, { selected: teamMemberRoleTitles, initialFocus: teamMemberRoleTitles.length === 0, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, {
                                                    ...getCurrentTeamMemberExperienceFilterValues(),
                                                    past_role_titles: newValues ?? []
                                                })) }) })] }), _jsxs("div", { children: [_jsx(SubSectionHeader, { children: "Education" }), _jsx(ButtonWrapper, { label: "Add schools", leadingIcon: PlusIcon, skipToChildren: teamMemberExperiencePastSchools.length > 0, children: _jsx(SchoolMultiselect, { selected: teamMemberExperiencePastSchools, initialFocus: teamMemberExperiencePastSchools.length === 0, entityType: EntityListType.COMPANY_SAVED_SEARCH, onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE, {
                                                    ...getCurrentTeamMemberExperienceFilterValues(),
                                                    past_schools: newValues ?? []
                                                })) }) })] })] })] }), _jsx(SectionDivider, {}), _jsxs("div", { id: "Filter-Team-Members-By-List", children: [_jsx(SmallSectionHeader, { children: "Filter team members by list" }), _jsxs("div", { className: "flex flex-row gap-g60", children: [_jsxs("div", { className: "w-1/2", children: [_jsx(SubSectionHeader, { children: "Include lists" }), _jsx(ButtonWrapper, { label: "Include lists", leadingIcon: CirclePlus, skipToChildren: (includeFromPeopleWatchlistIds ?? []).length > 0 ?? false, children: _jsx(ListMultiselect, { "data-testid": "Include-People-From-List", placeholder: "Select people lists to include", mode: "people_watchlist", initialFocus: (includeFromPeopleWatchlistIds ?? [])?.length === 0, onChange: (newOptions) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST, newOptions !== undefined && newOptions.length > 0
                                                    ? {
                                                        watchlist_ids: newOptions
                                                    }
                                                    : undefined)), selected: includeFromPeopleWatchlistIds ?? [] }) })] }), _jsxs("div", { className: "w-1/2", children: [_jsx(SubSectionHeader, { children: "Exclude lists" }), _jsx(ButtonWrapper, { label: "Exclude lists", leadingIcon: CircleMinus, skipToChildren: (excludeFromPeopleWatchlistIds ?? []).length > 0 ?? false, children: _jsx(ListMultiselect, { "data-testid": "Exclude-People-From-List", placeholder: "Select people lists to exclude", mode: "people_watchlist", excludeMode: true, initialFocus: (excludeFromPeopleWatchlistIds ?? [])?.length === 0, onChange: (newOptions) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST, newOptions !== undefined && newOptions.length > 0
                                                    ? {
                                                        watchlist_ids: newOptions
                                                    }
                                                    : undefined)), selected: excludeFromPeopleWatchlistIds ?? [] }) })] })] })] })] }) }));
};
export default TeamSection;
