import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Select from 'harmonic-components/Select/Select';
import { useState } from 'react';
var KeywordOperatorType;
(function (KeywordOperatorType) {
    KeywordOperatorType["AND"] = "AND";
    KeywordOperatorType["OR"] = "OR";
    KeywordOperatorType["NOT"] = "NOT";
})(KeywordOperatorType || (KeywordOperatorType = {}));
const KeywordOperators = [
    {
        label: 'Contains any of',
        value: KeywordOperatorType.OR,
        mode: 'match'
    },
    {
        label: 'Contains all of',
        value: KeywordOperatorType.AND,
        mode: 'include'
    },
    {
        label: 'Does not contain',
        value: KeywordOperatorType.NOT,
        mode: 'exclude'
    }
];
const SectorKeywords = ({ orKeywords = [], notKeywords = [], andKeywords = [], handleUpdateKeywords, disabled }) => {
    const handleOnSubmit = (inputValue, selectedOperator) => {
        if (inputValue === '')
            return;
        const inputValuesList = inputValue.split(',')?.map((value) => value.trim());
        // Wiggle animation for any keywords that are already included in the query
        const chipsToWiggle = [];
        inputValuesList.forEach((inputValue) => {
            if (orKeywords.includes(inputValue) ||
                notKeywords.includes(inputValue) ||
                andKeywords.includes(inputValue)) {
                chipsToWiggle.push(inputValue);
            }
        });
        // Filter out any keywords that are already included in the query
        const filteredInputValuesList = inputValuesList.filter((inputValue) => {
            return (!orKeywords.includes(inputValue) &&
                !notKeywords.includes(inputValue) &&
                !andKeywords.includes(inputValue));
        });
        // Update the query with the new keyword set
        if (selectedOperator === KeywordOperatorType.OR) {
            const updatedOrKeywords = [...orKeywords, ...filteredInputValuesList];
            handleUpdateKeywords(updatedOrKeywords, notKeywords, andKeywords);
        }
        else if (selectedOperator === KeywordOperatorType.NOT) {
            const updatedNotKeywords = [...notKeywords, ...filteredInputValuesList];
            handleUpdateKeywords(orKeywords, updatedNotKeywords, andKeywords);
        }
        else if (selectedOperator === KeywordOperatorType.AND) {
            const updatedAndKeywords = [...andKeywords, ...filteredInputValuesList];
            handleUpdateKeywords(orKeywords, notKeywords, updatedAndKeywords);
        }
    };
    const handleOnDelete = (keyword, operator) => {
        if (operator === KeywordOperatorType.OR) {
            const updatedOrKeywords = orKeywords.filter((orKeyword) => {
                return orKeyword !== keyword;
            });
            handleUpdateKeywords(updatedOrKeywords, notKeywords, andKeywords);
        }
        else if (operator === KeywordOperatorType.NOT) {
            const updatedNotKeywords = notKeywords.filter((notKeyword) => {
                return notKeyword !== keyword;
            });
            handleUpdateKeywords(orKeywords, updatedNotKeywords, andKeywords);
        }
        else if (operator === KeywordOperatorType.AND) {
            const updatedAndKeywords = andKeywords.filter((andKeyword) => {
                return andKeyword !== keyword;
            });
            handleUpdateKeywords(orKeywords, notKeywords, updatedAndKeywords);
        }
    };
    const [andUserInput, setAndUserInput] = useState('');
    const [orUserInput, setOrUserInput] = useState('');
    const [notUserInput, setNotUserInput] = useState('');
    return (_jsx("div", { "data-testid": "exact-keywords", children: _jsx("div", { className: classNames('w-full', {
                'opacity-50': disabled
            }), children: _jsx("div", { className: "flex flex-col gap-4", children: KeywordOperators.map((operator) => {
                    const userInput = operator.value === 'AND'
                        ? andUserInput
                        : operator.value === 'NOT'
                            ? notUserInput
                            : orUserInput;
                    const setUserInput = operator.value === 'AND'
                        ? setAndUserInput
                        : operator.value === 'NOT'
                            ? setNotUserInput
                            : setOrUserInput;
                    return (_jsxs("div", { children: [_jsx("p", { className: "typography-label-default-default text-content-weak", children: operator.label }), _jsx(Select, { dataTestId: `sector-keywords-${operator.value.toLowerCase()}`, dropdownDataTestId: `sector-keywords-${operator.value.toLowerCase()}-dropdown`, multiple: true, selected: operator.value === 'AND'
                                    ? andKeywords
                                    : operator.value === 'NOT'
                                        ? notKeywords
                                        : orKeywords, onRemove: (keyword) => handleOnDelete(keyword, operator.value), filterable: true, filterTerm: userInput, onFilterTermChange: (value) => setUserInput(value), freeSolo: true, placeholder: "Add keywords", onAdd: (value) => {
                                    handleOnSubmit(value, operator.value);
                                    setUserInput('');
                                }, hideDropdown: true, hideChevronDown: true })] }, operator.value));
                }) }) }) }));
};
export default SectorKeywords;
