import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
const SingleRowTruncatedList = ({ children, height, expandButton, className }) => {
    const contentRef = useRef(null);
    const lastVisibleChipRef = useRef(null);
    const [visibilityMap, setVisibilityMap] = useState({});
    const [isClamped, setClamped] = useState(false);
    const [numClamped, setNumClamped] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [plusButtonPosition, setPlusButtonPosition] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleExpand = (event) => {
        setAnchorEl(event.currentTarget);
        setExpanded(true);
    };
    const handleClose = () => {
        setExpanded(false);
        setAnchorEl(null);
    };
    const handleIntersection = useCallback((entries) => {
        const updatedEntries = new Map();
        entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
                const targetid = entry.target.dataset.targetid;
                if (targetid)
                    if (entry.isIntersecting) {
                        updatedEntries.set(targetid || '', true);
                    }
                    else {
                        updatedEntries.set(targetid || '', false);
                    }
            }
        });
        setVisibilityMap(() => ({
            ...Object.fromEntries(updatedEntries)
        }));
    }, []);
    // Listen to resiizing of the component
    useResizeObserver(contentRef.current, () => {
        if (contentRef && contentRef.current) {
            setClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
        }
    });
    // Attach observers for each chip. They will be used to deteremine which ones are intersecting with
    // the contentRef. These are the "unclamped" chips.
    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: contentRef.current,
            threshold: 0
        });
        if (contentRef?.current?.children) {
            Array.from(contentRef.current.children).forEach((item) => {
                if (item instanceof HTMLElement && item.dataset.targetid) {
                    observer.observe(item);
                }
            });
        }
        return () => observer.disconnect();
    }, [contentRef, children]);
    // Update the number of clamped chips and the last visible chip whenever the visibility map updates
    useEffect(() => {
        const newNumClamped = Object.keys(visibilityMap).reduce((acc, key) => {
            if (!visibilityMap[key]) {
                return (acc += 1);
            }
            else {
                return (acc += 0);
            }
        }, 0);
        setNumClamped(newNumClamped);
    }, [visibilityMap, children]);
    useEffect(() => {
        if (!numClamped)
            return;
        const childNodesLength = contentRef?.current?.childNodes?.length ?? 0;
        const lastElement = contentRef?.current?.childNodes[childNodesLength - numClamped - 1];
        setPlusButtonPosition(
        //eslint-disable-next-line
        //@ts-ignore
        (lastElement?.offsetLeft ?? 0) + (lastElement?.offsetWidth ?? 0) + 7);
    }, [numClamped]);
    const childrenArray = React.Children.toArray(children);
    return (_jsxs("div", { className: "flex flex-row justify-start w-6/8 pr-[100px] relative", children: [_jsx("div", { ref: contentRef, className: classNames(`overflow-hidden flex flex-wrap gap-g30 pt-[3px] pl-[3px]`, className), style: { height }, children: childrenArray.map((child, index) => {
                    const isLastVisibleChip = childrenArray.length - index - 1 == numClamped;
                    return (_jsx("div", { ref: isLastVisibleChip ? lastVisibleChipRef : null, "data-targetid": `truncated-list-item-${index}`, className: "inline-block", children: child }, `truncated-list-item-${index}`));
                }) }), isClamped && numClamped > 0 && (_jsx("div", { className: "absolute top-[3px]", style: {
                    left: plusButtonPosition
                }, children: expandButton({ count: numClamped, expand: handleExpand }) })), _jsx(Popover, { open: expanded, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, className: "ml-p30 overflow-hidden bg-none rounded-br40", PaperProps: {
                    style: {
                        borderRadius: 6
                    }
                }, children: _jsx("div", { className: "p-p50 max-h-96 overflow-y-auto flex flex-col gap-y-g40 w-min whitespace-nowrap bg-surface-default shadow-static-elevation-floating border-[1.5px] border-solid border-border rounded-br30", children: childrenArray.slice(childrenArray.length - numClamped) }) })] }));
};
export default SingleRowTruncatedList;
