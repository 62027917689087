import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { DEFAULT_DATE_SELECTOR_OPTIONS, TimeUnit } from 'interfaces/SearchV2';
import React, { useMemo } from 'react';
import { DEFAULT_CUSTOM_RANGE, parseCustomRange } from 'utils/searchV2/searchV2';
import ChipSelect from './ChipSelect';
import SelectorWithValue from './SelectorWithValue';
const ChipWithCustomRange = ({ selectedValue, onValueChange, options, selectedCustomRange, disabled, dataTestId }) => {
    const lastEditedCustomRange = useMemo(() => {
        return selectedCustomRange === undefined
            ? undefined
            : parseCustomRange(selectedCustomRange);
    }, [selectedCustomRange]);
    const chipOptions = [
        ...options,
        {
            label: 'Custom range',
            value: 'custom_rolling'
        }
    ];
    const onChipValueChange = (newValue) => {
        if (newValue === 'custom_rolling') {
            onValueChange({
                value: newValue,
                customRange: lastEditedCustomRange ?? DEFAULT_CUSTOM_RANGE
            });
        }
        else {
            onValueChange({
                value: newValue,
                customRange: undefined
            });
        }
    };
    return (_jsxs("div", { "data-testid": dataTestId, children: [_jsx(ChipSelect, { selectedValue: selectedValue, options: chipOptions, onValueChange: onChipValueChange, disabled: disabled }), selectedValue === 'custom_rolling' && (_jsxs("div", { className: classNames('mt-2 inline-flex flex rounded-md py-3 px-2 bg-surface-raised typography-label'), children: [_jsxs("div", { children: [_jsx("div", { className: "typography-label", children: "Between" }), _jsxs("div", { className: "mt-g40 flex flex-row items-center", children: [lastEditedCustomRange?.start.timeUnit !== undefined && (_jsx(SelectorWithValue, { selector: {
                                            options: DEFAULT_DATE_SELECTOR_OPTIONS,
                                            value: lastEditedCustomRange?.start.timeUnit,
                                            onChange: (value) => {
                                                onValueChange({
                                                    value: selectedValue,
                                                    customRange: {
                                                        start: {
                                                            timeUnit: value,
                                                            value: 0
                                                        },
                                                        end: lastEditedCustomRange.end
                                                    }
                                                });
                                            }
                                        }, value: lastEditedCustomRange?.start.value, hideValueInput: lastEditedCustomRange?.start.timeUnit === TimeUnit.TODAY, onValueChange: (e) => {
                                            // Use a regular expression to remove non-numeric characters
                                            const newValue = e.target.value.replace(/[^0-9]/g, '');
                                            onValueChange({
                                                value: selectedValue,
                                                customRange: {
                                                    start: {
                                                        timeUnit: lastEditedCustomRange?.start.timeUnit,
                                                        value: newValue ? parseInt(newValue) : undefined
                                                    },
                                                    end: lastEditedCustomRange.end
                                                }
                                            });
                                        } })), _jsx("span", { className: "mx-g50 typography-label", children: "-" })] })] }), lastEditedCustomRange?.end.timeUnit !== undefined && (_jsxs("div", { children: [_jsx("div", { className: "typography-label", children: "and" }), _jsx("div", { className: "mt-g40", children: _jsx(SelectorWithValue, { selector: {
                                        options: DEFAULT_DATE_SELECTOR_OPTIONS,
                                        value: lastEditedCustomRange?.end.timeUnit,
                                        onChange: (value) => {
                                            onValueChange({
                                                value: selectedValue,
                                                customRange: {
                                                    start: lastEditedCustomRange?.start,
                                                    end: {
                                                        timeUnit: value,
                                                        value: 0
                                                    }
                                                }
                                            });
                                        }
                                    }, value: lastEditedCustomRange?.end.value, hideValueInput: lastEditedCustomRange?.end.timeUnit === TimeUnit.TODAY, onValueChange: (e) => {
                                        // Use a regular expression to remove non-numeric characters
                                        const newValue = e.target.value.replace(/[^0-9]/g, '');
                                        onValueChange({
                                            value: selectedValue,
                                            customRange: {
                                                start: lastEditedCustomRange?.start,
                                                end: {
                                                    timeUnit: lastEditedCustomRange?.end.timeUnit,
                                                    value: newValue ? parseInt(newValue) : undefined
                                                }
                                            }
                                        });
                                    } }) })] }))] }))] }));
};
export default ChipWithCustomRange;
