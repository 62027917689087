import { GET_WATCHLIST } from 'queries/getWatchlist';
import { useBaseFetchSingleWatchlist } from './useBaseFetchSingleWatchlist';
import { DashboardLocation } from './useDashboardLocation';
const useFetchSingleWatchlist = ({ fetchPolicy = 'cache-first', skipLocalSearch = false } = {}) => {
    const { data, loading, error } = useBaseFetchSingleWatchlist({
        fetchPolicy,
        query: GET_WATCHLIST,
        location: DashboardLocation.COMPANY_LIST,
        transformData: (data) => data?.getCompanyWatchlistByIdOrUrn,
        skipLocalSearch
    });
    return {
        companyWatchlist: data,
        companyWatchlistLoading: loading,
        companyWatchlistError: error
    };
};
export default useFetchSingleWatchlist;
