import { ReactComponent as MyNetworkIcon } from 'common/assets/network/my-network.svg';
import { ReactComponent as NotInNetworkIcon } from 'common/assets/network/not-in-network.svg';
import { ReactComponent as TeamNetworkIcon } from 'common/assets/network/team-network.svg';
export const getNetworkIcon = (isCurrentUserConnected, networkLength) => {
  if (isCurrentUserConnected) {
    return MyNetworkIcon;
  }
  if (networkLength > 0) {
    return TeamNetworkIcon;
  }
  return NotInNetworkIcon;
};
export const generateNetworkLabel = (usersInNetwork, currentUserEmail, isCurrentUserConnected) => {
  const otherUsers = usersInNetwork.filter(user => user.email !== currentUserEmail);
  const numConnectionToShow = isCurrentUserConnected ? 1 : 2;
  const slicedConnections = otherUsers.slice(0, numConnectionToShow).map(user => user.name);
  const otherConnections = Math.max(0, otherUsers.length - numConnectionToShow);
  if (!isCurrentUserConnected && slicedConnections.length === 0) {
    return 'Not in network';
  }
  const connectionsList = slicedConnections.join(', ');
  const othersText = otherConnections > 0 ? ` and ${otherConnections} ${otherConnections > 1 ? 'others' : 'other'}` : '';
  if (isCurrentUserConnected) {
    if (slicedConnections.length === 0) {
      return 'You';
    }
    if (slicedConnections.length === 1 && otherConnections === 0) {
      return `You and ${slicedConnections[0]}`;
    }
    return `You, ${connectionsList}${othersText}`;
  }
  return `${connectionsList}${othersText}`;
};
export const getNetworkBadgeColor = networkLength => {
  return networkLength > 0 ? 'informative' : 'neutral';
};
const getLatestDate = conn => {
  const calendarDate = conn.latestCalendarEvent?.startTime;
  const emailDate = conn.latestEmail?.timestamp;
  if (!calendarDate && !emailDate) return 0;
  if (!calendarDate) return new Date(emailDate).getTime();
  if (!emailDate) return new Date(calendarDate).getTime();
  return Math.max(new Date(calendarDate).getTime(), new Date(emailDate).getTime());
};
// Helper to determine which connection is more recent
function isMoreRecent(conn1, conn2) {
  return getLatestDate(conn1) > getLatestDate(conn2);
}
export const formatConnectionStrings = (userConnections, currentUserEmail, targetName) => {
  // Helper to check if a connection involves the current user
  const isCurrentUserConnection = connection => connection.user?.email === currentUserEmail;
  // Deduplicate connections by user, prioritizing interactions over LinkedIn
  const userConnectionMap = new Map();
  userConnections.forEach(connection => {
    const userEmail = connection.user?.email;
    if (!userEmail) return;
    const existingConnection = userConnectionMap.get(userEmail);
    const hasNewInteraction = !!(connection.latestCalendarEvent || connection.latestEmail);
    const hasExistingInteraction = existingConnection && !!(existingConnection.latestCalendarEvent || existingConnection.latestEmail);
    // Replace existing connection if:
    // 1. No existing connection for this user, or
    // 2. New connection has interaction while existing doesn't, or
    // 3. Both have interactions but new one is more recent
    if (!existingConnection || hasNewInteraction && !hasExistingInteraction || hasNewInteraction && hasExistingInteraction && isMoreRecent(connection, existingConnection)) {
      userConnectionMap.set(userEmail, connection);
    }
  });
  const dedupedConnections = Array.from(userConnectionMap.values());
  // Sort connections: current user first, then email/calendar before linkedin
  const sortedConnections = dedupedConnections.sort((a, b) => {
    // Current user connections come first
    const aIsCurrentUser = isCurrentUserConnection(a);
    const bIsCurrentUser = isCurrentUserConnection(b);
    if (aIsCurrentUser !== bIsCurrentUser) {
      return aIsCurrentUser ? -1 : 1;
    }
    // Email/calendar before linkedin
    const aHasInteraction = !!(a.latestCalendarEvent || a.latestEmail);
    const bHasInteraction = !!(b.latestCalendarEvent || b.latestEmail);
    return aHasInteraction === bHasInteraction ? 0 : aHasInteraction ? -1 : 1;
  });
  // Helper to get interaction type and date
  const getInteractionDetails = connection => {
    const latestInteractionDate = getLatestDate(connection);
    if (latestInteractionDate) {
      return {
        type: 'last interacted with',
        date: new Date(latestInteractionDate).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
      };
    }
    return {
      type: 'are connected to',
      date: null
    };
  };
  // Get the first 3 connections for detailed strings
  const topConnections = sortedConnections.slice(0, 3);
  const remainingConnections = sortedConnections.slice(3);
  // Format the detailed strings for top 3
  const detailedConnections = topConnections.map(connection => {
    const isCurrentUser = isCurrentUserConnection(connection);
    const {
      type,
      date
    } = getInteractionDetails(connection);
    const connectionTargetName = connection.targetPerson?.fullName ?? targetName ?? 'Unknown';
    const sourceName = connection.user?.name || 'Unknown';
    const dateString = date ? ` on ${date}` : '';
    if (isCurrentUser) {
      return `You ${type} ${connectionTargetName}${dateString}.`;
    } else {
      if (date) {
        return `${sourceName} last interacted with ${connectionTargetName}${dateString}.`;
      }
      return `${sourceName} is connected to ${connectionTargetName} on LinkedIn.`;
    }
  });
  // Create summary string for remaining connections if any
  let summaryString = null;
  if (remainingConnections.length > 0) {
    const allNames = remainingConnections.map(conn => conn.user?.name || 'Unknown');
    let namesList;
    if (allNames.length === 1) {
      namesList = allNames[0];
    } else if (allNames.length === 2) {
      namesList = `${allNames[0]} and ${allNames[1]}`;
    } else {
      // Show first two names and count the rest
      namesList = `${allNames[0]}, ${allNames[1]}, and ${allNames.length - 2} ${allNames.length - 2 === 1 ? 'other' : 'others'}`;
    }
    summaryString = `${namesList} ${allNames.length === 1 ? 'is' : 'are'} also connected to ${targetName}`;
  }
  return {
    detailedConnections,
    summaryString
  };
};