export var CollectionType;
(function (CollectionType) {
    CollectionType["LISTS"] = "Lists";
    CollectionType["SEARCHES"] = "Searches";
})(CollectionType || (CollectionType = {}));
export var VisibilityEnum;
(function (VisibilityEnum) {
    VisibilityEnum["any"] = "any";
    VisibilityEnum["private"] = "private";
    VisibilityEnum["shared"] = "shared";
})(VisibilityEnum || (VisibilityEnum = {}));
export var CreatorEnum;
(function (CreatorEnum) {
    CreatorEnum["anyone"] = "anyone";
    CreatorEnum["me"] = "me";
})(CreatorEnum || (CreatorEnum = {}));
export var CurrentTabEnum;
(function (CurrentTabEnum) {
    CurrentTabEnum["all"] = "all";
    CurrentTabEnum["companies"] = "companies";
    CurrentTabEnum["people"] = "people";
})(CurrentTabEnum || (CurrentTabEnum = {}));
