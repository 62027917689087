import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'harmonic-components/Select/Select';
import { uniq } from 'lodash';
import { useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const MultiselectWithMode = ({ selected, onChange, initialFocus = false, label, mode = null, placeholder = 'Add role title', dataTestId }) => {
    const [userInput, setUserInput] = useState('');
    const handleOnSubmit = (inputValue) => {
        if (inputValue === '')
            return;
        let inputValuesList = inputValue.split(',');
        // Trim values if there are more than one to avoid list separated by comma and spaces
        // In the case that there is only one value we allow spaces so we can match words
        if (inputValuesList.length > 1) {
            inputValuesList = inputValuesList.map((value) => value.trim());
        }
        const filteredInputValuesList = uniq([...selected, ...inputValuesList]);
        onChange(filteredInputValuesList);
    };
    const handleOnDelete = (value) => {
        const updatedValues = selected.filter((selectedValue) => selectedValue !== value);
        onChange(updatedValues);
    };
    return (_jsxs("div", { className: "flex flex-col gap-g20", children: [label && (_jsx("label", { className: "text-content-weak typography-label", children: label })), _jsx(Select, { multiple: true, selected: selected, onRemove: (keyword) => handleOnDelete(keyword), filterable: true, filterTerm: userInput, onFilterTermChange: (value) => setUserInput(value), freeSolo: true, placeholder: placeholder, initialFocus: initialFocus, getTagColorFromValue: () => getTagColorBySelectMode(mode), getTagIconFromValue: () => getTagIconBySelectMode(mode), onAdd: (value) => {
                    handleOnSubmit(value);
                    setUserInput('');
                }, hideDropdown: true, hideChevronDown: true, dataTestId: dataTestId })] }));
};
export default MultiselectWithMode;
