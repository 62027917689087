import { gql } from '@apollo/client';
import { CorrespondenceSummary, UserConnectionFragment } from './getCompanyUserConnections';
const getPersonUserConnections = gql `
  query GetPersonUserConnections($id: Int!) {
    getPersonById(id: $id) {
      id
      correspondenceSummary {
        ...CorrespondenceSummary
      }
      userConnections {
        ...UserConnection
      }
    }
  }
  ${CorrespondenceSummary}
  ${UserConnectionFragment}
`;
export default getPersonUserConnections;
