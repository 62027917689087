import { gql } from '@apollo/client';
export const PEOPLE_WATCHLIST_DIGEST_CONFIG_FRAGMENT = gql `
  fragment PeopleWatchlistDigestConfig on PeopleWatchlistDigestConfig {
    deliveryCadence
    deliveryChannel
    entityUrn
    lastSentAt
    peopleWatchlists
    userUrn
    isEnabled
  }
`;
export const GET_PEOPLE_WATCHLIST_DIGEST_CONFIG = gql `
  query GetPeopleWatchlistDigestConfig {
    getPeopleWatchlistDigestConfig {
      ...PeopleWatchlistDigestConfig
    }
  }
  ${PEOPLE_WATCHLIST_DIGEST_CONFIG_FRAGMENT}
`;
export const GET_PEOPLE_WATCHLIST_DIGEST_CONFIG_MOCKED_RESPONSE = {
    getPeopleWatchlistDigestConfig: {
        deliveryCadence: 'WEEKLY',
        deliveryChannel: 'EMAIL',
        entityUrn: 'urn:harmonic:people_watchlist_digest_config:35',
        lastSentAt: null,
        peopleWatchlists: [
            'urn:harmonic:people_watchlist:6db89fb8-4712-4d0e-bd75-1d7a99523157'
        ],
        userUrn: 'urn:harmonic:user:357',
        isEnabled: true,
        __typename: 'PeopleWatchlistDigestConfig'
    }
};
