import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { Badge } from 'common/components';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useState } from 'react';
import { truncateString } from 'utils/utilities';
const DESCRIPTION_MAX_LENGTH = 300;
const CompanyAbout = ({ companyId }) => {
    const [showAllDescription, setShowAllDescription] = useState(false);
    const { data } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const companyData = data?.getCompanyById;
    const description = companyData?.description ?? '';
    const companyTags = companyData?.tagsV2?.map((tag) => tag?.displayValue) ?? [];
    const isDescriptionOverLimit = !!(description.length > DESCRIPTION_MAX_LENGTH);
    if (!description && !companyTags?.length)
        return null;
    return (_jsxs("div", { children: [_jsxs("p", { className: classNames('text-content-default typography-paragraph whitespace-pre-line'), children: [showAllDescription
                        ? description
                        : truncateString(description, DESCRIPTION_MAX_LENGTH), isDescriptionOverLimit && (_jsx("button", { className: classNames('ml-2 typography-paragraph-default-link', 'text-content-strong'), "data-testid": "CompanyAbout-Expand-Description", onClick: () => setShowAllDescription(!showAllDescription), children: showAllDescription ? 'see less' : 'see more' }))] }), _jsx("div", { className: "mt-3 text-sm flex items-center gap-g40 flex-wrap", children: companyTags?.map((tag, index) => (_jsx(Badge, { label: tag, color: "neutral", size: "large", intensity: "subtle" }, tag))) })] }));
};
export default CompanyAbout;
