import { gql } from '@apollo/client';
const SAVED_SEARCH_FRAGMENT = gql `
  fragment SavedSearchFields on SavedSearch {
    id
    name
    entityUrn
  }
`;
const DIGEST_SUBSCRIPTION_FRAGMENT = gql `
  fragment DigestSubscriptionFields on SavedSearchDigestConfigOutput {
    entityUrn
    deliveryChannel
    deliveryCadence
    isEnabled
    savedSearches {
      ...SavedSearchFields
    }
  }
`;
export const MUTATE_USER_DIGEST_SUBSCRIPTIONS = gql `
  ${SAVED_SEARCH_FRAGMENT}
  ${DIGEST_SUBSCRIPTION_FRAGMENT}
  mutation UpsertSavedSearchNotificationDigests(
    $savedSearchUrnsEnabledForDigest: [String!]!
    $deliveryChannel: String!
    $deliveryCadence: String!
    $isEnabled: Boolean!
    $entityUrn: String
  ) {
    upsertSavedSearchNotificationDigests(
      savedSearchUrnsEnabledForDigest: $savedSearchUrnsEnabledForDigest
      deliveryChannel: $deliveryChannel
      deliveryCadence: $deliveryCadence
      isEnabled: $isEnabled
      entityUrn: $entityUrn
    ) {
      ...DigestSubscriptionFields
    }
  }
`;
export const GET_USER_DIGEST_SUBSCRIPTIONS = gql `
  ${SAVED_SEARCH_FRAGMENT}
  ${DIGEST_SUBSCRIPTION_FRAGMENT}
  query getUserDigestSubscriptions {
    getUserDigestSubscriptions {
      ...DigestSubscriptionFields
    }
  }
`;
