import AfricaIcon from 'assets/regions/africa';
import AsiaIcon from 'assets/regions/asia';
import EuropeIcon from 'assets/regions/europe';
import NorthAmericaIcon from 'assets/regions/north-america';
import OceaniaIcon from 'assets/regions/oceania';
import SouthAmericaIcon from 'assets/regions/south-america';
export const chipDisplayOrder = [
    {
        title: 'North America',
        region: 'NORTH_AMERICA',
        icon: NorthAmericaIcon
    },
    {
        title: 'Latin America',
        region: 'LATIN_AMERICA',
        icon: SouthAmericaIcon
    },
    {
        title: 'Europe',
        region: 'EUROPE',
        icon: EuropeIcon
    },
    {
        title: 'Africa',
        region: 'AFRICA',
        icon: AfricaIcon
    },
    {
        title: 'Oceania',
        region: 'OCEANIA',
        icon: OceaniaIcon
    },
    {
        title: 'Asia',
        region: 'ASIA',
        icon: AsiaIcon
    }
];
