import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { Popover } from '@headlessui/react';
import VerticalMenuIcon from 'assets/harmonic-icons/vertical-menu';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useFlags from 'hooks/useFlags';
import { toast } from 'react-toastify';
import { SPLITS } from 'utils/constants';
const ExtraSideActionsButton = ({ duplicateFilter, showConvertToGroupOption, onConvertToGroup, onRemoveFilter, copyToClipboard }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'filter-settings' : undefined;
    const { enabled: showCopyJson } = useFlags(SPLITS.searchCopyJsonToClipboard);
    return (_jsx(_Fragment, { children: _jsxs(Popover, { className: "relative", children: [_jsx(Popover.Button, { children: _jsx(IconButton, { icon: VerticalMenuIcon, emphasis: "low", "aria-describedby": id, onClick: handleClick }) }), _jsx(Popover.Panel, { className: "absolute z-1400 w-40 mt-p20 right-0", children: _jsxs(Dropdown, { children: [onRemoveFilter && (_jsx(ListItem, { variant: ListVariant.default, onClick: () => {
                                    onRemoveFilter();
                                    handleClose();
                                }, label: "Remove", value: "Remove" })), _jsx(ListItem, { variant: ListVariant.default, onClick: () => {
                                    duplicateFilter();
                                    handleClose();
                                }, label: "Duplicate", value: "Duplicate" }), showConvertToGroupOption && (_jsx(ListItem, { variant: ListVariant.default, onClick: () => {
                                    if (onConvertToGroup)
                                        onConvertToGroup();
                                    handleClose();
                                }, label: "Convert to group", value: "Convert to group" })), showCopyJson && copyToClipboard && (_jsx(ListItem, { variant: ListVariant.default, onClick: () => {
                                    copyToClipboard();
                                    toast.success('Copied JSON to clipboard');
                                    handleClose();
                                }, label: "Copy JSON", value: "Copy JSON" }))] }) })] }) }));
};
export default ExtraSideActionsButton;
