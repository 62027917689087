import { jsx as _jsx } from "react/jsx-runtime";
import { BusinessIcon, DollarIcon, HashtagIcon, MoneyHandIcon, PersonCircleIcon, ProcessorIcon, SearchIcon, StreamingIcon } from 'assets/harmonic-icons';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import Button from 'harmonic-components/Button/Button';
import SingleRowTruncatedList from '../../../harmonic-components/SingleRowTruncatedList/SingleRowTruncatedList';
const buttonOptions = [
    {
        label: 'Date added to Harmonic',
        fieldNames: ['company_initialized_date_range_value'],
        icon: CalendarDaysIcon
    },
    {
        label: 'Total funding',
        fieldNames: ['company_funding_range'],
        icon: MoneyHandIcon
    },
    {
        label: 'Headcount',
        fieldNames: ['company_headcount_range'],
        icon: PersonCircleIcon
    },
    {
        label: 'Investors',
        fieldNames: ['company_investor_names_any_of'],
        icon: DollarIcon
    },
    {
        label: 'In list',
        fieldNames: [
            'company_in_watchlist_ids_any_of',
            'company_in_watchlist_ids_none_of'
        ],
        icon: HashtagIcon
    },
    {
        label: 'Keywords',
        fieldNames: [
            'company_keywords_contains_any_of',
            'company_keywords_contains_all_of',
            'company_keywords_contains_none_of'
        ],
        icon: SearchIcon
    },
    {
        label: 'Last funding date',
        fieldNames: ['company_last_funding_date_range_value'],
        icon: CalendarDaysIcon
    },
    {
        label: 'Founding date',
        fieldNames: ['company_founding_date_range_value'],
        icon: CalendarDaysIcon
    },
    {
        label: 'Industry',
        fieldNames: [
            'company_industry_tags_include_any_of',
            'company_industry_tags_exclude_all_of'
        ],
        icon: BusinessIcon
    },
    {
        label: 'Technology',
        fieldNames: [
            'company_technology_tags_include_any_of',
            'company_technology_tags_exclude_all_of'
        ],
        icon: ProcessorIcon
    },
    {
        label: 'Customer Type',
        fieldNames: [
            'company_customer_type_tags_include_any_of',
            'company_customer_type_tags_exclude_all_of'
        ],
        icon: StreamingIcon
    }
];
const CurrentCompanySectionExpandableButtons = ({ expandedFields, addNewExpandedField, mode }) => {
    let filteredButtonOptions = buttonOptions;
    if (mode === 'past') {
        filteredButtonOptions = buttonOptions.filter((option) => option.fieldNames?.[0] !== 'company_initialized_date_range_value');
    }
    return (_jsx(SingleRowTruncatedList, { height: 42, expandButton: ({ expand, count }) => (_jsx(Button, { type: "secondary", emphasis: "high", onClick: expand, label: `+${count}` })), children: filteredButtonOptions.map((option) => {
            if (!expandedFields.includes(option.fieldNames[0]))
                return (_jsx(Button, { dataTestId: `CurrentPastCompany-ExpandableButton-${option.fieldNames[0]}`, leadingIcon: option.icon, label: option.label, type: "secondary", emphasis: "high", onClick: () => addNewExpandedField(option.fieldNames) }, option.label));
        }) }));
};
export default CurrentCompanySectionExpandableButtons;
