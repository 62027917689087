import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useShallowPersistedFieldSpecStore } from 'stores/persistedFieldSpecStore';
import { SPLITS } from 'utils/constants';
import { fieldSpecPromise } from 'utils/grid';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
import { useMultipleFlags } from './useFlags';
export const useFieldSpec = () => {
    const { location } = useDashboardLocation();
    const { companiesFieldSpec, peopleFieldSpec, setCompaniesFieldSpec, setPeopleFieldSpec } = useShallowPersistedFieldSpecStore([
        'companiesFieldSpec',
        'peopleFieldSpec',
        'setCompaniesFieldSpec',
        'setPeopleFieldSpec'
    ]);
    const [peopleCalled, setPeopleCalled] = useState(false);
    const [companiesCalled, setCompaniesCalled] = useState(false);
    const [peopleLoading, setPeopleLoading] = useState(false);
    const [companiesLoading, setCompaniesLoading] = useState(false);
    const featureFlags = useMultipleFlags(Object.values(SPLITS));
    const filterFieldSpecForCurrentSplit = useCallback((spec) => spec.filter((field) => {
        const visibleSplits = field.visible_to_splits ?? [];
        if (!visibleSplits.length) {
            return true;
        }
        for (const visibleSplit of visibleSplits) {
            for (const split of Object.values(SPLITS)) {
                if (split === visibleSplit) {
                    if (featureFlags[split]?.enabled) {
                        return true;
                    }
                    break;
                }
            }
        }
        return false;
    }), [featureFlags]);
    const fetchCompanySpec = useCallback(async () => {
        const companiesSpec = await fieldSpecPromise(ApiResourceType.CompaniesList);
        if (typeof companiesSpec === 'string') {
            const data = JSON.parse(companiesSpec);
            setCompaniesFieldSpec(data);
        }
        else {
            setCompaniesFieldSpec(companiesSpec);
        }
        setCompaniesLoading(false);
    }, [setCompaniesFieldSpec]);
    const fetchPeopleSpec = useCallback(async () => {
        const peopleSpec = await fieldSpecPromise(ApiResourceType.PeopleList);
        if (typeof peopleSpec === 'string') {
            const data = JSON.parse(peopleSpec);
            setPeopleFieldSpec(data);
        }
        else {
            setPeopleFieldSpec(peopleSpec);
        }
        setPeopleLoading(false);
    }, [setPeopleFieldSpec]);
    useEffect(() => {
        if (!companiesCalled &&
            !companiesLoading &&
            companiesFieldSpec[0].unique_name === 'default') {
            setCompaniesLoading(true);
            setCompaniesCalled(true);
            fetchCompanySpec();
        }
        if (!peopleCalled &&
            !peopleLoading &&
            peopleFieldSpec[0].unique_name === 'default') {
            setPeopleLoading(true);
            setPeopleCalled(true);
            fetchPeopleSpec();
        }
    }, [companiesLoading, fetchCompanySpec, fetchPeopleSpec, peopleLoading]);
    const fieldSpecByPage = useMemo(() => {
        if (peopleLoading || companiesLoading) {
            return [];
        }
        if (location === DashboardLocation.PEOPLE_SEARCH ||
            location === DashboardLocation.PERSON_LIST) {
            return filterFieldSpecForCurrentSplit(peopleFieldSpec);
        }
        else {
            return filterFieldSpecForCurrentSplit(companiesFieldSpec);
        }
    }, [
        location,
        companiesFieldSpec,
        peopleFieldSpec,
        filterFieldSpecForCurrentSplit
    ]);
    return {
        fieldSpec: fieldSpecByPage,
        companiesFieldSpec,
        peopleFieldSpec
    };
};
