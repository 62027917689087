import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
const PersonAttribute = ({ src, href, name, disabled = false, internalLink = false }) => {
    const classes = classNames('flex items-start gap-g30 px-p30 py-p10 rounded-br30 bg-int-fill-secondary-enabled', 'aria-disabled:bg-int-fill-secondary-disabled aria-disabled:cursor-default', href && 'cursor-pointer hover:bg-int-fill-secondary-hover', !href && 'cursor-default');
    const mainContent = (_jsxs(_Fragment, { children: [_jsx(Avatar, { size: "medium", kind: "face", src: src, alt: "person-profile-picture" }), _jsx("p", { className: "text-content-strong typography-label-default-default line-clamp-1", children: name })] }));
    if (!internalLink)
        return (_jsx("a", { className: classes, "aria-disabled": disabled, href: href, target: "_blank", rel: "noopener noreferrer", children: mainContent }));
    return (_jsx(InternalLink, { to: { pathname: href }, children: _jsx("div", { className: classes, children: mainContent }) }));
};
export default PersonAttribute;
