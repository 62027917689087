import { gql } from '@apollo/client';
import { LocationType } from '__generated__/graphql';
const peopleFragment = gql `
  fragment TypeaheadPeople on Query {
    getPeopleWithTypeahead(query: $query) {
      ...GetPeopleWithTypeahead
    }
  }

  fragment GetPeopleWithTypeahead on Person {
    id
    fullName
    experience {
      company {
        id
        name
      }
      title
      department
      isCurrentPosition
    }
    watchlists {
      id
    }
    profilePictureUrl
  }
`;
const companiesFragment = gql `
  fragment TypeaheadCompanies on Query {
    getCompaniesWithTypeahead(query: $query) {
      ...GetCompaniesWithTypeahead
    }
  }

  fragment GetCompaniesWithTypeahead on Company {
    id
    logoUrl
    name
    headcount
    funding {
      lastFundingType
    }
    location {
      city
      state
      country
    }
    website {
      domain
    }
  }
`;
export const TYPEAHEAD_PEOPLE_QUERY = gql `
  query TypeaheadPeople($query: String!) {
    ...TypeaheadPeople
  }
  ${peopleFragment}
`;
const investorFragment = gql `
  fragment TypeaheadInvestor on Query {
    getInvestorsWithTypeahead(query: $query) {
      investors {
        ...GetInvestorWithTypeahead
      }
    }
  }

  fragment GetInvestorWithTypeahead on InternalInvestor {
    entityUrn
    details {
      ... on Company {
        id
        name
        logoUrl
        location {
          city
          state
        }
        website {
          domain
        }
      }
      ... on Person {
        id
        name: fullName
        logoUrl: profilePictureUrl
        location {
          city
          state
        }
      }
    }
  }
`;
export const TYPEAHEAD_COMPANIES_QUERY = gql `
  query TypeaheadCompanies($query: String!) {
    ...TypeaheadCompanies
  }
  ${companiesFragment}
`;
export const TYPEAHEAD_ALL_QUERY_WITH_INVESTORS = gql `
  query TypeaheadSearchWithInvestors($query: String!) {
    ...TypeaheadCompanies
    ...TypeaheadPeople
    ...TypeaheadInvestor
  }
  ${companiesFragment}
  ${peopleFragment}
  ${investorFragment}
`;
export const TYPEAHEAD_INVESTOR_COMPANIES_QUERY = gql `
  fragment GetInvestorCompanyWithTypeahead on InternalInvestor {
    entityUrn
    details {
      ... on Company {
        id
        name
        logoUrl
      }
      ... on Person {
        id
        name: fullName
        logoUrl: profilePictureUrl
      }
    }
  }

  query TypeaheadInvestorCompanies(
    $query: String!
    $type: InternalInvestorTypeaheadType
  ) {
    getInvestorsWithTypeahead(query: $query, type: $type) {
      investors {
        ...GetInvestorCompanyWithTypeahead
      }
    }
  }
`;
export const TYPEAHEAD_ALL_QUERY_MOCKED_RESPONSE = {
    getCompaniesWithTypeahead: [
        {
            id: 1518027,
            logoUrl: null,
            name: 'Cosan S.A.',
            headcount: null,
            funding: {
                lastFundingType: null,
                __typename: 'Funding'
            },
            location: null,
            __typename: 'Company'
        }
    ],
    __typename: 'Query',
    getPeopleWithTypeahead: [
        {
            id: 61750040,
            fullName: 'S Chm',
            experience: [
                {
                    company: {
                        id: 606452,
                        name: 'JeffreyGroup',
                        __typename: 'Company'
                    },
                    title: 'wsedrser',
                    department: null,
                    isCurrentPosition: false,
                    __typename: 'ExperienceMetadata'
                }
            ],
            profilePictureUrl: 'https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
            __typename: 'Person'
        }
    ]
};
export const mockTypeaheadLocations = {
    getLocationsWithTypeahead: [
        {
            entityUrn: 'urn:harmonic:location:United_States|California|San_Francisco',
            text: 'San Francisco, California, United States',
            locationType: LocationType.CITY,
            city: 'San Francisco',
            state: 'California',
            country: 'United States'
        },
        {
            entityUrn: 'urn:harmonic:location:United_States|California|San_Jose',
            text: 'San Jose, California, United States',
            locationType: LocationType.CITY,
            city: 'San Jose',
            state: 'California',
            country: 'United States'
        },
        {
            entityUrn: 'urn:harmonic:location:United_States',
            text: 'United States',
            locationType: LocationType.COUNTRY,
            city: '',
            state: '',
            country: 'United States'
        }
    ]
};
export const getLocationTypeahead = gql `
  query GetLocationsWithTypeahead($query: String!) {
    getLocationsWithTypeahead(query: $query) {
      city
      country
      entityUrn
      locationType
      state
      text
    }
  }
`;
export const TYPEAHEAD_SCHOOLS_QUERY = gql `
  query GetSchoolsWithTypeahead($query: String!) {
    getSchoolsWithTypeahead(query: $query) {
      entityUrn
      name
    }
  }
`;
