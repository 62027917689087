import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import ChipWithCustomRange from '../../Common/ChipWithCustomRange';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
import FoundingDateSelect from 'components/QueryBuilder/Common/FoundingDateSelect';
import { formatCustomRange, getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import AddLocation from '../../Common/AddLocation';
import ButtonWrapper from '../../Common/ButtonWrapper';
import ListMultiselect from '../../Common/ListMultiselect';
import { SectionDivider, SmallSectionHeader, SubSectionHeader } from '../../Common/Typography';
import { AffinityBanner } from './AffinityBanner';
import Banner from 'harmonic-components/Banner/Banner';
import { SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY } from 'utils/constants';
const CompanyInitializedOrUpdatedMultiselectOptions = [
    {
        label: 'Any',
        value: undefined
    },
    {
        label: 'In the last week',
        value: 'past_week'
    },
    {
        label: 'In the last month',
        value: 'past_thirty_days'
    }
];
const setAffinityBannerDismissLocalStorageState = (dismissed) => {
    localStorage.setItem('simpleSearchAffinityBannerDismissed', JSON.stringify(dismissed));
};
const getAffinityBannerDismissLocalStorageState = () => {
    const affinityBannerDismissed = localStorage.getItem('simpleSearchAffinityBannerDismissed');
    return affinityBannerDismissed ? JSON.parse(affinityBannerDismissed) : false;
};
const setProductBannerDismissLocalStorageState = (dismissed) => {
    localStorage.setItem(SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY, JSON.stringify(dismissed));
};
const getProductBannerDismissLocalStorageState = () => {
    const productBannerDismissed = localStorage.getItem(SIMPLE_SEARCH_PRODUCT_BANNER_DISMISSED_KEY);
    return productBannerDismissed ? JSON.parse(productBannerDismissed) : false;
};
const GeneralSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const [affinityBannerDismissed, setAffinityBannerDismissed] = React.useState(getAffinityBannerDismissLocalStorageState());
    const [productBannerDismissed, setProductBannerDismissed] = React.useState(getProductBannerDismissLocalStorageState());
    const companyFoundingDate = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE];
    const companyLocation = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION];
    const companyInitializedDate = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE];
    const includeFromCompanyWatchlistIds = controlledSearchState?.[FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST]?.watchlist_ids;
    const excludeFromCompanyWatchlistIds = controlledSearchState?.[FilterGroupGeneratorId
        .SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST]?.watchlist_ids;
    const onDismissAffinityBanner = () => {
        setAffinityBannerDismissed(true);
        setAffinityBannerDismissLocalStorageState(true);
    };
    const onDismissProductBanner = () => {
        setProductBannerDismissed(true);
        setProductBannerDismissLocalStorageState(true);
    };
    return (_jsx("div", { className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-g90", children: [!productBannerDismissed && (_jsx(Banner, { text: "Semantic and keyword filters have moved to the Product tab", showIcon: true, type: "informative", onClose: onDismissProductBanner })), _jsxs("div", { id: "Location", className: "w-full", children: [_jsx(SubSectionHeader, { children: "Location" }), _jsx(AddLocation, { locationState: companyLocation, onValueChange: (newLocationValue) => {
                                return onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION, newLocationValue));
                            } })] }), _jsxs("div", { id: "Founding-Date", children: [_jsx(SubSectionHeader, { children: "Founding year" }), _jsx(FoundingDateSelect, { selected: companyFoundingDate?.range_value, customRangeValue: companyFoundingDate
                                ?.custom_range, onChange: (newValue, newCustomValue) => {
                                onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE, newValue !== undefined
                                    ? {
                                        range_value: newValue,
                                        custom_range: newCustomValue
                                    }
                                    : undefined));
                            } })] }), _jsxs("div", { id: "Initialized-Date", children: [_jsx(SubSectionHeader, { children: "Added to Harmonic" }), _jsx(ChipWithCustomRange, { dataTestId: "Company-Date-Initialized", options: CompanyInitializedOrUpdatedMultiselectOptions, onValueChange: (newValue) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE, newValue?.value !== undefined
                                ? {
                                    range_value: newValue.value,
                                    custom_range: formatCustomRange(newValue.customRange)
                                }
                                : undefined)), selectedValue: companyInitializedDate?.range_value, disabled: disabled, selectedCustomRange: companyInitializedDate?.custom_range })] }), _jsx(SectionDivider, {}), _jsxs("div", { id: "Filter-By-List", children: [_jsx(SmallSectionHeader, { children: "Filter companies by list" }), !affinityBannerDismissed && (_jsx("div", { className: "mb-p70", children: _jsx(AffinityBanner, { onDismiss: onDismissAffinityBanner }) })), _jsxs("div", { className: classNames('flex flex-row gap-g60'), children: [_jsxs("div", { className: "w-1/2", children: [_jsx(SubSectionHeader, { children: "Include lists" }), _jsx(ButtonWrapper, { label: "Include lists", leadingIcon: CirclePlus, skipToChildren: (includeFromCompanyWatchlistIds ?? []).length > 0 ?? false, children: _jsx(ListMultiselect, { "data-testid": "Include-Companies-From-List", placeholder: "Select company lists to include", mode: "company_watchlist", initialFocus: (includeFromCompanyWatchlistIds ?? []).length === 0, onChange: (newOptions) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST, newOptions !== undefined && newOptions.length > 0
                                                    ? {
                                                        watchlist_ids: newOptions
                                                    }
                                                    : undefined)), selected: includeFromCompanyWatchlistIds ?? [], separateAffinityLists: true }) })] }), _jsxs("div", { className: "w-1/2", children: [_jsx(SubSectionHeader, { children: "Exclude lists" }), _jsx(ButtonWrapper, { label: "Exclude lists", leadingIcon: CircleMinus, skipToChildren: (excludeFromCompanyWatchlistIds ?? []).length > 0 ?? false, children: _jsx(ListMultiselect, { "data-testid": "Exclude-Companies-From-List", placeholder: "Select company lists to exclude", mode: "company_watchlist", excludeMode: true, initialFocus: (excludeFromCompanyWatchlistIds ?? []).length === 0, onChange: (newOptions) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST, newOptions !== undefined && newOptions.length > 0
                                                    ? {
                                                        watchlist_ids: newOptions
                                                    }
                                                    : undefined)), selected: excludeFromCompanyWatchlistIds ?? [], separateAffinityLists: true }) })] })] })] })] }) }));
};
export default GeneralSection;
