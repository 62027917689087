import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'harmonic-components/Select/Select';
import { isNil, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const KeywordsMultiselect = ({ selected, onChange, initialFocus = false, mode = null, dataTestId }) => {
    const [userInput, setUserInput] = useState('');
    const handleOnSubmit = (inputValue) => {
        if (inputValue === '')
            return;
        let inputValuesList = inputValue.split(',');
        // Trim values if there are more than one to avoid list separated by comma and spaces
        // In the case that there is only one value we allow spaces so we can match words
        if (inputValuesList.length > 1) {
            inputValuesList = inputValuesList.map((value) => value.trim());
        }
        const filteredInputValuesList = uniq([...selected, ...inputValuesList]);
        onChange(filteredInputValuesList);
    };
    const handleOnDelete = (value) => {
        const updatedValues = selected.filter((selectedValue) => selectedValue !== value);
        onChange(updatedValues);
    };
    const label = useMemo(() => {
        if (isNil(mode))
            return;
        if (mode === 'include')
            return 'Contains any of';
        if (mode === 'select')
            return 'Contains all of';
        if (mode === 'exclude')
            return 'Contains none of';
    }, [mode]);
    return (_jsxs("div", { className: "flex flex-col gap-g20", children: [label && (_jsx("label", { className: "text-content-weak typography-label", children: label })), _jsx(Select, { dataTestId: dataTestId, multiple: true, selected: selected, onRemove: (keyword) => handleOnDelete(keyword), filterable: true, filterTerm: userInput, onFilterTermChange: (value) => setUserInput(value), freeSolo: true, placeholder: "Add any keywords", initialFocus: initialFocus, getTagColorFromValue: () => getTagColorBySelectMode(mode), getTagIconFromValue: () => getTagIconBySelectMode(mode), onAdd: (value) => {
                    handleOnSubmit(value);
                    setUserInput('');
                }, hideDropdown: true, hideChevronDown: true })] }));
};
export default KeywordsMultiselect;
