import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AffinityIcon from 'assets/harmonic-icons/affinity';
import GreyLinearProgress from 'components/common/GreyLinearProgress';
import ListGroup from 'harmonic-components/ListGroup/ListGroup';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useFetchPeopleWatchlists from 'hooks/useFetchPeopleWatchlists';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { isNil } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { UserWatchlistType } from 'utils/watchlist';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const ListMultiselect = ({ onChange, selected, labelPrefix, placeholder, mode, separateAffinityLists, excludeMode, initialFocus, dataTestid, chipMode }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data: companyWatchlists, loading: companyWatchlistsLoading } = useFetchWatchlists();
    const { data: peopleWatchlists, loading: peopleWatchlistsLoading } = useFetchPeopleWatchlists();
    const onClickListItem = (watchlistId) => {
        const selectedOption = selected.find((s) => s === watchlistId);
        if (selectedOption) {
            onChange(selected.filter((s) => s !== watchlistId));
        }
        else {
            onChange([...selected, watchlistId]);
        }
        setSearchTerm('');
    };
    const loading = useMemo(() => {
        if (mode === 'company_watchlist') {
            return companyWatchlistsLoading;
        }
        else {
            return peopleWatchlistsLoading;
        }
    }, [mode, companyWatchlistsLoading, peopleWatchlistsLoading]);
    const typeaheadResults = useMemo(() => {
        let typeaheadResults = [];
        if (mode === 'company_watchlist') {
            typeaheadResults =
                companyWatchlists?.getCompanyWatchlistsForTeam?.map((watchlist) => ({
                    id: watchlist?.id ?? '',
                    name: watchlist?.name ?? '',
                    listType: watchlist?.userWatchlistType
                })) ?? [];
        }
        else {
            typeaheadResults =
                peopleWatchlists?.getPeopleWatchlistsForTeam?.map((watchlist) => ({
                    id: watchlist?.id ?? '',
                    name: watchlist?.name ?? '',
                    listType: watchlist?.userWatchlistType
                })) ?? [];
        }
        return typeaheadResults.filter((watchlist) => watchlist.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [mode, companyWatchlists, peopleWatchlists, searchTerm]);
    const getLabelFromValue = useCallback((value) => {
        return mode === 'company_watchlist'
            ? companyWatchlists?.getCompanyWatchlistsForTeam?.find((option) => option?.id === value)?.name ?? 'Loading...'
            : peopleWatchlists?.getPeopleWatchlistsForTeam?.find((option) => option?.id === value)?.name ?? 'Loading...';
    }, [mode, companyWatchlists, peopleWatchlists]);
    const isAffinityList = useCallback((id) => {
        return mode === 'company_watchlist'
            ? companyWatchlists?.getCompanyWatchlistsForTeam?.find((option) => option?.id === id)?.userWatchlistType ===
                UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST
            : false;
    }, [mode, companyWatchlists]);
    return (_jsxs(Select, { multiple: true, filterable: true, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selected, labelPrefix: labelPrefix, initialFocus: initialFocus, onRemove: (key) => {
            const newSelected = selected.filter((s) => s !== key);
            onChange(newSelected.length > 0 ? newSelected : undefined);
        }, placeholder: placeholder, getLabelFromValue: getLabelFromValue, getTagIconFromValue: (value) => {
            if (isNil(chipMode)) {
                return separateAffinityLists && isAffinityList(value)
                    ? AffinityIcon
                    : undefined;
            }
            return getTagIconBySelectMode(chipMode);
        }, getTagColorFromValue: (value) => {
            if (isNil(chipMode)) {
                return separateAffinityLists && isAffinityList(value)
                    ? 'expressive-22'
                    : undefined;
            }
            return getTagColorBySelectMode(chipMode);
        }, dataTestId: dataTestid, children: [loading && (_jsx("div", { className: "h-p20 w-full", children: _jsx(GreyLinearProgress, {}) })), !loading && separateAffinityLists && (_jsxs("div", { className: "w-full", children: [_jsx(ListGroup, { title: "Affinity lists", children: typeaheadResults
                            .filter((watchlist) => watchlist.listType ===
                            UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST)
                            .sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            if (a.name > b.name)
                                return 1;
                            return 0;
                        })
                            .map((watchlist) => {
                            if (!watchlist)
                                return null;
                            return (_jsx(SelectListItem, { value: watchlist.id, label: watchlist.name ?? '', onChange: () => onClickListItem(watchlist.id), selected: selected.includes(watchlist.id), variant: ListVariant.checkboxes, primaryIcon: AffinityIcon, excludeMode: excludeMode }, watchlist.id));
                        }) }), _jsx(ListGroup, { title: "Harmonic lists", children: typeaheadResults
                            .filter((watchlist) => watchlist.listType !==
                            UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST)
                            .sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            if (a.name > b.name)
                                return 1;
                            return 0;
                        })
                            .map((watchlist) => {
                            if (!watchlist)
                                return null;
                            return (_jsx(SelectListItem, { value: watchlist.id, label: watchlist.name ?? '', onChange: () => onClickListItem(watchlist.id), selected: selected.includes(watchlist.id), variant: ListVariant.checkboxes, excludeMode: excludeMode }, watchlist.id));
                        }) })] })), !loading &&
                !separateAffinityLists &&
                typeaheadResults.map((watchlist) => {
                    if (!watchlist)
                        return null;
                    return (_jsx(SelectListItem, { value: watchlist.id, label: watchlist.name ?? '', onChange: () => onClickListItem(watchlist.id), selected: selected.includes(watchlist.id), variant: ListVariant.checkboxes, excludeMode: excludeMode }, watchlist.id));
                })] }));
};
export default ListMultiselect;
