import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import { isNil } from 'lodash';
import { useState } from 'react';
import { formatDateRangeByMode } from 'utils/searchV2/searchV2';
import DateModal from './DateModal';
const TimelineRange = ({ mode, value, onChange, label, dataTestId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const pillLabel = formatDateRangeByMode(label, mode, value);
    return (_jsxs("div", { className: "flex gap-g30", children: [_jsx(Button, { isSelected: isOpen || !isNil(value), label: pillLabel, onClick: handleClick, emphasis: isOpen || !isNil(value) ? 'high' : 'medium', dataTestId: dataTestId }), _jsx(DateModal, { anchorEl: anchorEl, onClose: handleClose, open: isOpen, mode: mode, value: value, onChange: onChange }), !isNil(value) && (_jsx(Button, { dataTestId: "TimelineCleartBtn", type: "secondary", emphasis: "low", label: "Clear", onClick: () => onChange(undefined, undefined) }))] }));
};
export default TimelineRange;
