import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { EmployeeGroupType } from '__generated__/graphql';
import { ArrowRightIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Badge } from 'common/components';
import PersonCardV2 from 'components/common/PersonCard/PersonCardV2';
import ReportDataIssue from 'components/common/ReportDataIssue';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import { compact, isNil, uniq } from 'lodash';
import { shouldLinkCompanyId } from 'utils/company';
import { HighlightCategoryMap } from 'utils/constants';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';
export const CompanyFoundersContentLoader = () => {
    return (_jsx("div", { className: "mt-3 flex flex-col gap-4", "data-testid": "CompanyFounders-Loader", children: [...Array(3)].map((_, index) => (_jsx("div", { className: "w-full h-36 animated-box-pulse" }, index))) }));
};
export const getCompanyFounders = gql `
  fragment CompanyFounderEmployeesPerson on Person {
    linkedinHeadline
    fullName
    id
    profilePictureUrl
    education {
      school {
        entityUrn
        name
      }
      degree
      startDate
      endDate
    }
    highlights {
      text
      category
    }
    experience {
      title
      endDate
      startDate
      isCurrentPosition
      company {
        logoUrl
        id
        name
        highlights {
          text
          category
        }
      }
    }
    socials {
      linkedin {
        url
      }
    }
    userConnections {
      user {
        name
        email
      }
    }
  }

  query GetCompanyFounders(
    $companyUrn: CompanyUrn!
    $page: Int!
    $size: Int!
    $employeeGroupType: EmployeeGroupType!
  ) {
    getEmployeesByCompanyId(
      companyUrn: $companyUrn
      page: $page
      size: $size
      employeeGroupType: $employeeGroupType
    ) {
      edges {
        cursor
        node {
          ...CompanyFounderEmployeesPerson
        }
      }
      totalCount
    }
  }
`;
const SCHOOL_TO_FILTER_OUT = [
    //Y-Combinator
    'urn:harmonic:school:1fc4f78c-1480-43c1-8229-381382fba6e3'
];
export const SeeAllTeamButton = ({ onClick }) => {
    return (_jsx(IconButton, { onClick: onClick, icon: ArrowRightIcon, size: "compact", type: "secondary", emphasis: "high" }));
};
export const getFormattedPastExperiences = (experiences) => {
    const companyExperiencesMap = {};
    const filteredExperiences = experiences?.filter((exp) => exp);
    for (const experience of filteredExperiences) {
        const companyId = experience?.company?.id;
        const companyName = experience.company?.name ?? '';
        // Employee highlights are pre formatted. Company highlights are not.
        const companyHighlights = experience?.company?.highlights?.map((highlight) => HighlightCategoryMap?.[highlight?.category ?? '']) ?? [];
        const hashKey = shouldLinkCompanyId(companyId) ? companyId : companyName;
        if (!companyExperiencesMap?.[hashKey]) {
            companyExperiencesMap[hashKey] = {
                companyName: experience.company.name ?? '',
                logoUrl: experience?.company?.logoUrl ?? '',
                id: companyId,
                roles: [],
                highlights: companyHighlights
            };
        }
        companyExperiencesMap[hashKey].roles.push({
            title: experience?.title ?? '',
            startDate: experience?.startDate ?? '',
            endDate: experience?.endDate ?? '',
            isCurrentPosition: Boolean(experience?.isCurrentPosition)
        });
    }
    const formattedExperiences = Object.values(companyExperiencesMap);
    // Sort the roles. Recent appears at top and oldest at later index of array
    for (const experience of formattedExperiences) {
        experience?.roles?.sort((a, b) => {
            if (!a?.startDate || !b?.startDate)
                return -1;
            const startDateA = new Date(a?.startDate).getTime();
            const startDateB = new Date(b?.startDate).getTime();
            return startDateB - startDateA;
        });
    }
    formattedExperiences.sort((a, b) => {
        let startDateA = null;
        let startDateB = null;
        if (a?.roles?.length > 0) {
            startDateA = new Date(a?.roles?.[a.roles?.length - 1]?.startDate).getTime();
        }
        if (b?.roles?.length > 0) {
            startDateB = new Date(b?.roles?.[b.roles?.length - 1]?.startDate).getTime();
        }
        if (!startDateA)
            return 1;
        if (!startDateB)
            return -1;
        return startDateB - startDateA;
    });
    return formattedExperiences;
};
export const getRecentUniversity = (education, schoolToFilterOut = []) => {
    const schoolToFilterOutSet = new Set(schoolToFilterOut);
    const filteredEducation = education.filter((edu) => edu?.startDate && !schoolToFilterOutSet.has(edu?.school?.entityUrn));
    filteredEducation.sort((a, b) => new Date(b?.startDate).getTime() - new Date(a?.startDate).getTime());
    return filteredEducation?.[0]?.school?.name ?? '';
};
export const formatEmployees = (employees, companyId) => {
    const formattedPerson = [];
    employees.forEach((employee) => {
        const currentExperince = employee?.experience?.find((exp) => exp?.isCurrentPosition && exp.company.id === companyId);
        const pastExperiences = getFormattedPastExperiences(employee?.experience ?? []);
        const recentUniversity = getRecentUniversity(employee?.education ?? [], SCHOOL_TO_FILTER_OUT);
        const employeeHighlights = uniq(compact(employee?.highlights?.map((highlight) => highlight?.category))) ?? [];
        formattedPerson.push({
            id: employee?.id,
            fullName: employee?.fullName,
            profilePictureUrl: employee?.profilePictureUrl,
            universityName: recentUniversity,
            highlights: employeeHighlights,
            currentStartDate: currentExperince?.startDate ?? '',
            currentTitle: currentExperince?.title,
            pastExperiences: pastExperiences,
            linkedinUrl: employee?.socials?.linkedin?.url,
            userConnections: compact(employee?.userConnections?.map((connection) => connection?.user)) ?? []
        });
    });
    return formattedPerson;
};
export const FETCH_FOUNDERS_CEO_COUNT = 50;
const CompanyFoundersCeo = ({ companyId, onTabChange }) => {
    const { data } = useQuery(getCompanyFounders, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: FETCH_FOUNDERS_CEO_COUNT,
            employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
        },
        fetchPolicy: 'cache-first'
    });
    const employeeNodes = (data?.getEmployeesByCompanyId?.edges ?? []).map((edg) => edg.node);
    const formattedEmployees = formatEmployees(employeeNodes, companyId);
    const totalCount = formattedEmployees.length;
    const loading = isNil(data);
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsx("div", { className: "flex justify-between items-center w-full border-b border-solid border-border pb-p70", children: _jsxs("div", { className: "flex items-center gap-g40", children: [_jsx("p", { className: classNames('text-content-title flex items-center', {
                                        'typography-title-medium': isNil(onTabChange),
                                        'typography-title-small': !isNil(onTabChange)
                                    }), children: "Founders & CEO" }), totalCount !== 0 && (_jsx(Badge, { label: totalCount?.toString() ?? '', color: "neutral" }))] }) }), onTabChange === undefined && (_jsx(ReportDataIssue, { reportParams: {
                            companyUrn: 'urn:company:harmonic:' + companyId,
                            reportSourceView: ReportSourceView.COMPANY,
                            reportSourceSection: ReportSourceSection.FINANCING
                        }, placeholderText: "The company is showing incorrect founders" })), onTabChange !== undefined && (_jsx(IconButton, { onClick: () => onTabChange(CompanyNavbarOptions.TEAM), icon: ArrowRightIcon, size: "compact" }))] }), _jsx("div", { className: "pt-6", children: loading ? (_jsx(CompanyFoundersContentLoader, {})) : (_jsxs("div", { "data-testid": "CompanyFoundersAndCEO", children: [totalCount > 0 && (_jsx(_Fragment, { children: _jsx("div", { className: "flex flex-col gap-7", children: formattedEmployees.map((employee) => (_jsx(PersonCardV2, { ...employee }, employee?.fullName))) }) })), totalCount === 0 && (_jsx("p", { className: "pt-3 text-content-default typography-label-large", children: "No founders found" }))] })) })] }));
};
export default CompanyFoundersCeo;
