import { CircleCheckIcon } from 'assets/harmonic-icons';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import CirclePlus from 'assets/harmonic-icons/circle-plus';
export const getTagColorBySelectMode = (mode) => {
    if (!mode)
        return undefined;
    if (mode === 'include')
        return 'positive';
    if (mode === 'select')
        return 'informative';
    if (mode === 'exclude')
        return 'negative';
};
export const getTagIconBySelectMode = (mode) => {
    if (!mode)
        return undefined;
    if (mode === 'include')
        return CirclePlus;
    if (mode === 'select')
        return CircleCheckIcon;
    if (mode === 'exclude')
        return CircleMinus;
};
export const getAllowedValuesFromFieldSpec = (fieldSpecs, field) => {
    const roleSpecField = fieldSpecs?.find((spec) => spec.unique_name === field);
    return (roleSpecField?.value_restricted_to
        ?.filter((value) => value !== null)
        .map((value) => ({
        label: value,
        value: value
    })) || []);
};
