import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useFieldSpec } from 'hooks/useFieldSpec';
import { FilterGroupGeneratorId, SectorTagsRowTypes } from 'interfaces/SearchV2';
import React, { useMemo } from 'react';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import Checkboxes from '../../Common/Checkboxes';
const sectorRowConfigMap = {
    // No-op
    [SectorTagsRowTypes.INDUSTRY]: {
        filterOptions: ['No-op'],
        dataTestId: 'No-op',
        generatorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS,
        excludeGeneratorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS
    },
    [SectorTagsRowTypes.PRODUCT]: {
        filterOptions: ['company_product_type_tags'],
        dataTestId: 'Company-Include-Product-Tags',
        generatorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS,
        excludeGeneratorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS
    },
    [SectorTagsRowTypes.CUSTOMER]: {
        filterOptions: ['company_customer_type_tags'],
        dataTestId: 'Company-Include-Customer-Tags',
        generatorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS,
        excludeGeneratorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS
    },
    [SectorTagsRowTypes.TECHNOLOGY_TYPE]: {
        filterOptions: ['company_technology_type_tags'],
        dataTestId: 'Company-Include-technology-Tags',
        generatorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS,
        excludeGeneratorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS
    },
    [SectorTagsRowTypes.MARKET_VERTICAL]: {
        filterOptions: ['company_market_vertical_type_tags'],
        dataTestId: 'Company-Include-Market-Vertical-Tags',
        generatorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS,
        excludeGeneratorId: FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS
    }
};
const SectorSectionTagsRowV2 = ({ searchQuery, onUpdateSearchQuery, rowType, disabled }) => {
    const { fieldSpec } = useFieldSpec();
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [excludedOptions, setExcludedOptions] = React.useState([]);
    const sectorTagsRow = sectorRowConfigMap[rowType];
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    React.useEffect(() => {
        setSelectedOptions(getSelectedOptions(controlledSearchState, sectorTagsRow.generatorId));
        setExcludedOptions(getExcludedOptions(controlledSearchState, sectorTagsRow.excludeGeneratorId));
    }, [searchQuery]);
    const fieldSpecs = useMemo(() => {
        return fieldSpec.filter((fieldSpec) => fieldSpec.allowed_comparators &&
            fieldSpec.allowed_comparators.length > 0);
    }, [fieldSpec]);
    const tagOptions = useMemo(() => {
        let tagPossibleValues = [];
        fieldSpecs
            ?.filter((spec) => sectorTagsRow.filterOptions.includes(spec.unique_name))
            .forEach((tagListField) => {
            tagPossibleValues = tagPossibleValues.concat(tagListField?.value_restricted_to
                ?.filter((value) => value !== null)
                .map((value) => ({
                label: value,
                value: value
            })) || []);
        });
        return tagPossibleValues.sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }, [fieldSpecs, sectorTagsRow.filterOptions]);
    return (_jsx("div", { className: classNames(), children: _jsx(Checkboxes, { dataTestId: sectorTagsRow.dataTestId, options: tagOptions, selectedValues: selectedOptions, excludedValues: excludedOptions, enableExcludeMode: true, showAllIfSelected: true, onValueChange: (newSelectedValues, newExcludedValues) => {
                if (newSelectedValues) {
                    setSelectedOptions(newSelectedValues);
                }
                else {
                    setSelectedOptions([]);
                }
                if (newExcludedValues) {
                    setExcludedOptions(newExcludedValues);
                }
                else {
                    setExcludedOptions([]);
                }
                const queryWithNewIncludedValues = getUpdatedQuery(searchQuery, sectorTagsRow.generatorId, newSelectedValues !== undefined
                    ? buildSectorSelectPayload(rowType, newSelectedValues)
                    : undefined);
                const queryWithIncludedAndExcludedValues = getUpdatedQuery(queryWithNewIncludedValues, sectorTagsRow.excludeGeneratorId, newExcludedValues !== undefined
                    ? buildSectorSelectPayload(rowType, newExcludedValues)
                    : undefined);
                onUpdateSearchQuery(queryWithIncludedAndExcludedValues);
            }, disabled: disabled, onCheckChange: (checked, value) => {
                analytics.trackCustomEvent({
                    event: CustomTrackEvent.SECTOR_CATEGORY_CLICK,
                    properties: {
                        checked,
                        category: rowType.toString(),
                        categoryValue: value
                    }
                });
            } }) }));
};
function getSelectedOptions(controlledSearchState, generatorId) {
    switch (generatorId) {
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS:
            return controlledSearchState?.[generatorId]?.technology_tags || [];
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS:
            return controlledSearchState?.[generatorId]?.industry_tags || [];
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS:
            return controlledSearchState?.[generatorId]?.business_tags || [];
    }
    return [];
}
function getExcludedOptions(controlledSearchState, generatorId) {
    switch (generatorId) {
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS:
            return controlledSearchState?.[generatorId]?.technology_tags || [];
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS:
            return controlledSearchState?.[generatorId]?.industry_tags || [];
        case FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS:
            return controlledSearchState?.[generatorId]?.business_tags || [];
    }
    return [];
}
function buildSectorSelectPayload(rowType, selectedTags) {
    switch (rowType) {
        case SectorTagsRowTypes.MARKET_VERTICAL:
            return {
                industry_tags: selectedTags
            };
        case SectorTagsRowTypes.TECHNOLOGY_TYPE:
            return {
                technology_tags: selectedTags
            };
        case SectorTagsRowTypes.CUSTOMER:
            return {
                business_tags: selectedTags
            };
    }
}
export default SectorSectionTagsRowV2;
