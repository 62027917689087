import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { DateRangeShortHand } from 'interfaces/SearchV2';
import { isCustomRangeMode } from 'utils/searchV2/searchV2';
import CustomRange from './CustomRange/CustomRange';
const options = [
    {
        label: 'Any',
        value: undefined
    },
    {
        label: 'In the last week',
        value: DateRangeShortHand.PAST_WEEK
    },
    {
        label: 'In the last month',
        value: DateRangeShortHand.PAST_THIRTY_DAYS
    },
    {
        label: 'In the last 90 days',
        value: DateRangeShortHand.PAST_THREE_MONTHS
    }
];
const DateAddedToHarmonicSelect = ({ selected, customRangeValue, dataTestId, onChange }) => {
    const isCustomMode = isCustomRangeMode(selected);
    return (_jsxs("div", { "data-testid": dataTestId, className: "flex flex-row flex-wrap gap-g40", children: [options.map((option) => {
                return (_jsx(RadioPill, { label: option.label, selected: selected === option.value, onClick: () => onChange(option.value) }, option.value));
            }), _jsx(CustomRange, { mode: isCustomMode ? selected : undefined, value: customRangeValue, onChange: (mode, value) => {
                    onChange(mode, value);
                } })] }));
};
export default DateAddedToHarmonicSelect;
