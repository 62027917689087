import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import SectorPresetsPill from './SectorPresetsPill';
import TextSearchSection from './TextSearchSection/TextSearchSection';
const SectorSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const includeKeywords = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS]?.keywords;
    const excludeKeywords = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS]?.keywords;
    const matchKeywords = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS]?.keywords;
    const semanticInputs = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH];
    const handleUpdateKeywords = (orKeywords, notKeywords, matchKeywords, semanticInputs) => {
        const updatedIncludeKeywordsQuery = getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS, orKeywords.length > 0
            ? { keywords: orKeywords, include_website_text_content: true }
            : undefined);
        const updatedExcludeKeywordsQuery = getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS, notKeywords.length > 0
            ? { keywords: notKeywords, include_website_text_content: true }
            : undefined);
        const updatedMatchKeywordsQuery = getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS, matchKeywords.length > 0 ? { keywords: matchKeywords } : undefined);
        const updatedSemanticInputsQuery = semanticInputs &&
            getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH, semanticInputs?.query.trim() ? semanticInputs : undefined);
        onUpdateSearchQuery(updatedIncludeKeywordsQuery);
        onUpdateSearchQuery(updatedExcludeKeywordsQuery);
        onUpdateSearchQuery(updatedMatchKeywordsQuery);
        if (updatedSemanticInputsQuery) {
            onUpdateSearchQuery(updatedSemanticInputsQuery);
        }
    };
    return (_jsx("div", { className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-g70", children: [_jsx(TextSearchSection, { andKeywords: matchKeywords, orKeywords: includeKeywords, notKeywords: excludeKeywords, semanticInputs: semanticInputs, handleUpdateKeywords: handleUpdateKeywords, disabled: disabled }), _jsx(SectorPresetsPill, { onUpdateSearchQuery: onUpdateSearchQuery, searchQuery: searchQuery, disabled: disabled })] }) }));
};
export default SectorSection;
