import { useLazyQuery, useQuery } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { GET_SAVED_SEARCH } from 'queries/getSavedSearch';
import { useCallback, useMemo } from 'react';
import { useLocalSearchState } from './useAppState';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
const useFormatSavedSearch = ({ skipLocalSearch }) => {
    const { searchByKey } = useLocalSearchState();
    const formatSavedSearch = useCallback((urn, data) => {
        const localSavedSearch = searchByKey[urn];
        if (!skipLocalSearch && localSavedSearch && data?.getSavedSearch) {
            return {
                ...data.getSavedSearch,
                name: localSavedSearch.name ?? data.getSavedSearch?.name,
                searchQuery: localSavedSearch.searchQuery ?? data.getSavedSearch?.searchQuery,
                visibleColumns: localSavedSearch.visibleColumns ??
                    data.getSavedSearch?.visibleColumns
            };
        }
        return data?.getSavedSearch;
    }, [skipLocalSearch, searchByKey]);
    return {
        formatSavedSearch
    };
};
export const useLazyFetchSingleSavedSearch = () => {
    const { formatSavedSearch } = useFormatSavedSearch({
        skipLocalSearch: false
    });
    const [fetchSavedSearch, result] = useLazyQuery(GET_SAVED_SEARCH, {
        returnPartialData: true
    });
    const fetchSavedSearchByUrn = useCallback(async (urn, options) => {
        const response = await fetchSavedSearch({
            variables: {
                idOrUrn: urn
            },
            ...options
        });
        return formatSavedSearch(urn, response?.data);
    }, [fetchSavedSearch, formatSavedSearch]);
    const data = useMemo(() => {
        if (!result.data)
            return null;
        return formatSavedSearch(result.data.getSavedSearch?.entityUrn, result.data);
    }, [result.data, formatSavedSearch]);
    return {
        fetchSavedSearchByUrn,
        ...result,
        data
    };
};
const useFetchSingleSavedSearch = ({ skipLocalSearch } = {}) => {
    const { urn, location } = useDashboardLocation();
    const { formatSavedSearch } = useFormatSavedSearch({
        skipLocalSearch
    });
    const { data, loading: fetchLoading, called, error } = useQuery(GET_SAVED_SEARCH, {
        variables: {
            idOrUrn: getIdFromUrn(urn ?? 'NA') ?? 'NA'
        },
        fetchPolicy: 'cache-first',
        skip: !urn ||
            (location !== DashboardLocation.COMPANY_SEARCH &&
                location !== DashboardLocation.PEOPLE_SEARCH)
    });
    const currentPageSavedSearch = useMemo(() => {
        if (!urn)
            return null;
        if (called && !fetchLoading && !error && data) {
            return formatSavedSearch(urn, data);
        }
        return null;
    }, [called, data, error, fetchLoading, urn, formatSavedSearch]);
    return {
        loading: !currentPageSavedSearch && fetchLoading,
        currentPageSavedSearch,
        error
    };
};
export default useFetchSingleSavedSearch;
