import { useQuery } from '@apollo/client';
import { GET_USER_DIGEST_SUBSCRIPTIONS } from 'queries/digestNotifications';
import { GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS } from 'queries/getSavedSearchNotificationSubscriptions';
import { useMemo } from 'react';
/**
 * @returns a map of savedSearchUrns that have subscriptions enabled
 */
const useSavedSearchEnabledSubscriptions = () => {
    const { data: slackSubscriptions } = useQuery(GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS);
    const { data: digestSubscriptions } = useQuery(GET_USER_DIGEST_SUBSCRIPTIONS);
    const savedSearchesWithSubscriptions = useMemo(() => {
        const subscriptions = new Set();
        slackSubscriptions?.getSavedSearchNotificationSubscriptions?.forEach((sub) => {
            subscriptions.add(sub?.savedSearchUrn);
        });
        digestSubscriptions?.getUserDigestSubscriptions?.savedSearches.forEach((ss) => {
            subscriptions.add(ss?.entityUrn);
        });
        return subscriptions;
    }, [slackSubscriptions, digestSubscriptions]);
    const hasSavedSearchSubscription = (savedSearchUrn) => {
        if (!savedSearchUrn)
            return false;
        return Boolean(savedSearchesWithSubscriptions?.has(savedSearchUrn));
    };
    return { hasSavedSearchSubscription };
};
export default useSavedSearchEnabledSubscriptions;
