import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobeIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from 'assets/harmonic-icons';
import LineChartV2 from 'components/Charts/LineChartV2';
import SegmentedControl from 'harmonic-components/SegmentedControl/SegmentedControl';
import { SocialMetricsType } from 'interfaces/DataModel/Company';
import { useState } from 'react';
const SocialMetricTypeIconMap = {
    [SocialMetricsType.WEB_TRAFFIC]: ({ className }) => (_jsx(GlobeIcon, { className: className, applyCurrentColor: false })),
    [SocialMetricsType.FACEBOOK_LIKES]: ({ className }) => _jsx(GlobeIcon, { className: className, applyCurrentColor: false }),
    [SocialMetricsType.TWITTER_FOLLOWERS]: ({ className }) => _jsx(TwitterIcon, { className: className, applyCurrentColor: false }),
    [SocialMetricsType.INSTAGRAM_FOLLOWERS]: ({ className }) => _jsx(InstagramIcon, { className: className, applyCurrentColor: false }),
    [SocialMetricsType.LINKEDIN_FOLLOWERS]: ({ className }) => _jsx(LinkedInIcon, { className: className, applyCurrentColor: false })
};
const SocialMetricsTypeLabel = {
    [SocialMetricsType.WEB_TRAFFIC]: 'Web traffic',
    [SocialMetricsType.FACEBOOK_LIKES]: 'Facebook',
    [SocialMetricsType.TWITTER_FOLLOWERS]: 'Twitter',
    [SocialMetricsType.INSTAGRAM_FOLLOWERS]: 'Instagram',
    [SocialMetricsType.LINKEDIN_FOLLOWERS]: 'Linkedin'
};
const SocialMetricsGraph = ({ socialMetricsDataPointMap }) => {
    const socialMetricsTypes = Object.keys(socialMetricsDataPointMap);
    const [selectedSocialMetric, setSelectedSocialMetric] = useState(socialMetricsTypes?.[0]);
    return (_jsxs("div", { className: "border border-solid border-border rounded-br40 bg-surface-default", children: [_jsx("div", { className: "p-p60 border-b border-solid border-border", children: _jsx(SegmentedControl, { options: socialMetricsTypes.map((metricType) => ({
                        label: SocialMetricsTypeLabel?.[metricType] ?? '',
                        value: metricType,
                        leadingIcon: SocialMetricTypeIconMap?.[metricType]
                    })), selectedOption: selectedSocialMetric, onChange: (value) => setSelectedSocialMetric(value) }) }), _jsx("div", { className: "p-p60 h-64 w-full", children: _jsx(LineChartV2, { yAxisPrefix: "", label: SocialMetricsTypeLabel?.[selectedSocialMetric] ?? '', data: socialMetricsDataPointMap?.[selectedSocialMetric] ?? [] }) })] }));
};
export default SocialMetricsGraph;
