import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { TYPEAHEAD_COMPANIES_QUERY } from 'queries/typeahead';
import { useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const CompanyMultiselect = ({ onChange, selected, placeholder, initialFocus = false, freeSolo, mode = null, dataTestId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data: companiesData, loading } = useQuery(TYPEAHEAD_COMPANIES_QUERY, { variables: { query: searchTerm } });
    const onClickListItem = (company) => {
        const selectedOption = selected.find((s) => s === company.name);
        if (selectedOption) {
            onChange(selected.filter((s) => s !== company.name));
        }
        else {
            onChange([...selected, company.name ?? '']);
        }
        setSearchTerm('');
    };
    return (_jsxs(Select, { multiple: true, filterable: true, freeSolo: freeSolo, onAdd: (value) => {
            onChange([...selected, value]);
            setSearchTerm('');
        }, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selected, placeholder: placeholder, onRemove: (key) => onChange(selected.filter((s) => s !== key)), initialFocus: initialFocus, hideDropdown: !loading && companiesData?.getCompaniesWithTypeahead?.length === 0, getTagColorFromValue: () => getTagColorBySelectMode(mode), getTagIconFromValue: () => getTagIconBySelectMode(mode), clampValues: true, dropdownMaxHeight: "400px", dataTestId: dataTestId, children: [loading && (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(HarmonicLoader, { showText: false }) })), !loading &&
                companiesData?.getCompaniesWithTypeahead?.map((company) => {
                    if (!company)
                        return null;
                    return (_jsx(SelectListItem, { value: company.name ?? '', label: company.name ?? '', logo: "logo", src: company.logoUrl ?? '', onClick: () => onClickListItem(company), variant: ListVariant.entity }, company.id));
                })] }));
};
export default CompanyMultiselect;
