import { jsx as _jsx } from "react/jsx-runtime";
import EmailIcon from 'assets/harmonic-icons/email-icon';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import React from 'react';
import PersonEmailStaticModal from './PersonEmailStaticModal';
const ContactButton = ({ name, emailAddress }) => {
    const { show } = useModal();
    const handleOnUnlock = () => {
        show(_jsx(PersonEmailStaticModal, { name: name, emailAddress: emailAddress }));
    };
    return (_jsx(IconButton, { icon: EmailIcon, onClick: handleOnUnlock, type: "secondary", emphasis: "high", size: "compact", dataTestId: "ContactStaticEmailIcon" }));
};
export default ContactButton;
