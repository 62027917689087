import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BagIcon, CalendarIcon, PeopleCopyIcon } from 'assets/harmonic-icons';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import ChartIcon from 'assets/harmonic-icons/chart';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import RangeInput from 'harmonic-components/RangeInput/RangeInput';
import { useFieldSpec } from 'hooks/useFieldSpec';
import { FilterGroupGeneratorId, companyFundingTotalDefaultValue, companyTeamHeadcountRangeDefaultValue, fundingSliderStepRanges, headcountStepRanges } from 'interfaces/SearchV2';
import { capitalize, isNil, omitBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import CollapsibleFieldWrapper from '../Common/CollapsibleFieldWrapper';
import CompanyMultiselectById from '../Common/CompanyMultiselectById';
import DateAddedToHarmonicSelect from '../Common/DateAddedToHarmonicSelect';
import DateWithSelect from '../Common/DateWithSelect';
import FoundingDateSelect from '../Common/FoundingDateSelect';
import IncludeExcludeMultiselect from '../Common/IncludeExcludeMultiselect';
import InvestorMultiselectByName from '../Common/InvestorMultiselectByName';
import KeywordsMultiselect from '../Common/KeywordsMultiselect';
import LastFundingDateSelect from '../Common/LastFundingDateSelect';
import ListMultiselect from '../Common/ListMultiselect';
import MultiselectWithMode from '../Common/MultiselectWithMode';
import TenureSelect from '../Common/TenureSelect';
import { PeopleSmallSectionHeader, PeopleSubSectionHeader, SectionDivider } from '../Common/Typography';
import { getAllowedValuesFromFieldSpec } from '../Common/utils';
import CurrentCompanySectionExpandableButtons from './CurrentExperienceExpandableButtons';
import TenureFundingStageMultiselect from './TenureFundingStageMultiselect';
const CurrentOrPastExperience = ({ searchQuery, onUpdateSearchQuery, mode, expandedFields, onUpdateExpandedFields }) => {
    // State to store the values before they were removed/deleted
    // When we decide to expand the filter again, it gets from the cache
    const [cachedExperienceState, setCachedExperienceState] = useState(null);
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    let currentExperienceState = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE];
    if (mode === 'past') {
        currentExperienceState = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE];
    }
    useEffect(() => {
        if (isNil(cachedExperienceState) && !isNil(currentExperienceState)) {
            setCachedExperienceState(currentExperienceState);
        }
    }, [currentExperienceState, cachedExperienceState]);
    const updateFieldValuesFromCachedState = (fieldNames) => {
        // Updates the value from the cached state.
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cachedValues = {};
        for (const fieldName of fieldNames) {
            const cachedValue = cachedExperienceState?.[fieldName];
            if (cachedValue) {
                cachedValues[fieldName] = cachedValue;
            }
        }
        updateValues(cachedValues);
    };
    const addNewExpandedField = (fieldNames) => {
        // Field names array includes multiple fields names for certain fields to account for include/exclude, any/none/all mode
        // We only need to add first field name in the array to the expanded fields
        // Rest of the array are for reading values from cached state
        const firstFieldName = fieldNames[0];
        updateFieldValuesFromCachedState(fieldNames);
        onUpdateExpandedFields([...expandedFields, firstFieldName]);
    };
    const updateValues = (newValues, updateCachedQuery = true) => {
        const newStateWithoutUndefinedFields = omitBy({
            ...(currentExperienceState ?? {}),
            ...newValues,
            current_or_past: mode
        }, (value) => value === undefined ||
            value === null ||
            (Array.isArray(value) && value.length === 0));
        const noArgumentsExist = Object.keys(newStateWithoutUndefinedFields).length === 1 &&
            Object.keys(newStateWithoutUndefinedFields)[0] === 'current_or_past';
        const updatedQuery = getUpdatedQuery(searchQuery, mode === 'current'
            ? FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
            : FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE, noArgumentsExist ? undefined : newStateWithoutUndefinedFields);
        onUpdateSearchQuery(updatedQuery);
        if (updateCachedQuery) {
            setCachedExperienceState({
                ...cachedExperienceState,
                ...newValues
            });
        }
    };
    const removeFields = (fieldNames) => {
        const newExpandedFields = expandedFields.filter((field) => !fieldNames.includes(field));
        onUpdateExpandedFields(newExpandedFields);
        const fieldsToUpdate = {};
        fieldNames.forEach((fieldName) => {
            fieldsToUpdate[fieldName] = undefined;
        });
        updateValues(fieldsToUpdate, false);
    };
    const { fieldSpec } = useFieldSpec();
    const fieldSpecs = useMemo(() => {
        return fieldSpec?.filter((fieldSpec) => fieldSpec.allowed_comparators &&
            fieldSpec.allowed_comparators.length > 0);
    }, [fieldSpec]);
    const roleDepartmentOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, 'person_position_department'), [fieldSpecs]);
    const roleTypeOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, 'person_position_role_type').map((option) => ({
        label: capitalize(option.label),
        value: option.value
    })), [fieldSpecs]);
    const technologyTagOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, 'person_company_technology_type_tags'), [fieldSpecs]);
    const industryTagOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, 'person_company_market_vertical_type_tags'), [fieldSpecs]);
    const customerTypeTagOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, 'person_company_customer_type_tags'), [fieldSpecs]);
    const roleTitleContainsAnyOfField = (_jsx(MultiselectWithMode, { label: "Contains all of", selected: currentExperienceState?.role_title_contains_all_of || [], onChange: (newValues) => updateValues({ role_title_contains_all_of: newValues ?? [] }), mode: "select" }));
    const roleTitleContainsNoneOfField = (_jsx(MultiselectWithMode, { label: "Contains none of", selected: currentExperienceState?.role_title_contains_none_of || [], onChange: (newValues) => updateValues({ role_title_contains_none_of: newValues ?? [] }), mode: "exclude" }));
    const roleDepartmentField = (_jsx("div", { children: _jsx(CollapsibleFieldWrapper, { title: "Role Department", onRemove: () => removeFields(['role_department_any_of', 'role_department_none_of']), closeBtnTestId: "RoleDepartmentCloseBtn", children: _jsx(IncludeExcludeMultiselect, { options: roleDepartmentOptions, included: currentExperienceState?.role_department_any_of || [], excluded: currentExperienceState?.role_department_none_of || [], onChangeInclude: (newValues) => updateValues({ role_department_any_of: newValues ?? [] }), onChangeExclude: (newValues) => updateValues({ role_department_none_of: newValues ?? [] }), dataTestId: "RoleDepartmentSelect", placeholder: "Select a role department (e.g. Product, Design, Engineering)" }) }) }));
    const roleTypeField = (_jsx("div", { children: _jsx(CollapsibleFieldWrapper, { title: "Role Type", onRemove: () => removeFields(['role_type_any_of', 'role_type_none_of']), children: _jsx(IncludeExcludeMultiselect, { options: roleTypeOptions, included: currentExperienceState?.role_type_any_of || [], excluded: currentExperienceState?.role_type_none_of || [], onChangeInclude: (newValues) => updateValues({ role_type_any_of: newValues ?? [] }), onChangeExclude: (newValues) => updateValues({ role_type_none_of: newValues ?? [] }), placeholder: "Select a role type (e.g. Founder, Executive, Operator)" }) }) }));
    // Current Or Past Company fields
    const addedToHarmonicField = mode === 'current' && (_jsx(CollapsibleFieldWrapper, { title: "Added to Harmonic", onRemove: () => {
            removeFields(['company_initialized_date_range_value']);
        }, children: _jsx(DateAddedToHarmonicSelect, { selected: currentExperienceState?.company_initialized_date_range_value, customRangeValue: currentExperienceState?.company_initialized_date_custom_range, onChange: (newValue, newCustomValue) => {
                const updatedValues = {
                    company_initialized_date_range_value: newValue
                };
                if (newCustomValue) {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_initialized_date_custom_range =
                        newCustomValue;
                }
                else {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_initialized_date_custom_range = null;
                }
                updateValues(updatedValues);
            } }) }));
    const totalFundingField = (_jsx(CollapsibleFieldWrapper, { title: "Total funding", onRemove: () => {
            removeFields(['company_funding_range']);
        }, children: _jsx("div", { className: "w-[96%]", children: _jsx(RangeInput, { value: currentExperienceState?.company_funding_range, defaultValue: companyFundingTotalDefaultValue.range, sliderStepRanges: fundingSliderStepRanges, onValueChange: (newValues) => updateValues({
                    company_funding_range: newValues ?? undefined
                }), prefix: "$" }) }) }));
    const headcountRangeField = (_jsx(CollapsibleFieldWrapper, { title: "Headcount", onRemove: () => {
            removeFields(['company_headcount_range']);
        }, children: _jsx("div", { className: "w-[96%]", children: _jsx(RangeInput, { value: currentExperienceState?.company_headcount_range, defaultValue: companyTeamHeadcountRangeDefaultValue.range, sliderStepRanges: headcountStepRanges, onValueChange: (newValues) => updateValues({
                    company_headcount_range: newValues ?? undefined
                }) }) }) }));
    const investorsField = (_jsx(CollapsibleFieldWrapper, { title: "Investors", onRemove: () => {
            removeFields(['company_investor_names_any_of']);
        }, children: _jsx(InvestorMultiselectByName, { selected: currentExperienceState?.company_investor_names_any_of ?? [], onChange: (newValues) => updateValues({ company_investor_names_any_of: newValues ?? [] }), placeholder: "Search investor", mode: "include" }) }));
    const companyIsInListField = (_jsx(CollapsibleFieldWrapper, { title: "Company lists", onRemove: () => {
            removeFields([
                'company_in_watchlist_ids_any_of',
                'company_in_watchlist_ids_none_of'
            ]);
        }, children: _jsxs("div", { className: "flex gap-g60", children: [_jsx("div", { className: "w-1/2", children: _jsx(ListMultiselect, { mode: "company_watchlist", selected: currentExperienceState?.company_in_watchlist_ids_any_of ?? [], onChange: (newValues) => updateValues({ company_in_watchlist_ids_any_of: newValues ?? [] }), placeholder: "Select company lists to include", separateAffinityLists: true }) }), _jsx("div", { className: "w-1/2", children: _jsx(ListMultiselect, { mode: "company_watchlist", selected: currentExperienceState?.company_in_watchlist_ids_none_of ?? [], onChange: (newValues) => updateValues({
                            company_in_watchlist_ids_none_of: newValues ?? []
                        }), placeholder: "Select company lists to exclude", excludeMode: true, separateAffinityLists: true }) })] }) }));
    const companyKeywordsField = (_jsx(CollapsibleFieldWrapper, { title: "Company keywords", onRemove: () => {
            removeFields([
                'company_keywords_contains_any_of',
                'company_keywords_contains_all_of',
                'company_keywords_contains_none_of'
            ]);
        }, children: _jsxs("div", { className: "flex flex-col gap-g40", children: [_jsx(KeywordsMultiselect, { selected: currentExperienceState?.company_keywords_contains_any_of || [], onChange: (newValues) => updateValues({
                        company_keywords_contains_any_of: newValues ?? []
                    }), mode: "include", dataTestId: "CurrentPastCompanyKeywords-anyof" }), _jsx(KeywordsMultiselect, { selected: currentExperienceState?.company_keywords_contains_all_of || [], onChange: (newValues) => updateValues({
                        company_keywords_contains_all_of: newValues ?? []
                    }), mode: "select", dataTestId: "CurrentPastCompanyKeywords-allof" }), _jsx(KeywordsMultiselect, { selected: currentExperienceState?.company_keywords_contains_none_of || [], onChange: (newValues) => updateValues({
                        company_keywords_contains_none_of: newValues ?? []
                    }), mode: "exclude", dataTestId: "CurrentPastCompanyKeywords-noneof" })] }) }));
    const lastFundingDateField = (_jsx(CollapsibleFieldWrapper, { title: "Last funding date", onRemove: () => {
            removeFields(['company_last_funding_date_range_value']);
        }, children: _jsx(LastFundingDateSelect, { selected: currentExperienceState?.company_last_funding_date_range_value, customRangeValue: currentExperienceState?.company_last_funding_date_custom_range, onChange: (newValue, newCustomValue) => {
                const updatedValues = {
                    company_last_funding_date_range_value: newValue
                };
                if (newCustomValue) {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_last_funding_date_custom_range =
                        newCustomValue;
                }
                else {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_last_funding_date_custom_range = null;
                }
                updateValues(updatedValues);
            } }) }));
    const foundingDateField = (_jsx(CollapsibleFieldWrapper, { title: "Founding date", onRemove: () => {
            removeFields(['company_founding_date_range_value']);
        }, children: _jsx(FoundingDateSelect, { selected: currentExperienceState?.company_founding_date_range_value, customRangeValue: currentExperienceState?.company_founding_date_custom_range, onChange: (newValue, newCustomValue) => {
                const updatedValues = {
                    company_founding_date_range_value: newValue
                };
                if (newCustomValue) {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_founding_date_custom_range = newCustomValue;
                }
                else {
                    //eslint-disable-next-line
                    //@ts-ignore
                    updatedValues.company_founding_date_custom_range = null;
                }
                updateValues(updatedValues);
            } }) }));
    const industryField = (_jsx(CollapsibleFieldWrapper, { title: "Industry", onRemove: () => {
            removeFields([
                'company_industry_tags_include_any_of',
                'company_industry_tags_exclude_all_of'
            ]);
        }, children: _jsx(IncludeExcludeMultiselect, { options: industryTagOptions, included: currentExperienceState?.company_industry_tags_include_any_of || [], excluded: currentExperienceState?.company_industry_tags_exclude_all_of || [], onChangeInclude: (newValues) => updateValues({
                company_industry_tags_include_any_of: newValues ?? []
            }), onChangeExclude: (newValues) => updateValues({
                company_industry_tags_exclude_all_of: newValues ?? []
            }), placeholder: "Select an industry (e.g. Industrial and manufacturing)" }) }));
    const technologyField = (_jsx(CollapsibleFieldWrapper, { title: "Technology", onRemove: () => {
            removeFields([
                'company_technology_tags_include_any_of',
                'company_technology_tags_exclude_all_of'
            ]);
        }, children: _jsx(IncludeExcludeMultiselect, { options: technologyTagOptions, included: currentExperienceState?.company_technology_tags_include_any_of || [], excluded: currentExperienceState?.company_technology_tags_exclude_all_of || [], onChangeInclude: (newValues) => updateValues({
                company_technology_tags_include_any_of: newValues ?? []
            }), onChangeExclude: (newValues) => updateValues({
                company_technology_tags_exclude_all_of: newValues ?? []
            }), placeholder: "Select technology (e.g. Artificial intelligence, Robotics, Hardware)" }) }));
    const customerTypeField = (_jsx(CollapsibleFieldWrapper, { title: "Customer Type", onRemove: () => {
            removeFields([
                'company_customer_type_tags_include_any_of',
                'company_customer_type_tags_exclude_all_of'
            ]);
        }, children: _jsx(IncludeExcludeMultiselect, { options: customerTypeTagOptions, included: currentExperienceState?.company_customer_type_tags_include_any_of ||
                [], excluded: currentExperienceState?.company_customer_type_tags_exclude_all_of ||
                [], onChangeInclude: (newValues) => updateValues({
                company_customer_type_tags_include_any_of: newValues ?? []
            }), onChangeExclude: (newValues) => updateValues({
                company_customer_type_tags_exclude_all_of: newValues ?? []
            }), placeholder: "Select a customer type (e.g. B2C, B2B, B2G)" }) }));
    // Timeline fields
    const employmentDurationField = (_jsx(CollapsibleFieldWrapper, { title: "Employment timeline", onRemove: () => {
            removeFields([
                'role_start_date_mode',
                'role_start_date_value',
                'role_end_date_mode',
                'role_end_date_value'
            ]);
        }, children: _jsxs("div", { className: classNames(mode === 'past' && 'grid grid-cols-2 gap-g40'), children: [_jsxs("div", { className: "flex flex-col gap-g20", children: [_jsx("p", { className: "text-content-weak typography-label", children: "Start date" }), _jsx(DateWithSelect, { mode: currentExperienceState?.role_start_date_mode, value: currentExperienceState?.role_start_date_value, onChange: (mode, value) => {
                                if (isNil(value)) {
                                    return updateValues({
                                        role_start_date_mode: undefined,
                                        role_start_date_value: undefined
                                    });
                                }
                                updateValues({
                                    role_start_date_mode: mode,
                                    role_start_date_value: value
                                });
                            }, label: "Add start date" })] }), mode === 'past' && (_jsxs("div", { className: "flex flex-col gap-g20", children: [_jsx("p", { className: "text-content-weak typography-label", children: "End date" }), _jsx(DateWithSelect, { mode: currentExperienceState?.role_end_date_mode, value: currentExperienceState?.role_end_date_value, onChange: (mode, value) => {
                                if (isNil(value)) {
                                    return updateValues({
                                        role_end_date_mode: undefined,
                                        role_end_date_value: undefined
                                    });
                                }
                                updateValues({
                                    role_end_date_mode: mode,
                                    role_end_date_value: value
                                });
                            }, label: "Add end date" })] }))] }) }));
    const tenureFundingStageField = (_jsx(CollapsibleFieldWrapper, { title: "Funding stage experience", onRemove: () => {
            removeFields(['role_tenure_funding_stage_any_of']);
        }, children: _jsx(TenureFundingStageMultiselect, { selected: currentExperienceState?.role_tenure_funding_stage_any_of ?? [], onChange: (newValues) => updateValues({ role_tenure_funding_stage_any_of: newValues ?? [] }) }) }));
    const tenureField = (_jsx(CollapsibleFieldWrapper, { title: "Tenure", onRemove: () => {
            removeFields(['role_tenure_value', 'role_tenure_comparator']);
        }, children: _jsx(TenureSelect, { selected: currentExperienceState?.role_tenure_value, comparator: currentExperienceState?.role_tenure_comparator, onChange: (comparator, newValue) => updateValues({
                role_tenure_comparator: comparator,
                role_tenure_value: newValue
            }) }) }));
    // All the expanded fields are kept track in array in particular order
    const roleTitleExpandedFields = expandedFields
        .map((field) => {
        if (field === 'role_title_contains_all_of') {
            return roleTitleContainsAnyOfField;
        }
        if (field === 'role_title_contains_none_of') {
            return roleTitleContainsNoneOfField;
        }
    })
        .filter((val) => val);
    const roleDepartmentAndTypeExpandedFields = expandedFields
        .map((field) => {
        if (field === 'role_department_any_of') {
            return roleDepartmentField;
        }
        if (field === 'role_type_any_of') {
            return roleTypeField;
        }
    })
        .filter((val) => val);
    const currentOrPastCompanyExpandedFields = expandedFields
        .map((field) => {
        if (field === 'company_initialized_date_range_value') {
            return addedToHarmonicField;
        }
        if (field === 'company_funding_range') {
            return totalFundingField;
        }
        if (field === 'company_headcount_range') {
            return headcountRangeField;
        }
        if (field === 'company_investor_names_any_of') {
            return investorsField;
        }
        if (field === 'company_in_watchlist_ids_any_of') {
            return companyIsInListField;
        }
        if (field === 'company_keywords_contains_any_of') {
            return companyKeywordsField;
        }
        if (field === 'company_last_funding_date_range_value') {
            return lastFundingDateField;
        }
        if (field === 'company_founding_date_range_value') {
            return foundingDateField;
        }
        if (field === 'company_industry_tags_include_any_of') {
            return industryField;
        }
        if (field === 'company_technology_tags_include_any_of') {
            return technologyField;
        }
        if (field === 'company_customer_type_tags_include_any_of') {
            return customerTypeField;
        }
    })
        .filter((val) => val);
    const timelineExpandedFields = expandedFields
        .map((field) => {
        if (field === 'role_start_date_value') {
            return employmentDurationField;
        }
        if (field === 'role_tenure_value') {
            return tenureField;
        }
        if (field === 'role_tenure_funding_stage_any_of') {
            return tenureFundingStageField;
        }
    })
        .filter((val) => val);
    return (_jsxs("div", { className: "w-full", children: [_jsxs(PeopleSmallSectionHeader, { children: [mode === 'current' ? 'Current role' : 'Past role', ' '] }), _jsx(PeopleSubSectionHeader, { children: "Role title" }), _jsxs("div", { className: "flex flex-col gap-g40", children: [_jsx(MultiselectWithMode, { label: "Contains any of", selected: currentExperienceState?.role_title_contains_any_of || [], onChange: (newValues) => updateValues({ role_title_contains_any_of: newValues ?? [] }), mode: "include" }), roleTitleExpandedFields, _jsxs("div", { className: "flex gap-g40", children: [!expandedFields.includes('role_title_contains_all_of') && (_jsx(Button, { type: "secondary", label: "Contains all of", emphasis: "high", size: "compact", onClick: () => addNewExpandedField(['role_title_contains_all_of']) })), !expandedFields.includes('role_title_contains_none_of') && (_jsx(Button, { type: "secondary", label: "Contains none of", emphasis: "high", size: "compact", onClick: () => addNewExpandedField(['role_title_contains_none_of']) }))] })] }), roleDepartmentAndTypeExpandedFields.length > 0 && (_jsx("div", { className: "mt-g80 flex flex-col gap-g80", children: roleDepartmentAndTypeExpandedFields })), _jsxs("div", { className: "mt-g80 flex gap-g40", children: [!expandedFields.includes('role_department_any_of') && (_jsx(Button, { type: "secondary", leadingIcon: BagIcon, label: "Role department", emphasis: "high", onClick: () => addNewExpandedField([
                            'role_department_any_of',
                            'role_department_none_of'
                        ]) })), !expandedFields.includes('role_type_any_of') && (_jsx(Button, { type: "secondary", leadingIcon: PeopleCopyIcon, label: "Role type", emphasis: "high", onClick: () => addNewExpandedField(['role_type_any_of', 'role_type_none_of']) }))] }), _jsx("div", { className: "py-p80 my-p40", children: _jsx(SectionDivider, {}) }), _jsxs(PeopleSmallSectionHeader, { children: [mode === 'current' ? 'Current company' : 'Past company', ' '] }), _jsxs("div", { className: "flex flex-col gap-g40", children: [_jsx(CompanyMultiselectById, { selected: currentExperienceState?.company_id_any_of?.map((id) => ({
                            name: '',
                            id
                        })) ?? [], placeholder: currentExperienceState?.select_stealth_companies
                            ? 'Unselect all stealth companies to search'
                            : undefined, onChange: (newValues) => updateValues({
                            company_id_any_of: newValues?.map((value) => value.id) ?? []
                        }), disabled: currentExperienceState?.select_stealth_companies, mode: "include" }), _jsx(Checkbox, { id: "select-stealth-companies-checkbox", dataTestId: "select-stealth-companies-checkbox", checked: currentExperienceState?.select_stealth_companies, label: "Select all stealth companies", onChange: () => updateValues({
                            select_stealth_companies: !currentExperienceState?.select_stealth_companies
                        }) })] }), currentOrPastCompanyExpandedFields.length > 0 && (_jsx("div", { className: "mt-g80 flex flex-col gap-g80", children: currentOrPastCompanyExpandedFields })), _jsx("div", { className: "mt-g80", "data-visual-test": "transparent", children: _jsx(CurrentCompanySectionExpandableButtons, { mode: mode, expandedFields: expandedFields, addNewExpandedField: addNewExpandedField }) }), _jsx("div", { className: "py-p80 my-p40", children: _jsx(SectionDivider, {}) }), _jsx(PeopleSmallSectionHeader, { children: "Timeline" }), timelineExpandedFields.length > 0 && (_jsx("div", { className: "flex flex-col gap-g80", children: timelineExpandedFields })), _jsxs("div", { className: "mt-g80 flex gap-g30", children: [!expandedFields.includes('role_tenure_value') && (_jsx(Button, { type: "secondary", label: "Tenure", leadingIcon: CalendarIcon, emphasis: "high", dataTestId: "CurrentPastCompany-ExpandableButton-role_tenure_value", onClick: () => addNewExpandedField([
                            'role_tenure_value',
                            'role_tenure_comparator'
                        ]) })), !expandedFields.includes('role_start_date_value') && (_jsx(Button, { type: "secondary", label: "Employment timeline", leadingIcon: CalendarDaysIcon, emphasis: "high", dataTestId: "CurrentPastCompany-ExpandableButton-role_start_date_value", onClick: () => addNewExpandedField([
                            'role_start_date_value',
                            'role_start_date_mode',
                            'role_end_date_value',
                            'role_end_date_mode'
                        ]) })), !expandedFields.includes('role_tenure_funding_stage_any_of') && (_jsx(Button, { type: "secondary", label: "Funding stage", leadingIcon: ChartIcon, emphasis: "high", onClick: () => addNewExpandedField(['role_tenure_funding_stage_any_of']), dataTestId: "CurrentPastCompany-ExpandableButton-role_tenure_funding_stage_any_of" }))] })] }));
};
export default CurrentOrPastExperience;
