import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Badge } from '../Badge';
import { generateNetworkLabel, getNetworkBadgeColor, getNetworkIcon } from './util';
const NetworkSummaryBadge = props => {
  const {
    usersInNetwork,
    currentUserEmail
  } = props;
  const isCurrentUserConnected = useMemo(() => usersInNetwork.some(user => user.email === currentUserEmail), [usersInNetwork, currentUserEmail]);
  return _jsx("div", {
    className: "flex",
    "data-testid": "network-summary-badge",
    children: _jsx(Badge, {
      leadingIcon: getNetworkIcon(isCurrentUserConnected, usersInNetwork.length),
      color: getNetworkBadgeColor(usersInNetwork.length),
      size: "large",
      label: generateNetworkLabel(usersInNetwork, currentUserEmail, isCurrentUserConnected)
    })
  });
};
export default NetworkSummaryBadge;