import { jsx as _jsx } from "react/jsx-runtime";
import { useFieldSpec } from 'hooks/useFieldSpec';
import { EntityListType } from 'interfaces/SearchModel/Search';
import SearchFilterDropdown from './SearchFilterDropdown';
import SearchFilterGroup from './SearchFilterGroupBase';
const CompanySearchFilterGroup = (props) => {
    const { fieldSpec } = useFieldSpec();
    const fieldSpecs = fieldSpec?.filter((fieldSpec) => fieldSpec.allowed_comparators && fieldSpec.allowed_comparators.length > 0);
    return (_jsx(SearchFilterGroup, { ...props, fieldSpecs: fieldSpecs, entityType: EntityListType.COMPANY_SAVED_SEARCH, FilterDropdownComponent: ({ onAddNewFilter }) => {
            return (_jsx(SearchFilterDropdown, { fields: fieldSpecs, entityType: EntityListType.COMPANY_SAVED_SEARCH, onUpdateComparatorName: (name, urn) => onAddNewFilter(name, urn), selectedField: "company_name" }));
        } }));
};
export default CompanySearchFilterGroup;
