import { useQuery } from '@apollo/client';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import { GET_WATCHLISTS_FROM_PEOPLE } from 'queries/getWatchlistsFromPeople';
import { useMemo } from 'react';
import useTableStore from 'stores/tableStore';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
const useFetchPeopleWatchlists = () => {
    const routeDetails = useDashboardLocation();
    const { data: peopleWatchlistsData, loading, ...rest } = useQuery(GET_PEOPLE_WATCHLISTS, {});
    const watchlists = peopleWatchlistsData?.getPeopleWatchlistsForTeam ?? [];
    const selectedRowIds = useTableStore((state) => state.selectedRowIds);
    const isPersonSearchOrList = routeDetails.location == DashboardLocation.PEOPLE_SEARCH ||
        routeDetails.location == DashboardLocation.PERSON_LIST;
    const { data: watchlistsFromPeople } = useQuery(GET_WATCHLISTS_FROM_PEOPLE, {
        variables: {
            ids: selectedRowIds,
            extended: false
        },
        skip: selectedRowIds.length === 0 || !isPersonSearchOrList
    });
    const commonWatchlistsOfBulkSelect = useMemo(() => {
        const people = watchlistsFromPeople?.getPersonsByIds ?? [];
        if (people.length === 0) {
            return [];
        }
        const commonWatchlists = people[0]?.watchlists;
        return people.reduce((common, current) => {
            const currentWatchlists = current?.watchlists;
            return common?.filter((watchlist) => currentWatchlists?.some((current) => current?.id === watchlist?.id));
        }, commonWatchlists);
    }, [watchlistsFromPeople]);
    return {
        watchlists,
        loading,
        data: peopleWatchlistsData,
        commonWatchlistsOfBulkSelect,
        ...rest
    };
};
export default useFetchPeopleWatchlists;
