import { gql } from '@apollo/client';
const CompanySearchExtended = gql `
  fragment CompanySearchExtended on Company {
    company_type: companyType
    contact {
      emails
      execEmails
      phoneNumbers
    }
    likelihoodOfBacking
    fundingPerEmployee
    person_relationships: employees(
      employeeSearchInput: {
        employeeGroupType: LEADERSHIP
        employeeStatus: ACTIVE
      }
    ) {
      ...CompanyEmployeeFragment
    }
    person_relationships_founders_and_ceos: employees(
      employeeSearchInput: {
        employeeGroupType: FOUNDERS_AND_CEO
        employeeStatus: ACTIVE
      }
    ) {
      ...CompanyEmployeeFragment
    }
    person_relationships_executives: employees(
      employeeSearchInput: {
        employeeGroupType: EXECUTIVES
        employeeStatus: ACTIVE
      }
    ) {
      ...CompanyEmployeeFragment
    }
    leadership_prior_companies: employeesCompanies(
      employeeSearchInput: {
        employeeGroupType: LEADERSHIP
        employeeStatus: ACTIVE
      }
    ) {
      lastCompanies(paginationInput: { first: 25 }) {
        edges {
          node {
            ...CompanyEmployeeCompany
          }
        }
      }
    }
    customer_type: customerType
    entityUrn
    stage
    foundingDate {
      date
      granularity
    }
    funding {
      fundingTotal
      numFundingRounds
      investors {
        __typename
        ... on Company {
          id
          companyUrn: entityUrn
          name
          logoUrl
          investorUrn
        }
        ... on Person {
          personUrn: entityUrn
          id
          name: fullName
          logoUrl: profilePictureUrl
          investorUrn
        }
      }
      lastFundingAt
      lastFundingType
      lastFundingTotal
      fundingStage
    }
    funding_attribute_null_status: fundingAttributeNullStatus
    headcount
    correctedHeadcount
    externalHeadcount
    highlights {
      category
      text
    }
    employeeHighlights {
      category
      text
    }
    location {
      addressFormatted
      location
      street
      city
      state
      zip
      country
    }
    ownership_status: ownershipStatus
    socials {
      facebook {
        url
        status
        likeCount
      }
      twitter {
        url
        followerCount
        status
      }
      linkedin {
        url
        followerCount
        status
      }
      instagram {
        url
        followerCount
        status
      }
      crunchbase {
        url
        followerCount
        status
      }
      pitchbook {
        url
        followerCount
        status
      }
      angellist {
        url
        followerCount
        status
      }
      indeed {
        url
        followerCount
        status
      }
      youtube {
        url
        followerCount
        status
      }
      monster {
        url
        followerCount
        status
      }
      stackoverflow {
        url
        followerCount
        status
      }
    }
    typed_tags: tags {
      entityUrn
      tag_value: displayValue
      dateAdded
      tag_type: type
    }
    typed_tags_v2: tagsV2 {
      entityUrn
      tag_value: displayValue
      dateAdded
      tag_type: type
    }
    user_notes: userNotes
    team_notes: teamNotes
    user_connections: userConnections {
      targetPerson {
        id
        fullName
      }
      user {
        email
        name
      }
    }
    website {
      url
      domain
      isBroken
    }
    userConnectionsOverview {
      correspondenceSummary {
        lastEmailAt
        lastEmailContactPersonEmail
        lastEmailContactPerson {
          fullName
        }
        lastMeetingAt
        lastMeetingContactPersonEmail
        lastMeetingContactPerson {
          fullName
        }
      }
    }
    webTraffic
    tractionMetrics {
      headcount {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountAdvisor {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountCustomerSuccess {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountData {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountDesign {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountEngineering {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountFinance {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountLegal {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountMarketing {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountOperations {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountOther {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountPeople {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountProduct {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountSales {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      headcountSupport {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      fundingTotal {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      facebookFollowerCount {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      linkedinFollowerCount {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      instagramFollowerCount {
        latestMetricValue
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      twitterFollowerCount {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
      }
      webTraffic {
        ago14d {
          value_14d_ago: value
          real_change_14d_ago: change
          percent_change_14d_ago: percentChange
        }
        ago30d {
          value_30d_ago: value
          real_change_30d_ago: change
          percent_change_30d_ago: percentChange
        }
        ago90d {
          value_90d_ago: value
          real_change_90d_ago: change
          percent_change_90d_ago: percentChange
        }
        ago180d {
          value_180d_ago: value
          real_change_180d_ago: change
          percent_change_180d_ago: percentChange
        }
        ago365d {
          value_365d_ago: value
          real_change_365d_ago: change
          percent_change_365d_ago: percentChange
        }
        latestMetricValue
      }
    }
    affinityLists {
      name
      affinityId
    }
    watchlists {
      id
      name
      entityUrn
    }
    isLikedByCurrentUser
    isHiddenFromSearchesByCurrentUser
    viewRecord {
      createdAt
      updatedAt
    }
    relevanceScore
  }

  fragment CompanyEmployeeFragment on Person {
    firstName
    lastName
    about
    profilePictureUrl
    entityUrn
    id
    fullName
    socials {
      linkedin {
        url
      }
    }
    contact {
      emails
    }
  }

  fragment CompanyEmployeeCompany on Company {
    id
    name
    logoUrl
    entityUrn
  }
`;
export const FullCompanySearch = gql `
  fragment CompanySearch on Company {
    id
    name
    legal_name: legalName
    description
    external_description: externalDescription
    logo_url: logoUrl
    updatedAt
    created_at: initializedDate
    ...CompanySearchExtended @skip(if: $skipExtended)
  }
  ${CompanySearchExtended}
`;
