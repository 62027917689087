import { jsx as _jsx } from "react/jsx-runtime";
import SelectionPill from 'harmonic-components/SelectionPill/SelectionPill';
const options = [
    {
        label: 'Pre-seed',
        value: 'PRE_SEED'
    },
    {
        label: 'Seed',
        value: 'SEED'
    },
    { label: 'Series A', value: 'SERIES_A' },
    { label: 'Series B', value: 'SERIES_B' },
    {
        label: 'Series C',
        value: 'SERIES_C'
    },
    { label: 'Series D+', value: 'SERIES_D_PLUS' }
];
const TenureFundingStageMultiselect = ({ selected, dataTestId, onChange }) => {
    return (_jsx("div", { "data-testid": dataTestId, className: "flex flex-row flex-wrap gap-g40", children: options.map((option) => {
            return (_jsx(SelectionPill, { label: option.label, selected: selected.includes(option.value), onClick: () => {
                    if (selected.includes(option.value)) {
                        onChange(selected.filter((value) => value !== option.value));
                    }
                    else {
                        onChange([...selected, option.value]);
                    }
                } }, option.value));
        }) }));
};
export default TenureFundingStageMultiselect;
