import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { flip } from '@floating-ui/dom';
import { CalendarCheckIcon } from 'assets/harmonic-icons';
import dayjs from 'dayjs';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { dayjsExt } from '../../config/dayjs';
import DatePickerHeader from './DatePickerHeader';
import DatePickerInput from './DatePickerInput';
const RangeDatePicker = ({ startDate, endDate, onChange }) => {
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);
    const onStartDateChange = (date) => {
        onChange(date ? dayjs(date).format('YYYY-MM-DD') : null, endDate);
        setStartDateOpen(false);
        setEndDateOpen(true);
    };
    const onEndDateChange = (date) => {
        onChange(startDate, date ? dayjs(date).format('YYYY-MM-DD') : null);
        setEndDateOpen(false);
    };
    const onDateChange = (startDate, endDate) => {
        onChange(startDate ? dayjs(startDate).format('YYYY-MM-DD') : null, endDate ? dayjs(endDate).format('YYYY-MM-DD') : null);
        setStartDateOpen(false);
        setEndDateOpen(false);
    };
    const startDateObj = startDate ? dayjs(startDate).toDate() : null;
    const endDateObj = endDate ? dayjs(endDate).toDate() : null;
    const listActions = (_jsxs(_Fragment, { children: [_jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "This week", label: "This week", onClick: () => {
                    const today = dayjs();
                    const startOfWeek = today.clone().startOf('week');
                    onDateChange(startOfWeek.toDate(), today.toDate());
                } }), _jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "This month", label: "This month", onClick: () => {
                    const today = dayjs();
                    const startOfWeek = today.clone().startOf('month');
                    onDateChange(startOfWeek.toDate(), today.toDate());
                } }), _jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "This quarter", label: "This quarter", onClick: () => {
                    const today = dayjsExt();
                    const startOfWeek = today.clone().startOf('quarter');
                    onDateChange(startOfWeek.toDate(), today.toDate());
                } }), _jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "This year", label: "This year", onClick: () => {
                    const today = dayjs();
                    const startOfWeek = today.clone().startOf('year');
                    onDateChange(startOfWeek.toDate(), today.toDate());
                } })] }));
    const popperModifiers = [
        flip({
            mainAxis: true,
            crossAxis: true,
            fallbackPlacements: ['bottom', 'top', 'left', 'right'],
            padding: 10
        })
    ];
    return (_jsxs("div", { className: "flex items-center", children: [_jsx(ReactDatePicker, { open: startDateOpen, onInputClick: () => setStartDateOpen(true), onClickOutside: () => setStartDateOpen(false), selected: startDateObj, onChange: (date) => onStartDateChange(date), dateFormat: "yyyy-MM-dd", selectsStart: true, dropdownMode: "select", showPopperArrow: false, renderCustomHeader: ({ ...props }) => {
                    return _jsx(DatePickerHeader, { ...props, selectedDate: startDate });
                }, scrollableMonthYearDropdown: true, scrollableYearDropdown: true, startDate: startDateObj, popperModifiers: popperModifiers, endDate: endDateObj, calendarClassName: "shadow-static-elevation-floating flex flex-col range-date-picker", 
                //eslint-disable-next-line
                //@ts-ignore
                customInput: _jsx(DatePickerInput, {}), children: _jsx("div", { className: "border-t border-solid border-border p-p30", children: listActions }) }), _jsx("div", { className: "px-p40", children: "-" }), _jsx(ReactDatePicker, { open: endDateOpen, onInputClick: () => setEndDateOpen(true), onClickOutside: () => setEndDateOpen(false), onChange: (date) => onEndDateChange(date), selected: endDateObj, showPopperArrow: false, dropdownMode: "select", selectsEnd: true, calendarClassName: "shadow-static-elevation-floating flex flex-col", dateFormat: "yyyy-MM-dd", renderCustomHeader: ({ ...props }) => {
                    return _jsx(DatePickerHeader, { ...props, selectedDate: endDate });
                }, popperModifiers: popperModifiers, startDate: startDateObj, endDate: endDateObj, minDate: startDateObj, 
                //eslint-disable-next-line
                //@ts-ignore
                customInput: _jsx(DatePickerInput, {}), children: _jsxs("div", { className: "border-t border-solid border-border p-p30", children: [_jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "Today", label: "Today", onClick: () => {
                                onEndDateChange(dayjs().toDate());
                            } }), listActions] }) })] }));
};
export default RangeDatePicker;
