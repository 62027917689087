import { DashboardLocation } from 'hooks/useDashboardLocation';
import { ENVIRONMENT, FrontendEnvironments } from './constants';
export var CustomTrackEvent;
(function (CustomTrackEvent) {
    CustomTrackEvent["COMPANY_PROFILE_CLICK"] = "company_profile_click";
    CustomTrackEvent["PERSON_PROFILE_CLICK"] = "person_profile_click";
    CustomTrackEvent["PERSON_PROFILE_LOAD"] = "person_profile_load";
    CustomTrackEvent["PERSON_PROFILE_COMPANY_EXPAND"] = "person_profile_company_expand";
    CustomTrackEvent["COMPANY_PROFILE_LOAD"] = "company_profile_load";
    CustomTrackEvent["SEARCH_OPENED"] = "search_opened";
    CustomTrackEvent["SEARCH_INITIATED"] = "search_initiated";
    CustomTrackEvent["SEARCH_COMPLETED"] = "search_completed";
    CustomTrackEvent["SAVED_SEARCH_CREATED"] = "saved_search_created";
    CustomTrackEvent["SAVED_SEARCH_PAGE_LOAD"] = "saved_search_page_load";
    CustomTrackEvent["COMPANY_ACTION_CLICK"] = "company_action_click";
    CustomTrackEvent["EXTERNAL_LINK_CLICKED"] = "external_link_clicked";
    CustomTrackEvent["SEE_SIMILAR_COMPANIES_CLICKED"] = "see_similar_companies_clicked";
    CustomTrackEvent["SEARCH_SECTION_CLICKED"] = "search_section_clicked";
    CustomTrackEvent["SIMILAR_COMPANIES_QUALIFICATION"] = "similar_companies_qualification";
    CustomTrackEvent["SECTOR_PRESET_CLICK"] = "sector_preset_click";
    CustomTrackEvent["SECTOR_CATEGORY_CLICK"] = "sector_category_click";
    CustomTrackEvent["REGION_PRESET_CLICK"] = "region_preset_click";
    CustomTrackEvent["COMPANY_PROFILE_TAB_CHANGE"] = "company_profile_tab_change";
    CustomTrackEvent["COMPANY_PROFILE_OUTLINK_CLICK"] = "company_profile_outlink_click";
    CustomTrackEvent["PERSON_PROFILE_OUTLINK_CLICK"] = "person_profile_outlink_click";
    CustomTrackEvent["INVESTOR_PROFILE_CLICK"] = "investor_profile_click";
    CustomTrackEvent["PERFORMANCE_METRIC"] = "performance_metric";
    CustomTrackEvent["OPENED_COMPANY_LIST_LOCAL_SEARCH"] = "opened_company_list_local_search";
    CustomTrackEvent["OPENED_PERSON_LIST_LOCAL_SEARCH"] = "opened_person_list_local_search";
    CustomTrackEvent["DISPLAY_SIDEBAR_SHOW_GRID_VIEW"] = "display_sidebar_show_grid_view";
    CustomTrackEvent["DISPLAY_SIDEBAR_SHOW_BOARD_VIEW"] = "display_sidebar_show_board_view";
    CustomTrackEvent["BOARD_VIEW_DROP_END"] = "board_view_drop_end";
    CustomTrackEvent["BOARD_EMPTY_CLICK_STATUS_FIELD"] = "board_empty_click_status_field";
    CustomTrackEvent["BOARD_EMPTY_CLICK_SELECT_FIELD"] = "board_empty_click_select_field";
    CustomTrackEvent["BOARD_CHANGE_GROUP_BY_FIELD"] = "board_change_group_by_field";
    CustomTrackEvent["DISMISS_NET_NEW_ENTITY_CLICKED"] = "dismiss_net_new_entity_clicked";
    CustomTrackEvent["DISMISS_NET_NEW_FOR_SEARCH_CLICKED"] = "dismiss_net_new_for_search_clicked";
    CustomTrackEvent["NOTIFICATION_BELL_CLICKED"] = "notification_bell_clicked";
    CustomTrackEvent["DIGESTS_ENABLED"] = "digest_subscription_enabled";
    CustomTrackEvent["SLACK_SUBSCRIPTION_CREATED"] = "slack_subscription_created";
    // semantic search
    CustomTrackEvent["SEMANTIC_SEARCH_QUERY_SUBMIT"] = "semantic_search_query_submit";
    CustomTrackEvent["SEMANTIC_SEARCH_RATING"] = "semantic_search_rating";
    CustomTrackEvent["SEMANTIC_SEARCH_COMPANY_CLICK"] = "semantic_search_company_click";
    CustomTrackEvent["SEMANTIC_SEARCH_ADD_TO_LIST"] = "semantic_search_add_to_list";
    CustomTrackEvent["GENERATE_RESEARCH_REPORT"] = "generate_research_report";
    CustomTrackEvent["COMPANY_SEARCH_COMPLETED_WITH_SEMANTIC_KEYWORDS"] = "company_search_completed_with_semantic_keywords";
})(CustomTrackEvent || (CustomTrackEvent = {}));
var ComponentLocation;
(function (ComponentLocation) {
    ComponentLocation["GRID_TABLE"] = "grid_table";
    ComponentLocation["GRID_TABLE_COMPANY_NAME_COMPANY_SEARCH"] = "grid_table_company_name_company_search";
    ComponentLocation["GRID_TABLE_COMPANY_NAME_PERSON_SEARCH"] = "grid_table_company_name_person_search";
    ComponentLocation["GRID_TABLE_PERSON_EXPERIENCE"] = "grid_table_person_experience";
    ComponentLocation["GRID_TABLE_HIGHLIGHTS"] = "grid_table_highlights";
    ComponentLocation["GRID_TABLE_INVESTORS"] = "grid_table_investors";
    ComponentLocation["GRID_TABLE_TEAM"] = "grid_table_team";
    ComponentLocation["HEADER_TYPEAHEAD_SEARCH"] = "header_typeahead_search";
    ComponentLocation["STANDALONE_TYPEAHEAD_SEARCH"] = "standalone_typeahead_search";
    ComponentLocation["COMPANY_PROFILE_SIMILAR_COMPANIES"] = "company_profile_similar_companies";
    ComponentLocation["COMPANY_PROFILE_INVESTORS"] = "company_profile_investors";
    ComponentLocation["PERSON_PROFILE_EXPERIENCE"] = "person_profile_experience";
})(ComponentLocation || (ComponentLocation = {}));
var ApplicationLocation;
(function (ApplicationLocation) {
    ApplicationLocation["SLACK"] = "slack";
})(ApplicationLocation || (ApplicationLocation = {}));
export const EventLocations = { ...DashboardLocation, ...ComponentLocation };
export var SearchVersion;
(function (SearchVersion) {
    SearchVersion["V1"] = "V1";
    SearchVersion["V2"] = "V2";
})(SearchVersion || (SearchVersion = {}));
export var CompanyActionEventType;
(function (CompanyActionEventType) {
    CompanyActionEventType["LIKE"] = "LIKE";
    CompanyActionEventType["UNLIKE"] = "UNLIKE";
    CompanyActionEventType["HIDE"] = "HIDE";
    CompanyActionEventType["UNHIDE"] = "UNHIDE";
    CompanyActionEventType["VIEW"] = "VIEW";
    CompanyActionEventType["UNVIEW"] = "UNVIEW";
    CompanyActionEventType["ADD_TO_WATCHLIST"] = "ADD_TO_WATCHLIST";
    CompanyActionEventType["REMOVE_FROM_WATCHLIST"] = "REMOVE_FROM_WATCHLIST";
    CompanyActionEventType["REMOVE_FROM_CURRENT_WATCHLIST"] = "REMOVE_FROM_CURRENT_WATCHLIST";
})(CompanyActionEventType || (CompanyActionEventType = {}));
export var PerformanceMeasureType;
(function (PerformanceMeasureType) {
    PerformanceMeasureType["TIME_TO_DATA_ON_GRID_ON_FIRST_LOAD"] = "time-to-data-on-grid-on-first-load";
})(PerformanceMeasureType || (PerformanceMeasureType = {}));
const loadHeap = () => {
    const heapAppId = ENVIRONMENT === FrontendEnvironments.Staging ? '1714009127' : '1950460381';
    if (window.heap && window.heap.load) {
        window.heap.load(heapAppId);
    }
};
const initializeAnalytics = async ({ name, email, entityUrn }) => {
    if (!window.heap.loaded)
        await loadHeap();
    window.heap.addUserProperties({ Name: name, Email: email });
    window.heap.identify(entityUrn);
};
const addEventProperties = (properties) => {
    window.heap.addEventProperties(properties);
};
const trackCustomEvent = (params) => {
    if (!window || !window?.heap || !window?.heap?.loaded)
        return;
    window.heap.track(params.event, params.properties);
};
export default {
    loadHeap,
    initializeAnalytics,
    addEventProperties,
    trackCustomEvent
};
