import { jsx as _jsx } from "react/jsx-runtime";
import CoinIcon from 'icons/CoinIcon';
import CompanyBuildingOutlineIcon from 'icons/CompanyBuildingOutlineIcon';
import PersonOutlineIcon from 'icons/PersonOutlineIcon';
import ThreeDotIcon from 'icons/ThreeDotIcon';
import TrendingUpIcon from 'icons/TrendingUpIcon';
import { EntityListType, FieldCategory } from 'interfaces/SearchModel/Search';
const CompanyTabs = [
    {
        category: FieldCategory.COMPANY,
        icon: _jsx(CompanyBuildingOutlineIcon, {})
    },
    {
        category: FieldCategory.PERSON,
        icon: _jsx(PersonOutlineIcon, {})
    },
    {
        category: FieldCategory.FINANCING,
        icon: _jsx(CoinIcon, {})
    },
    {
        category: FieldCategory.TRACTION,
        icon: _jsx(TrendingUpIcon, {})
    },
    {
        category: FieldCategory.OTHER,
        icon: _jsx(ThreeDotIcon, {})
    }
];
const CompanyListTabs = [...CompanyTabs];
const PersonTabs = [
    {
        category: FieldCategory.PERSON,
        icon: _jsx(PersonOutlineIcon, {})
    },
    {
        category: FieldCategory.ROLE,
        icon: _jsx(TrendingUpIcon, {})
    },
    {
        category: FieldCategory.COMPANY,
        icon: _jsx(CompanyBuildingOutlineIcon, {})
    },
    {
        category: FieldCategory.EDUCATION,
        icon: _jsx(CompanyBuildingOutlineIcon, {})
    },
    {
        category: FieldCategory.OTHER,
        icon: _jsx(ThreeDotIcon, {})
    }
];
const PersonListTabs = [...PersonTabs];
export const EntityFilterCategoryMap = {
    [EntityListType.PEOPLE_SAVED_SEARCH]: PersonTabs,
    [EntityListType.COMPANY_SAVED_SEARCH]: CompanyTabs,
    [EntityListType.COMPANY_WATCHLIST]: CompanyListTabs,
    [EntityListType.PEOPLE_WATCHLIST]: PersonListTabs
};
