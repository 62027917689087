import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import RadioCard from 'harmonic-components/RadioCard/RadioCard';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { debounce } from 'lodash';
import { getLocationTypeahead } from 'queries/typeahead';
import { useCallback, useEffect, useState } from 'react';
import { chipDisplayOrder } from './RegionPresets';
import { useLocationState } from './hooks/useLocationState';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
import { parseSelectedLocations } from './utils/utils';
const AddLocation = ({ onValueChange, locationState }) => {
    const { handleRegionClick, handleOnRemoveClick, handleLocationChange, handleExcludeLocationChange, locationFilterTerm, setLocationFilterTerm } = useLocationState({ locationState, onValueChange });
    const [debouncedTerm, setDebouncedTerm] = useState(locationFilterTerm);
    const debouncedSetTerm = debounce(setDebouncedTerm, 300);
    useEffect(() => {
        debouncedSetTerm(locationFilterTerm);
    }, [locationFilterTerm, debouncedSetTerm]);
    const { loading, data } = useQuery(getLocationTypeahead, {
        variables: { query: debouncedTerm },
        skip: locationFilterTerm.length === 0
    });
    const options = data?.getLocationsWithTypeahead || [];
    const included = parseSelectedLocations(locationState).includedLocationsArray;
    const excluded = parseSelectedLocations(locationState).excludedLocationsArray;
    const selected = [...included, ...excluded];
    const getTagColorFromValue = useCallback((value) => getTagColorBySelectMode(included.includes(value)
        ? 'include'
        : excluded.includes(value)
            ? 'exclude'
            : 'select'), [included, excluded]);
    const getTagIconFromValue = useCallback((value) => getTagIconBySelectMode(included.includes(value)
        ? 'include'
        : excluded.includes(value)
            ? 'exclude'
            : 'select'), [included, excluded]);
    return (_jsx("div", { className: "my-p40", children: _jsxs(Select, { filterTerm: locationFilterTerm, onFilterTermChange: setLocationFilterTerm, multiple: true, filterable: true, placeholder: "Search City, State or Country", selected: selected, onRemove: handleOnRemoveClick, fullWidth: true, getTagColorFromValue: (value) => getTagColorFromValue(value ?? ''), getTagIconFromValue: (value) => getTagIconFromValue(value ?? ''), dataTestId: "location-include-exclude-multiselect", children: [!loading && locationFilterTerm.length < 1 && (_jsxs("div", { className: "p-p40 w-full", "data-testid": "region-select", children: [_jsx("div", { className: "mb-p40", children: "Select from presets:" }), _jsx("div", { className: "grid grid-cols-3 place-content-around gap-2 w-full", children: chipDisplayOrder.map((chipMeta) => (_jsx(RadioCard, { label: chipMeta.title, icon: chipMeta.icon, selected: !!locationState?.region?.includes(chipMeta.region), onClick: () => {
                                    handleRegionClick(chipMeta.region);
                                } }, chipMeta.title))) })] })), loading && locationFilterTerm.length > 0 ? (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(HarmonicLoader, { showText: false }) })) : (options.map((location) => {
                    if (!location) {
                        return null;
                    }
                    const selected = [
                        ...(locationState?.locations ?? []),
                        ...(locationState?.excluded_locations ?? [])
                    ].some((value) => value.entity_urn === location.entityUrn); // entity_urn is used here as using text will cause clashes with legacy filter
                    return (_jsx(SelectListItem, { variant: ListVariant.checkboxes, selected: selected, onChange: () => handleLocationChange({
                            selected,
                            location
                        }), label: location.text, value: location.text, onExclude: () => handleExcludeLocationChange({
                            selected,
                            location
                        }), excludeMode: excluded.includes(location.text) }, location.entityUrn));
                }))] }) }));
};
export default AddLocation;
