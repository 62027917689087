import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'mobx-react';
import { createContext, useContext } from 'react';
const StoreContext = createContext({
    rootStore: null
});
export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context.rootStore) {
        throw new Error('useStore must be used within StoreProvider');
    }
    return context;
};
const StoreProvider = ({ children, rootStore }) => {
    return (_jsx(StoreContext.Provider, { value: { rootStore }, children: _jsx(Provider, { rootStore: rootStore, children: children }) }));
};
export default StoreProvider;
