import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import AddLocation from '../Common/AddLocation';
import { SubSectionHeader } from '../Common/Typography';
const GeneralLocationSubSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const peopleLocation = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION];
    return (_jsxs("div", { "data-testid": "Location-Section", className: "w-full", children: [_jsx(SubSectionHeader, { children: "Location" }), _jsx(AddLocation, { locationState: peopleLocation, onValueChange: (newLocationValue) => {
                    return onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION, newLocationValue));
                } })] }));
};
export default GeneralLocationSubSection;
