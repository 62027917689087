import { gql } from '@apollo/client';
import { CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, TEXT_CUSTOM_FIELD_VALUE_FRAGMENT, WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT } from './getWatchlistWithCompanies';
import { FullPeopleSearch } from './peopleSearchFragment';
export const PEOPLE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql `
  fragment PeopleListCustomFieldValue on PeopleListCustomFieldValue {
    customField {
      urn
      type
      __typename
    }
    data {
      __typename
      ... on NumberListCustomFieldValue {
        ...NumberListCustomFieldValue
      }
      ... on TextCustomFieldValue {
        ...TextCustomFieldValue
      }
      ... on SingleSelectCustomFieldValue {
        ...SingleSelectCustomFieldValue
      }
      ... on MultiSelectCustomFieldValue {
        ...MultiSelectCustomFieldValue
      }
      ... on PersonSelectCustomFieldValue {
        ...PersonSelectCustomFieldValue
      }
      ... on DateListCustomFieldValue {
        ...DateListCustomFieldValue
      }
      ... on WebsiteListCustomFieldValue {
        ...WebsiteListCustomFieldValue
      }
      ... on CheckboxListCustomFieldValue {
        ...CheckboxListCustomFieldValue
      }
      ... on StatusListCustomFieldValue {
        ...StatusListCustomFieldValue
      }
    }
    updatedAt
    urn
    createdAt
    __typename
  }
  ${NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${TEXT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;
export const PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT = gql `
  fragment PeopleListEntryNode on PeopleWatchlistEntryNode {
    __typename
    entryUrn
    entryCreatedAt
    customFieldValues {
      ...PeopleListCustomFieldValue
    }
    person {
      ...PeopleSearch
    }
    addedBy {
      __typename
      name
      entityUrn
      email
    }
  }
  ${FullPeopleSearch}
  ${PEOPLE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;
const PEOPLE_WATCHLIST_ENTRIES_FRAGMENT = gql `
  fragment PeopleWatchlistEntries on PeopleWatchlistEntriesConnection {
    edges {
      cursor
      node {
        ...PeopleListEntryNode
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
  ${PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
export const WATCHLIST_WITH_PEOPLE = gql `
  query GetWatchlistWithPeople(
    $idOrUrn: String!
    $skipExtended: Boolean = false
    $first: Int
    $after: String
    $sortField: String
    $sortDescending: Boolean
    $searchQuery: JSON
    $sortCustomFieldUrn: PeopleListCustomFieldUrn
  ) {
    getPeopleWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      id
      personEntries(
        first: $first
        after: $after
        sortField: $sortField
        sortDescending: $sortDescending
        searchQuery: $searchQuery
        sortCustomFieldUrn: $sortCustomFieldUrn
      ) {
        __typename
        ...PeopleWatchlistEntries
      }
    }
  }
  ${PEOPLE_WATCHLIST_ENTRIES_FRAGMENT}
`;
export const WATCHLIST_WITH_PEOPLE_MOCKED_RESPONSE = {
    getPeopleWatchlistByIdOrUrn: {
        id: '1',
        personEntries: {
            __typename: 'PeopleWatchlistEntriesConnection',
            totalCount: 2,
            edges: [
                {
                    cursor: 'urn:harmonic:person:123',
                    node: {
                        addedBy: null,
                        __typename: 'PeopleWatchlistEntryNode',
                        entryUrn: 'urn:harmonic:entry:123',
                        entryCreatedAt: '2021-08-25T00:00:00Z',
                        customFieldValues: [],
                        person: {
                            personUrn: 'urn:harmonic:person:123',
                            id: 123,
                            fullName: 'Harsh Snehanshu',
                            firstName: 'Harsh',
                            lastName: 'Snehanshu',
                            about: null,
                            watchlists: [],
                            profilePictureUrl: 'https://media-exp1.licdn.com/dms/image/C4D03AQHGNn54RzCalQ/profile-displayphoto-shrink_200_200/0/1517563909607?e=1634774400&v=beta&t=QcoMl1Oq-__xnfhlJHZabKjVuF9f9M4bKSgMVb9vpZA',
                            linkedinHeadline: null,
                            location: {
                                location: 'Bangalore Urban, Karnataka, India',
                                state: 'Karnataka',
                                city: 'Bengaluru',
                                country: 'India',
                                __typename: 'LocationMetadata'
                            },
                            highlights: [
                                {
                                    category: 'Prior VC Backed Executive',
                                    text: 'Prior VC Backed Executive, Harsh Snehanshu: VP, Business Strategy and Development @ Frankly.me, ',
                                    __typename: 'PersonHighlight'
                                }
                            ],
                            socials: {
                                linkedin: {
                                    followerCount: null,
                                    url: 'https://linkedin.com/in/harshsnehanshu',
                                    status: null,
                                    __typename: 'SocialMetadata'
                                },
                                twitter: null,
                                __typename: 'Socials'
                            },
                            experience: [
                                {
                                    company: {
                                        id: 1415510,
                                        entityUrn: 'urn:harmonic:company:1415510',
                                        logoUrl: 'https://assets.harmonic.ai/company_d3f0bb887e576781e8773419ff140da331df8a2a97d3c30723423b347944d710.png',
                                        name: "YourQuote: India's Largest Writing App",
                                        tags: [],
                                        location: {
                                            city: 'Bengaluru',
                                            state: 'Karnataka',
                                            zip: '',
                                            country: 'India',
                                            __typename: 'LocationMetadata'
                                        },
                                        __typename: 'Company'
                                    },
                                    isCurrentPosition: true,
                                    startDate: '2016-09-01T00:00:00Z',
                                    endDate: null,
                                    location: 'Bangalore, Karnataka',
                                    title: 'Co-founder/CEO',
                                    department: null,
                                    __typename: 'ExperienceMetadata'
                                }
                            ],
                            education: [],
                            contact: null,
                            updates: [],
                            emailEnrichmentStatus: null,
                            userConnections: [],
                            correspondenceSummary: {
                                lastEmailAt: '2021-08-25T00:00:00Z',
                                lastEmailContactPersonEmail: 'erlich@harmonic.ai',
                                lastEmailContactPerson: null,
                                lastMeetingAt: '2021-08-25T00:00:00Z',
                                lastMeetingContactPersonEmail: 'erlich@harmonic.ai',
                                lastMeetingContactPerson: null
                            },
                            __typename: 'Person'
                        }
                    },
                    __typename: 'PeopleWatchlistEntryEdge'
                },
                {
                    cursor: 'urn:harmonic:person:3796',
                    node: {
                        addedBy: null,
                        __typename: 'PeopleWatchlistEntryNode',
                        entryUrn: 'urn:harmonic:entry:3796',
                        entryCreatedAt: '2021-08-25T00:00:00Z',
                        customFieldValues: [],
                        person: {
                            personUrn: 'urn:harmonic:person:3796',
                            id: 3796,
                            fullName: 'James Parker',
                            firstName: 'James',
                            lastName: 'Parker',
                            about: null,
                            watchlists: [],
                            profilePictureUrl: 'https://storage.googleapis.com/harmonic-asset-test/person_ae1f31e1ba28b07bde594969df8f0121931f39f7e83c632ab974ee5fe1c78245.png',
                            linkedinHeadline: null,
                            location: {
                                location: 'Boston, Massachusetts, United States',
                                state: 'Utah',
                                city: 'Provo',
                                country: 'United States',
                                __typename: 'LocationMetadata'
                            },
                            highlights: [],
                            socials: {
                                linkedin: {
                                    followerCount: null,
                                    url: 'https://linkedin.com/in/james-parker-156a1311',
                                    status: null,
                                    __typename: 'SocialMetadata'
                                },
                                twitter: null,
                                __typename: 'Socials'
                            },
                            experience: [
                                {
                                    company: {
                                        id: 801484,
                                        entityUrn: 'urn:harmonic:company:801484',
                                        logoUrl: 'https://assets.harmonic.ai/company_fd4f977954b5978c8c8b940141d3f32955c725a916e209d92aba79de6498678e.png',
                                        name: 'HiHome',
                                        tags: [],
                                        location: {
                                            city: 'Boston',
                                            state: 'Massachusetts',
                                            zip: '',
                                            country: 'United States',
                                            __typename: 'LocationMetadata'
                                        },
                                        __typename: 'Company'
                                    },
                                    isCurrentPosition: true,
                                    startDate: '2021-06-01T00:00:00Z',
                                    endDate: null,
                                    location: null,
                                    title: 'Co-Founder',
                                    department: null,
                                    __typename: 'ExperienceMetadata'
                                }
                            ],
                            education: [],
                            contact: null,
                            updates: [],
                            emailEnrichmentStatus: null,
                            userConnections: [],
                            correspondenceSummary: {
                                lastEmailAt: '2021-08-25T00:00:00Z',
                                lastEmailContactPersonEmail: 'erlich@harmonic.ai',
                                lastEmailContactPerson: null,
                                lastMeetingAt: '2021-08-25T00:00:00Z',
                                lastMeetingContactPersonEmail: 'erlich@harmonic.ai',
                                lastMeetingContactPerson: null
                            },
                            __typename: 'Person'
                        }
                    },
                    __typename: 'PeopleWatchlistEntryEdge'
                }
            ],
            pageInfo: null
        },
        __typename: 'PeopleWatchlist'
    }
};
