import { getQueryFromDashboardSearchModel } from 'components/QueryBuilder/QueryBuilder';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { useMemo } from 'react';
import useFilterStore from '../stores/filterStore';
import { transformSearchModelFromApiv2ForDashboard } from '../utils/search';
import { useDashboardState } from './useAppState';
import useDashboardLocation from './useDashboardLocation';
import useFetchSingleSavedSearch from './useFetchSingleSavedSearch';
import useSearchQuery from './useSearchQuery';
const usePersistedQuery = (entityType) => {
    const { urn } = useDashboardLocation();
    const { initialSearchFilterGroupQuery } = useSearchQuery();
    const companiesDashboardFilters = useFilterStore((state) => state.companiesDashboardFilters);
    const peopleDashboardFilters = useFilterStore((state) => state.peopleDashboardFilters);
    const { currentPageSavedSearch } = useFetchSingleSavedSearch();
    const savedSearchQuery = useMemo(() => {
        if (!currentPageSavedSearch)
            return null;
        return transformSearchModelFromApiv2ForDashboard(currentPageSavedSearch?.searchQuery, entityType === EntityListType.PEOPLE_SAVED_SEARCH);
    }, [currentPageSavedSearch, entityType]);
    const dashboardState = useDashboardState();
    if (entityType === EntityListType.COMPANY_WATCHLIST ||
        entityType === EntityListType.PEOPLE_WATCHLIST) {
        return getQueryFromDashboardSearchModel(dashboardState);
    }
    if (entityType === EntityListType.COMPANY_SAVED_SEARCH) {
        if (urn) {
            return savedSearchQuery ?? initialSearchFilterGroupQuery;
        }
        return companiesDashboardFilters ?? initialSearchFilterGroupQuery;
    }
    if (entityType === EntityListType.PEOPLE_SAVED_SEARCH) {
        if (urn) {
            return savedSearchQuery ?? initialSearchFilterGroupQuery;
        }
        return peopleDashboardFilters ?? initialSearchFilterGroupQuery;
    }
    return initialSearchFilterGroupQuery;
};
export default usePersistedQuery;
