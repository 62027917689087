import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeadInvestorIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { getIdFromUrn } from 'common/utils/urn';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import React from 'react';
import { DASHBOARD_ROUTE, INVESTOR_ROUTE } from 'utils/constants';
import { getFundingRoundStyle } from 'utils/funding';
export const InvestorPill = ({ investorName, investorLogoUrl, investorUrn, small = false, isLead = false, roundTypes }) => {
    const id = getIdFromUrn(investorUrn);
    const renderPill = () => {
        return (_jsxs("div", { title: investorName, "data-testId": 'CompanyFinancing-Rbr-Investor', className: classNames(`h-[24px] max-w-[200px] rounded-md px-p30 flex gap-1 items-center`, {
                'bg-[#493AA6] bg-opacity-[12%] hover:bg-opacity-[16%] active:bg-opacity-[24%]': isLead && investorUrn,
                'bg-[#F0F2F4] hover:bg-[#E8EAEE] active:bg-[#D0D4DD]': !isLead && investorUrn,
                'bg-[#493AA6] bg-opacity-[8%] cursor-default': isLead && !investorUrn,
                'bg-[#F6F7F9] cursor-default': !isLead && !investorUrn
            }), children: [_jsx("div", { "data-testid": "investor-avatar-container", children: _jsx(Avatar, { size: "small", src: investorLogoUrl ?? '', alt: investorName }) }), _jsx("div", { className: classNames('font-normal truncate', {
                        'text-xs': small,
                        'text-sm': !small
                    }), children: investorName }), _jsxs("div", { className: "flex gap-[2px]", children: [isLead && small && (_jsx("div", { "data-testid": "lead-investor-icon", className: "flex items-center justify-center bg-[#493AA6] bg-opacity-20 rounded w-[16px] h-[16px]", children: _jsx(LeadInvestorIcon, {}) })), roundTypes &&
                            Array.from(roundTypes).map((roundType) => {
                                const fundingRoundStyle = getFundingRoundStyle(roundType);
                                const roundAbbr = fundingRoundStyle.pillString;
                                const displayName = getFundingTypeDisplayName(roundType);
                                return (_jsx(Tooltip, { title: displayName, children: _jsx("div", { className: `flex items-center justify-center ${fundingRoundStyle.pillBgColor} ${fundingRoundStyle.textColor} text-xs font-normal rounded p-p10 ${roundAbbr.length === 1 ? 'min-w-[16px]' : 'min-w-[24px]'} h-[16px] shadow-lg`, children: roundAbbr }) }, roundType));
                            })] })] }));
    };
    if (!investorUrn) {
        return renderPill();
    }
    return (_jsx(InternalLink, { to: { pathname: `${DASHBOARD_ROUTE}${INVESTOR_ROUTE}/${id}` }, children: renderPill() }));
};
