import { useAppStore } from '../hooks/useAppStore';
export const updateSearchModel = (partialSearchModel) => {
    useAppStore.getState().dashboard.updateSearchModel(partialSearchModel);
    if (partialSearchModel.sort && partialSearchModel.sort[0]?.sort_field) {
        useAppStore
            .getState()
            .dashboard.setSortField(partialSearchModel.sort[0]?.sort_field);
        useAppStore
            .getState()
            .dashboard.setSortIsDescending(partialSearchModel.sort[0]?.descending);
    }
};
