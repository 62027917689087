import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, LinearProgress, Popover } from '@material-ui/core';
import { PlusIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { FilterPlaceholder, FilterStringComparator, INITIAL_SEARCH_FILTER, INITIAL_SEARCH_FILTER_GROUP } from 'interfaces/SearchModel/Search';
import { capitalize, cloneDeep, isNumber } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MAX_FILTER_DEPTH } from 'utils/constants';
import { addKeyToFilterGroupAndFilterDeep, addNoOperationToFilterDeep } from 'utils/search';
import { deepOmitFromObject, replaceValueFromObject } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';
import ExtraSideActionsButton from './ExtraSideActionsButton';
import FilterQuery from './FilterQuery';
import SearchFilterError from './SearchFilterError';
import { SearchFilterGroupWrapper } from './styles';
import { isCustomFieldUrn } from './utils';
export const InitSearchContext = {
    rootSearchFilterGroup: INITIAL_SEARCH_FILTER_GROUP,
    extraOptions: {},
    searchFilterMethods: {
        changeJoinOperator: () => {
            null;
        },
        updateFilterGroup: () => {
            null;
        },
        duplicateFilterGroup: () => {
            null;
        },
        removeFilterGroup: () => {
            null;
        },
        addNewSearchFilter: () => {
            null;
        },
        addNewSearchFilters: () => {
            null;
        },
        applyFilterOperation: () => {
            null;
        },
        convertFilterToGroup: () => {
            null;
        },
        addSearchFilterGroup: () => {
            null;
        },
        removeSearchFilter: () => {
            null;
        },
        duplicateSearchFilter: () => {
            null;
        },
        updateSearchFilter: () => {
            null;
        }
    },
    fieldSpecs: []
};
export const SearchFilterContext = createContext(InitSearchContext);
const SearchFilterGroup = (props) => {
    const fieldSpecs = props.fieldSpecs;
    // Remove any field specs that do not have any allowed comparators. This means we cannot filter on that field.
    // const fieldSpecs = fieldSpec?.filter(
    //   (fieldSpec) =>
    //     fieldSpec.allowed_comparators && fieldSpec.allowed_comparators.length > 0
    // );
    const [rootFilterGroup, setRootFilterGroup] = useState(props.filterGroup
        ? cloneDeep(props.filterGroup)
        : INITIAL_SEARCH_FILTER_GROUP);
    const searchFilterContext = {
        rootSearchFilterGroup: rootFilterGroup,
        searchFilterMethods: {
            changeJoinOperator: (value) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    join_operator: value
                });
            },
            updateFilterGroup: (newFilterGroup, index) => {
                if (!isNumber(index))
                    return;
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filter_groups: rootFilterGroup.filter_groups.map((filter_group, i) => i === index ? newFilterGroup : filter_group)
                });
            },
            duplicateFilterGroup: (index) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filter_groups: [
                        ...rootFilterGroup.filter_groups.slice(0, index + 1),
                        { ...rootFilterGroup.filter_groups[index], key: uuidv4() },
                        ...rootFilterGroup.filter_groups.slice(index + 1)
                    ]
                });
            },
            removeFilterGroup: (index) => {
                const updatedFilterGroups = rootFilterGroup.filter_groups.filter((element, i) => i !== index);
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filter_groups: updatedFilterGroups
                });
            },
            addNewSearchFilter: (newFilter) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: [
                        ...rootFilterGroup.filters,
                        newFilter ? newFilter : INITIAL_SEARCH_FILTER
                    ]
                });
                if (props.recursiveLevel === 0)
                    handleClose();
            },
            addNewSearchFilters: (newFilters) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: [...rootFilterGroup.filters, ...newFilters]
                });
                if (props.recursiveLevel === 0)
                    handleClose();
            },
            addSearchFilterGroup: (searchFilters, groupName, 
            // When creating grouped filters with name like "Person Past Experience",
            // we delete the existing filter.
            filterDeleteIndex) => {
                if (props.recursiveLevel >= MAX_FILTER_DEPTH) {
                    return;
                }
                const updatedFilters = rootFilterGroup.filters.filter((element, index) => index !== filterDeleteIndex);
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: updatedFilters,
                    filter_groups: [
                        ...rootFilterGroup.filter_groups,
                        {
                            name: groupName,
                            filters: searchFilters,
                            filter_groups: [],
                            join_operator: 'and',
                            key: uuidv4()
                        }
                    ]
                });
                if (props.recursiveLevel === 0)
                    handleClose();
            },
            convertFilterToGroup: (index) => {
                if (props.recursiveLevel >= MAX_FILTER_DEPTH ||
                    !searchFilterContext.rootSearchFilterGroup.filters[index]) {
                    return;
                }
                const selectedFilter = cloneDeep(searchFilterContext.rootSearchFilterGroup.filters[index]);
                const updatedFilters = rootFilterGroup.filters.filter((element, i) => i !== index);
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: updatedFilters,
                    filter_groups: [
                        {
                            filters: [selectedFilter],
                            filter_groups: [],
                            join_operator: 'and',
                            key: uuidv4()
                        },
                        ...rootFilterGroup.filter_groups
                    ]
                });
            },
            duplicateSearchFilter: (filter) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: [...rootFilterGroup.filters, { ...filter, key: uuidv4() }]
                });
            },
            applyFilterOperation: (filterIndex, operation) => {
                if (props.recursiveLevel >= MAX_FILTER_DEPTH)
                    return;
                const operationClone = cloneDeep(operation);
                const selectedFilter = rootFilterGroup.filters[filterIndex];
                const updatedFilters = rootFilterGroup.filters.filter((element, index) => index !== filterIndex);
                replaceValueFromObject(operationClone, FilterPlaceholder.FIELD, selectedFilter.field);
                replaceValueFromObject(operationClone, FilterPlaceholder.COMPARATOR, selectedFilter.comparator);
                replaceValueFromObject(operationClone, FilterPlaceholder.FILTER_VALUE, selectedFilter.filter_value);
                let newFilterGroup = addKeyToFilterGroupAndFilterDeep(operationClone.filter_group);
                newFilterGroup = addNoOperationToFilterDeep(newFilterGroup);
                setRootFilterGroup({
                    ...searchFilterContext.rootSearchFilterGroup,
                    filters: updatedFilters,
                    filter_groups: [
                        newFilterGroup,
                        ...searchFilterContext.rootSearchFilterGroup.filter_groups
                    ]
                });
            },
            removeSearchFilter: (filterIndex) => {
                const updatedFilters = rootFilterGroup.filters.filter((element, index) => index !== filterIndex);
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: updatedFilters
                });
            },
            updateSearchFilter: (filterIndex, partialFilter) => {
                setRootFilterGroup({
                    ...rootFilterGroup,
                    filters: rootFilterGroup.filters.map((filter, index) => index === filterIndex ? { ...filter, ...partialFilter } : filter)
                });
            }
        },
        fieldSpecs: fieldSpecs
    };
    useEffect(() => {
        // Pass its state to parent component.
        // Parent component will store this component rootfilterGroup inside filter_groups[index]
        if (props.updateParentFilterGroup) {
            props.updateParentFilterGroup(searchFilterContext.rootSearchFilterGroup, props.index);
        }
    }, [searchFilterContext.rootSearchFilterGroup]);
    const onAddNewFilter = (fieldName, fieldUrn) => {
        let filteredFieldSpec = fieldSpecs?.filter((spec) => spec.unique_name === fieldName);
        if (isCustomFieldUrn(fieldUrn)) {
            filteredFieldSpec = fieldSpecs?.filter((spec) => spec.urn === fieldUrn);
        }
        if (filteredFieldSpec?.length === 0 || !fieldName)
            return;
        if (filteredFieldSpec) {
            const selectedFieldSpec = filteredFieldSpec[0];
            const defaultComparator = selectedFieldSpec.allowed_comparators?.length > 0
                ? selectedFieldSpec.allowed_comparators[0]
                : FilterStringComparator.IS_EMPTY;
            const newFilter = {
                field: fieldName,
                filter_value: '',
                comparator: defaultComparator,
                field_urn: selectedFieldSpec.urn,
                key: uuidv4()
            };
            searchFilterContext.searchFilterMethods.addNewSearchFilter(newFilter);
        }
    };
    const onAddNewFilterGroup = () => {
        searchFilterContext.searchFilterMethods.addSearchFilterGroup([]);
    };
    // Anchor for adding filter or FilterGroup.
    const [anchorEl, setAnchorEl] = useState(null);
    const handleAddFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onlyOneFilter = searchFilterContext?.rootSearchFilterGroup?.filters?.length === 1;
    const noFilter = searchFilterContext?.rootSearchFilterGroup?.filters?.length === 0;
    const moreThanTwoFilter = searchFilterContext?.rootSearchFilterGroup?.filters?.length >= 2;
    const onRemoveFilter = (index) => {
        searchFilterContext.searchFilterMethods.removeFilterGroup(index);
    };
    return (_jsx(ErrorBoundary, { FallbackComponent: SearchFilterError, children: _jsx(SearchFilterContext.Provider, { value: searchFilterContext, children: _jsx(_Fragment, { children: _jsxs(SearchFilterGroupWrapper, { children: [_jsx("div", { className: "w-full", children: !fieldSpecs ||
                                //Persisted store has default one fieldSpec. That hides the loader. Hence doing this check
                                (Array.isArray(fieldSpecs) && fieldSpecs.length < 1) ? (_jsx(LinearProgress, { className: "mt-6" })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: `${props.recursiveLevel !== 0
                                            ? 'main-filter overflow-visible'
                                            : 'my-p70'}`, children: [_jsx(FilterQuery, { disabled: props.disabled, recursiveLevel: props.recursiveLevel, entityType: props.entityType, QuickFiltersComponent: props.QuickFiltersComponent }), searchFilterContext.rootSearchFilterGroup.filter_groups?.map((filter_group, index) => (
                                            // Pay extra attention to setting key. It can cause bugs
                                            _jsxs("div", { className: "flex items-start mb-2 gap-2", children: [_jsxs("div", { id: "Group-Prefix", className: "w-16 pr-p50", children: [noFilter && index === 0 && (_jsx("div", { className: "typography-label", children: "Where" })), ((onlyOneFilter && index === 0) ||
                                                                (noFilter && index === 1)) && (_jsx("div", { className: "w-18 relative -left-2", children: _jsx(Select, { multiple: false, selected: searchFilterContext.rootSearchFilterGroup
                                                                        .join_operator, getLabelFromValue: (value) => capitalize(value), children: ['and', 'or'].map((operator) => (_jsx(ListItem, { value: operator, label: capitalize(operator), onClick: () => {
                                                                            searchFilterContext.searchFilterMethods.changeJoinOperator(operator);
                                                                        }, variant: ListVariant.default }, operator))) }) })), ((onlyOneFilter && index > 0) ||
                                                                (noFilter && index > 1) ||
                                                                moreThanTwoFilter) && (_jsx("div", { className: "typography-label text-right w-18 pr-p50", children: _jsx("div", { className: "pr-p50", children: capitalize(searchFilterContext.rootSearchFilterGroup
                                                                        .join_operator) }) }))] }), _jsx("div", { "data-testid": "SearchFilterGroup-Nested", className: classNames('p-p40 border border-solid rounded-md w-full', {
                                                            // Style for one level nested filter
                                                            'bg-surface-nested border-border': props.recursiveLevel >= 0,
                                                            // Style for one level nested filter
                                                            'bg-surface-raised border-border': props.recursiveLevel >= 1
                                                        }), children: _jsx(SearchFilterGroup, { entityType: props.entityType, entityId: props.entityId, recursiveLevel: props.recursiveLevel + 1, index: index, filterGroup: filter_group, updateParentFilterGroup: searchFilterContext.searchFilterMethods
                                                                .updateFilterGroup, fieldSpecs: props.fieldSpecs, isSearchOwner: props.isSearchOwner, disabled: props.disabled, QuickFiltersComponent: props.QuickFiltersComponent, FilterDropdownComponent: props.FilterDropdownComponent }, filter_group.key) }), !props.disabled && (_jsx(_Fragment, { children: _jsx(ExtraSideActionsButton, { duplicateFilter: () => {
                                                                searchFilterContext.searchFilterMethods.duplicateFilterGroup(index);
                                                            }, showConvertToGroupOption: false, onRemoveFilter: () => onRemoveFilter(index), copyToClipboard: () => {
                                                                navigator.clipboard.writeText(JSON.stringify(deepOmitFromObject(filter_group, ['key']), null, 2));
                                                            } }) }))] }, filter_group.key)))] }), !props.disabled && (_jsxs("div", { className: "flex gap-g40", children: [_jsx(Button, { dataTestId: "SearchFilterGroup-Filter", leadingIcon: PlusIcon, emphasis: props.recursiveLevel == 0 ? 'high' : 'low', onClick: handleAddFilterClick, label: "Add filter" }), props.recursiveLevel < MAX_FILTER_DEPTH && (_jsx(Button, { dataTestId: "SearchFilterGroup-Filter-Group", leadingIcon: PlusIcon, emphasis: props.recursiveLevel == 0 ? 'high' : 'low', onClick: onAddNewFilterGroup, label: "Add group" })), _jsx(Popover, { disableAutoFocus: true, disableEnforceFocus: true, anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                }, transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }, elevation: 0, anchorEl: anchorEl, open: anchorEl ? true : false, onClose: handleClose, className: "mt-p20", classes: {
                                                    paper: classNames('bg-surface-default shadow-static-elevation-floating transition-all', 'rounded-br40 border border-border border-solid', 'w-fit flex flex-col')
                                                }, children: props.FilterDropdownComponent({ onAddNewFilter }) })] }))] })) }), _jsx(Divider, {})] }) }) }) }));
};
export default SearchFilterGroup;
