import { useCallback } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
export const useCompanySearchSort = () => {
    const { editFilterStore, companyExplorePageSort } = useShallowFilterStore([
        'editFilterStore',
        'companyExplorePageSort'
    ]);
    const changeSort = useCallback(({ field, descending }) => {
        editFilterStore('companyExplorePageSort', {
            sortField: field,
            descending: descending
        });
    }, [editFilterStore]);
    return {
        changeSort,
        sort: companyExplorePageSort
    };
};
