import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown, ChevronUp } from 'assets/harmonic-icons';
import Accordion from 'harmonic-components/Accordion/Accordion';
import { useState } from 'react';
const ListGroup = ({ title, children, defaultOpen = true, hideChevron = false }) => {
    const [open, setOpen] = useState(defaultOpen);
    return (_jsxs("div", { "data-testid": "group", className: "w-full", children: [_jsxs("button", { "aria-expanded": open, "data-testid": "group-expand-button", onClick: (e) => {
                    setOpen(!open);
                    e.stopPropagation();
                }, className: "w-full flex px-p40 pt-p30 pb-p20 gap-g40 items-center self-stretch typography-label-small-default text-content-weak", children: [_jsx("div", { className: "flex flex-1", children: _jsx("p", { children: title }) }), !hideChevron &&
                        (open ? (_jsx(ChevronUp, { className: "w-4 h-4" })) : (_jsx(ChevronDown, { className: "w-4 h-4" })))] }), _jsx(Accordion, { open: open, children: children })] }));
};
export default ListGroup;
