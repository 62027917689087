import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useUpsertPeopleWatchlistNamedViews from 'hooks/useUpsertPeopleWatchlistNamedViews';
import { INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { useCallback, useMemo } from 'react';
import useStore from 'stores/zustandStore';
import { getSelectedPersonNamedViewIndex } from 'utils/namedViews';
import { getModifiedPeopleSortValueAfterValidationCheck } from 'utils/sort';
export const usePeopleWatchlistSort = () => {
    const { peopleWatchlist } = useFetchSinglePeopleWatchlist({
        fetchPolicy: 'cache-only'
    });
    const personListSelectedNamedView = useStore((state) => state.personListSelectedNamedView);
    const selectedNamedViewIndex = useMemo(() => {
        return getSelectedPersonNamedViewIndex(peopleWatchlist, personListSelectedNamedView);
    }, [personListSelectedNamedView, peopleWatchlist]);
    const currentNamedViews = peopleWatchlist?.namedViews?.[selectedNamedViewIndex];
    const { upsertCurrentSelectedNamedView } = useUpsertPeopleWatchlistNamedViews();
    const updateSortDataOnBackend = useCallback(async (sortField, isDescending, sortContextValues) => {
        const updatedSearchQuery = {
            ...INITIAL_SEARCH_MODEL,
            ...(currentNamedViews?.searchQuery ?? {}),
            sort: [
                {
                    sortField,
                    descending: isDescending,
                    sortContextValues
                }
            ]
        };
        upsertCurrentSelectedNamedView({
            variables: {
                searchQuery: updatedSearchQuery
            }
        });
    }, [upsertCurrentSelectedNamedView, currentNamedViews]);
    const changeSort = useCallback(({ field, descending, sortContextValues }) => {
        updateSortDataOnBackend(field, descending, sortContextValues);
    }, [updateSortDataOnBackend]);
    const sort = getModifiedPeopleSortValueAfterValidationCheck(currentNamedViews?.searchQuery?.sort?.[0]?.sortField, currentNamedViews?.searchQuery?.sort?.[0]?.descending, currentNamedViews?.searchQuery?.sort?.[0]?.sortContextValues, peopleWatchlist?.customFields);
    return {
        changeSort,
        sort
    };
};
