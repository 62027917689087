import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import { FundingSourcesIcon } from 'assets/harmonic-icons';
import { InvestorPill } from 'components/common/Investors/InvestorPill';
import dayjs from 'dayjs';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import React from 'react';
import { UNKNOWN_VALUE } from 'utils/constants';
import { getFormattedFundingTotal, getFundingFavIconsByDomain, getFundingRoundStyle, isExcludedFromFundingTotal } from 'utils/funding';
export const CompanySingleRbrV2 = ({ fundingRound, investors, investorToFundingRoundTypesMap }) => {
    const fundingRoundType = getFundingTypeDisplayName(fundingRound?.fundingRoundType);
    const isExcludedFromTotal = isExcludedFromFundingTotal(fundingRound?.fundingRoundType);
    const fundingRoundStyle = getFundingRoundStyle(fundingRoundType);
    const FundingRoundIcon = fundingRoundStyle.icon;
    const domain = fundingRound?.sourceUrl
        ? new URL(fundingRound.sourceUrl).hostname.replace('www.', '')
        : '';
    const faviconUrl = getFundingFavIconsByDomain(domain);
    return (_jsxs("div", { className: `${fundingRoundStyle.bgColor} rounded-lg py-0.5 px-0.5 w-full`, children: [_jsxs("div", { className: `w-full h-[24px] px-2 py-1 flex gap-1 items-center`, children: [_jsx(FundingRoundIcon, {}), _jsx("div", { className: `text-sm font-medium ${fundingRoundStyle.textColor}`, children: `${fundingRoundType}${isExcludedFromTotal ? ' (Excluded from total funding)' : ''}` })] }), _jsxs("div", { className: "bg-white rounded-lg p-p20 w-full", children: [_jsxs("div", { className: "grid grid-cols-10 px-p20 py-p40", children: [_jsx("div", { "data-testid": "CompanySingleRbrV2-FundingAmount", className: "col-span-2 sm:col-span-1 text-sm font-medium flex items-center", children: getFormattedFundingTotal(fundingRound?.fundingAmount, null) }), _jsx("div", { className: "col-span-3 sm:col-span-1 text-sm font-medium flex items-center", children: dayjs(fundingRound?.announcementDate).utc().format('M/D/YYYY') ??
                                    UNKNOWN_VALUE }), _jsx("div", { className: "col-span-4 sm:col-span-7 flex items-center px-3", children: !!fundingRound?.postMoneyValuation && (_jsx("div", { className: `text-xs ${fundingRoundStyle.textColor} ${fundingRoundStyle.bgColor} rounded-full px-2 py-0.5`, children: `${getFormattedFundingTotal(fundingRound?.postMoneyValuation, null)} Post-money` })) }), _jsx("div", { className: "col-span-1 text-base font-medium flex items-center justify-end", children: fundingRound?.sourceUrl && (_jsxs(Popover, { className: "relative", children: [_jsx(Popover.Button, { className: "bg-[#F0F2F4] rounded-lg w-[34px] h-[28px] flex gap-2 items-center justify-center p-p20 hover:bg-gray-200", children: _jsx(FundingSourcesIcon, {}) }), _jsx(Popover.Panel, { children: ({ close }) => (_jsx("div", { className: "absolute z-10 right-0 mt-2 px-1 max-w-48 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 h-[44px] flex items-center justify-center", children: _jsx("div", { className: "min-w-[100px] p-2 flex items-center justify-center truncate rounded-md hover:bg-gray-100 h-[36px]", children: _jsx("a", { href: fundingRound.sourceUrl, target: "_blank", rel: "noopener noreferrer", className: "w-full", onClick: () => close(), children: _jsxs("div", { className: "p-2 flex gap-3 items-center justify-center", children: [_jsx("img", { src: faviconUrl, alt: `${domain} favicon`, className: "w-4 h-4" }), _jsx("span", { className: "text-sm font-medium", children: domain })] }) }) }) })) })] })) })] }), investors.length > 0 && (_jsxs("div", { className: "pt-p10 px-1", children: [_jsx("hr", { className: "border-t border-solid border-[#E8EAEE]" }), _jsx("div", { className: "flex flex-wrap gap-1 pt-2 pb-1", children: investors.map((inv) => {
                                    if (!inv.name)
                                        return;
                                    const otherRoundTypes = new Set(investorToFundingRoundTypesMap[inv.entityUrn]
                                        .map((fr) => fr.fundingRoundType)
                                        .filter((fr) => fr !== inv.roundType));
                                    return (_jsx(InvestorPill, { investorName: inv.name, investorLogoUrl: inv.logoUrl, investorUrn: inv.investorUrn, isLead: inv.isLead, roundTypes: otherRoundTypes, small: true }, inv.name));
                                }) })] })), !investors.length && (_jsxs("div", { className: "pt-p10 px-1", children: [_jsx("hr", { className: "border-t border-solid" }), _jsx("p", { className: "text-xs text-[#7C8598] py-1", children: "Investors not available" })] }))] })] }));
};
