import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BusinessIcon, TeamIcon } from 'assets/harmonic-icons';
import { Badge, Tooltip } from 'common/components';
import fuzzysort from 'fuzzysort';
import ListGroup from 'harmonic-components/ListGroup/ListGroup';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Tabs from 'harmonic-components/Tabs/Tabs';
import useFlags from 'hooks/useFlags';
import ThreeDotIcon from 'icons/ThreeDotIcon';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { EntityListType, FieldCategory, FieldCategoryLabel, FieldEntityType } from 'interfaces/SearchModel/Search';
import { capitalize, groupBy } from 'lodash';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { EntityFilterCategoryMap } from './EntityFilterCategoryTabs';
import PopularFilters from './PopularFilters';
import { getFieldsByCategory, getGroupTitle } from './utils';
const FilterField = ({ fieldSpec, onUpdateComparatorName }) => {
    const icon = fieldSpec?.entity_type === FieldEntityType.PERSON ? TeamIcon : BusinessIcon;
    let listItem = (_jsx(ListItem, { testId: `SearchFilterDropdown-ListItem-${fieldSpec?.urn}`, variant: ListVariant.default, value: fieldSpec.urn, onClick: () => onUpdateComparatorName(fieldSpec.unique_name, fieldSpec.urn), label: fieldSpec.display_name, primaryIcon: icon, secondaryIcon: fieldSpec.beta ? (_jsx(Badge, { label: "Beta", size: "small", color: "neutral", intensity: "highlight", borderRadius: "square" })) : undefined, showSecondaryIcon: () => true }, fieldSpec.urn));
    if (fieldSpec.description) {
        listItem = (_jsx(Tooltip, { alignment: "right", title: fieldSpec.description, children: listItem }, fieldSpec.unique_name));
    }
    return listItem;
};
const FilterFieldGroup = ({ title, fields, onUpdateComparatorName, defaultOpen = true }) => {
    return (_jsx(ListGroup, { title: title, defaultOpen: defaultOpen, children: fields.map((field) => (_jsx(FilterField, { fieldSpec: field, onUpdateComparatorName: onUpdateComparatorName }, field.unique_name))) }));
};
const SearchFilterDropdown = ({ fields, selectedField, onUpdateComparatorName, entityType }) => {
    let selectableFields = fields.filter((field) => field.display_name && !field.deprecated && field.entity_type !== undefined);
    const { enabled: correspondenceFieldsEnabled } = useFlags(SPLITS.showCorrespondencesInSearch);
    const { enabled: enableAddedByColumn } = useFlags(SPLITS.enableAddedByColumn);
    const { enabled: enableCustomColumns } = useFlags(SPLITS.enableCustomColumns);
    if (!correspondenceFieldsEnabled) {
        selectableFields = selectableFields.filter((field) => !field.unique_name.includes('customers_connections_last_'));
    }
    if (!enableAddedByColumn) {
        selectableFields = selectableFields.filter((field) => !field.unique_name.includes('entry_added_by'));
    }
    const resourceType = entityType === EntityListType.PEOPLE_SAVED_SEARCH ||
        entityType === EntityListType.PEOPLE_WATCHLIST
        ? ApiResourceType.PeopleList
        : ApiResourceType.CompaniesList;
    let defaultCategory = resourceType === ApiResourceType.PeopleList
        ? FieldCategory.PERSON
        : FieldCategory.COMPANY;
    const selectedFieldDetail = selectableFields?.filter((field) => field?.unique_name === selectedField) ??
        [];
    if (selectedFieldDetail.length === 1) {
        defaultCategory = selectedFieldDetail[0]?.category;
    }
    const [category, setCategory] = React.useState(defaultCategory);
    const [searchTerm, setSearchTerm] = React.useState('');
    const hasSearchTerm = React.useMemo(() => !!searchTerm.trim(), [searchTerm]);
    const getFieldSearchResults = () => {
        if (!selectableFields) {
            return [];
        }
        const searchTermFields = selectableFields.filter((field) => !!fuzzysort.single(searchTerm, field.display_name)?.target);
        return searchTermFields;
    };
    const fieldsByCategory = getFieldsByCategory(selectableFields, resourceType);
    const categoryFields = fieldsByCategory[category] ?? [];
    const fieldsByGrouping = groupBy(selectableFields.filter((field) => field.grouping && field.category === category), (field) => field.grouping);
    const fieldsSearchResult = hasSearchTerm ? getFieldSearchResults() : [];
    let filterCategoryMap = EntityFilterCategoryMap[entityType] || [];
    if (enableCustomColumns &&
        (entityType === EntityListType.PEOPLE_WATCHLIST ||
            entityType === EntityListType.COMPANY_WATCHLIST)) {
        filterCategoryMap = [
            ...filterCategoryMap,
            {
                category: FieldCategory.CUSTOM,
                icon: _jsx(ThreeDotIcon, {})
            }
        ];
    }
    const tabs = filterCategoryMap.map((filterTab) => ({
        label: filterTab.category === FieldCategory.PERSON &&
            resourceType === ApiResourceType.PeopleList
            ? 'Person'
            : FieldCategoryLabel[filterTab.category],
        value: filterTab.category,
        variation: 'label'
    }));
    return (_jsxs("div", { "data-testid": "SearchFilterDropdown", className: "flex flex-col flex-1 max-w-[512px] min-h-[530px] max-h-[530px]", children: [_jsx("div", { className: "mx-p50 mt-p50 mb-p20", children: _jsx(SearchInput, { value: searchTerm, onChange: (newValue) => setSearchTerm(newValue), placeholder: "Search for a filter", fullWidth: true }) }), _jsx(Tabs, { tabs: tabs, onTabChange: (category) => {
                    setSearchTerm('');
                    setCategory(category);
                }, selectedTabValue: category }), _jsxs("div", { className: "flex flex-col min-w-0 overflow-y-auto flex-1 min-h-0 p-p20", children: [!hasSearchTerm && (_jsx(ListGroup, { title: "Popular filters", children: _jsx("div", { className: "flex flex-row flex-wrap min-w-0", children: _jsx(PopularFilters, { fields: selectableFields, onUpdateComparatorName: onUpdateComparatorName }) }) })), hasSearchTerm && (_jsx(FilterFieldGroup, { fields: fieldsSearchResult, onUpdateComparatorName: onUpdateComparatorName, title: 'Search results' })), !hasSearchTerm && categoryFields.length > 0 && (_jsx(FilterFieldGroup, { fields: categoryFields, onUpdateComparatorName: onUpdateComparatorName, title: FieldCategoryLabel[category] })), !hasSearchTerm &&
                        Object.entries(fieldsByGrouping).map(([grouping, groupingFields]) => {
                            const title = capitalize(getGroupTitle(grouping));
                            return (_jsx(FilterFieldGroup, { fields: groupingFields, onUpdateComparatorName: onUpdateComparatorName, title: title, defaultOpen: false }, grouping));
                        })] })] }));
};
export default SearchFilterDropdown;
