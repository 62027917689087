import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { CrossIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import Select from 'harmonic-components/Select/Select';
import { TimeUnit } from 'interfaces/SearchV2';
import { capitalize, isArray, isNil, toString } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { formatTenureValue, parseTenureValue } from 'utils/searchV2/searchV2';
import SelectorWithValue from './SelectorWithValue';
var TenureModeType;
(function (TenureModeType) {
    TenureModeType["SHORTER_THAN"] = "SHORTER_THAN";
    TenureModeType["LONGER_THAN"] = "LONGER_THAN";
    TenureModeType["BETWEEN"] = "BETWEEN";
})(TenureModeType || (TenureModeType = {}));
const modeOptions = [
    {
        label: 'Shorter Than',
        value: TenureModeType.SHORTER_THAN
    },
    {
        label: 'Longer Than',
        value: TenureModeType.LONGER_THAN
    },
    {
        label: 'Between',
        value: TenureModeType.BETWEEN
    }
];
const DEFAULT_TENURE_SELECTOR_OPTIONS = [
    {
        value: TimeUnit.MONTHS,
        label: 'Months'
    },
    {
        value: TimeUnit.YEARS,
        label: 'Years'
    }
];
const DEFAULT_TENURE_VALUE = '0 MONTHS';
const DEFAULT_TENURE_RANGE_VALUE = ['0 MONTHS', '0 MONTHS'];
const TenureCustomRange = ({ value, comparator, onChange }) => {
    const [modeType, setModeType] = useState(TenureModeType.SHORTER_THAN);
    const [shorterOrLongerValue, setShorterOrLongerValue] = useState(DEFAULT_TENURE_VALUE);
    const [rangeValue, setRangeValue] = useState(DEFAULT_TENURE_RANGE_VALUE);
    useEffect(() => {
        if (isNil(value))
            return;
        if (isArray(value)) {
            setModeType(TenureModeType.BETWEEN);
            setRangeValue(value);
            return;
        }
        if (comparator === 'lessThan') {
            setShorterOrLongerValue(value);
            setModeType(TenureModeType.SHORTER_THAN);
            return;
        }
        if (comparator === 'greaterThan') {
            setShorterOrLongerValue(value);
            setModeType(TenureModeType.LONGER_THAN);
            return;
        }
    }, [value]);
    const pillLabel = useMemo(() => {
        if (isNil(value))
            return 'Custom Range';
        if (isArray(value)) {
            const startParsedValue = parseTenureValue(value[0]);
            const endParsedValue = parseTenureValue(value[1]);
            return `Between ${startParsedValue.value} ${startParsedValue.timeUnit.toLowerCase()} To ${endParsedValue.value} ${endParsedValue.timeUnit.toLowerCase()}`;
        }
        const parsedSoloValue = parseTenureValue(value);
        if (comparator === 'lessThan') {
            return `Shorter Than ${parsedSoloValue.value} ${parsedSoloValue.timeUnit.toLowerCase()}`;
        }
        else {
            return `Longer Than ${parsedSoloValue.value} ${parsedSoloValue.timeUnit.toLowerCase()}`;
        }
    }, [value]);
    const shorterOrLongerParsedValue = parseTenureValue(shorterOrLongerValue);
    const rangeStartParsedValue = parseTenureValue(rangeValue[0]);
    const rangeEndParsedValue = parseTenureValue(rangeValue[1]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onApplyFilter = () => {
        if (modeType === TenureModeType.SHORTER_THAN) {
            onChange('lessThan', shorterOrLongerValue);
        }
        if (modeType === TenureModeType.LONGER_THAN) {
            onChange('greaterThan', shorterOrLongerValue);
        }
        if (modeType === TenureModeType.BETWEEN) {
            onChange('inRange', rangeValue);
        }
        handleClose();
    };
    const isOpen = Boolean(anchorEl);
    return (_jsxs("div", { children: [_jsx(RadioPill, { dataTestId: "TenureCustomRange-Radio-Pill", selected: isOpen || !isNil(value), label: pillLabel, onClick: handleClick }), _jsx(Popover, { open: isOpen, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }, classes: {
                    paper: 'border-border border-solid shadow-static-elevation-floating mt-p30'
                }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs("div", { className: "w-[360px] h-[300px] flex flex-col", children: [_jsxs("div", { className: "p-p50 flex justify-between gap-g50 border-b border-solid border-border", children: [_jsx("p", { className: "typography-label", children: "Custom range" }), _jsx(IconButton, { size: "tiny", type: "secondary", emphasis: "low", onClick: handleClose, icon: CrossIcon })] }), _jsxs("div", { className: "p-p50 flex flex-col gap-g50 justify-between flex-grow", children: [_jsxs("div", { className: "flex flex-col gap-g50", children: [_jsx(Select, { selected: modeType, dataTestId: "TenureModeType-Select", multiple: false, getLabelFromValue: (value) => {
                                                return capitalize(toString(value?.replace('_', ' ')));
                                            }, children: modeOptions.map((option) => (_jsx(ListItem, { variant: ListVariant.default, testId: `TenureModeType-ListItem-${option.value}`, value: option.value, label: option.label, onClick: () => {
                                                    setModeType(option.value);
                                                } }, option.value))) }), (modeType === TenureModeType.SHORTER_THAN ||
                                            modeType === TenureModeType.LONGER_THAN) && (_jsx(SelectorWithValue, { fullWidth: true, selector: {
                                                options: DEFAULT_TENURE_SELECTOR_OPTIONS,
                                                value: shorterOrLongerParsedValue.timeUnit,
                                                onChange: (value) => {
                                                    const formattedValue = formatTenureValue({
                                                        value: shorterOrLongerParsedValue.value,
                                                        timeUnit: value
                                                    });
                                                    setShorterOrLongerValue(formattedValue);
                                                }
                                            }, value: shorterOrLongerParsedValue.value, onValueChange: (e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                const formattedValue = formatTenureValue({
                                                    value: value ? parseInt(value) : 0,
                                                    timeUnit: shorterOrLongerParsedValue.timeUnit
                                                });
                                                setShorterOrLongerValue(formattedValue);
                                            } })), modeType === TenureModeType.BETWEEN && (_jsxs("div", { children: [_jsx(SelectorWithValue, { fullWidth: true, selector: {
                                                        options: DEFAULT_TENURE_SELECTOR_OPTIONS,
                                                        value: rangeStartParsedValue.timeUnit,
                                                        onChange: (value) => {
                                                            const formattedStartValue = formatTenureValue({
                                                                value: rangeStartParsedValue.value,
                                                                timeUnit: value
                                                            });
                                                            setRangeValue([formattedStartValue, rangeValue[1]]);
                                                        }
                                                    }, value: rangeStartParsedValue.value, onValueChange: (e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                                        const formattedStartValue = formatTenureValue({
                                                            value: value ? parseInt(value) : 0,
                                                            timeUnit: rangeStartParsedValue.timeUnit
                                                        });
                                                        setRangeValue([formattedStartValue, rangeValue[1]]);
                                                    } }), _jsx("p", { className: "mt-g50 mb-g40", children: "And" }), _jsx(SelectorWithValue, { fullWidth: true, selector: {
                                                        options: DEFAULT_TENURE_SELECTOR_OPTIONS,
                                                        value: rangeEndParsedValue.timeUnit,
                                                        onChange: (value) => {
                                                            const formattedEndValue = formatTenureValue({
                                                                value: rangeEndParsedValue.value,
                                                                timeUnit: value
                                                            });
                                                            setRangeValue([rangeValue[0], formattedEndValue]);
                                                        }
                                                    }, value: rangeEndParsedValue.value, onValueChange: (e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                                        const formattedEndValue = formatTenureValue({
                                                            value: value ? parseInt(value) : 0,
                                                            timeUnit: rangeEndParsedValue.timeUnit
                                                        });
                                                        setRangeValue([rangeValue[0], formattedEndValue]);
                                                    } })] }))] }), _jsx(Button, { type: "secondary", emphasis: "high", label: "Apply", fullWidth: true, dataTestId: "TenureCustomRange-Apply-Btn", onClick: onApplyFilter })] })] }) })] }));
};
export default TenureCustomRange;
