import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { Link } from 'react-router-dom';
const InternalLink = (props) => {
    const to = cloneDeep(props.to);
    if (!to) {
        /**
         * HACK: This scenario is only added for retrocompatibility with the way
         * GenericSearch is implemented. That component tries to render a link
         * without navigation capabilities in some cases.
         */
        return _jsx("a", { ...props, children: props.children });
    }
    const { openedFromLocation, ...restProps } = props;
    if (openedFromLocation) {
        // Adding the origin that opened this internal link as a UTM parameter.
        // This make it compatible with most analytics tools.
        // https://en.wikipedia.org/wiki/UTM_parameters
        const parsedSearch = to.search ? parse(to.search) : {};
        parsedSearch.utm_source = 'harmonic';
        parsedSearch.utm_content = openedFromLocation;
        to.search = `?${stringify(parsedSearch)}`;
    }
    return (_jsx(Link, { "data-testid": props.dataTestId, ...restProps, to: to, target: props.openInNewTab ? '_blank' : undefined, role: "link", children: props.children }));
};
export default InternalLink;
