import { RoleType } from '__generated__/graphql';
import { compact, find, groupBy, sortBy } from 'lodash';
export const getGroupedCompanyUserConnections = (userConnections, companyId) => {
    const groupedPeople = groupBy(userConnections, (connection) => connection?.targetPerson?.id ?? connection?.targetPersonEmailAddress);
    return compact(sortBy(Object.values(groupedPeople), (value) => {
        // Sort by role type
        const person = value[0]?.targetPerson;
        if (!person)
            return 0;
        const current = person.experience?.find((exp) => {
            return exp?.isCurrentPosition && exp.company.id === companyId;
        });
        return current?.roleType === RoleType.FOUNDER
            ? -6
            : current?.roleType === RoleType.EXECUTIVE
                ? -5
                : current?.roleType === RoleType.OPERATOR
                    ? -4
                    : current?.roleType === RoleType.ADVISER
                        ? -3
                        : current?.roleType === RoleType.EMPLOYEE
                            ? -2
                            : -1;
    }).map((value) => {
        if (!value[0]?.user)
            return null;
        return {
            person: value[0].targetPerson ?? undefined,
            personEmail: find(value, (item) => !!item.targetPersonEmailAddress)
                ?.targetPersonEmailAddress ?? '',
            userConnections: value.map((connection) => connection?.user),
            latestCalendarEvent: value[0]?.latestCalendarEvent,
            latestEmail: value[0]?.latestEmail,
            userConnectionsWithFullMetadata: value
        };
    }));
};
export const getParticipantResponseStatusMap = (participantResponseStatuses) => {
    const statusMap = {};
    participantResponseStatuses.forEach((participant) => {
        const person = participant.person;
        const status = participant.status;
        const key = person?.emailAddress.emailAddress;
        if (!key)
            return;
        if (person && status)
            statusMap[key] = status;
    });
    return statusMap;
};
export const getDisplayNameFromParticipantList = (emailAddress, participants) => {
    const participant = participants.find((participant) => participant.emailAddress === emailAddress);
    return participant?.name ? participant.name : emailAddress;
};
