import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { compact, toInteger, toString } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { TYPEAHEAD_COMPANIES_QUERY } from 'queries/typeahead';
import { useCallback, useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const CompanyMultiselectById = ({ onChange, selected, labelPrefix, initialFocus, mode, disabled, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data: companiesData, loading } = useQuery(TYPEAHEAD_COMPANIES_QUERY, { variables: { query: searchTerm } });
    const { data: companyNameData } = useQuery(getCompaniesNames, {
        variables: {
            ids: compact(selected.map((company) => company.id))
        },
        fetchPolicy: 'cache-and-network'
    });
    const onClickListItem = (company) => {
        const selectedCompany = {
            name: company.name ?? '',
            id: company.id
        };
        const currentOptionIndex = selected.findIndex((option) => option.id
            ? option.id === selectedCompany.id
            : option.name === selectedCompany.name);
        if (currentOptionIndex === -1) {
            onChange([...selected, selectedCompany]);
        }
        setSearchTerm('');
    };
    const onRemoveItem = (companyId) => {
        const updatedSelectedOptions = selected.filter((option) => option.id?.toString() !== companyId);
        onChange(updatedSelectedOptions);
    };
    const selectedIds = selected.map((option) => option?.id?.toString() ?? '');
    const getLabelFromValue = useCallback((value) => {
        const companyTypeaheadResult = selected?.find((company) => company?.id === toInteger(value));
        const fetchedCompanyName = companyNameData?.getCompaniesByIds?.find((company) => toString(company?.id) === value)?.name;
        return fetchedCompanyName ?? companyTypeaheadResult?.name ?? '';
    }, [selected, companyNameData]);
    return (_jsx("div", { className: "min-w-56 w-full", children: _jsxs(Select, { multiple: true, filterable: true, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: !disabled ? selectedIds : [], labelPrefix: labelPrefix, placeholder: placeholder || 'Search company', getLabelFromValue: getLabelFromValue, initialFocus: initialFocus, onRemove: (company) => onRemoveItem(company), hideDropdown: !loading && companiesData?.getCompaniesWithTypeahead?.length === 0, getTagColorFromValue: () => getTagColorBySelectMode(mode), getTagIconFromValue: () => getTagIconBySelectMode(mode), disabled: disabled, children: [loading && (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(HarmonicLoader, { showText: false }) })), !loading &&
                    companiesData?.getCompaniesWithTypeahead?.map((company) => {
                        if (!company)
                            return null;
                        return (_jsx(SelectListItem, { value: company.name ?? '', label: company.name ?? '', logo: "logo", src: company.logoUrl ?? '', onClick: () => onClickListItem(company), variant: ListVariant.entity }, company.id));
                    })] }) }));
};
export default CompanyMultiselectById;
