import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SectorPresetArtificialIntelligence from 'assets/sectors/ai';
import SectorPresetB2bSaas from 'assets/sectors/b2b-saas';
import SectorPresetBiotech from 'assets/sectors/biotech';
import SectorPresetClimate from 'assets/sectors/climate';
import SectorPresetConsumer from 'assets/sectors/consumer';
import SectorPresetConsumerProductGoods from 'assets/sectors/cpg';
import SectorPresetCybersecurity from 'assets/sectors/cybersecurity';
import SectorPresetEdtech from 'assets/sectors/edtech';
import SectorPresetFintech from 'assets/sectors/fintech';
import SectorPresetProptech from 'assets/sectors/proptech';
import React from 'react';
import Button from 'harmonic-components/Button/Button';
import RadioCard from 'harmonic-components/RadioCard/RadioCard';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
export const chipDisplayOrder = [
    {
        title: 'Artificial intelligence',
        sectorPreset: 'ARTIFICIAL_INTELLIGENCE',
        icon: SectorPresetArtificialIntelligence
    },
    {
        title: 'Consumer software',
        sectorPreset: 'CONSUMER_SOFTWARE',
        icon: SectorPresetConsumer
    },
    {
        title: 'Edtech',
        sectorPreset: 'ED_TECH',
        icon: SectorPresetEdtech
    },
    {
        title: 'B2B SaaS',
        sectorPreset: 'B2B_SAAS',
        icon: SectorPresetB2bSaas
    },
    {
        title: 'Climate',
        sectorPreset: 'CLIMATE',
        icon: SectorPresetClimate
    },
    {
        title: 'Fintech',
        sectorPreset: 'FINTECH',
        icon: SectorPresetFintech
    },
    {
        title: 'Biotech',
        sectorPreset: 'BIOTECH',
        icon: SectorPresetBiotech
    },
    {
        title: 'Cybersecurity',
        sectorPreset: 'CYBERSECURITY',
        icon: SectorPresetCybersecurity
    },
    {
        title: 'Proptech',
        sectorPreset: 'PROPTECH',
        icon: SectorPresetProptech
    },
    {
        title: 'Consumer goods',
        sectorPreset: 'CONSUMER_GOODS',
        icon: SectorPresetConsumerProductGoods
    }
];
const SectorPresets = ({ searchQuery, onUpdateSearchQuery }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const [selectedPreset, setSelectedPreset] = React.useState('');
    React.useEffect(() => {
        setSelectedPreset(controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET]?.preset || '');
    }, [controlledSearchState]);
    const onSectorPresetClick = (selected, preset) => {
        analytics.trackCustomEvent({
            event: CustomTrackEvent.SECTOR_PRESET_CLICK,
            properties: {
                selected,
                preset
            }
        });
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET, !selected ? { preset: preset } : undefined));
    };
    const [showAllOptions, setShowAllOptions] = React.useState(false);
    const maxDisplayCount = 9;
    const showToggleButton = chipDisplayOrder.length > maxDisplayCount;
    return (_jsxs("div", { className: "flex flex-col gap-g50", children: [_jsx("div", { className: "grid grid-cols-3 place-content-around gap-2", children: chipDisplayOrder
                    .slice(0, showAllOptions ? chipDisplayOrder.length : maxDisplayCount)
                    .map((chipMeta) => (_jsx(RadioCard, { label: chipMeta.title, selected: selectedPreset === chipMeta.sectorPreset, icon: chipMeta.icon, onClick: () => onSectorPresetClick(selectedPreset === chipMeta.sectorPreset, chipMeta.sectorPreset) }, chipMeta.title))) }), showToggleButton && (_jsx("div", { className: "flex-shrink", children: _jsx(Button, { emphasis: "high", label: !showAllOptions ? 'Show more' : 'Show less', onClick: () => setShowAllOptions(!showAllOptions) }) }))] }));
};
