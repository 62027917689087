import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import ListMultiselect from '../Common/ListMultiselect';
import { SubSectionHeader } from '../Common/Typography';
const GeneralListsSubSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const inListsValue = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS]?.in_lists;
    const notInListsValue = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS]?.not_in_lists;
    return (_jsxs("div", { "data-testid": "people-lists-section", className: "w-full", children: [_jsx("div", { className: "flex flex-col justify-center items-start pb-p50", children: _jsx(SubSectionHeader, { children: "People lists" }) }), _jsxs("div", { className: "flex gap-g60", children: [_jsxs("div", { className: "w-1/2 flex flex-col gap-g20", children: [_jsx("label", { className: "text-content-weak typography-label", children: "Include lists" }), _jsx(ListMultiselect, { mode: "people_watchlist", dataTestid: "people-lists-include-multiselect", selected: inListsValue ?? [], onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS, {
                                    in_lists: newValues,
                                    not_in_lists: notInListsValue
                                })), placeholder: "Select people lists to include" })] }), _jsxs("div", { className: "w-1/2 flex flex-col gap-g20", children: [_jsx("label", { className: "text-content-weak typography-label", children: "Exclude lists" }), _jsx(ListMultiselect, { mode: "people_watchlist", dataTestid: "people-lists-exclude-multiselect", selected: notInListsValue ?? [], onChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS, {
                                    in_lists: inListsValue,
                                    not_in_lists: newValues
                                })), placeholder: "Select people lists to exclude" })] })] })] }));
};
export default GeneralListsSubSection;
