import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { useSchoolTypeahead } from './hooks/useSchoolTypeahead';
const SchoolMultiselect = ({ selected, dataTestId, onChange, initialFocus, entityType }) => {
    const { searchTerm, options: schoolTypeaheadOptions, handleSearch } = useSchoolTypeahead({ entityType });
    const onClickListItem = (value) => {
        const selectedOption = selected.find((s) => s === value);
        if (selectedOption) {
            onChange(selected.filter((s) => s !== value));
        }
        else {
            onChange([...selected, value]);
        }
        handleSearch('');
    };
    return (_jsxs(Select, { multiple: true, selected: selected, onRemove: (key) => {
            const newSelected = selected.filter((s) => s !== key);
            onChange(newSelected.length > 0 ? newSelected : undefined);
        }, filterable: true, filterTerm: searchTerm, onFilterTermChange: handleSearch, freeSolo: true, placeholder: "Type school name", initialFocus: initialFocus, onAdd: (value) => value && onClickListItem(value), getLabelFromValue: (value) => schoolTypeaheadOptions.find((option) => option?.value === value)
            ?.label ?? '', dataTestId: dataTestId, fullWidth: true, children: [_jsx("div", { className: "overflow-y-scroll w-full", children: schoolTypeaheadOptions.map((school) => {
                    if (!school)
                        return null;
                    return (_jsx(ListItem, { value: school.value, label: school.label ?? '', onChange: () => onClickListItem(school.value), selected: selected.includes(school.value), variant: ListVariant.checkboxes }, school.value));
                }) }), searchTerm && (_jsx("div", { className: classNames('w-full', schoolTypeaheadOptions.length > 0 &&
                    'border-t border-solid border-border'), children: _jsx(ListItem, { label: `+ Add ${searchTerm}`, value: searchTerm, onClick: () => onClickListItem(searchTerm), variant: ListVariant.default }, `add-${searchTerm}`) }))] }));
};
export default SchoolMultiselect;
