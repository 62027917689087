import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import { isArray } from 'lodash';
import TenureCustomRange from './TenureCustomRange';
const options = [
    {
        label: 'Any',
        value: undefined
    },
    {
        label: '> 1 year',
        value: '1 YEARS'
    },
    {
        label: '> 2 years',
        value: '2 YEARS'
    },
    {
        label: '> 4 years',
        value: '4 YEARS'
    }
];
const TenureSelect = ({ selected, comparator, dataTestId, onChange }) => {
    const isCustomRange = isArray(selected) ||
        comparator !== 'greaterThan' ||
        !options.map((option) => option.value).includes(selected);
    return (_jsxs("div", { "data-testid": dataTestId, className: "flex flex-row flex-wrap gap-g40", children: [options.map((option) => {
                return (_jsx(RadioPill, { label: option.label, selected: (selected === option.value && comparator === 'greaterThan') ||
                        (selected === undefined && option.value === undefined) // for the Any pill.
                    , onClick: () => onChange('greaterThan', option.value) }, option.value));
            }), _jsx(TenureCustomRange, { comparator: comparator, value: isCustomRange ? selected : null, onChange: onChange })] }));
};
export default TenureSelect;
