import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown, SearchIcon } from 'assets/harmonic-icons';
import { DEFAULT_SEMANTIC_INPUTS, SEMANTIC_OPTIONS } from 'components/Dashboard/Semantic/types';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOutsideClick } from 'utils/hooks';
const SemanticInput = ({ value = DEFAULT_SEMANTIC_INPUTS, onChange }) => {
    const selectedSemantic = SEMANTIC_OPTIONS.find((option) => option.value === value?.similarity) ||
        SEMANTIC_OPTIONS[1];
    const [showSelect, setShowSelect] = useState(false);
    const outsideClickRef = useOutsideClick(() => setShowSelect(false));
    return (_jsxs("div", { className: "flex flex-col gap-g20 w-full", children: [_jsxs("div", { className: "flex flex-row gap-g40", "data-testid": "semantic-input", children: [_jsx(TextInput, { value: value.query, onChange: (e) => {
                            onChange({ ...value, query: e.target.value });
                        }, placeholder: "Describe the company's focus, e.g. AI startups in fintech focused on...", leadingIcon: SearchIcon, borderOverrideClasses: "rounded-md", dataTestId: "semantic-text-input", "data-testid": "semantic-text-input" }), _jsxs("div", { className: "min-w-34 relative", children: [_jsx(Button, { label: selectedSemantic.label, onClick: () => setShowSelect(!showSelect), emphasis: "high", size: "default", leadingIcon: () => (_jsx("span", { className: "icon icon-outlined icon-20", children: "tune" })), trailingIcon: ChevronDown, fullWidth: true }), showSelect && (_jsx("div", { ref: outsideClickRef, className: "absolute w-full z-100", children: _jsx(Dropdown, { dataTestId: "semantic-options-dropdown", children: SEMANTIC_OPTIONS.map((option) => (_jsx(ListItem, { testId: `semantic-option-${option.label}`, value: option.value, variant: ListVariant.default, label: option.label, onClick: () => {
                                            onChange({ ...value, similarity: option.value });
                                            setShowSelect(false);
                                        } }, option.value))) }) }))] })] }), _jsxs("p", { className: "typography-label-small-default text-content-weak", "data-testid": "semantic-input-helper-text", children: ["Experiment with the", ' ', _jsx(Link, { className: "text-blue-500 font-extrabold typography-label-small-strong", to: '/dashboard/semantic', children: "search agent" }), ' ', "for more powerful natural language search"] })] }));
};
export default SemanticInput;
