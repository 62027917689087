import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
/**
 * For banners with a left graphic. Currently used for ConnectionsBanner and AffinityBanner
 */
const LeftImageBanner = (props) => {
    const { leftGraphic, children } = props;
    return (_jsxs("div", { className: "rounded-br40 flex border border-border border-solid overflow-clip", children: [leftGraphic && (_jsx("div", { className: "w-[220px] overflow-clip border-r border-solid border-border flex-shrink-0", children: leftGraphic })), _jsx("div", { className: "flex-grow py-p50 px-p60 bg-surface-nested", children: children })] }));
};
export default LeftImageBanner;
