import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import useTableStore from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import { getSelectedCompanyNamedViewIndex } from 'utils/namedViews';
import { getModifiedCompanySortValueAfterValidationCheck } from 'utils/sort';
import { camelize } from 'utils/utilities';
import { FilterArrayComparator, INITIAL_SEARCH_MODEL } from '../interfaces/SearchModel/Search';
import useFetchSingleWatchlist from './useFetchSingleWatchlist';
import useWatchlistSearchVariables from './useWatchlistSearchVariables';
export const useWatchlistCompanySearchVariables = () => {
    const companyListLocalSearchTerm = useTableStore((state) => state.companyListLocalSearchTerm);
    const companyListSelectedNamedView = useStore((state) => state.companyListSelectedNamedView);
    const { companyWatchlist } = useFetchSingleWatchlist({
        fetchPolicy: 'cache-only'
    });
    const selectedNamedViewIndex = getSelectedCompanyNamedViewIndex(companyWatchlist, companyListSelectedNamedView);
    const currentNamedViewSearchQuery = companyWatchlist?.namedViews?.[selectedNamedViewIndex]?.searchQuery;
    const formattedCurrentNamedViewSearchQuery = useMemo(() => {
        let currentSearchQuery = cloneDeep(camelize(INITIAL_SEARCH_MODEL));
        if (currentNamedViewSearchQuery) {
            currentSearchQuery = currentNamedViewSearchQuery;
        }
        if (companyListLocalSearchTerm &&
            companyListLocalSearchTerm?.[companyWatchlist?.entityUrn]) {
            return {
                ...currentSearchQuery,
                filterGroup: {
                    ...currentSearchQuery.filterGroup,
                    filters: [
                        ...currentSearchQuery.filterGroup.filters,
                        {
                            field: 'company_name',
                            filterValue: [
                                companyListLocalSearchTerm?.[companyWatchlist?.entityUrn]
                            ],
                            comparator: FilterArrayComparator.SUBSTRING_MATCH
                        }
                    ]
                }
            };
        }
        return currentSearchQuery;
    }, [
        currentNamedViewSearchQuery,
        companyListLocalSearchTerm,
        companyWatchlist?.entityUrn
    ]);
    const { watchlistSearchVariables } = useWatchlistSearchVariables(formattedCurrentNamedViewSearchQuery);
    const formattedSortValue = getModifiedCompanySortValueAfterValidationCheck(currentNamedViewSearchQuery?.sort?.[0]?.sortField, currentNamedViewSearchQuery?.sort?.[0]?.descending, currentNamedViewSearchQuery?.sort?.[0]?.sortContextValues, companyWatchlist?.customFields);
    return {
        ...watchlistSearchVariables,
        searchQuery: {
            ...watchlistSearchVariables.searchQuery,
            sort: [formattedSortValue]
        },
        // We pass sortField and sortDescending separately in the route.
        // After named views, named views sort should be given priority
        // TODO: update backend after it's rolled out
        sortField: formattedSortValue?.sortField,
        sortDescending: formattedSortValue?.descending,
        sortCustomFieldUrn: formattedSortValue?.sortContextValues?.customFieldUrn
    };
};
