import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { useFieldSpec } from 'hooks/useFieldSpec';
import useFlags from 'hooks/useFlags';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import { getAcceleratorFilters } from 'queries/getAcceleratorFilters';
import React, { useEffect, useState } from 'react';
import { convertToBackendData, mapInitialStateToSelections } from 'utils/accelerators';
import { SPLITS } from 'utils/constants';
import { getUpdatedQuery } from 'utils/searchV2/searchV2';
import Checkboxes from '../Common/Checkboxes';
import { SubSectionHeader } from '../Common/Typography';
import AcceleratorItem from './AcceleratorItem';
// TODO EVAL-370: Cleanup after migrating saved searches using search_v2_company_list_and_more_accelerators
const CompanyAcceleratorOptions = [
    {
        label: 'YC S24',
        value: 'S24'
    },
    {
        label: 'YC W24',
        value: 'W24'
    },
    {
        label: 'YC S23',
        value: 'S23'
    },
    {
        label: 'YC W23',
        value: 'W23'
    },
    {
        label: 'YC S22',
        value: 'S22'
    },
    {
        label: 'YC W22',
        value: 'W22'
    },
    {
        label: 'YC S21',
        value: 'S21'
    },
    {
        label: 'YC W21',
        value: 'W21'
    }
];
const AcceleratorV2Options = [
    {
        label: 'a16z CSX Fall 2024',
        value: 'urn:harmonic:accelerator_cohort:34'
    },
    {
        label: 'YC F24',
        value: 'urn:harmonic:accelerator_cohort:11'
    },
    {
        label: 'YC S24',
        value: 'urn:harmonic:accelerator_cohort:10'
    },
    {
        label: 'YC W24',
        value: 'urn:harmonic:accelerator_cohort:9'
    },
    {
        label: 'YC S23',
        value: 'urn:harmonic:accelerator_cohort:8'
    },
    {
        label: 'YC W23',
        value: 'urn:harmonic:accelerator_cohort:7'
    },
    {
        label: 'YC S22',
        value: 'urn:harmonic:accelerator_cohort:6'
    },
    {
        label: 'YC W22',
        value: 'urn:harmonic:accelerator_cohort:5'
    },
    {
        label: 'YC S21',
        value: 'urn:harmonic:accelerator_cohort:4'
    },
    {
        label: 'YC W21',
        value: 'urn:harmonic:accelerator_cohort:3'
    },
    {
        label: 'YC S20',
        value: 'urn:harmonic:accelerator_cohort:2'
    },
    {
        label: 'YC W20',
        value: 'urn:harmonic:accelerator_cohort:1'
    },
    {
        label: 'Techstars 2024',
        value: 'urn:harmonic:accelerator_cohort:30'
    },
    {
        label: 'Techstars 2023',
        value: 'urn:harmonic:accelerator_cohort:29'
    },
    {
        label: 'Techstars 2022',
        value: 'urn:harmonic:accelerator_cohort:28'
    },
    {
        label: 'Techstars 2021',
        value: 'urn:harmonic:accelerator_cohort:27'
    },
    {
        label: 'Techstars 2020',
        value: 'urn:harmonic:accelerator_cohort:28'
    },
    {
        label: 'Techstars 2019',
        value: 'urn:harmonic:accelerator_cohort:25'
    },
    {
        label: 'Techstars 2018',
        value: 'urn:harmonic:accelerator_cohort:24'
    },
    {
        label: 'Techstars 2017',
        value: 'urn:harmonic:accelerator_cohort:23'
    },
    {
        label: 'Techstars 2016',
        value: 'urn:harmonic:accelerator_cohort:22'
    },
    {
        label: 'Techstars 2015',
        value: 'urn:harmonic:accelerator_cohort:21'
    }
];
const FundingSectionAccelerator = ({ controlledSearchState, searchQuery, onUpdateSearchQuery, disabled }) => {
    const { data: acceleratorData } = useQuery(getAcceleratorFilters);
    // Initialize selected cohorts from controlledSearchState
    const [selectedCohortUrns, setSelectedCohortUrns] = useState(null);
    // Set initial state when data is available
    useEffect(() => {
        if (!acceleratorData)
            return;
        const initialState = controlledSearchState[FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR];
        if (initialState) {
            const initialCohorts = mapInitialStateToSelections(acceleratorData, initialState);
            setSelectedCohortUrns(initialCohorts);
        }
        else {
            setSelectedCohortUrns([]);
        }
    }, [acceleratorData]);
    // Reset selections when search state is cleared
    useEffect(() => {
        if (selectedCohortUrns &&
            controlledSearchState &&
            !Object.keys(controlledSearchState).includes(FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR) &&
            selectedCohortUrns.length > 0) {
            setSelectedCohortUrns([]);
        }
    }, [controlledSearchState]);
    // Update search query whenever selections change
    useEffect(() => {
        if (!acceleratorData || !selectedCohortUrns)
            return;
        const backendSelections = convertToBackendData(selectedCohortUrns, acceleratorData);
        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR, backendSelections));
    }, [selectedCohortUrns, onUpdateSearchQuery]);
    const getAllCohortUrns = (item) => {
        switch (item.type) {
            case 'group':
                return item.accelerators.flatMap((acc) => acc.cohorts.map((cohort) => cohort.entityUrn));
            case 'accelerator':
                return item.cohorts.map((cohort) => cohort.entityUrn);
            case 'cohort':
                return [item.urn];
            default:
                return [];
        }
    };
    const isItemChecked = (cohortUrns) => {
        if (!selectedCohortUrns)
            return false;
        return cohortUrns.every((urn) => selectedCohortUrns.includes(urn));
    };
    const handleItemChange = (cohortUrns, checked) => {
        setSelectedCohortUrns((prev) => {
            if (!prev)
                return null;
            if (checked) {
                // Remove all cohorts
                return prev.filter((urn) => !cohortUrns.includes(urn));
            }
            else {
                // Add all cohorts
                return [...new Set([...prev, ...cohortUrns])];
            }
        });
    };
    const { enabled: enableCompanySearchAcceleratorsV3 } = useFlags(SPLITS.enableCompanySearchAcceleratorsV3);
    // TODO EVAL-370: Cleanup after migrating saved searches using search_v2_company_list_and_more_accelerators
    const { companiesFieldSpec } = useFieldSpec();
    const acceleratorsList = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS]?.accelerators;
    const acceleratorsV2List = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR]
        ?.accelerator_cohorts;
    const ycBatchList = companiesFieldSpec?.find((option) => option.unique_name === 'company_yc_batch_tags')?.value_restricted_to ?? [];
    const formattedAcceleratorsOptions = ycBatchList
        .filter((value) => value)
        .reverse()
        .map((value) => ({
        label: `YC ${value}`,
        value: value
    }));
    const hasAcceleratorsV1ValueSelected = !!controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS];
    if (hasAcceleratorsV1ValueSelected) {
        return (_jsxs("div", { "data-testid": "accelerators-section", children: [_jsx(SubSectionHeader, { children: "Accelerators" }), _jsx(Checkboxes, { options: formattedAcceleratorsOptions ?? CompanyAcceleratorOptions, selectedValues: acceleratorsList, onValueChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS, newValues !== undefined
                        ? {
                            accelerators: newValues
                        }
                        : undefined)), disabled: disabled })] }));
    }
    else if (enableCompanySearchAcceleratorsV3) {
        const renderAccelerator = (props) => {
            const { type } = props;
            const cohortUrns = getAllCohortUrns(props);
            // Calculate count of nested cohorts
            const getNestedCount = () => {
                switch (type) {
                    case 'group':
                        return props.accelerators.reduce((total, acc) => total + acc.cohorts.length, 0);
                    case 'accelerator':
                        return props.cohorts.length;
                    default:
                        return undefined;
                }
            };
            const childCohorts = type === 'group'
                ? props.accelerators.flatMap((acc) => acc.cohorts)
                : props.cohorts;
            const isIndeterminate = (type === 'group' || type === 'accelerator') &&
                (() => {
                    const selectedCount = childCohorts.filter((cohort) => selectedCohortUrns?.includes(cohort.entityUrn) ?? false).length;
                    return selectedCount > 0 && selectedCount < childCohorts.length;
                })();
            const checked = isItemChecked(cohortUrns) || isIndeterminate;
            return (_jsxs(AcceleratorItem, { name: props.name, count: getNestedCount(), checked: checked, indeterminate: isIndeterminate, onChange: () => handleItemChange(cohortUrns, checked), isParent: props.type === 'group' || props.type === 'accelerator', children: [props.type === 'group' &&
                        props.accelerators.map((acc) => renderAccelerator({
                            type: 'accelerator',
                            name: acc.text,
                            urn: acc.entityUrn,
                            cohorts: acc.cohorts,
                            group: props,
                            selections: {
                                accelerator_cohorts: selectedCohortUrns ?? []
                            },
                            setSelections: (newSelections) => setSelectedCohortUrns(newSelections.accelerator_cohorts)
                        })), props.type === 'accelerator' &&
                        props.cohorts.map((cohort) => renderAccelerator({
                            type: 'cohort',
                            name: cohort.text,
                            urn: cohort.entityUrn,
                            accelerator: props,
                            group: props.group,
                            selections: {
                                accelerator_cohorts: selectedCohortUrns ?? []
                            },
                            setSelections: (newSelections) => setSelectedCohortUrns(newSelections.accelerator_cohorts)
                        }))] }, props.urn));
        };
        if (!acceleratorData)
            return null;
        return (_jsxs("div", { "data-testid": "accelerators-v3-section", children: [_jsx(SubSectionHeader, { children: "Accelerators" }), acceleratorData.getAcceleratorFilters.accelerators?.map((acc) => renderAccelerator({
                    type: 'accelerator',
                    name: acc.text,
                    urn: acc.entityUrn,
                    cohorts: acc.cohorts,
                    selections: {
                        accelerator_cohorts: selectedCohortUrns ?? []
                    },
                    setSelections: (newSelections) => setSelectedCohortUrns(newSelections.accelerator_cohorts)
                })), acceleratorData.getAcceleratorFilters.acceleratorGroups?.map((group) => renderAccelerator({
                    type: 'group',
                    name: group.text,
                    urn: group.entityUrn,
                    accelerators: group.accelerators,
                    selections: {
                        accelerator_cohorts: selectedCohortUrns ?? []
                    },
                    setSelections: (newSelections) => setSelectedCohortUrns(newSelections.accelerator_cohorts)
                }))] }));
    }
    else {
        return (_jsxs("div", { "data-testid": "accelerators-v2-section", children: [_jsx(SubSectionHeader, { children: "Accelerators" }), _jsx(Checkboxes, { options: AcceleratorV2Options, selectedValues: acceleratorsV2List, onValueChange: (newValues) => onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR, newValues !== undefined
                        ? {
                            accelerator_groups: [],
                            accelerators: [],
                            accelerator_cohorts: newValues
                        }
                        : undefined)), disabled: disabled })] }));
    }
};
export default FundingSectionAccelerator;
