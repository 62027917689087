import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { TYPEAHEAD_INVESTOR_COMPANIES_QUERY } from 'queries/typeahead';
import { useState } from 'react';
import { getTagColorBySelectMode, getTagIconBySelectMode } from './utils';
const InvestorMultiselect = ({ onChange, selected, placeholder, initialFocus = false, freeSolo, mode = null, type = null, dataTestId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data: investorsData, loading } = useQuery(TYPEAHEAD_INVESTOR_COMPANIES_QUERY, {
        variables: {
            query: searchTerm,
            type: type
        }
    });
    const onClickListItem = (investor) => {
        const selectedOption = selected.find((s) => s === investor?.details?.name);
        if (selectedOption) {
            onChange(selected.filter((s) => s !== investor?.details?.name));
        }
        else {
            onChange([...selected, investor?.details?.name ?? '']);
        }
        setSearchTerm('');
    };
    const investors = investorsData?.getInvestorsWithTypeahead?.investors || [];
    // HACK: Deduplicate investors by name
    const uniqueInvestors = Array.from(new Map(investors.map((investor) => [investor?.details?.name, investor])).values());
    return (_jsxs(Select, { multiple: true, filterable: true, freeSolo: freeSolo, onAdd: (value) => {
            onChange([...selected, value]);
            setSearchTerm('');
        }, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selected, placeholder: placeholder, onRemove: (key) => onChange(selected.filter((s) => s !== key)), initialFocus: initialFocus, hideDropdown: !loading && investors.length === 0, getTagColorFromValue: () => getTagColorBySelectMode(mode), getTagIconFromValue: () => getTagIconBySelectMode(mode), dataTestId: dataTestId, children: [loading && (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(HarmonicLoader, { showText: false }) })), !loading &&
                uniqueInvestors.map((investor) => {
                    if (!investor)
                        return null;
                    return (_jsx(SelectListItem, { value: investor.details?.name ?? '', label: investor.details?.name ?? '', logo: investor.details?.__typename === 'Person' ? 'face' : 'logo', src: investor.details?.logoUrl ?? '', onClick: () => onClickListItem(investor), variant: ListVariant.entity }, investor.details?.id));
                })] }));
};
export default InvestorMultiselect;
