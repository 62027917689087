import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ReactComponent as AddIcon } from 'assets/plus.svg';
import classNames from 'classnames';
import React from 'react';
import { SearchFilterContext } from '../SearchFilterGroupBase';
const OperationsInput = (props) => {
    const { index, operations } = props;
    const searchFilterContext = React.useContext(SearchFilterContext);
    if (operations?.length === 0)
        return null;
    return (_jsx(_Fragment, { children: operations && (_jsx("div", { className: "flex flex-wrap items-center", children: operations?.map((operation) => {
                return (_jsxs("button", { className: classNames('flex items-center bg-blue-lightest border border-solid px-2 py-1 gap-0.5', 'text-ink-lighter hover:bg-blue-lighter border-blue rounded text-xs cursor-pointer'), onClick: () => {
                        searchFilterContext.searchFilterMethods.applyFilterOperation(index, operation);
                    }, disabled: props.disabled, children: [_jsx(AddIcon, {}), operation?.label] }, operation?.label));
            }) })) }));
};
export default OperationsInput;
