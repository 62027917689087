import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AffinityListsGraphic from 'assets/affinity-lists-graphic';
import Button from 'harmonic-components/Button/Button';
import LeftImageBanner from 'harmonic-components/LeftImageBanner/LeftImageBanner';
import { useNavigate } from 'react-router-dom';
export const AffinityBanner = (props) => {
    const navigate = useNavigate();
    return (_jsxs(LeftImageBanner, { leftGraphic: _jsx(AffinityListsGraphic, {}), children: [_jsx("h3", { className: "typography-label-large-strong text-content-strong", children: "You can now filter across your Affinity lists" }), _jsx("p", { className: "typography-paragraph-default-default text-content-default", children: "Integrate with Affinity to include or exclude companies from your team's Affinity lists in your search results." }), _jsxs("div", { className: "flex gap-g20 mt-p40", children: [_jsx(Button, { label: "Set up integration", emphasis: "medium", onClick: () => {
                            navigate('/settings?t=integrations');
                            props.onDismiss();
                        } }), _jsx(Button, { label: "Dismiss", emphasis: "low", onClick: props.onDismiss })] })] }));
};
