import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import GeneralConnectionsSubSection from './GeneralConnectionsSubSection';
import GeneralHighlightsSubSection from './GeneralHighlightsSubSection';
import GeneralLinkedinHeadlineSubSection from './GeneralLinkedinHeadlineSubSection';
import GeneralListsSubSection from './GeneralListsSubSection';
import GeneralLocationSubSection from './GeneralLocationSubSection';
const GeneralSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const setLinkedInBannerDismissedState = (dismissed) => {
        localStorage.setItem('LinkedInBannerDismissedState', JSON.stringify(dismissed));
    };
    const getLinkedInBannerDismissedState = () => {
        const linkedinBannerDismissed = localStorage.getItem('LinkedInBannerDismissedState');
        return linkedinBannerDismissed
            ? JSON.parse(linkedinBannerDismissed)
            : false;
    };
    const [linkedInBannerDismissed, setLinkedInBannerDismissed] = React.useState(getLinkedInBannerDismissedState);
    const onDismissLinkedinBanner = () => {
        setLinkedInBannerDismissed(true);
        setLinkedInBannerDismissedState(true);
    };
    return (_jsxs("div", { className: "w-full flex flex-col items-start gap-g90 self-stretch", children: [_jsx(GeneralHighlightsSubSection, { searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled }), _jsx(GeneralLocationSubSection, { searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled }), _jsx(GeneralConnectionsSubSection, { searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled }), _jsx(GeneralLinkedinHeadlineSubSection, { searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled }), _jsx(GeneralListsSubSection, { searchQuery: searchQuery, onUpdateSearchQuery: onUpdateSearchQuery, disabled: disabled })] }));
};
export default GeneralSection;
