import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import React from 'react';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import MultiselectWithMode from '../Common/MultiselectWithMode';
import { SubSectionHeader } from '../Common/Typography';
const GeneralLinkedinHeadlineSubSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const linkedinHeadlines = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES];
    return (_jsxs("div", { "data-testid": "Connections-section", className: "w-full", children: [_jsxs("div", { className: "flex flex-col justify-center items-start pb-p50", children: [_jsx(SubSectionHeader, { children: "Keywords" }), _jsx("p", { className: "text-content-default typography-label-default-default", children: "Filter to terms used in LinkedIn profile headlines, job titles, and job descriptions." })] }), _jsxs("div", { className: "flex flex-col gap-g40", children: [_jsx(MultiselectWithMode, { dataTestId: "Linkedin-Headlines-Any-Of-Input", label: "Contains any of", selected: linkedinHeadlines?.any_headlines || [], onChange: (newValues) => {
                            const empty = !newValues?.length &&
                                !linkedinHeadlines?.not_any_headlines.length &&
                                !linkedinHeadlines?.all_headlines.length;
                            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES, !empty
                                ? {
                                    any_headlines: newValues ?? [],
                                    all_headlines: linkedinHeadlines?.all_headlines || [],
                                    not_any_headlines: linkedinHeadlines?.not_any_headlines || []
                                }
                                : undefined));
                        }, placeholder: "Add keywords", mode: "include" }), _jsx(MultiselectWithMode, { dataTestId: "Linkedin-Headlines-All-Of-Input", label: "Contains all of", selected: linkedinHeadlines?.all_headlines || [], onChange: (newValues) => {
                            const empty = !newValues?.length &&
                                !linkedinHeadlines?.not_any_headlines.length &&
                                !linkedinHeadlines?.any_headlines.length;
                            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES, !empty
                                ? {
                                    all_headlines: newValues ?? [],
                                    any_headlines: linkedinHeadlines?.any_headlines || [],
                                    not_any_headlines: linkedinHeadlines?.not_any_headlines || []
                                }
                                : undefined));
                        }, placeholder: "Add keywords", mode: "select" }), _jsx(MultiselectWithMode, { dataTestId: "Linkedin-Headlines-None-Of-Input", label: "Contains none of", selected: linkedinHeadlines?.not_any_headlines || [], onChange: (newValues) => {
                            const empty = !newValues?.length &&
                                !linkedinHeadlines?.all_headlines.length &&
                                !linkedinHeadlines?.any_headlines.length;
                            onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES, !empty
                                ? {
                                    not_any_headlines: newValues ?? [],
                                    all_headlines: linkedinHeadlines?.all_headlines || [],
                                    any_headlines: linkedinHeadlines?.any_headlines || []
                                }
                                : undefined));
                        }, placeholder: "Add keywords", mode: "exclude" })] })] }));
};
export default GeneralLinkedinHeadlineSubSection;
