import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import GreyLinearProgress from 'components/common/GreyLinearProgress';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { compact, uniq } from 'lodash';
import { GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS } from 'queries/getAllTeamMembersWithIntegratedEmails';
import { useMemo, useState } from 'react';
import { useAuthState } from '../../../hooks/useAppState';
const IntegratedEmailUserMultiselect = ({ onChange, selected, labelPrefix, initialFocus }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const authState = useAuthState();
    const customerUrn = authState.userMetadata?.customer_urn ?? '';
    const { data: usersData, loading } = useQuery(GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS, {
        variables: { customer_urn: customerUrn }
    });
    const onClickListItem = (email) => {
        onChange(uniq([...selected, email]));
        setSearchTerm('');
    };
    const onRemoveItem = (email) => {
        const updatedSelectedOptions = selected.filter((option) => option !== email);
        onChange(updatedSelectedOptions);
    };
    const getDisplayNameFromEmail = (email) => {
        const user = usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer?.find((user) => user?.integratedEmails?.some((integratedEmail) => integratedEmail === email));
        return user?.name ?? email;
    };
    const filteredEmailAddresses = useMemo(() => compact(usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer
        ?.flatMap((user) => {
        if (!user)
            return null;
        return user.integratedEmails?.filter((email) => email?.toLowerCase().includes(searchTerm.toLowerCase()));
    })
        .filter((email) => email && !selected.includes(email))) ?? [], [usersData, searchTerm, selected]);
    return (_jsx("div", { className: "min-w-56 w-full", children: _jsxs(Select, { dataTestId: "integrated-email-user-multiselect", multiple: true, filterable: true, fullWidth: true, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selected, labelPrefix: labelPrefix, placeholder: "Search for people on your team", initialFocus: initialFocus, onRemove: (company) => onRemoveItem(company), getLabelFromValue: (email) => getDisplayNameFromEmail(email ?? ''), hideDropdown: !loading &&
                (Boolean(usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer) === false ||
                    usersData?.getAllTeamMembersWithIntegratedEmailsByCustomer
                        ?.length === 0), children: [loading && (_jsx("div", { className: "h-p20 w-full", children: _jsx(GreyLinearProgress, {}) })), !loading &&
                    filteredEmailAddresses.map((email) => (_jsx(SelectListItem, { value: email ?? '', label: getDisplayNameFromEmail(email), onClick: () => email && onClickListItem(email), variant: ListVariant.default }, email)))] }) }));
};
export default IntegratedEmailUserMultiselect;
