import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export const SectionDivider = () => {
    return _jsx("div", { className: "border-solid border-b border-border w-full" });
};
export const SmallSectionHeader = ({ children }) => {
    return (_jsx("p", { className: classNames('typography-title-small pb-p70 text-content-title'), children: children }));
};
export const PeopleSmallSectionHeader = ({ children }) => {
    return (_jsx("p", { className: classNames('typography-title-small mb-g80 text-content-title'), children: children }));
};
export const SubSectionHeader = ({ children }) => {
    return (_jsx("p", { className: classNames('typography-title-extraSmall text-content-title pb-p40 mb-p40 border-solid border-b border-border w-full'), children: children }));
};
export const PeopleSubSectionHeader = ({ children }) => {
    return (_jsx("p", { className: classNames('typography-title-extraSmall text-content-title pb-p40 mb-g50 border-solid border-b border-border w-full'), children: children }));
};
