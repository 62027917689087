import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import TextField from 'components/common/lib/TextField';
import React, { useCallback } from 'react';
import SearchFilterDropdown from '../SearchFilterDropdown';
import { isCustomFieldUrn } from '../utils';
const FieldInput = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentFieldSpec = props.fieldSpecs.find((fieldSpec) => {
        if (isCustomFieldUrn(props?.field_urn)) {
            return fieldSpec.urn == props.field_urn;
        }
        return fieldSpec.unique_name == props.field;
    });
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const onUpdateComparatorName = (newValue, newUrn) => {
        handleClose();
        props.onUpdateComparatorName(newValue, newUrn);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'search-field-input-popover' : undefined;
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { dataTestId: "SingleFilter-FieldInput-TextField", size: "small", readOnly: true, value: currentFieldSpec?.display_name || '', "aria-describedby": id, showDropdownIcon: true, disabled: props.disabled, onClick: handleClick, cursorPointer: true, autoComplete: "off" }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }, elevation: 0, className: "mt-p20", classes: {
                    paper: classNames('bg-surface-default shadow-static-elevation-floating transition-all', 'rounded-br40 border border-border border-solid', 'w-fit flex flex-col')
                }, children: _jsx(SearchFilterDropdown, { entityType: props.entityType, fields: props.fieldSpecs, onUpdateComparatorName: onUpdateComparatorName, selectedField: props.field }) })] }));
};
export default FieldInput;
