import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Popover } from '@headlessui/react';
import { FundingTotalIcon, LastFundingDateIcon, LeadInvestorIconGrey } from 'assets/harmonic-icons';
import { CompanySingleRbrV2 } from 'components/Dashboard/Company/CompanyFinancing/CompanySingleRbrV2';
import { FundingMetricCard } from 'components/common/CompanyCard/FundingMetricCard';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import { InvestorPill } from 'components/common/Investors/InvestorPill';
import ReportDataIssue from 'components/common/ReportDataIssue';
import dayjs from 'dayjs';
import useFlags from 'hooks/useFlags';
import { ReportSourceSection, ReportSourceView, getCommonlyReportedIssuesBySection } from 'interfaces/UserReportedDataIssue';
import { isNil } from 'lodash';
import getCompanyCapData from 'mocks/mock-data/get_company_cap_table';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { useEffect, useMemo, useState } from 'react';
import { getCapTableForCompanyId } from 'utils/api';
import { SPLITS, UNKNOWN_VALUE } from 'utils/constants';
import { getFormattedFundingTotal } from 'utils/funding';
import CapTable from './CapTable';
import CapTableWaitlistForm from './CapTableWaitlistForm';
import CompanyFinancingContentLoader from './CompanyFinancingContentLoader';
import HarmonicNoneIcon from 'assets/harmonic-icons/harmonic-none';
const MAX_LEAD_INVESTORS = 5;
const getFundingRoundId = (fr) => `${fr.fundingRoundType ?? 'unknown'}_${fr.announcementDate ?? 'unknown'}_${fr.fundingAmount ?? 'unknown'}_${fr.sourceUrl ?? 'unknown'}`;
const CompanyFinancing = ({ companyId }) => {
    const { data } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const { enabled: showCapTable } = useFlags(SPLITS.showCapTable);
    const { enabled: showCapTableMock } = useFlags(SPLITS.showCapTableMock);
    const { enabled: showCapTableWaitlist } = useFlags(SPLITS.showCapTableWaitlist);
    const loading = isNil(data);
    const companyFunding = data?.getCompanyById?.funding;
    const fundingTotal = getFormattedFundingTotal(companyFunding?.fundingTotal);
    let lastFundingDate = undefined;
    companyFunding?.fundingRounds?.forEach((fr) => {
        if (!lastFundingDate || fr?.announcementDate > lastFundingDate) {
            lastFundingDate = fr?.announcementDate;
        }
    });
    const investorMap = useMemo(() => {
        const investorMap = {};
        companyFunding?.investors?.forEach((i) => {
            if (!i?.name || !i?.logoUrl)
                return;
            const entityUrn = (i.__typename === 'Company' ? i.companyUrn : i.personUrn);
            investorMap[entityUrn] = {
                name: i.name,
                logoUrl: i.logoUrl,
                entityUrn
            };
        });
        companyFunding?.fundingRounds?.forEach((fr) => {
            if (!fr?.investors)
                return;
            fr.investors.forEach((i) => {
                if (!i?.investor || !i.entityUrn)
                    return;
                if (!investorMap[i.entityUrn]) {
                    if (!i.investorName)
                        return;
                    investorMap[i.entityUrn] = {
                        name: i.investorName,
                        entityUrn: i.entityUrn
                    };
                }
                investorMap[i.entityUrn] = {
                    ...investorMap[i.entityUrn],
                    investorUrn: i.investorUrn
                };
            });
        });
        return investorMap;
    }, [companyFunding]);
    const fundingRoundToInvestorsMap = useMemo(() => {
        return (companyFunding?.fundingRounds?.reduce((acc, fr) => {
            if (!fr?.investors)
                return acc;
            const frId = getFundingRoundId(fr);
            const investorList = fr.investors
                .filter((i) => i?.investor)
                .map((i) => {
                const entityUrn = i.entityUrn;
                return {
                    id: frId,
                    roundType: fr.fundingRoundType,
                    isLead: i.isLead,
                    ...investorMap[entityUrn]
                };
            });
            investorList.sort((a, b) => {
                // First sort by isLead (true comes before false)
                if (a.isLead !== b.isLead) {
                    return b.isLead ? 1 : -1; // true values first
                }
                // Then sort by name
                const investorA = investorMap[a.entityUrn];
                const investorB = investorMap[b.entityUrn];
                return investorA.name.localeCompare(investorB.name);
            });
            acc[frId] = investorList;
            return acc;
        }, {}) ?? {});
    }, [companyFunding?.fundingRounds, investorMap]);
    const investorToFundingRoundTypesMap = useMemo(() => {
        return (companyFunding?.fundingRounds?.reduce((acc, fr) => {
            if (!fr?.investors || !fr.fundingRoundType)
                return acc;
            fr.investors.forEach((i) => {
                if (!i?.entityUrn)
                    return;
                if (!acc[i.entityUrn])
                    acc[i.entityUrn] = [];
                acc[i.entityUrn].push({
                    fundingRoundType: fr.fundingRoundType,
                    isLead: i.isLead ?? false
                });
            });
            return acc;
        }, {}) ??
            {});
    }, [companyFunding?.fundingRounds]);
    const companyName = data?.getCompanyById?.name;
    const investors = companyFunding?.investors || [];
    const filteredInvestors = investors?.filter((investor) => investor?.name !== '');
    const fundingRounds = companyFunding?.fundingRounds ?? [];
    const rbrExist = fundingRounds.length > 0;
    const noInvestorsExist = fundingRounds?.length === 0 && filteredInvestors?.length === 0;
    const [capTableData, setCapTableData] = useState();
    const [containerRef, setContainerRef] = useState(null);
    const [visibleCount, setVisibleCount] = useState(MAX_LEAD_INVESTORS);
    useEffect(() => {
        if (!containerRef)
            return;
        const calculateVisiblePills = () => {
            const containerWidth = containerRef.offsetWidth;
            const pillWidth = 200; // Approximate width of each pill including gap
            const plusButtonWidth = 27; // Width of the +N button including gap
            const maxPillsInRow = Math.floor((containerWidth - plusButtonWidth) / pillWidth);
            setVisibleCount(maxPillsInRow);
        };
        calculateVisiblePills();
        window.addEventListener('resize', calculateVisiblePills);
        return () => window.removeEventListener('resize', calculateVisiblePills);
    }, [containerRef]);
    useEffect(() => {
        if (!showCapTable)
            return;
        getCapTableForCompanyId(companyId).then((resp) => {
            setCapTableData(resp);
        });
    }, [companyId, showCapTable]);
    const renderLeadInvestorPills = () => {
        if (!fundingRoundToInvestorsMap ||
            Object.keys(fundingRoundToInvestorsMap).length === 0)
            return UNKNOWN_VALUE;
        const leadInvestorSet = new Set();
        Object.keys(fundingRoundToInvestorsMap).forEach((frId) => {
            const investors = fundingRoundToInvestorsMap[frId];
            investors.forEach((i) => {
                if (!i.entityUrn)
                    return;
                if (i.isLead)
                    leadInvestorSet.add(i.entityUrn);
            });
        });
        const leadInvestorList = Array.from(leadInvestorSet).sort((a, b) => {
            const investorA = investorMap[a];
            const investorB = investorMap[b];
            return investorA.name.localeCompare(investorB.name);
        });
        return (_jsx("div", { ref: setContainerRef, className: "flex flex-nowrap gap-1 items-center w-full", children: leadInvestorList.map((investor, index) => {
                const investorData = investorMap[investor];
                if (index === visibleCount && leadInvestorSet.size > visibleCount) {
                    const remainingInvestors = leadInvestorList.slice(visibleCount);
                    return (_jsxs(Popover, { className: "relative", children: [_jsx(Popover.Button, { className: "w-[34px] h-[24px] bg-[#E8EAEE] p-p20 rounded flex items-center justify-center text-sm hover:bg-[#D8DADD] transition-colors", children: `+${leadInvestorSet.size - visibleCount}` }), _jsx(Popover.Panel, { className: "absolute left-0 z-10 mt-2 w-content rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5", children: _jsx("div", { className: "flex flex-col gap-2 p-1", children: remainingInvestors.map((investorUrn) => {
                                        const investor = investorMap[investorUrn];
                                        return (_jsx("div", { className: "hover:bg-gray-50 rounded-md", children: _jsx(InvestorPill, { investorName: investor.name, investorLogoUrl: investor.logoUrl ?? '', investorUrn: investor.investorUrn, isLead: true, small: false }) }, investor.entityUrn));
                                    }) }) })] }, `${leadInvestorSet.size - visibleCount}`));
                }
                if (index >= visibleCount)
                    return;
                return (_jsx(InvestorPill, { investorName: investorData.name, investorLogoUrl: investorData.logoUrl ?? '', investorUrn: investorData.investorUrn, isLead: true, small: false }, investorData.name));
            }) }));
    };
    const renderNoInvestorsFound = () => {
        return (_jsxs("div", { className: "mt-20 flex items-center justify-center flex-col gap-4", children: [_jsx(HarmonicNoneIcon, { className: "opacity-50" }), _jsx("p", { className: "text-content-default typography-label text-[#7C8598]", children: "No records of funding events" })] }));
    };
    const renderInvestorsWithoutRbr = () => {
        return (_jsxs("div", { "data-testid": "CompanyFinancing-Investors-Without-Rbr", children: [_jsx("div", { children: _jsx("p", { className: "text-clickables-buttonHover typography-title-small", children: "Investors" }) }), _jsx("div", { className: "mt-6 grid sm:grid-cols-2 gap-4", children: filteredInvestors.map((investor) => {
                        const entityUrn = (investor?.__typename === 'Company'
                            ? investor?.companyUrn
                            : investor?.personUrn);
                        const investorName = investor?.name;
                        const logoUrl = investorMap[entityUrn]?.logoUrl;
                        return (_jsx(InvestorCard, { investorName: investorName, logoUrl: logoUrl, entityUrn: entityUrn, dataTestId: "CompanyFinancing-NoRbr-Investor" }, investorName));
                    }) })] }));
    };
    const renderInvestorsWithRbr = () => {
        if (!rbrExist)
            return null;
        return (_jsx("div", { "data-testid": "CompanyFinacing-Round-By-Round", className: "flex flex-col gap-3", children: fundingRounds.map((fundingRound, index) => (_jsx(CompanySingleRbrV2, { fundingRound: fundingRound, investors: fundingRoundToInvestorsMap[getFundingRoundId(fundingRound)], investorToFundingRoundTypesMap: investorToFundingRoundTypesMap }, index))) }));
    };
    const renderCapTable = () => {
        return (_jsxs("div", { className: "py-p80", children: [_jsxs("div", { className: "flex justify-between py-p60 border-b mb-g80 border-solid border-border", children: [_jsx("div", { className: "text-content-title typography-title-small", children: "Cap table" }), _jsx(ReportDataIssue, { reportParams: {
                                companyUrn: 'urn:company:harmonic:' + companyId,
                                reportSourceView: ReportSourceView.COMPANY,
                                reportSourceSection: ReportSourceSection.CAP_TABLE
                            }, placeholderText: "This company's captable is missing an entry - Series A-1 ..." })] }), capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: capTableData })), showCapTableWaitlist && !capTableData && (_jsx(CapTableWaitlistForm, { companyId: companyId, companyName: companyName })), showCapTableMock && !capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: getCompanyCapData.capTable }))] }));
    };
    const renderSectionHeader = (title, reportDataIssue) => {
        return (_jsxs("div", { className: "flex justify-between items-center pb-p70", children: [_jsx("p", { className: "typography-title-medium text-content-title border-b border-solid border-border pb-p70 w-full", children: title }), reportDataIssue && (_jsx(ReportDataIssue, { reportParams: {
                        companyUrn: 'urn:company:harmonic:' + companyId,
                        reportSourceView: ReportSourceView.COMPANY,
                        reportSourceSection: ReportSourceSection.FINANCING
                    }, commonlyReportedIssues: getCommonlyReportedIssuesBySection(ReportSourceSection.FINANCING) }))] }));
    };
    if (loading)
        return _jsx(CompanyFinancingContentLoader, {});
    return (_jsx("div", { className: "min-h-screen", children: _jsxs("div", { className: "w-full", children: [renderSectionHeader('Funding summary', false), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FundingMetricCard, { icon: FundingTotalIcon, value: fundingTotal, label: "Funding total" }), _jsx(FundingMetricCard, { icon: LastFundingDateIcon, value: lastFundingDate
                                ? dayjs(lastFundingDate).format('MM/DD/YYYY')
                                : UNKNOWN_VALUE, label: "Latest funding date" }), _jsx(FundingMetricCard, { icon: LeadInvestorIconGrey, value: renderLeadInvestorPills(), label: "Lead investors" })] }), showCapTable && renderCapTable(), _jsx("div", { className: "pt-10" }), renderSectionHeader('Funding events', true), noInvestorsExist && renderNoInvestorsFound(), !noInvestorsExist && !rbrExist && renderInvestorsWithoutRbr(), !noInvestorsExist && rbrExist && renderInvestorsWithRbr()] }) }));
};
export default CompanyFinancing;
