import { useMutation } from '@apollo/client';
import { DELETE_PERSON_WATCHLIST } from 'queries/deletePersonWatchlist';
const useDeletePersonWatchlist = () => {
    const [deletePersonWatchlistMutation, { loading, error }] = useMutation(DELETE_PERSON_WATCHLIST);
    const deletePersonWatchlist = async (watchlist) => {
        const payload = {
            idOrUrn: watchlist
        };
        await deletePersonWatchlistMutation({
            variables: payload,
            optimisticResponse: {
                deletePeopleWatchlist: {
                    __typename: 'DeletePeopleWatchlistPayload',
                    entityUrn: {
                        urn: `urn:harmonic:people_watchlist:${watchlist}`
                    }
                }
            },
            update: (cache) => {
                cache.evict({
                    id: cache.identify({
                        __typename: 'PeopleWatchlist',
                        id: watchlist
                    })
                });
            }
        });
    };
    return {
        deletePersonWatchlist,
        loading,
        error
    };
};
export default useDeletePersonWatchlist;
