import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { isNumber } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { addCommasToNumber, returnNumberFromString } from 'utils/utilities';
import RangeSlider from './RangeSlider';
const RangeInput = ({ value, onValueChange, defaultValue, sliderStepRanges, prefix, suffix, dataTestId, disabled, displaySlider = true }) => {
    const minimumDefaultValue = defaultValue[0];
    const maximumDefaultValue = defaultValue[1];
    const [formattedMinValue, setFormattedMinValue] = useState('');
    const [formattedMaxValue, setFormattedMaxValue] = useState('');
    const currentMinValue = useMemo(() => {
        return value && isNumber(value[0]) ? value[0] : minimumDefaultValue;
    }, [value, minimumDefaultValue]);
    const currentMaxValue = useMemo(() => {
        return value && isNumber(value[1]) ? value[1] : maximumDefaultValue;
    }, [value, maximumDefaultValue]);
    const currentValue = [currentMinValue, currentMaxValue];
    useEffect(() => {
        if (isNaN(currentMinValue) ||
            currentMinValue === Number.POSITIVE_INFINITY ||
            currentMinValue === Number.NEGATIVE_INFINITY) {
            // We should only show real numbers in the input fields
            setFormattedMinValue('');
            return;
        }
        setFormattedMinValue(addCommasToNumber(currentMinValue.toString()));
    }, [currentMinValue]);
    useEffect(() => {
        if (isNaN(currentMaxValue) ||
            currentMaxValue === Number.POSITIVE_INFINITY ||
            currentMaxValue === Number.NEGATIVE_INFINITY) {
            // We should only show real numbers in the input fields
            setFormattedMaxValue('');
            return;
        }
        setFormattedMaxValue(addCommasToNumber(currentMaxValue.toString()));
    }, [currentMaxValue]);
    const updateRangeIfNotValid = () => {
        if (currentMinValue > currentMaxValue)
            onValueChange([currentMaxValue, currentMinValue]);
    };
    const triggerOnValueChange = (valueRange) => {
        const valueRangeToBeReported = valueRange[0] !== minimumDefaultValue ||
            valueRange[1] !== maximumDefaultValue
            ? valueRange
            : // When the default value is selected, we report the range as undefined so the
                // indicating that the value is back to the original state
                undefined;
        onValueChange(valueRangeToBeReported);
    };
    return (_jsxs("div", { "data-testid": dataTestId, children: [_jsxs("div", { className: classNames('flex items-center', {
                    'opacity-50': disabled
                }), children: [_jsx(TextInput, { value: formattedMinValue, label: "Min", isDisabled: disabled, placeholder: formattedMinValue === '' ? 'any' : '', onChange: (e) => {
                            const inputText = e.target.value;
                            if (
                            // The symbol '-' is an exception to the rule that we only show real numbers in the input fields
                            // We allow the user to type '-' to indicate that they want to input negative numbers
                            (inputText === '-' && sliderStepRanges[0] < 0) ||
                                // Allow the user to delete the entire input field
                                inputText === '') {
                                setFormattedMinValue(inputText);
                                return;
                            }
                            const numberString = returnNumberFromString(inputText);
                            setFormattedMinValue(numberString);
                            let newMinValue = parseInt(numberString);
                            if (isNaN(newMinValue)) {
                                newMinValue = minimumDefaultValue;
                            }
                            triggerOnValueChange([newMinValue, currentMaxValue]);
                        }, onBlur: updateRangeIfNotValid, prefix: prefix, suffix: suffix }), _jsxs("span", { className: "mx-2 text-content-muted mt-4", children: [" ", '–'] }), _jsx(TextInput, { value: formattedMaxValue, label: "Max", isDisabled: disabled, placeholder: formattedMaxValue === '' ? 'any' : '', onChange: (e) => {
                            const inputText = e.target.value;
                            if ((inputText === '-' && sliderStepRanges[0] < 0) ||
                                // Allow the user to delete the entire input field
                                inputText === '') {
                                // The symbol '-' is an exception to the rule that we only show real numbers in the input fields
                                // We allow the user to type '-' to indicate that they want to input negative numbers
                                setFormattedMaxValue(inputText);
                                return;
                            }
                            const numberString = returnNumberFromString(inputText);
                            setFormattedMaxValue(numberString);
                            let newMaxValue = parseInt(numberString);
                            if (isNaN(newMaxValue)) {
                                newMaxValue = maximumDefaultValue;
                            }
                            triggerOnValueChange([currentMinValue, newMaxValue]);
                        }, onBlur: updateRangeIfNotValid, prefix: prefix, suffix: suffix })] }), displaySlider && (_jsx("div", { className: "mt-2", children: _jsx(RangeSlider, { value: currentValue, onValueChange: triggerOnValueChange, stepRanges: sliderStepRanges, disabled: disabled, isActive: currentValue[0] !== minimumDefaultValue ||
                        currentValue[1] !== maximumDefaultValue }) }))] }));
};
export default RangeInput;
