import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import { ChevronDown } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Tag from 'harmonic-components/Tag/Tag';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useMultiSelectHiddenCount } from './useMultiSelectHiddenCount';
export const MultiSelect = React.forwardRef(({ selected, open, close, onRemove, onAdd, filterable, filterTerm, setFilterTerm, getLabelFromValue, getTagIconFromValue, getTagColorFromValue, onArrowDownPress, onArrowUpPress, onEnterPress, labelPrefix, dataTestId, freeSolo, placeholder, hideChevronDown, disabled, minHeight, clampValues, borderOverrideClasses, externalInputRef, size }, ref) => {
    const { containerRef, lastElementPosition, hiddenElementsCount } = useMultiSelectHiddenCount({
        inputOpen: open,
        runCalculation: Boolean(clampValues),
        selectedValues: selected ?? []
    });
    const inputRef = useRef(null);
    const [prevOpenState, setPrevOpenState] = React.useState(open);
    const [tagIndexFocused, setTagIndexFocused] = React.useState(null);
    const [inDeletionMode, setInDeletionMode] = React.useState(false);
    useImperativeHandle(externalInputRef, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }));
    // When in freeSolo mode, add the filter term to the selected list when the dropdown closes
    useEffect(() => {
        const didClose = !open && prevOpenState;
        if (freeSolo && didClose && filterTerm) {
            onAdd?.(filterTerm);
        }
        setPrevOpenState(open);
    }, [filterTerm, freeSolo, onAdd, open, prevOpenState]);
    useEffect(() => {
        if (filterTerm && !open && !freeSolo) {
            inputRef.current?.click();
            //Clicking the input causes the text to be highlighted in blue. setSelectionRange is used to remove the highlight
            setTimeout(() => {
                inputRef.current?.setSelectionRange(filterTerm.length, filterTerm.length);
            }, 0);
        }
    }, [filterTerm]);
    // When in freeSolo mode, add the filter term if it ends with a ', '
    useEffect(() => {
        if (freeSolo && filterTerm?.endsWith(', ')) {
            onAdd?.(filterTerm.slice(0, -2));
        }
    }, [filterTerm, freeSolo, onAdd, setFilterTerm]);
    // Reset deletion mode when the dropdown closes
    useEffect(() => {
        const didClose = !open && prevOpenState;
        if (didClose) {
            setTagIndexFocused(null);
        }
    }, [open, prevOpenState]);
    const selectedValues = selected;
    useEffect(() => {
        if (clampValues) {
            inputRef.current?.focus();
        }
    }, []);
    useEffect(() => {
        if (clampValues && ref && !open) {
            setTimeout(() => {
                //eslint-disable-next-line
                //@ts-ignore
                ref?.current?.scrollTo({
                    top: 0,
                    behavior: 'instant'
                });
            }, 0);
            inputRef.current?.focus();
        }
    }, [open, clampValues, ref]);
    const showHiddenElementCount = !open && clampValues && hiddenElementsCount > 0;
    return (_jsxs(Popover.Button, { "data-open": open, onClick: (e) => {
            if (filterable) {
                if (tagIndexFocused !== null) {
                    setTagIndexFocused(null);
                }
                inputRef.current?.focus();
                setTimeout(() => {
                    inputRef.current?.select();
                }, 0);
            }
            if (open) {
                e.stopPropagation();
                e.preventDefault();
            }
        }, disabled: disabled, "data-testid": dataTestId, ref: ref, className: classNames(
        // Base classes
        'w-full flex border-[1.5px] border-solid rounded-br30 bg-surface-default', size !== 'small' && 'px-p50 py-p40', 'typography-label-default-default text-input-value-default hover:text-input-value-hover cursor-pointer justify-between', !borderOverrideClasses &&
            classNames('border-int-outline-secondary-enabled', 
            // Hover state
            'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover', 
            // Active state
            'active:border-int-outline-secondary-pressed', 
            // Focus state
            'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled', 'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled', 
            // Disabled state
            'disabled:border-int-outline-secondary-disabled'), 
        // Hover state
        'hover:text-input-value-hover', 
        // Active state
        'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed', 
        // Focus state
        'focus-visible:bg-surface-default', 'data-[open=true]:bg-surface-default', 
        // Disabled state
        'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled', clampValues && open && 'max-h-[100px] overflow-y-auto border-box', clampValues && !open && 'max-h-[38px] overflow-y-hidden', {
            'caret-transparent': tagIndexFocused !== null,
            'items-center': !minHeight && !clampValues,
            'py-p50': size !== 'small' && minHeight
        }, borderOverrideClasses, size === 'small' && 'p-p20'), style: { minHeight }, children: [_jsxs("div", { className: classNames('flex flex-wrap gap-g20 w-full relative', !open && clampValues && 'gap-y-g40 pr-[40px]', filterable && 'cursor-text', clampValues && 'truncate', size === 'small' && 'flex-nowrap'), ref: containerRef, children: [labelPrefix && (_jsx("p", { className: "typography-label-default-default text-content-weak", children: labelPrefix })), selectedValues?.map((selectedItem, index) => {
                        return (_jsx(Tag, { onRemove: onRemove ? () => onRemove(selectedItem) : undefined, label: getLabelFromValue?.(selectedItem) || selectedItem, color: getTagColorFromValue?.(selectedItem), leadingIcon: getTagIconFromValue?.(selectedItem), intensity: tagIndexFocused !== null && index === tagIndexFocused
                                ? 'highlight'
                                : 'subtle', dataTestId: "MultiSelect-Value", truncate: true, size: "compact" }, selectedItem));
                    }), showHiddenElementCount && (_jsx("div", { className: "absolute left-0 top-0", style: {
                            transform: `translateX(${lastElementPosition}px)`
                        }, children: _jsx(Tag, { label: `+${hiddenElementsCount}`, size: "compact", intensity: "subtle" }) })), (!selected || selected.length === 0) && !filterTerm && (_jsx("p", { className: classNames('typography-label-default-default text-content-weak', {
                            absolute: filterable
                        }), children: placeholder })), filterable && (_jsx("input", { "data-testid": `${dataTestId}-input`, ref: inputRef, className: classNames('flex-grow min-w-20 outline-none bg-inherit', !open && !filterTerm && 'w-0'), value: filterTerm, onKeyDown: (e) => {
                            e.stopPropagation();
                        }, onChange: (e) => {
                            setFilterTerm?.(e.target.value);
                        }, onKeyUp: (e) => {
                            if (e.key === 'Backspace') {
                                if (inDeletionMode) {
                                    setInDeletionMode(false);
                                }
                            }
                        }, onKeyDownCapture: (e) => {
                            // Shift tagIndexInDeletionMode to the previous tag if the left arrow key is pressed
                            if (e.key === 'ArrowLeft' && selected) {
                                if (tagIndexFocused !== null) {
                                    setTagIndexFocused((prev) => {
                                        if (prev === null) {
                                            return selected.length - 1;
                                        }
                                        if (prev === 0) {
                                            return prev;
                                        }
                                        return prev - 1;
                                    });
                                }
                                else {
                                    setTagIndexFocused(selected.length - 1);
                                }
                                return;
                            }
                            // Shift tagIndexInDeletionMode to the next tag if the right arrow key is pressed, or set to null if the last tag is reached
                            if (e.key === 'ArrowRight' &&
                                tagIndexFocused !== null &&
                                selected) {
                                setTagIndexFocused((prev) => {
                                    if (prev === null) {
                                        return null;
                                    }
                                    if (prev === selected.length - 1) {
                                        return null;
                                    }
                                    return prev + 1;
                                });
                                return;
                            }
                            if (e.key === 'ArrowDown') {
                                onArrowDownPress();
                            }
                            if (e.key === 'ArrowUp') {
                                onArrowUpPress();
                            }
                            // Call onRemove on the last tag when the backspace key is pressed and the filter term is empty
                            if (e.key === 'Backspace' &&
                                filterTerm === '' &&
                                selected &&
                                selected.length > 0) {
                                if (tagIndexFocused !== null && !inDeletionMode) {
                                    const isLastSelected = tagIndexFocused === selected.length - 1;
                                    onRemove?.(selected[tagIndexFocused]);
                                    setTagIndexFocused((prev) => !isLastSelected ? prev : null);
                                }
                                else {
                                    setTagIndexFocused(selected.length - 1);
                                    setInDeletionMode(true);
                                }
                                return;
                            }
                            // Enter key should add the filter term to the selected list when in freeSolo mode
                            if (e.key === 'Enter') {
                                onEnterPress();
                                return;
                            }
                            // All other keys should remove the deletion mode
                            if (tagIndexFocused !== null) {
                                setTagIndexFocused(null);
                            }
                        } }))] }), !hideChevronDown && (_jsx("div", { onClick: close, className: "w-4 flex-shrink-0", "data-testid": "multiselect-chevron-down", children: _jsx(ChevronDown, { width: 16 }) }))] }));
});
