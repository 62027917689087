import { useQuery } from '@apollo/client';
import { useFieldSpec } from 'hooks/useFieldSpec';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { debounce } from 'lodash';
import { TYPEAHEAD_SCHOOLS_QUERY } from 'queries/typeahead';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAllowedValuesFromFieldSpec } from '../utils';
export const useSchoolTypeahead = ({ entityType } = {}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const { fieldSpec } = useFieldSpec();
    const fieldSpecs = useMemo(() => {
        return fieldSpec?.filter((fieldSpec) => fieldSpec.allowed_comparators &&
            fieldSpec.allowed_comparators.length > 0);
    }, [fieldSpec]);
    const schoolListSpecName = useMemo(() => entityType === EntityListType.COMPANY_SAVED_SEARCH
        ? 'employee_education_experience_school'
        : 'person_education_experience_school', [entityType]);
    const initialOptions = useMemo(() => getAllowedValuesFromFieldSpec(fieldSpecs, schoolListSpecName), [fieldSpecs, schoolListSpecName]);
    const { data, loading } = useQuery(TYPEAHEAD_SCHOOLS_QUERY, {
        variables: { query: debouncedSearchTerm },
        skip: !debouncedSearchTerm
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetSearch = useCallback(debounce((term) => {
        setDebouncedSearchTerm(term);
    }, 300), []);
    useEffect(() => {
        return () => {
            debouncedSetSearch.cancel();
        };
    }, [debouncedSetSearch]);
    const handleSearch = useCallback((term) => {
        setSearchTerm(term);
        debouncedSetSearch(term);
    }, [debouncedSetSearch]);
    const options = useMemo(() => {
        if (!debouncedSearchTerm || loading)
            return initialOptions;
        const uniqueSchools = new Map();
        data?.getSchoolsWithTypeahead?.forEach((school) => {
            if (school?.name) {
                uniqueSchools.set(school.name.toLowerCase(), {
                    label: school.name,
                    value: school.name
                });
            }
        });
        initialOptions.forEach((option) => {
            const key = option.label.toLowerCase();
            if (!uniqueSchools.has(key) &&
                key.includes(debouncedSearchTerm.toLowerCase())) {
                uniqueSchools.set(key, option);
            }
        });
        return Array.from(uniqueSchools.values());
    }, [
        data?.getSchoolsWithTypeahead,
        loading,
        initialOptions,
        debouncedSearchTerm
    ]);
    return {
        searchTerm,
        loading,
        options,
        handleSearch
    };
};
