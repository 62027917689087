import React, { useEffect } from 'react';
import { useRef } from 'react';
import { logger, LoggerEvent } from '../utils/logger';
const useRenderPerformance = (componentName, children) => {
    const renderCount = useRef(0);
    const lastRenderTime = useRef(performance.now());
    const renderStartTime = performance.now();
    useEffect(() => {
        const currentTime = performance.now();
        const renderDuration = currentTime - renderStartTime;
        renderCount.current += 1;
        logger.sendPerformanceEvent(LoggerEvent.RENDER_COMPONENT, {
            componentName,
            renderCount: renderCount.current,
            renderDuration: renderDuration.toFixed(2),
            numChildren: React.Children.count(children)
        });
        lastRenderTime.current = currentTime;
    });
};
export default useRenderPerformance;
