const Colors = require('./primitives');
module.exports = {
  surface: {
    background: Colors.functional.grey[20],
    nested: Colors.functional.grey[10],
    default: Colors.functional.white,
    elevated: Colors.functional.grey[10],
    raised: Colors.functional.grey[20],
    scrim: Colors.transparent.darken[60],
    sentiment: {
      neutral: Colors.functional.grey[30],
      informative: Colors.functional.blue[20],
      positive: Colors.functional.green[20],
      negative: Colors.functional.red[10],
      caution: Colors.functional.orange[10],
      highlight: {
        neutral: Colors.functional.grey[80],
        informative: Colors.functional.blue[70],
        positive: Colors.functional.green[80],
        negative: Colors.functional.red[70],
        caution: Colors.functional.orange[70]
      },
      hover: {
        neutral: Colors.functional.grey[40],
        informative: Colors.functional.blue[30],
        positive: Colors.functional.green[30],
        negative: Colors.functional.red[20],
        caution: Colors.functional.orange[20]
      }
    },
    overlay: {
      lighten: Colors.transparent.lighten[70],
      darken: Colors.transparent.darken[70],
      informative: Colors.transparent.blue[20],
      positive: Colors.transparent.green[20],
      negative: Colors.transparent.red[20],
      caution: Colors.transparent.orange[20]
    },
    inverted: {
      background: Colors.functional.grey[120],
      elevated: Colors.functional.grey[110],
      raised: Colors.functional.grey[100]
    }
  },
  content: {
    standaloneGray: '#525252',
    headerGray: '#939393',
    headerGrayAlt: '#666666',
    core: '#202020',
    textfieldGray: '#B0B0B0',
    lightGray: '#717171',
    toastHeader: '#666666',
    lighterGray: '#D9D9D9',
    red: '#BF311A',
    title: Colors.functional.grey[120],
    strong: Colors.functional.grey[110],
    default: Colors.functional.grey[90],
    weak: Colors.functional.grey[70],
    muted: Colors.functional.grey[50],
    sentiment: {
      neutral: Colors.functional.grey[80],
      informative: Colors.functional.blue[70],
      positive: Colors.functional.green[80],
      negative: Colors.functional.red[70],
      caution: Colors.functional.orange[70]
    },
    onSentiment: {
      neutral: Colors.functional.grey[100],
      informative: Colors.functional.blue[90],
      positive: Colors.functional.green[100],
      negative: Colors.functional.red[90],
      caution: Colors.functional.orange[100]
    },
    inverted: {
      title: Colors.functional.grey[10],
      strong: Colors.functional.grey[20],
      default: Colors.functional.grey[50],
      weak: Colors.functional.grey[60],
      muted: Colors.functional.grey[80]
    }
  },
  border: {
    paper: '#EBEBEB',
    strong: Colors.functional.grey[40],
    DEFAULT: Colors.functional.grey[30],
    weak: Colors.functional.grey[20],
    sentiment: {
      neutral: Colors.functional.grey[70],
      informative: Colors.functional.blue[60],
      positive: Colors.functional.green[70],
      negative: Colors.functional.red[60],
      caution: Colors.functional.orange[70]
    },
    inverted: {
      strong: Colors.functional.grey[70],
      default: Colors.functional.grey[80],
      weak: Colors.functional.grey[90]
    }
  },
  int: {
    fill: {
      primary: {
        enabled: Colors.functional.grey[110],
        hover: Colors.functional.grey[120],
        pressed: Colors.functional.grey[120],
        disabled: Colors.functional.grey[60],
        onEnabled: Colors.functional.white,
        onDisabled: Colors.functional.grey[30],
        onSelected: Colors.functional.white,
        selected: {
          enabled: Colors.functional.grey[120],
          hover: Colors.functional.grey[110],
          pressed: Colors.functional.grey[120]
        }
      },
      secondary: {
        enabled: Colors.functional.grey[20],
        hover: Colors.functional.grey[30],
        pressed: Colors.functional.grey[40],
        disabled: Colors.functional.grey[10],
        onEnabled: Colors.functional.grey[110],
        onDisabled: Colors.functional.grey[70],
        onSelected: Colors.functional.white,
        selected: {
          enabled: Colors.functional.blue[60],
          hover: Colors.functional.blue[70],
          pressed: Colors.functional.blue[80]
        }
      },
      inverted: {
        enabled: Colors.functional.grey[10],
        hover: Colors.functional.grey[20],
        pressed: Colors.functional.grey[30],
        disabled: Colors.functional.grey[40],
        onEnabled: Colors.functional.grey[110],
        onDisabled: Colors.functional.grey[90],
        onSelected: Colors.functional.grey[110],
        selected: {
          enabled: Colors.functional.grey[30],
          hover: Colors.functional.grey[40],
          pressed: Colors.functional.grey[50]
        }
      },
      negative: {
        enabled: Colors.functional.red[60],
        hover: Colors.functional.red[70],
        pressed: Colors.functional.red[80],
        disabled: Colors.functional.red[40],
        onEnabled: Colors.functional.white,
        onDisabled: Colors.functional.red[20],
        onSelected: Colors.functional.white,
        selected: {
          enabled: Colors.functional.red[80],
          hover: Colors.functional.red[90],
          pressed: Colors.functional.red[100]
        }
      },
      'net-new': {
        enabled: Colors.functional.lilac[10],
        hover: Colors.functional.lilac[20],
        pressed: Colors.functional.lilac[30],
        disabled: Colors.functional.lilac[10],
        selected: {
          enabled: Colors.functional.lilac[80],
          hover: Colors.functional.lilac[70],
          pressed: Colors.functional.lilac[60]
        },
        onEnabled: Colors.functional.lilac[100],
        onDisabled: Colors.functional.lilac[40],
        onSelected: Colors.functional.lilac[10]
      },
      draft: {
        enabled: Colors.functional.orange[10],
        hover: Colors.functional.orange[20],
        pressed: Colors.functional.orange[30],
        disabled: Colors.functional.orange[10],
        selected: {
          enabled: Colors.functional.orange[80],
          hover: Colors.functional.orange[70],
          pressed: Colors.functional.orange[60]
        },
        onEnabled: Colors.functional.orange[100],
        onDisabled: Colors.functional.orange[40],
        onSelected: Colors.functional.orange[10]
      }
    },
    overlay: {
      secondary: {
        enabled: Colors.functional.transparent,
        hover: Colors.transparent.darken[20],
        pressed: Colors.transparent.darken[30],
        disabled: Colors.functional.transparent,
        onEnabled: Colors.functional.grey[80],
        onDisabled: Colors.functional.grey[60],
        onSelected: Colors.functional.grey[110],
        selected: {
          enabled: Colors.transparent.darken[20],
          hover: Colors.transparent.darken[30],
          pressed: Colors.transparent.darken[20]
        }
      },
      inverted: {
        enabled: Colors.functional.transparent,
        hover: Colors.transparent.lighten[20],
        pressed: Colors.transparent.lighten[30],
        disabled: Colors.functional.transparent,
        onEnabled: Colors.functional.grey[30],
        onDisabled: Colors.functional.grey[50],
        onSelected: Colors.functional.grey[10],
        selected: {
          enabled: Colors.transparent.lighten[40],
          hover: Colors.transparent.lighten[30],
          pressed: Colors.transparent.lighten[20]
        }
      }
    },
    outline: {
      secondary: {
        enabled: Colors.functional.grey[40],
        hover: Colors.functional.grey[50],
        pressed: Colors.functional.grey[60],
        disabled: Colors.functional.grey[30],
        onEnabled: Colors.functional.grey[110],
        onDisabled: Colors.functional.grey[90],
        contentEnabled: Colors.functional.grey[110],
        contentDisabled: Colors.functional.grey[90],
        selected: {
          enabled: Colors.functional.blue[70],
          hover: Colors.functional.blue[80],
          pressed: Colors.functional.blue[90]
        }
      },
      inverted: {
        enabled: Colors.functional.grey[60],
        hover: Colors.functional.grey[50],
        pressed: Colors.functional.grey[40],
        disabled: Colors.functional.grey[80],
        onEnabled: Colors.functional.grey[10],
        onDisabled: Colors.functional.grey[50],
        contentEnabled: Colors.functional.grey[10],
        contentDisabled: Colors.functional.grey[50],
        selected: {
          enabled: Colors.functional.grey[20],
          hover: Colors.functional.grey[30],
          pressed: Colors.functional.grey[40]
        }
      }
    }
  }
};