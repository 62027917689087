import { InternalModeType } from 'components/QueryBuilder/Common/CustomRange/DateModal';
import { dayjsExt } from 'config/dayjs';
import dayjs from 'dayjs';
import { EntityListType, FilterArrayComparator, FilterListComparator, INITIAL_SEARCH_FILTER_GROUP } from 'interfaces/SearchModel/Search';
import { BackendCustomRangeModeType, CompanyFilterTabs, CompanyFoundationDateOptions, FilterGroupGeneratorId, FilterTabToFilterGroupGeneratorId, PeopleFilterTabs, TimeUnit } from 'interfaces/SearchV2';
import { clone, cloneDeep, isNil, omit } from 'lodash';
const formatCustomRangeDateFields = [
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE,
    FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE
];
export const DEFAULT_CUSTOM_RANGE = {
    start: {
        timeUnit: TimeUnit.TODAY
    },
    end: { timeUnit: TimeUnit.MONTHS, value: 0 }
};
// Function to format CompanyDateRange type to string based date ranges
// ex: { start: { timeUnit: "TODAY" }, end: { timeUnit: "MONTHS", value: 1 }} -> ["TODAY", "1 MONTH"]
export const formatCustomRange = (customRange) => {
    if (!customRange)
        return;
    const newCustomRange = clone(customRange);
    const startTimeUnit = newCustomRange.start.timeUnit;
    const startValue = newCustomRange.start.value ?? 0;
    let start = '';
    if (startTimeUnit === TimeUnit.TODAY) {
        start = TimeUnit.TODAY;
    }
    else {
        start = `${startValue} ${startTimeUnit}`;
    }
    const endTimeUnit = newCustomRange.end.timeUnit;
    const endValue = newCustomRange.end.value ?? 0;
    let end = '';
    if (endTimeUnit === TimeUnit.TODAY) {
        end = TimeUnit.TODAY;
    }
    else {
        end = `${endValue} ${endTimeUnit}`;
    }
    return [start, end];
};
// Function to format string based date ranges to the CompanyDateRange type
// ex: ["TODAY", "1 MONTH"] -> { start: { timeUnit: "TODAY" }, end: { timeUnit: "MONTHS", value: 1 }}
export const parseCustomRange = (customRange) => {
    const [start, end] = customRange;
    const startSplit = start.split(' ');
    const endSplit = end.split(' ');
    const formattedCustomDateRange = cloneDeep(DEFAULT_CUSTOM_RANGE);
    if (startSplit.length == 1) {
        formattedCustomDateRange.start.timeUnit = startSplit[0];
        if (startSplit[0] !== TimeUnit.TODAY) {
            throw new Error('Invalid start date format');
        }
    }
    else {
        formattedCustomDateRange.start.timeUnit = startSplit[1];
        formattedCustomDateRange.start.value = parseInt(startSplit[0]);
    }
    if (endSplit.length == 1) {
        formattedCustomDateRange.end.timeUnit = endSplit[0];
        if (endSplit[0] !== TimeUnit.TODAY) {
            throw new Error('Invalid end date format');
        }
    }
    else {
        formattedCustomDateRange.end.timeUnit = endSplit[1];
        formattedCustomDateRange.end.value = parseInt(endSplit[0]);
    }
    return formattedCustomDateRange;
};
export const getInternalModeFromBackendMode = (mode, value) => {
    if (mode === BackendCustomRangeModeType.CUSTOM_ROLLING) {
        return InternalModeType.ROLLING_RANGE;
    }
    // Custom mode. We determine whether it is before, after or range
    // [null, '2023-01-01'] -> before
    if (isNil(value?.[0])) {
        return InternalModeType.BEFORE;
    }
    // ['2023-01-01', null] -> after
    if (isNil(value?.[1])) {
        return InternalModeType.AFTER;
    }
    // check if its 1st jan and 31 dec of the same year
    const startYear = dayjsExt(value?.[0]).year();
    const endYear = dayjsExt(value?.[1]).year();
    if (startYear === endYear &&
        value?.[0]?.endsWith('-01-01') &&
        value?.[1]?.endsWith('-12-31'))
        return InternalModeType.IN;
    return InternalModeType.RANGE;
};
export const formatYearRangeByMode = (defaultLabel, mode, value) => {
    if (isNil(value) || isNil(mode))
        return defaultLabel;
    const internalMode = getInternalModeFromBackendMode(mode, value);
    if (internalMode === InternalModeType.BEFORE)
        return `is in or before ${dayjsExt(value?.[1]).format('YYYY')}`;
    if (internalMode === InternalModeType.AFTER)
        return `is in or after ${dayjsExt(value?.[0]).format('YYYY')}`;
    if (internalMode === InternalModeType.RANGE)
        return `is between ${dayjsExt(value?.[0]).format('YYYY')} and ${dayjsExt(value?.[1]).format('YYYY')}`;
    if (internalMode === InternalModeType.IN)
        return `is in ${dayjsExt(value?.[0]).format('YYYY')}`;
    if (internalMode === InternalModeType.ROLLING_RANGE) {
        const startSplit = value?.[0]?.split(' ');
        const endSplit = value?.[1]?.split(' ');
        const startValue = startSplit?.length == 2 ? startSplit?.[0] : '0';
        const endValue = endSplit?.length == 2 ? endSplit?.[0] : '0';
        let startUnit = startSplit?.length == 2
            ? startSplit?.[1].toLowerCase()
            : startSplit?.[0].toLowerCase();
        let endUnit = endSplit?.length == 2
            ? endSplit?.[1].toLowerCase()
            : endSplit?.[0].toLowerCase();
        if (parseInt(startValue) < 2 && startUnit?.[startUnit.length - 1] === 's')
            startUnit = startUnit?.slice(0, -1);
        if (parseInt(endValue) < 2 && endUnit?.[endUnit.length - 1] === 's')
            endUnit = endUnit?.slice(0, -1);
        const startStr = startUnit === 'today' ? 'today' : `${startValue} ${startUnit}`;
        const endStr = endUnit === 'today' ? 'today' : `${endValue} ${endUnit}`;
        return `is between ${startStr} and ${endStr}${endStr !== 'today' ? ' ago' : ''}`;
    }
    return '';
};
export const formatDateRangeByMode = (defaultLabel, mode, value) => {
    if (isNil(value) || isNil(mode))
        return defaultLabel;
    const internalMode = getInternalModeFromBackendMode(mode, value);
    if (internalMode === InternalModeType.BEFORE)
        return `is on or before ${dayjs(value?.[1]).format('MMM DD, YYYY')}`;
    if (internalMode === InternalModeType.AFTER)
        return `is on or after ${dayjs(value?.[0]).format('MMM DD, YYYY')}`;
    if (internalMode === InternalModeType.RANGE)
        return `is between ${dayjs(value?.[0]).format('MMM DD, YYYY')} and ${dayjs(value?.[1]).format('MMM DD, YYYY')}`;
    if (internalMode === InternalModeType.ROLLING_RANGE) {
        const startSplit = value?.[0]?.split(' ');
        const endSplit = value?.[1]?.split(' ');
        const startValue = startSplit?.length == 2 ? startSplit?.[0] : '0';
        const endValue = endSplit?.length == 2 ? endSplit?.[0] : '0';
        let startUnit = startSplit?.length == 2
            ? startSplit?.[1].toLowerCase()
            : startSplit?.[0].toLowerCase();
        let endUnit = endSplit?.length == 2
            ? endSplit?.[1].toLowerCase()
            : endSplit?.[0].toLowerCase();
        if (parseInt(startValue) < 2 && startUnit?.[startUnit.length - 1] === 's')
            startUnit = startUnit?.slice(0, -1);
        if (parseInt(endValue) < 2 && endUnit?.[endUnit.length - 1] === 's')
            endUnit = endUnit?.slice(0, -1);
        const startStr = startUnit === 'today' ? 'today' : `${startValue} ${startUnit}`;
        const endStr = endUnit === 'today' ? 'today' : `${endValue} ${endUnit}`;
        return `is between ${startStr} and ${endStr}${endStr !== 'today' ? ' ago' : ''}`;
    }
    return '';
};
export const isCustomRangeMode = (value) => {
    if (isNil(value))
        return false;
    if ([
        BackendCustomRangeModeType.CUSTOM,
        BackendCustomRangeModeType.CUSTOM_ROLLING
    ].includes(value))
        return true;
    return false;
};
export const parseTenureValue = (value) => {
    if (value === TimeUnit.INFINITY)
        return { value: 0, timeUnit: TimeUnit.INFINITY };
    const splittedValue = value.split(' ');
    if (splittedValue.length !== 2)
        throw new Error('Invalid tenure value');
    return {
        value: parseInt(splittedValue[0]),
        timeUnit: splittedValue[1]
    };
};
export const formatTenureValue = (value) => {
    return `${value.value} ${value.timeUnit}`;
};
export const convertSearchDataToFilterGroupGenerator = (searchData) => {
    const filterGenerators = [];
    const filteredSearchData = omit(searchData, 'customBuilderFilterGroup');
    for (const [key, value] of Object.entries(filteredSearchData)) {
        const filterGroupGeneratorId = key;
        let newValue = value;
        if (formatCustomRangeDateFields.includes(filterGroupGeneratorId) &&
            filterGroupGeneratorId ===
                FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE) {
            const clonedNewValue = cloneDeep(value);
            if (clonedNewValue.custom_range &&
                clonedNewValue.range_value === CompanyFoundationDateOptions.CUSTOM) {
                clonedNewValue.custom_range = formatCustomRange(clonedNewValue.custom_range);
                newValue = clonedNewValue;
            }
        }
        filterGenerators.push({
            generator_id: key,
            arguments: newValue
        });
    }
    return filterGenerators;
};
const getCountOfFiltersInFilterGroup = (filterGroup) => {
    let count = 0;
    filterGroup?.filters?.forEach(() => (count += 1));
    filterGroup?.filter_groups?.forEach((fGroup) => (count += getCountOfFiltersInFilterGroup(fGroup)));
    return count;
};
// Include and exclude should be counted as same. Hence separate function for that edge case
const getSectorTabCount = (generatorIds) => {
    let count = 0;
    const bothBusinessTagsExist = generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS) &&
        generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS);
    const bothTechnologyTagsExist = generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS) &&
        generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS);
    const bothIndustryTagsExist = generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS) &&
        generatorIds.includes(FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS);
    generatorIds.forEach((generator_id) => {
        if (!FilterTabToFilterGroupGeneratorId[CompanyFilterTabs.PRODUCT].includes(generator_id))
            return;
        count += 1;
    });
    if (bothBusinessTagsExist)
        count -= 1;
    if (bothTechnologyTagsExist)
        count -= 1;
    if (bothIndustryTagsExist)
        count -= 1;
    return count;
};
const getEducationTabCount = (educationParameters) => {
    if (!educationParameters)
        return 0;
    const { degrees, schools, start_date, end_date } = educationParameters;
    let count = 0;
    if (schools && schools.length)
        count += 1;
    if (degrees && degrees.length)
        count += 1;
    if (start_date)
        count += 1;
    if (end_date)
        count += 1;
    return count;
};
const roleFieldsOrder = [
    ['role_title_contains_all_of'],
    ['role_title_contains_none_of'],
    ['role_department_any_of', 'role_department_none_of'],
    ['role_type_any_of', 'role_type_none_of']
];
const currentOrPastCompanyFieldsOrder = [
    ['company_id_any_of'],
    ['company_initialized_date_range_value'],
    ['company_funding_range'],
    ['company_headcount_range'],
    ['company_investor_names_any_of'],
    ['company_in_watchlist_ids_any_of', 'company_in_watchlist_ids_none_of'],
    [
        'company_keywords_contains_any_of',
        'company_keywords_contains_all_of',
        'company_keywords_contains_none_of'
    ],
    ['company_last_funding_date_range_value'],
    ['company_founding_date_range_value'],
    [
        'company_industry_tags_include_any_of',
        'company_industry_tags_exclude_all_of'
    ],
    [
        'company_technology_tags_include_any_of',
        'company_technology_tags_exclude_all_of'
    ],
    [
        'company_customer_type_tags_include_any_of',
        'company_customer_type_tags_exclude_all_of'
    ]
];
// Returns the key of fields that are expanded in particular order.
// Array order determines the order they show up on search
export const getExpandedFieldsOrderForCurrentOrPastExperienceGenerator = (mode, searchState) => {
    if (isNil(searchState)) {
        if (mode === 'current')
            return ['role_tenure_value'];
        return ['role_tenure_funding_stage_any_of'];
    }
    const expandedFields = [];
    // ROLE FIELDS
    const roleAndCompanyCombinedOrder = [
        ...roleFieldsOrder,
        ...currentOrPastCompanyFieldsOrder
    ];
    for (const fields of roleAndCompanyCombinedOrder) {
        // Fields is array to handle include and exclude. If either include or exclude is set, we add that field to expand list.
        if (fields.some((field) => searchState[field]))
            expandedFields.push(fields[0]);
    }
    // TENURE FIELDS
    // Tenure is always first for current exp, followed by funding stage
    if (mode === 'current') {
        expandedFields.push('role_tenure_value');
        if (searchState.role_tenure_funding_stage_any_of)
            expandedFields.push('role_tenure_funding_stage_any_of');
    }
    // Funding stage is always first for past exp, followed by tenure
    if (mode === 'past') {
        expandedFields.push('role_tenure_funding_stage_any_of');
        if (searchState.role_tenure_value)
            expandedFields.push('role_tenure_value');
    }
    if (searchState.role_start_date_value || searchState.role_end_date_value) {
        // Start date value field triggers the code to show the employment timeline
        expandedFields.push('role_start_date_value');
    }
    return expandedFields;
};
const getExperienceTabCount = (experienceArguments) => {
    if (!experienceArguments)
        return 0;
    const ignoredFields = [
        'current_or_past',
        'role_tenure_comparator',
        'role_start_date_mode',
        'role_end_date_mode',
        'company_initialized_date_custom_range',
        'company_founding_date_custom_range',
        'company_last_funding_date_custom_range',
        'company_id_any_of',
        'select_stealth_companies'
    ];
    let numFilters = 0;
    for (const key of Object.keys(experienceArguments)) {
        // skip ignored fields
        if (!ignoredFields.includes(key))
            numFilters += 1;
    }
    if (experienceArguments.company_id_any_of ||
        experienceArguments.select_stealth_companies) {
        numFilters += 1;
    }
    return numFilters;
};
function getArgumentsByGeneratorId(generatorId, controlledFilterGroup) {
    const filterGenerator = controlledFilterGroup?.filter_group_generators?.find(({ generator_id }) => generator_id === generatorId);
    return filterGenerator?.arguments;
}
export const getActiveFilterCountByTab = (searchQuery) => {
    const controlledFilterGroup = searchQuery.controlled_filter_group;
    const customFilterGroup = searchQuery.filter_group;
    const educationTabArguments = getArgumentsByGeneratorId(FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION, controlledFilterGroup);
    const currentExperienceTabArguments = getArgumentsByGeneratorId(FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE, controlledFilterGroup);
    const pastExperienceTabArguments = getArgumentsByGeneratorId(FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE, controlledFilterGroup);
    const allGeneratorIds = controlledFilterGroup.filter_group_generators?.map(({ generator_id }) => generator_id) || [];
    const generatorCountByTab = {
        [CompanyFilterTabs.GENERAL]: 0,
        [CompanyFilterTabs.FUNDING]: 0,
        [CompanyFilterTabs.TEAM]: 0,
        [CompanyFilterTabs.PRODUCT]: getSectorTabCount(allGeneratorIds),
        [CompanyFilterTabs.CUSTOM]: getCountOfFiltersInFilterGroup(customFilterGroup),
        [PeopleFilterTabs.FILTER]: getCountOfFiltersInFilterGroup(customFilterGroup),
        [PeopleFilterTabs.CURRENT_EXP]: getExperienceTabCount(currentExperienceTabArguments),
        [PeopleFilterTabs.PAST_EXP]: getExperienceTabCount(pastExperienceTabArguments),
        [PeopleFilterTabs.EDUCATION]: getEducationTabCount(educationTabArguments),
        [PeopleFilterTabs.GENERAL]: 0,
        [PeopleFilterTabs.CUSTOM]: getCountOfFiltersInFilterGroup(customFilterGroup)
    };
    // These are the tabs that are counted in a different way due to their implementation
    const excludedTabs = [
        CompanyFilterTabs.PRODUCT,
        PeopleFilterTabs.EDUCATION,
        PeopleFilterTabs.CURRENT_EXP,
        PeopleFilterTabs.PAST_EXP
    ];
    // Reverse mapping of FilterTabToFilterGroupGeneratorId
    // so we can quickly find the tabs a generator belongs to
    const tabsByGenerators = {};
    Object.entries(FilterTabToFilterGroupGeneratorId).forEach(([key, generatorIds]) => {
        const tab = key;
        if (excludedTabs.includes(tab))
            return;
        generatorIds.forEach((generatorId) => {
            if (!tabsByGenerators[generatorId]) {
                tabsByGenerators[generatorId] = [];
            }
            tabsByGenerators[generatorId].push(tab);
        });
    });
    allGeneratorIds?.forEach((generator_id) => {
        const tabs = tabsByGenerators[generator_id];
        tabs?.forEach((tab) => {
            generatorCountByTab[tab] += 1;
        });
    });
    return generatorCountByTab;
};
export const getFilterGroupGeneratorsMap = (filterGroup) => {
    const generatorIdToIndexMap = {};
    filterGroup?.filter_group_generators?.forEach((generator) => {
        //eslint-disable-next-line
        //@ts-ignore
        generatorIdToIndexMap[generator.generator_id] = generator.arguments;
    });
    return generatorIdToIndexMap;
};
// Updates the filter group generator of existing query with the param passed
// and returns new query
export const getUpdatedQuery = (filterGroupQuery, generatorId, args) => {
    const clonedFilterGroupQuery = clone(filterGroupQuery);
    const controlledFilterGroup = clonedFilterGroupQuery.controlled_filter_group;
    if (!controlledFilterGroup.filter_group_generators)
        controlledFilterGroup.filter_group_generators = [];
    if (args === undefined) {
        controlledFilterGroup.filter_group_generators =
            controlledFilterGroup.filter_group_generators?.filter((generator) => generator.generator_id !== generatorId);
        return clonedFilterGroupQuery;
    }
    const generatorIndex = controlledFilterGroup.filter_group_generators?.findIndex((generator) => generator.generator_id === generatorId);
    if (generatorIndex !== -1)
        controlledFilterGroup.filter_group_generators[generatorIndex].arguments =
            args;
    else
        controlledFilterGroup.filter_group_generators.push({
            generator_id: generatorId,
            arguments: args
        });
    return clonedFilterGroupQuery;
};
// Resets all the filters related to a particular tab
export const resetGeneratorsFromATab = (filterGroupQuery, tab) => {
    const clonedFilterGroupQuery = clone(filterGroupQuery);
    const currentTabGenerator = FilterTabToFilterGroupGeneratorId[tab];
    if (clonedFilterGroupQuery.controlled_filter_group &&
        tab !== CompanyFilterTabs.CUSTOM)
        clonedFilterGroupQuery.controlled_filter_group.filter_group_generators =
            clonedFilterGroupQuery.controlled_filter_group.filter_group_generators?.filter(({ generator_id }) => !currentTabGenerator.includes(generator_id)) ?? [];
    if (tab === CompanyFilterTabs.CUSTOM ||
        tab === PeopleFilterTabs.FILTER ||
        tab === PeopleFilterTabs.CUSTOM)
        clonedFilterGroupQuery.filter_group = INITIAL_SEARCH_FILTER_GROUP;
    return clonedFilterGroupQuery;
};
export const generateCompanyInListFilterGroup = (watchlistId) => ({
    join_operator: 'and',
    filter_groups: [],
    filters: [
        {
            field: 'company_collection_id',
            comparator: FilterArrayComparator.ANY_OF_ARRAY,
            filter_value: [watchlistId]
        }
    ]
});
export const generatePeopleInListFilterGroup = (watchlistId) => ({
    join_operator: 'and',
    filter_groups: [],
    filters: [
        {
            field: 'person_name_id',
            comparator: FilterListComparator.IS_IN_LIST,
            filter_value: [watchlistId]
        }
    ]
});
export const FILTER_TABS_MAPPING = {
    [EntityListType.COMPANY_SAVED_SEARCH]: [
        CompanyFilterTabs.GENERAL,
        CompanyFilterTabs.FUNDING,
        CompanyFilterTabs.TEAM,
        CompanyFilterTabs.PRODUCT,
        CompanyFilterTabs.CUSTOM
    ],
    [EntityListType.PEOPLE_SAVED_SEARCH]: [
        PeopleFilterTabs.GENERAL,
        PeopleFilterTabs.CURRENT_EXP,
        PeopleFilterTabs.PAST_EXP,
        PeopleFilterTabs.EDUCATION,
        PeopleFilterTabs.CUSTOM
    ],
    [EntityListType.COMPANY_WATCHLIST]: [CompanyFilterTabs.CUSTOM],
    [EntityListType.PEOPLE_WATCHLIST]: [PeopleFilterTabs.CUSTOM]
};
