import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import MyNetworkList from 'assets/harmonic-icons/my-network-list';
import NotInNetwork from 'assets/harmonic-icons/not-in-network';
import TeamNetworkList from 'assets/harmonic-icons/team-network-list';
import RadioPill from 'harmonic-components/RadioPill/RadioPill';
import useFlags from 'hooks/useFlags';
import { FilterGroupGeneratorId } from 'interfaces/SearchV2';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { getFilterGroupGeneratorsMap, getUpdatedQuery } from 'utils/searchV2/searchV2';
import { ConnectionsBanner } from '../Common/ConnectionsBanner';
import CorrespondencesSubSection from '../Common/CorrespondencesSubSection';
import { SubSectionHeader } from '../Common/Typography';
const GeneralConnectionsSubSection = ({ searchQuery, onUpdateSearchQuery, disabled }) => {
    const controlledSearchState = getFilterGroupGeneratorsMap(searchQuery?.controlled_filter_group);
    const { enabled: correspondencesEnabled } = useFlags(SPLITS.showCorrespondencesInSearch);
    const connections = controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS];
    const { data } = useQuery(GET_PEOPLE_WATCHLISTS, {
        fetchPolicy: 'cache-first'
    });
    const userConnectionsList = data?.getPeopleWatchlistsForTeam?.find((wl) => wl?.userWatchlistType === 'USER_CONNECTIONS');
    const teamConnectionsList = data?.getPeopleWatchlistsForTeam?.find((wl) => wl?.userWatchlistType === 'TEAM_CONNECTIONS');
    const myConnectionsSelected = connections?.in_lists?.includes(userConnectionsList?.id);
    const teamConnectionsSelected = connections?.in_lists?.includes(teamConnectionsList?.id);
    const notConnectedSelected = connections?.not_in_lists?.includes(userConnectionsList?.id) &&
        connections?.not_in_lists?.includes(teamConnectionsList?.id);
    const anySelected = !connections?.in_lists?.includes(userConnectionsList?.id) &&
        !connections?.in_lists?.includes(teamConnectionsList?.id) &&
        !notConnectedSelected;
    return (_jsx("div", { "data-testid": "Connections-section", className: "w-full", children: _jsxs("div", { className: "flex flex-col gap-g50", children: [_jsx("div", { className: "mb-g80", children: _jsx(ConnectionsBanner, {}) }), _jsx(SubSectionHeader, { children: "Network" }), _jsxs("div", { className: "flex items-start gap-g40 self-stretch", children: [_jsx(RadioPill, { dataTestId: "Any-Connections-Radio-Pill", onClick: () => {
                                onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS, undefined));
                            }, label: "Any", selected: anySelected }), _jsx(RadioPill, { dataTestId: "My-Connections-Radio-Pill", onClick: () => {
                                onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS, {
                                    in_lists: [userConnectionsList?.id],
                                    not_in_lists: []
                                }));
                            }, label: "In my network", leadingIcon: MyNetworkList, selected: myConnectionsSelected ?? false, disabled: !userConnectionsList?.id }), _jsx(RadioPill, { dataTestId: "Team-Connections-Radio-Pill", onClick: () => {
                                onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS, {
                                    in_lists: [teamConnectionsList?.id],
                                    not_in_lists: []
                                }));
                            }, label: "In team network", leadingIcon: TeamNetworkList, selected: teamConnectionsSelected ?? false, disabled: !teamConnectionsList?.id }), _jsx(RadioPill, { dataTestId: "Not-Connected-Radio-Pill", onClick: () => {
                                onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS, {
                                    in_lists: [],
                                    not_in_lists: [
                                        userConnectionsList?.id,
                                        teamConnectionsList?.id
                                    ]
                                }));
                            }, label: "Not in network", leadingIcon: NotInNetwork, selected: notConnectedSelected ?? false, disabled: !userConnectionsList?.id || !teamConnectionsList?.id })] }), correspondencesEnabled && (_jsx(CorrespondencesSubSection, { addTopMarginOnFieldOpen: true, generatorArgs: controlledSearchState?.[FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES], onUpdateGeneratorArgs: (newArgs) => {
                        onUpdateSearchQuery(getUpdatedQuery(searchQuery, FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES, newArgs));
                    } }))] }) }));
};
export default GeneralConnectionsSubSection;
