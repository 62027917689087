import { GET_PEOPLE_WATCHLIST } from 'queries/getPeopleWatchlist';
import { useBaseFetchSingleWatchlist } from './useBaseFetchSingleWatchlist';
import { DashboardLocation } from './useDashboardLocation';
const useFetchSinglePeopleWatchlist = ({ fetchPolicy = 'cache-first', skipLocalSearch = false } = {}) => {
    const { data, loading, error } = useBaseFetchSingleWatchlist({
        fetchPolicy,
        query: GET_PEOPLE_WATCHLIST,
        location: DashboardLocation.PERSON_LIST,
        transformData: (data) => data?.getPeopleWatchlistByIdOrUrn,
        skipLocalSearch
    });
    return {
        peopleWatchlist: data,
        peopleWatchlistLoading: loading,
        peopleWatchlistError: error
    };
};
export default useFetchSinglePeopleWatchlist;
