import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import GreyLinearProgress from 'components/common/GreyLinearProgress';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import { compact, toString } from 'lodash';
import { GET_PEOPLE_NAMES_BY_IDS } from 'queries/getPeopleNames';
import { TYPEAHEAD_PEOPLE_QUERY } from 'queries/typeahead';
import { useState } from 'react';
const PersonMultiSelectById = ({ onChange, selected, labelPrefix, initialFocus }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { data: peopleData, loading } = useQuery(TYPEAHEAD_PEOPLE_QUERY, { variables: { query: searchTerm } });
    const { data: peopleNameData } = useQuery(GET_PEOPLE_NAMES_BY_IDS, {
        variables: {
            ids: compact(selected.map((person) => person.id))
        }
    });
    const onClickListItem = (person) => {
        const selectedPerson = {
            name: person.fullName ?? '',
            id: person.id
        };
        const currentOptionIndex = selected.findIndex((option) => option.id
            ? option.id === selectedPerson.id
            : option.name === selectedPerson.name);
        if (currentOptionIndex === -1) {
            onChange([...selected, selectedPerson]);
        }
        setSearchTerm('');
    };
    const onRemoveItem = (companyId) => {
        const updatedSelectedOptions = selected.filter((option) => option.id?.toString() !== companyId);
        onChange(updatedSelectedOptions);
    };
    const selectedIds = selected.map((option) => option?.id?.toString() ?? '');
    return (_jsx("div", { className: "min-w-56 w-full", children: _jsxs(Select, { multiple: true, filterable: true, fullWidth: true, filterTerm: searchTerm, onFilterTermChange: setSearchTerm, selected: selectedIds, labelPrefix: labelPrefix, placeholder: "Search people", getLabelFromValue: (value) => {
                return (peopleNameData?.getPersonsByIds?.find((person) => toString(person?.id) === value)?.fullName ?? '');
            }, initialFocus: initialFocus, onRemove: (company) => onRemoveItem(company), hideDropdown: !loading &&
                (Boolean(peopleData?.getPeopleWithTypeahead) === false ||
                    peopleData?.getPeopleWithTypeahead?.length === 0), children: [loading && (_jsx("div", { className: "h-p20 w-full", children: _jsx(GreyLinearProgress, {}) })), !loading &&
                    peopleData?.getPeopleWithTypeahead?.map((person) => {
                        if (!person)
                            return null;
                        return (_jsx(SelectListItem, { value: person.fullName ?? '', label: person.fullName ?? '', logo: "logo", src: person.profilePictureUrl ?? '', onClick: () => onClickListItem(person), variant: ListVariant.entity }, person.id));
                    })] }) }));
};
export default PersonMultiSelectById;
